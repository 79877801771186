import { Button, DatePicker, Form, InputNumber, Popconfirm, Select, Space, Table, Tag, Tooltip, Typography } from "antd";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useState } from "react";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import './Anggaran.css';
import dayjs from "dayjs";
import ImportAnggaran from "../import/ImportAnggaran";
import { useAnggaranUnitPagination } from "hooks/finance-report-hook/useAnggaranUnitPagination";
import { decryptCookies } from "components/Helper/CookiesHelper";
import axios from "axios";
import { DeleteApi } from "api/DeleteApi";
import { useKodeKeuanganPagination } from "hooks/finance-report-hook/useKodeKeuanganPagination";
import { numberFormat } from "pages/si-finance-report/utlis/utlis";
const { Text } = Typography;

const disabled = { color: "#ccc", cursor: "not-allowed" };

const Anggaran = () => {
  const [showImport, setShowImport] = useState(false);
  const [foundationId, setFoundationId] = useState("");
  const [year, setYear] = useState(dayjs());
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });
  const [unitId, setUnitId] = useState("");
  const [newData, setNewData] = useState({});
  const [form] = Form.useForm();

  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  const { REACT_APP_HOST_API: url } = process.env;


  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  const {
    data: dataAnggaran,
    isLoading,
    isFetching,
    refetch,
  } = useAnggaranUnitPagination(dataTable, foundationId, dayjs(year).year(), unitId);

  const {
    data: dataKodeUnit,
    isLoading: isLoadingKodeUnit,
    isFetching: isFetchingKodeUnit,
  } = useKodeKeuanganPagination(
    {
      current_page: 1,
      per_page: 1000,
      total: 0,
    },
    "",
    foundationId,
    "unit");

  // fetch permission
  const { data: dataPermission } = usePermission("mdlFinance", "mnuAnggaran");

  const onImport = useCallback(() => {
    setShowImport(false);
    refetch();
  }, [refetch]);

  const onCancel = () => {
    setShowImport(false);
  };


  const btnImport = dataPermission?.find((x) => x.id === "btnImportAnggaran");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteAnggaran");
  const btnUpdate = dataPermission?.find((x) => x.id === "btnUpdateAnggaran");

  const dataSource = dataAnggaran?.dataObject?.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        key: data.id,
        index: index + 1,
        code: `${data.financeCode.code} - ${data.financeCode.name}`,
        ...data.amounts,
        total: data.totalAmount,
        id: data.id
      };
    });

  const onUpdateBudget = async (x) => {
    if (Object.keys(newData).length === 0) return;

    for (const key in newData) {
      if (Object.hasOwnProperty.call(newData, key)) {
        const element = newData[key];
        if (element === "") newData[key] = null;
      }
    }

    try {
      await axios.put(
        url + `/financial-report/budget-units/${x.id}`,
        {
          amounts: newData
        },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
    } catch (error) {
      alert(error?.response?.data?.message);
    }
    setNewData({});
  };



  const columns = [
    {
      title: "No",
      dataIndex: "index",
      width: 70,
      fixed: "left",
    },
    { title: "Unit", dataIndex: "code", fixed: "left", width: 250, },
    {
      title: "Januari",
      dataIndex: "januari",
      width: 350,
      render: (amount, data) => {
        return <Form.Item>
          <InputNumber
            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
            style={{ width: "100%" }}
            defaultValue={amount}
            onChange={(x) => {
              setNewData({
                ...newData,
                januari: x,
              })
            }
            }
            onBlur={() => onUpdateBudget(data)}
            onPressEnter={() => onUpdateBudget(data)}
            size="small"
          />
        </Form.Item>
      },
    },
    {
      title: "Februari",
      dataIndex: "februari",
      width: 350,
      render: (amount, data) => {
        return <Form.Item>
          <InputNumber
            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
            style={{ width: "100%" }}
            defaultValue={amount}
            onChange={(x) => {
              setNewData({
                ...newData,
                februari: x,
              })
            }
            }
            onBlur={() => onUpdateBudget(data)}
            onPressEnter={() => onUpdateBudget(data)}
            size="small"
          />
        </Form.Item>
      },
    },
    {
      title: "Maret",
      dataIndex: "maret",
      width: 350,
      render: (amount, data) => {
        return <Form.Item>
          <InputNumber
            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
            style={{ width: "100%" }}
            defaultValue={amount}
            onChange={(x) => {
              setNewData({
                ...newData,
                maret: x,
              })
            }
            }
            onBlur={() => onUpdateBudget(data)}
            onPressEnter={() => onUpdateBudget(data)}
            size="small"
          />
        </Form.Item>
      },
    },
    {
      title: "April",
      dataIndex: "april",
      width: 350,
      render: (amount, data) => {
        return <Form.Item>
          <InputNumber
            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
            style={{ width: "100%" }}
            defaultValue={amount}
            onChange={(x) => {
              setNewData({
                ...newData,
                april: x,
              })
            }
            }
            onBlur={() => onUpdateBudget(data)}
            onPressEnter={() => onUpdateBudget(data)}
            size="small"
          />
        </Form.Item>
      },
    },
    {
      title: "Mei",
      dataIndex: "mei",
      width: 350,
      render: (amount, data) => {
        return <Form.Item>
          <InputNumber
            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
            style={{ width: "100%" }}
            defaultValue={amount}
            onChange={(x) => {
              setNewData({
                ...newData,
                mei: x,
              })
            }
            }
            onBlur={() => onUpdateBudget(data)}
            onPressEnter={() => onUpdateBudget(data)}
            size="small"
          />
        </Form.Item>
      },
    },
    {
      title: "Juni",
      dataIndex: "juni",
      width: 350,
      render: (amount, data) => {
        return <Form.Item>
          <InputNumber
            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
            style={{ width: "100%" }}
            defaultValue={amount}
            onChange={(x) => {
              setNewData({
                ...newData,
                juni: x,
              })
            }
            }
            onBlur={() => onUpdateBudget(data)}
            onPressEnter={() => onUpdateBudget(data)}
            size="small"
          />
        </Form.Item>
      },
    },
    {
      title: "Juli",
      dataIndex: "juli",
      width: 350,
      render: (amount, data) => {
        return <Form.Item>
          <InputNumber
            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
            style={{ width: "100%" }}
            defaultValue={amount}
            onChange={(x) => {
              setNewData({
                ...newData,
                juli: x,
              })
            }
            }
            onBlur={() => onUpdateBudget(data)}
            onPressEnter={() => onUpdateBudget(data)}
            size="small"
          />
        </Form.Item>
      },
    },
    {
      title: "Agustus",
      dataIndex: "agustus",
      width: 350,
      render: (amount, data) => {
        return <Form.Item>
          <InputNumber
            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
            style={{ width: "100%" }}
            defaultValue={amount}
            onChange={(x) => {
              setNewData({
                ...newData,
                agustus: x,
              })
            }
            }
            onBlur={() => onUpdateBudget(data)}
            onPressEnter={() => onUpdateBudget(data)}
            size="small"
          />
        </Form.Item>
      },
    },
    {
      title: "September",
      dataIndex: "september",
      width: 350,
      render: (amount, data) => {
        return <Form.Item>
          <InputNumber
            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
            style={{ width: "100%" }}
            defaultValue={amount}
            onChange={(x) => {
              setNewData({
                ...newData,
                september: x,
              })
            }
            }
            onBlur={() => onUpdateBudget(data)}
            onPressEnter={() => onUpdateBudget(data)}
            size="small"
          />
        </Form.Item>
      },
    },
    {
      title: "Oktober",
      dataIndex: "oktober",
      width: 350,
      render: (amount, data) => {
        return <Form.Item>
          <InputNumber
            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
            style={{ width: "100%" }}
            defaultValue={amount}
            onChange={(x) => {
              setNewData({
                ...newData,
                oktober: x,
              })
            }
            }
            onBlur={() => onUpdateBudget(data)}
            onPressEnter={() => onUpdateBudget(data)}
            size="small"
          />
        </Form.Item>
      },
    },
    {
      title: "November",
      dataIndex: "november",
      width: 350,
      render: (amount, data) => {
        return <Form.Item>
          <InputNumber
            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
            style={{ width: "100%" }}
            defaultValue={amount}
            onChange={(x) => {
              setNewData({
                ...newData,
                november: x,
              })
            }
            }
            onBlur={() => onUpdateBudget(data)}
            onPressEnter={() => onUpdateBudget(data)}
            size="small"
          />
        </Form.Item>
      },
    },
    {
      title: "Desember",
      dataIndex: "desember",
      width: 350,
      render: (amount, data) => {
        return <Form.Item>
          <InputNumber
            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
            style={{ width: "100%" }}
            defaultValue={amount}
            onChange={(x) => {
              setNewData({
                ...newData,
                desember: x,
              })
            }
            }
            onBlur={() => onUpdateBudget(data)}
            onPressEnter={() => onUpdateBudget(data)}
            size="small"
          />
        </Form.Item>
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      render: (amount) => {
        return numberFormat(amount);
      }
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <>
            <Popconfirm
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({ url: "/financial-report/budget-units/", dataId: id, refetch })
              }
              title="Yakin ingin menghapus ?"
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    defaultPageSize: dataTable.per_page,
    total: dataAnggaran?.dataObject.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100, Number(dataAnggaran?.dataObject?.meta.total) > 200 ? dataAnggaran?.dataObject?.meta.total : 200],
    onChange: (curr, size) =>
      setDataTable((value) => {
        return {
          ...value,
          current_page: curr,
          per_page: size,
        };
      }),
  };

  return (
    <>
      <div className="table-header">
        <h1>Anggaran</h1>
        <Space>
          <Button
            hidden={!btnUpdate}
            onClick={() => refetch()}
            disabled={btnUpdate && btnUpdate.type === "disabled"}
          >
            Update data
          </Button>
          <Button
            type="primary"
            hidden={!btnImport}
            onClick={() => setShowImport(true)}
            disabled={btnImport && btnImport.type === "disabled"}
          >
            Import
          </Button>
        </Space>
      </div >
      <Form className="filter-kode-anggaran" form={form}
        layout="inline"
      >
        <Form.Item
          style={{
            width: "100%",
          }}
        >
          <Tooltip Tooltip title="Periode">
            <DatePicker picker="year"
              value={year}
              allowClear={false}
              onChange={(x) => setYear(x)}
            />
          </Tooltip>
        </Form.Item>
        {isSuperAdmin && (
          <Form.Item
            style={{
              width: "100%",
            }}
          >
            <Tooltip Tooltip title="Yayasan">
              <Select
                placeholder="Pilih Yayasan"
                style={{ minWidth: 150 }}
                onChange={(x) => {
                  setUnitId("")
                  setFoundationId(x)
                  form.resetFields(["unitId"])
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                disabled={isLoading}
              >
                <Select.Option className="select-option-foundation" value="">
                  ALL
                </Select.Option>
                {dataFoundation?.data?.data?.map((x) => (
                  <Select.Option
                    key={x.id}
                    value={x.id}
                    className="select-option-foundation"
                  >
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
          </Form.Item>
        )}
        <Form.Item name="unitId"
          style={{
            width: "100%",
          }}
        >
          <Tooltip Tooltip title="Unit">
            <Select
              placeholder="Pilih Unit"
              style={{ minWidth: 150 }}
              onChange={(_, data) => setUnitId(data?.key)}
              showSearch
              optionFilterProp="children"
              filterOption={
                (input, option) => option.value.toLowerCase().includes(input.toLowerCase())
              }
              disabled={isLoadingKodeUnit || isFetchingKodeUnit}
            >
              <Select.Option className="select-option-foundation" key={""} value="">
                ALL
              </Select.Option>
              {dataKodeUnit?.data?.data?.map((x) => (
                <Select.Option
                  key={x.id}
                  value={`${x.code} - ${x.name}${isSuperAdmin && ` - ${x.foundation.name}`}`}
                  className="select-option-foundation"
                >
                  {x.code} - {x.name}{isSuperAdmin && ` - ${x.foundation.name}`}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        </Form.Item>
      </Form>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "40vh", x: 1900 }
            : window.innerHeight < 760
              ? { y: "50vh", x: 1900 }
              : { y: "55vh", x: 1900 }
        }
        summary={() => {
          return (
            dataAnggaran?.dataObject?.data.length > 0 &&
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={2}>Grand Total</Table.Summary.Cell>
                <Table.Summary.Cell
                  index={2} colSpan={14}>
                  <Text>{dataAnggaran?.grandTotal ? numberFormat(dataAnggaran?.grandTotal) : 0}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <ImportAnggaran onCancel={onCancel} onImport={onImport} show={showImport} />
    </>
  );
};

export default Anggaran;
