import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useHeroList = (is_active) => {
  return useQuery(["get-hero-list", is_active], () =>
    GetList(
      `/landing-page/heros?mode=list&page=1&limit=10&is_active=${is_active}`
    )
  );
};
