import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useDetailDataVa = (id, enabled) => {
  return useQuery(
    ["get-data-va-detail", id],
    () => GetDetail(`/financial-report/virtual-accounts/${id}`),
    {
      enabled,
    }
  );
};
