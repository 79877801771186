import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useTransitDataPagination = (
  dataTable,
  keyword,
  foundationId,
  date,
  financeCodeId,
  showNull = false
) => {
  return useQuery(
    [
      "get-transit-data-finance-report-pagination",
      dataTable.current_page,
      dataTable.per_page,
      keyword || "",
      foundationId || "",
      date || "",
      financeCodeId || "",
      showNull,
    ],
    () =>
      GetPagination(
        `/financial-report/transit-data?page=${dataTable.current_page}&limit=${dataTable.per_page}&mode=page&keyword=${keyword}&foundationId=${foundationId}&date=${date}&financeCodeId=${financeCodeId}&showNull=${showNull}`
      )
  );
};
