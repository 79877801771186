import { Form, Input, message, Modal, Select } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import React, { useEffect, useState } from "react";

export default function CreateCategory({ open, setOpen }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [foundationId, setFoundationId] = useState("");

  const { REACT_APP_HOST_API } = process.env;
  const roles = decryptCookies("role").split(",");

  //fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  //fetch employee list
  const { data: dataEmployee, refetch } = useEmployeeList(
    "",
    "",
    "",
    "",
    foundationId
  );

  useEffect(() => {
    if (open) {
      refetch();
    }
    // eslint-disable-next-line
  }, [open, foundationId]);

  const errorModal = (title, content) => {
    Modal.error({
      title,
      content,
    });
  };

  const onSubmit = async () => {
    const { foundation_id, name, employee_id } = await form.validateFields();
    setIsLoading(true);
    try {
      await axios.post(
        REACT_APP_HOST_API + "/links/categories",
        { foundation_id, name, employee_id },
        {
          headers: {
            Authorization: `Bearer ${decryptCookies("token")}`,
          },
        }
      );

      message.success("Berhasil menambahkan kategori");
      form.resetFields();
      setOpen();
    } catch (error) {
      console.log(error);
      errorModal(
        "Terjadi Kesalahan",
        <pre>{error?.response?.data?.message}</pre>
      );
    } finally {
      setIsLoading(false);
    }
  };
  const onCancel = () => {
    form.resetFields();
    setOpen();
  };
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
      okText="Simpan"
      cancelText="Batal"
      confirmLoading={isLoading}
    >
      <Form form={form} layout="vertical">
        {roles.some((x) => x === "super_admin") && (
          <Form.Item
            label="Foundation"
            name="foundation_id"
            rules={[{ required: true }]}
          >
            <Select
              onChange={(e) => {
                setFoundationId(e);
                form.resetFields(["employee_id"]);
              }}
              placeholder="Pilih Yayasan"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {roles.some((x) => x === "super_admin") && (
          <Form.Item
            label="Owner"
            name="employee_id"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataEmployee?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="name"
          label="Nama Kategori"
          rules={[{ required: true, min: 3 }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
