import RequireAuth from "components/Auth/RequireAuth";
import RequireMenuFoundation from "components/Auth/RequireMenuFoundation";
import Foundation from "pages/si-foundation/foundation/master/Foundation";
import LayoutFoundation from "Layouts/LayoutFoundation/LayoutFoundation";
import MasterFoundation from "pages/si-foundation/MasterFoundation";
import DetailFoundation from "pages/si-foundation/foundation/detail/DetailFoundation";
import Unit from "pages/si-foundation/unit/master/Unit";
import DetailUnit from "pages/si-foundation/unit/detail/DetailUnit";
import AddEmployeeBatch from "pages/si-foundation/unit/detail/AddEmployeeBatch";
import DivisionTable from "pages/si-hrd/division/master/Division";
import DetailDivision from "pages/si-foundation/division/detail/DetailDivision";
import AddEmployeeToDivisiBatch from "pages/si-foundation/division/detail/AddEmployeeToDivisiBatch";


export const DataRoutesFoundation = [
  {
    path: "foundation",
    element: (
      <RequireAuth>
        <RequireMenuFoundation>
          <LayoutFoundation content={<MasterFoundation />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
  {
    path: "foundation/foundation",
    element: (
      <RequireAuth>
        <RequireMenuFoundation menu="mnuFoundation">
          <LayoutFoundation content={<Foundation />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
  {
    path: "foundation/foundation/:id",
    element: (
      <RequireAuth>
        <RequireMenuFoundation menu="mnuFoundation">
          <LayoutFoundation content={<DetailFoundation />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
  {
    path: "foundation/unitfoundation",
    element: (
      <RequireAuth>
        <RequireMenuFoundation menu="mnuUnitFoundation">
          <LayoutFoundation content={<Unit />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
  {
    path: "foundation/unitfoundation/:unit_id",
    element: (
      <RequireAuth>
        <RequireMenuFoundation menu="mnuUnitFoundation">
          <LayoutFoundation content={<DetailUnit />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
  {
    path: "foundation/unitfoundation/:unit_id/add-employee-batch",
    element: (
      <RequireAuth>
        <RequireMenuFoundation menu="mnuUnitFoundation">
          <LayoutFoundation content={<AddEmployeeBatch />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
  {
    path: "foundation/divisionfoundation",
    element: (
      <RequireAuth>
        <RequireMenuFoundation menu="mnuDivisionFoundation">
          <LayoutFoundation content={<DivisionTable />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
  {
    path: "foundation/divisionfoundation/:division_id",
    element: (
      <RequireAuth>
        <RequireMenuFoundation menu="mnuDivisionFoundation">
          <LayoutFoundation content={<DetailDivision />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
  {
    path: "foundation/divisionfoundation/:division_id/add-employee-divisi-batch",
    element: (
      <RequireAuth>
        <RequireMenuFoundation menu="mnuDivisionFoundation">
          <LayoutFoundation content={<AddEmployeeToDivisiBatch />} />
        </RequireMenuFoundation>
      </RequireAuth>
    ),
  },
];
