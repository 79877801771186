import { SearchOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Space, Table, Tooltip } from "antd";
import { Popconfirm, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useState } from "react";
import CreateDataVa from "../create/CreateDataVa";
import EditDataVa from "../edit/EditDataVa";
import ImportDataVa from "../import/ImportDataVa";
import { useKodeKeuanganPagination } from "hooks/finance-report-hook/useKodeKeuanganPagination";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { decryptCookies } from "components/Helper/CookiesHelper";
import './DataVa.css';
import { useDataVaPagination } from "hooks/finance-report-hook/useDataVaPagination";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const DataVa = () => {
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [showEdit, setShowUpdate] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [foundationId, setFoundationId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });


  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");
  const [form] = Form.useForm();

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  const {
    data: dataKodeUnit,
    isLoading: isLoadingKodeUnit,
    isFetching: isFetchingKodeUnit,
  } = useKodeKeuanganPagination(
    {
      current_page: 1,
      per_page: 1000,
      total: 0,
    },
    "",
    foundationId,
    "unit");

  const {
    data: dataVa,
    isLoading,
    isFetching,
    refetch,
  } = useDataVaPagination(dataTable, keyword, foundationId, unitId);

  // fetch permission
  const { data: dataPermission } = usePermission("mdlFinanceReport", "mnuDataVa");

  const onCreate = useCallback(() => {
    setShowCreate(false);
    refetch();
  }, [refetch]);

  const onImport = useCallback(() => {
    setShowImport(false);
    refetch();
  }, [refetch]);

  const onUpdate = useCallback(() => {
    setShowUpdate(false);
    refetch();
  }, [refetch]);

  const onCancel = () => {
    setShowCreate(false);
    setShowUpdate(false);
    setShowImport(false);
  };

  const handleChange = async (param) => {
    setKeyword(param.target.value);
  };

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddDataVa");
  const btnImport = dataPermission?.find((x) => x.id === "btnImportDataVa");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditDataVa");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteDataVa");

  const dataSource = dataVa?.data?.data?.slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
        foundationName: data.foundation.name,
        unitName: `${data.codeUnit.code} - ${data.codeUnit.name}`,
      };
    });

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      width: 50,
      fixed: "left",
      responsive: ["sm"],
    },
    {
      title: "Yayasan", dataIndex: "foundationName",
      hidden: !roles.some((x) => x === "super_admin")
    },
    { title: "VA", dataIndex: "va", width: 100 },
    { title: "Nama", dataIndex: "name" },
    {
      title: "Jurusan", dataIndex: "jurusan",
      width: 80,
    },
    { title: "Unit", dataIndex: "unitName" },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <>
            <Popconfirm
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({ url: "/financial-report/virtual-accounts/", dataId: id, refetch })
              }
              title="Yakin ingin menghapus ?"
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setShowUpdate(true);
                  setDataId(id);
                }
              }}
            >
              Ubah
            </Tag>
          </>
        );
      },
    },
  ].filter((x) => !x.hidden);

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    defaultPageSize: dataTable.per_page,
    total: dataVa?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) =>
      setDataTable((value) => {
        return {
          ...value,
          current_page: curr,
          per_page: size,
        };
      }),
  };
  return (
    <>
      <div className="table-header">
        <h1>Daftar Data VA</h1>
        <Space>
          <Button
            hidden={!btnAdd}
            onClick={() => setShowCreate(true)}
            disabled={btnAdd && btnAdd.type === "disabled"}
          >
            Tambah
          </Button>
          <Button
            type="primary"
            hidden={!btnImport}
            onClick={() => setShowImport(true)}
            disabled={btnImport && btnImport.type === "disabled"}
          >
            Import
          </Button>
        </Space>
      </div>
      <Form className="filter-data-va-keuangan" form={form}
        layout="inline"
      >
        <Form.Item
          style={{
            width: "100%",
          }}
        >
          <Input
            value={keyword}
            onChange={handleChange}
            prefix={<SearchOutlined />}
            placeholder="Cari  berdasarkan  nama  atau VA"
          />
        </Form.Item>
        {roles.some((x) => x === "super_admin") && (
          <Form.Item
            style={{
              width: "100%",
            }}
          >
            <Tooltip Tooltip title="Yayasan">
              <Select
                placeholder="Pilih Yayasan"
                style={{ minWidth: 150 }}
                onChange={(x) => {
                  setFoundationId(x)
                  setUnitId("")
                  form.resetFields(["unitId"])
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option className="select-option-foundation" value="">
                  ALL
                </Select.Option>
                {dataFoundation?.data?.data?.map((x) => (
                  <Select.Option
                    key={x.id}
                    value={x.id}
                    className="select-option-foundation"
                  >
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
          </Form.Item>
        )}
        <Form.Item name="unitId"
          style={{
            width: "100%",
          }}
        >
          <Tooltip Tooltip title="Unit">
            <Select
              placeholder="Pilih Unit"
              style={{ minWidth: 150 }}
              onChange={(_, data) => {
                setUnitId(data?.key)
              }}
              showSearch
              optionFilterProp="children"
              filterOption={
                (input, option) => option.value.toLowerCase().includes(input.toLowerCase())
              }
              disabled={isLoadingKodeUnit || isFetchingKodeUnit}
            >
              <Select.Option className="select-option-foundation" value="" key=""
              >
                ALL
              </Select.Option>
              {dataKodeUnit?.data?.data?.map((x) => (
                <Select.Option
                  key={x.id}
                  value={`${x.name}${isSuperAdmin && ` - ${x.foundation.name}`}`}
                  className="select-option-foundation"
                >
                  {x.name}{isSuperAdmin && ` - ${x.foundation.name}`}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        </Form.Item>
      </Form>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "40vh", x: 800 }
            : window.innerHeight < 760
              ? { y: "50vh", x: 800 }
              : { y: "55vh", x: 800 }
        }
      />
      <CreateDataVa onCancel={onCancel} onCreate={onCreate} show={showCreate} />
      <ImportDataVa onCancel={onCancel} onImport={onImport} show={showImport} />
      <EditDataVa
        id={dataId}
        onCancel={onCancel}
        onUpdate={onUpdate}
        show={showEdit}
      />
    </>
  );
};

export default DataVa;
