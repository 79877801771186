import { RightOutlined } from "@ant-design/icons";
import { Button, Divider, Space } from "antd";
import axios from "axios";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MasterFoundation = () => {
  const [dataMenuFoundation, setDataMenuFoundation] = useState([]);

  const { REACT_APP_HOST_API } = process.env;
  const navigate = useNavigate();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchDashboard = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } },
          { cancelToken: cancelToken.token }
        );

        const resultModule = data.data[0].role.permissions.modules;
        if (resultModule.find((x) => x.id === "mdlFoundation")) {
          const resultMenuFoundation =
            data.data[0].role.permissions.modules.find(
              (x) => x.id === "mdlFoundation"
            ).menus;

          setDataMenuFoundation(resultMenuFoundation);
        }
      } catch (error) {
        if (error.response.status === 401) {
          alert("Akun belum terverifikasi, silahkan hubungi IT / HRD");
          return navigate("/");
        } else {
          alert(error);
        }
      }
    };

    fetchDashboard();

    return () => {
      cancelToken.cancel();
    };
  }, [REACT_APP_HOST_API, navigate]);

  const handleClickMenu = (menu) => {
    encryptCookies("defaultFoundationsMenu", menu);
    if (menu === "mnuFoundation") {
      navigate("/foundation/foundation");
    } else navigate(menu.toLowerCase().split("nu")[1]);
  };

  return (
    <div>
      <Divider
        orientation="left"
        orientationMargin="0"
        style={{ textTransform: "uppercase" }}
      >
        Selamat Datang di Dashboard Foundation
      </Divider>
      <h3 style={{ textTransform: "uppercase" }}>Daftar Menu</h3>
      <Space direction="vertical">
        {dataMenuFoundation.map((x) => (
          <Button
            key={x.id}
            type="primary"
            ghost
            icon={<RightOutlined />}
            onClick={() => handleClickMenu(x.id)}
          >
            {x.id.split("nu")[1]}
          </Button>
        ))}
      </Space>
    </div>
  );
};

export default MasterFoundation;
