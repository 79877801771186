import { Button, message, Modal, Popconfirm, Table, Tag, Tooltip } from "antd";
import { DeleteApi } from "api/DeleteApi";
// import { decryptCookies } from "components/Helper/CookiesHelper";
import { usePermission } from "hooks/usePermissions";
import React from "react";
import { FaRegCopy } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const disabled = { color: "#ccc", cursor: "not-allowed" };
export default function Detail({ data, refetch }) {
  const navigate = useNavigate();
  // const roles = decryptCookies("role").split(",");
  // const isSuperAdmin = roles.some((x) => x === "super_admin");
  // const isAdminFoundation = roles.some((x) => x === "admin_foundation");
  // const isAdminHrd = roles.some((x) => x === "admin_hrd");

  // fetch  permission
  const { data: dataPermission } = usePermission("mdlLink", "mnuLinkGroup");

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddLinkLG");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditLinkLG");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteLinkLG");

  const info = (categories, title) => {
    Modal.info({
      title: title,
      content: (
        <div>
          {categories.map((item, index) => (
            <p key={item.id} style={{ marginBottom: 0 }}>
              {index + 1}. {item.name}
            </p>
          ))}
        </div>
      ),
      onOk() { },
    });
  };

  const limitCategories = (categories) => {
    const nameArr = categories
      .slice(0, 3)
      .map((item) => item?.name)
      .join(", ");

    return (
      <>
        {nameArr}
        {categories.length > 3 && (
          <p>
            ...
            <span
              style={{
                cursor: "pointer",
                color: "#4096FF",
                fontWeight: "bold",
              }}
              onClick={() => info(categories, "Kategori")}
            >
              detail
            </span>
          </p>
        )}
      </>
    );
  };

  const onCopyLink = (url) => {
    navigator.clipboard.writeText(url);
    message.success("Berhasil menyalin link");
  };

  const columns = [
    {
      title: "Deskripsi",
      dataIndex: "description",
      width: 150,
    },
    {
      title: "Link",
      dataIndex: "url",
      render: (url) => (
        <div className="flex items-center gap-1">
          <Tooltip title="Copy Link">
            <span
              style={{
                cursor: "pointer",
                marginLeft: 5,
                color: "#4096FF",
              }}
              onClick={() => onCopyLink(url)}
            >
              <FaRegCopy />
            </span>
          </Tooltip>
          <a
            href={
              url.startsWith("http://") || url.startsWith("https://")
                ? url
                : `https://${url}`
            }
            target="_blank"
            rel="noreferrer"
            className="text-[#4096FF]"
          >
            {url.length > 50 ? `${url.slice(0, 50)}...` : url}
          </a>
        </div>
      ),
    },
    {
      title: "Kategori",
      dataIndex: "categories",
      width: 200,
      render: (categories) => limitCategories(categories || []),
    },
    {
      title: "Grub",
      dataIndex: "groups",
      render: (groups) => {
        return groups.map((x) => x.name).join(", ");
      },
    },
    // {
    //   title: "Order",
    //   dataIndex: "order",
    //   width: 50,
    //   isHidden: !isSuperAdmin && !isAdminFoundation && !isAdminHrd
    // },
    {
      title: "aksi",
      dataIndex: "id",
      render: (id) => {
        // Fungsi untuk mengecek apakah tombol harus dinonaktifkan
        const isDisabled = (action) => {
          return action?.type === "disabled";
        };

        return (
          <>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                const dataId = id;
                DeleteApi({ url: "/links/links/", dataId, refetch });
              }}
              disabled={isDisabled(btnDelete)}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={isDisabled(btnDelete) ? disabled : { cursor: "pointer" }}
              >
                Hapus
              </Tag>
            </Popconfirm>

            <Tag
              color="orange"
              hidden={!btnEdit}
              style={isDisabled(btnEdit) ? disabled : { cursor: "pointer" }}
              onClick={() => {
                if (!isDisabled(btnEdit)) {
                  navigate(`edit-link/${id}`);
                }
              }}
              disabled={isDisabled(btnEdit)}
            >
              Ubah
            </Tag>
          </>
        );
      },
    },
  ].filter((x) => !x.isHidden);

  const dataSource = data?.data?.links?.map((link) => {
    return {
      key: link?.id,
      ...link,
    };
  });

  return (
    <div>
      <table className="detail-teachers__table">
        <tbody>
          <tr>
            <th>Nama</th>
            <td>: {data?.data?.name}</td>
          </tr>
          <tr>
            <th>Owner</th>
            <td>: {data?.data?.owner?.name}</td>
          </tr>
          <tr>
            <th>Deskripsi</th>
            <td>: {data?.data?.description}</td>
          </tr>
        </tbody>
      </table>

      <Button
        hidden={!btnAdd}
        disabled={btnAdd?.type === "disabled"}
        onClick={() => {
          if (btnAdd?.type === "disabled") return;
          navigate("add-link");
        }}
        type="primary"
        className="float-right mb-5"
      >
        Tambah Link
      </Button>

      <Table
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: "max-content", y: "calc(100vh - 300px)" }}
        pagination={false}
      />
    </div>
  );
}
