import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Table } from "antd";
import AddManufacturer from "./AddManufacturer";
import EditManufacturer from "./EditManufacturer";
import ManufacturersLogic from "./ManufacturersLogic";
import DetailManufacturer from "./DetailManufacturer";

const Manufacturers = () => {
  const {
    record,
    handleOpenDetail,
    openDetail,
    btnAdd,
    dataId,
    dataKey,
    keyword,
    columns,
    isLoading,
    isFetching,
    pagination,
    showAddManufacturer,
    showEditManufacturer,
    onCreate,
    onCancel,
    onUpdate,
    handleChange,
    setShowAddManufacturer,
  } = ManufacturersLogic();

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Produsen Inventaris</h1>
        <Button
          type="primary"
          hidden={!btnAdd}
          disabled={btnAdd && btnAdd.type === "disabled"}
          onClick={() => setShowAddManufacturer(true)}
        >
          Tambah Produsen
        </Button>
      </div>
      <div className="search-wrapper filter-wrapper">
        <Input
          prefix={<SearchOutlined />}
          value={keyword}
          onChange={handleChange}
          placeholder="Cari Produsen"
        />
      </div>
      <Table
        size="small"
        tableLayout="auto"
        dataSource={dataKey}
        columns={columns}
        loading={isLoading || isFetching}
        pagination={pagination}
        scroll={
          window.innerHeight < 760
            ? {
                y: "50vh",
                x: 800,
              }
            : {
                y: "55vh",
                x: 800,
              }
        }
      />
      <AddManufacturer
        show={showAddManufacturer}
        onCreate={onCreate}
        onCancel={onCancel}
      />
      <EditManufacturer
        id={dataId}
        show={showEditManufacturer}
        onUpdate={onUpdate}
        onCancel={onCancel}
      />
      <DetailManufacturer
        open={openDetail}
        setOpen={handleOpenDetail}
        data={record}
      />
    </div>
  );
};

export default Manufacturers;
