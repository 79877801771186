import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Tag,
  Upload,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useAssetStatusList } from "hooks/asset-hook/useAssetStatusList";
import { useContext, useEffect, useRef, useState } from "react";
import { InventoryContext } from "../../inventory-context/InventoryContext";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useAssetSumberDana } from "hooks/asset-hook/useAssetSumberDana";
import { useAssetCategories } from "hooks/asset-hook/useAssetCategories";
const { TextArea } = Input;

const AddAssets = ({ show, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [foundationId, setFoundationId] = useState("");

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputVisible, setInputVisible] = useState(false);
  const inputRef = useRef(null);

  const { setInventoryFetch } = useContext(InventoryContext);
  const { REACT_APP_HOST_API } = process.env;
  const format = "YYYY-MM-DD";
  const roles = decryptCookies("role").split(",");

  const { data, refetch: fetchStatus } = useAssetStatusList();
  const { data: dataFoundation } = useFoundationList();
  const { data: dataSumberDana, refetch: fetchSumberDana } = useAssetSumberDana(
    "",
    foundationId
  );
  const { data: dataCategory, refetch: fetchCategory } = useAssetCategories(
    "",
    foundationId
  );

  // sumber dana state
  const [isEmptyNameSumberDana, setIsEmptyNameSumberDana] = useState(false);
  const [itemsSumberDana, setItemsSumberDana] = useState([]);
  const [nameSumberDana, setNameSumberDana] = useState("");
  const inputRefSumberDana = useRef(null);
  // category state
  const [isEmptyNameCategory, setIsEmptyNameCategory] = useState(false);
  const [itemsCategory, setItemsCategory] = useState([]);
  const [nameCategory, setNameCategory] = useState("");
  const inputRefCategory = useRef(null);

  useEffect(() => {
    if (show) fetchStatus();
    form.setFieldsValue({ assetStatusId: "AVAILABLE" });
  }, [show, fetchStatus, form]);

  const errorModal = (title, content) => {
    Modal.error({
      title,
      content,
    });
  };

  useEffect(() => {
    setItemsSumberDana(dataSumberDana?.data);
    setItemsCategory(dataCategory?.data);
  }, [dataSumberDana, dataCategory]);

  // block sumber dana
  const onNameChangeSumberDana = (event) => {
    setNameSumberDana(event.target.value);
  };

  const addItemSumberDana = async () => {
    const foundation_id = form.getFieldValue("foundationId") || "";

    if (!foundation_id && roles.some((x) => x === "super_admin")) {
      errorModal(
        "Gagal menambahkan sumber dana",
        "Harap pilih foundation terlebih dahulu"
      );
      return;
    }

    if (!nameSumberDana) {
      message.error("Sumber Dana harus diisi");
      setIsEmptyNameSumberDana(true);
      return;
    }

    try {
      await axios.post(
        REACT_APP_HOST_API + "/inventories/asset-fund-source",
        { foundation_id, name: nameSumberDana },
        {
          headers: {
            Authorization: `Bearer ${decryptCookies("token")}`,
          },
        }
      );
      setNameSumberDana("");
      setIsEmptyNameSumberDana(false);
      fetchSumberDana();
      message.success("Berhasil menambahkan sumber dana");
    } catch (error) {
      errorModal(
        "Gagal menambahkan sumber dana",
        <pre>{error?.response?.data?.message}</pre>
      );
    }
  };
  // end block Sumber dana

  // block Kategori
  const onNameChangeCategory = (event) => {
    setNameCategory(event.target.value);
  };

  const addItemCategory = async () => {
    const foundation_id = form.getFieldValue("foundationId") || "";

    if (!foundation_id && roles.some((x) => x === "super_admin")) {
      errorModal(
        "Gagal menambahkan kategori",
        "Harap pilih foundation terlebih dahulu"
      );
      return;
    }

    if (!nameCategory) {
      message.error("kategori harus diisi");
      setIsEmptyNameCategory(true);
      return;
    }

    try {
      await axios.post(
        REACT_APP_HOST_API + "/inventories/asset-categories",
        { foundation_id, name: nameCategory },
        {
          headers: {
            Authorization: `Bearer ${decryptCookies("token")}`,
          },
        }
      );
      setNameCategory("");
      setIsEmptyNameCategory(false);
      fetchCategory();
      message.success("Berhasil menambahkan kategori");
    } catch (error) {
      errorModal(
        "Gagal menambahkan kategori",
        <pre>{error?.response?.data?.message}</pre>
      );
    }
  };
  // end block Kategori

  const handleSubmit = async () => {
    if (fileList.length === 0) {
      form.resetFields(["image"]);
    }
    const values = await form.validateFields();
    if (fileList.length > 0) {
      values.image = values.image.file;
    }
    try {
      setLoading(true);
      const { data } = await axios.post(
        REACT_APP_HOST_API + `/inventories/assets`,
        {
          ...values,
          purchaseDate:
            values.purchaseDate && values.purchaseDate.format(format),
          tag: tags,
        },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      message.success(data.message);
      form.resetFields();
      setFileList([]);
      onCreate();
      setInventoryFetch(true);
      setTags([]);
    } catch (error) {
      errorModal(
        "Gagal menambahkan asset",
        <pre>{error?.response?.data?.message}</pre>
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCancelModal = () => {
    setInputVisible(false);
    form.resetFields();
    setFileList([]);
    setTags([]);
    onCancel();
  };

  // tag function

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleInputTagChange = ({ target: { value } }) => {
    setInputValue(value);
  };

  const handleInputTagSubmit = () => {
    if (inputValue && tags.indexOf(inputValue) === -1)
      setTags((curr) => [...curr, inputValue]);

    setInputVisible(false);
    setInputValue("");
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const onSubmitEditTag = () => {
    setTags([...tags, inputValue]);

    setInputVisible(false);
    setInputValue("");
  };

  const handleRemoveTag = (param) => {
    const newTags = tags.filter((tag) => tag !== param);
    setTags(newTags);
  };

  // upload image
  const isImage = (file) => {
    return (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    );
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // Set the preview property if it's not already set
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    // Show preview modal
    Modal.info({
      title: file.name,
      content: (
        <img
          alt="preview"
          style={{ width: "100%" }}
          src={file.url || file.preview}
        />
      ),
    });
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
    form.setFieldValue("file", null);
  };

  const beforeUpload = (file) => {
    if (!isImage(file)) {
      message.error("hanya bisa upload file gambar (.jpeg, .jpg, .png)");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("gambar ukurannya harus kurang dari 2MB!");
      return false;
    }
    if (!isImage(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isImage(file) ? [file] : []);
    return false;
  };
  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const isLt2M = newFileList[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return;
      }
    }
    setFileList(newFileList);
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    fileList,
    onChange: handleChange,
  };

  // end upload image

  return (
    <Modal
      className="top-5"
      open={show}
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      onCancel={handleCancelModal}
      okButtonProps={{ loading }}
      width="60%"
    >
      <Form form={form} layout="vertical" className="full-form">
        <div className="first-form">
          {roles.includes("super_admin") && (
            <Form.Item
              label="Yayasan"
              name="foundationId"
              rules={[{ required: true, message: "Harap diisi" }]}
            >
              <Select
                placeholder="Pilih Yayasan"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => {
                  setFoundationId(e);
                  form.resetFields(["assetFundSourceId"]);
                  form.resetFields(["categories"]);
                }}
              >
                {dataFoundation?.data?.data?.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="serial"
            label="Serial"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="description" label="Deskripsi">
            <TextArea rows={4} name="description" placeholder="Deskripsi" />
          </Form.Item>

          <Form.Item name="assetFundSourceId" label="Sumber Dana">
            <Select
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder="pilih sumber dana..."
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                  <Space
                    style={{
                      padding: "0 8px 4px",
                      position: "relative",
                    }}
                  >
                    <div className="w-[180px]">
                      <Input
                        placeholder="Masukan Sumber Dana..."
                        ref={inputRefSumberDana}
                        value={nameSumberDana}
                        onChange={onNameChangeSumberDana}
                        onKeyDown={(e) => e.stopPropagation()}
                        className={
                          isEmptyNameSumberDana && "border border-[#ff4d4f]"
                        }
                      />
                      {isEmptyNameSumberDana && (
                        <span className="text-[#ff4d4f] text-sm">
                          Mohon Isi Sumber Dana
                        </span>
                      )}
                    </div>

                    <Button
                      style={{ position: "absolute", top: 0 }}
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={addItemSumberDana}
                    >
                      Tambah
                    </Button>
                  </Space>
                </>
              )}
              options={itemsSumberDana?.map((item) => ({
                label: item?.name,
                value: item?.id,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="assetStatusId"
            label="Status"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {data?.data.map((status) => {
                if (status.id === "BORROWED") return null;

                return (
                  <Select.Option key={status.id} value={status.id}>
                    {status.id}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="categories" label="Kategori">
            <Select
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              mode="multiple"
              placeholder="pilih kategori..."
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                  <Space
                    style={{
                      padding: "0 8px 4px",
                      position: "relative",
                    }}
                  >
                    <div className="w-[180px]">
                      <Input
                        placeholder="Masukan Nama Kategori..."
                        ref={inputRefCategory}
                        value={nameCategory}
                        onChange={onNameChangeCategory}
                        onKeyDown={(e) => e.stopPropagation()}
                        className={
                          isEmptyNameCategory && "border border-[#ff4d4f]"
                        }
                      />
                      {isEmptyNameCategory && (
                        <span className="text-[#ff4d4f] text-sm">
                          Mohon Isi Kategori
                        </span>
                      )}
                    </div>

                    <Button
                      style={{ position: "absolute", top: 0 }}
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={addItemCategory}
                    >
                      Tambah
                    </Button>
                  </Space>
                </>
              )}
              options={itemsCategory?.map((item) => ({
                label: item?.name,
                value: item?.id,
              }))}
            />
          </Form.Item>

          <Form.Item label="Tag">
            {tags.length > 0 &&
              tags.map((tag) => {
                return (
                  <Tag
                    key={tag}
                    className="edit-tag"
                    closable
                    onClose={() => handleRemoveTag(tag)}
                  >
                    {tag}
                  </Tag>
                );
              })}
            {inputVisible && (
              <Input
                size="small"
                type="text"
                className="tag-input"
                pattern="[a-zA-Z0-9-]+"
                title="allow only alphanum and dash"
                ref={inputRef}
                onBlur={onSubmitEditTag}
                onChange={handleInputTagChange}
                onPressEnter={handleInputTagSubmit}
              />
            )}
            {!inputVisible && (
              <Tag className="site-tag-plus" onClick={showInput}>
                <PlusOutlined /> Add Tag
              </Tag>
            )}
          </Form.Item>
        </div>

        <div className="second-form">
          <Form.Item name="purchaseDate" label="Tanggal Pembelian">
            <DatePicker format={format} placeholder="Pilih Tanggal" />
          </Form.Item>
          <Form.Item name="orderNumber" label="Nomer Order">
            <Input />
          </Form.Item>
          <Form.Item
            name="price"
            label="Harga"
            rules={[
              {
                pattern: new RegExp("^([0-9]*)$"),
                message: "Masukkan hanya Nomor",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="warranty"
            label="Garansi (bulan)"
            rules={[
              {
                pattern: new RegExp("^([0-9]*)$"),
                message: "Masukkan hanya Nomor",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="notes" label="Catatan">
            <TextArea rows={3} />
          </Form.Item>

          <Form.Item
            name="image"
            label="Gambar"
            rules={[
              {
                validator: (_, value) => {
                  const file = value?.fileList[0];
                  if (!file) {
                    return Promise.resolve();
                  }
                  if (!isImage(file)) {
                    return Promise.reject(
                      new Error("Please upload an image file")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              name="image"
              accept=".jpg,.jpeg,.png"
              listType="picture-card"
              showUploadList={true}
              onPreview={handlePreview}
              {...propsUpload}
              disabled={loading}
            >
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  {fileList.length === 0 ? (
                    <span style={{ fontSize: "12px" }}>
                      Upload <br />
                      (max 2 mb)
                    </span>
                  ) : (
                    "Ganti"
                  )}
                  {fileList.length === 0 ? "" : ""}
                </div>
              </div>
            </Upload>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default AddAssets;
