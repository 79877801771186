import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { renderAmount } from "pages/si-finance/utils/utlisFunction";

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "14.28%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableColHeader: {
    backgroundColor: "#e6e6b0",
  },
  tableCellLeftText: {
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "left",
  },
  tableCell: {
    fontSize: 10,
    textAlign: "center",
  },
  headerText: {
    fontWeight: "bold",
  },
});

const PdfDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page} orientation="landscape">
      <View style={styles.table}>
        {/* Header */}
        <View style={[styles.tableRow, styles.tableColHeader]}>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.headerText]}>
              No. Rekening
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.headerText]}>Nama</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.headerText]}>NISN</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.headerText]}>Kelas</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.headerText]}>Program</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.headerText]}>
              Status Siswa
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.headerText]}>Saldo</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.headerText]}>Unit</Text>
          </View>
        </View>
        {/* Data */}

        {data?.data?.data?.map((item, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item?.number}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellLeftText}>{item?.account_name}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item?.student?.nisn}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellLeftText}>
                {item?.student?.class?.name}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item?.student?.program}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {item?.student?.is_graduated ? "Lulus" : "Belum Lulus"}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellLeftText}>
                {renderAmount(item?.balance)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellLeftText}>
                {item?.codeUnit?.code}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

export default PdfDocument;
