import { useEffect, useCallback } from "react";

const useResetPage = (setDataTable, dependencies) => {
  const resetPage = useCallback(() => {
    setDataTable((value) => {
      return {
        ...value,
        current_page: 1,
      };
    });
  }, [setDataTable]);

  useEffect(() => {
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPage, ...dependencies]);
};

export default useResetPage;
