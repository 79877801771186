import { AppstoreFilled, LogoutOutlined } from "@ant-design/icons";
import Logout from "components/Auth/Logout";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { usePermission } from "hooks/usePermissions";
import Cookies from "js-cookie";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiCarousel } from "react-icons/bi";
import { RiCommunityLine } from "react-icons/ri";
import { MdOutlineContacts } from "react-icons/md";

const LayoutLandingPageLogic = () => {
  const [collapsed, setCollapsed] = useState(
    window.innerWidth > 1200 ? false : true
  );

  const navigate = useNavigate();
  const email = Cookies.get("email") && decryptCookies("email");

  const { data } = usePermission("mdlLandingPage");

  const [open, setOpen] = useState(false);

  const handleSelectMenu = (param) => {
    encryptCookies("defaultLandingPageMenu", param);
  };

  const handleClickItemUser = (e) => {
    if (e.key === "profile") navigate("/profile");
  };

  // Menu variable
  const mnuAboutMe = data?.find((x) => x.id === "mnuAboutMe");
  const mnuHero = data?.find((x) => x.id === "mnuHero");
  const mnuWorkUnit = data?.find((x) => x.id === "mnuWorkUnit");

  const handleClickMenu = (param) => {
    if (param.key === "") {
      return;
    } else {
      handleSelectMenu(param.key);

      if (param.key === "logout") {
        return;
      } else if (param.key === "home") navigate("/home");
      else if (param.key === "mnuAboutMe") navigate("/landing-page/about");
      else if (param.key === "mnuHero") navigate("/landing-page/hero");
      else if (param.key === "mnuWorkUnit") navigate("/landing-page/work-unit");
      else navigate("/landing-page/" + param.key.toLowerCase().split("mnu")[1]);
    }

    setOpen(false);
  };

  const itemsUser = [
    { key: "profile", label: <span>Profile</span> },
    { key: "logout", label: <Logout>Logout</Logout> },
  ];

  const items = [
    { key: "home", icon: <AppstoreFilled />, label: "Home" },
    mnuAboutMe && {
      key: "mnuAboutMe",
      icon: <MdOutlineContacts />,
      label: "About",
      disabled: mnuAboutMe?.type === "disabled",
    },

    mnuHero && {
      key: "mnuHero",
      icon: <BiCarousel />,
      label: "Hero",
      disabled: mnuHero?.type === "disabled",
    },

    mnuWorkUnit && {
      key: "mnuWorkUnit",
      icon: <RiCommunityLine />,
      label: "Work Units",
      disabled: mnuWorkUnit?.type === "disabled",
    },
  ];

  const items2 = [
    { key: "logout", icon: <LogoutOutlined />, label: <Logout>Logout</Logout> },
  ];

  return {
    email,
    collapsed,
    items,
    items2,
    open,
    setOpen,
    itemsUser,
    setCollapsed,
    handleClickItemUser,
    handleClickMenu,
  };
};

export default LayoutLandingPageLogic;
