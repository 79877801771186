import {
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Skeleton,
  Tag,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AddUserRole from "./AddUserRole";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useCallback, useEffect, useState } from "react";
import { useEmployeeNotInUserList } from "hooks/employee-hook/useEmployeeNotInUser";

const EditUser = (props) => {
  const [form] = Form.useForm();
  const [roles, setRoles] = useState([]);
  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(false);
  const [skeleton, setSkeleton] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [showAddRole, setShowAddRole] = useState(false);
  const rolesUser = decryptCookies("role").split(",");
  const isSuperAdmin = rolesUser.some((x) => x === "super_admin");

  const { REACT_APP_HOST_API } = process.env;

  const { data: dataEmployee, refetch: fetchEmployee } = useEmployeeNotInUserList("", props.employeeId);

  useEffect(() => {
    if (props.show) {
      fetchEmployee();
    }
  }, [props.show, fetchEmployee]);

  const getUser = useCallback(async () => {
    setSkeleton(true);
    try {
      const { data } = await axios.get(
        REACT_APP_HOST_API + `/system/users/${props.id}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      setRoles(data.data?.roles);
      form.setFieldsValue({
        name: data.data.name,
        email: data.data.email,
        employee_id: data.data.employee_id,
        division_id: data.data.division_id,
        verified: data.data.verified,
      });
    } catch (error) {
      alert(error.message);
    }
    setSkeleton(false);
  }, [REACT_APP_HOST_API, props.id, form]);

  useEffect(() => {
    if (props.show) getUser();

    return () => setNewData({});
  }, [props.show, getUser]);

  const handleRadioChange = ({ target: { value } }) => {
    setRadioValue(value);
    newData["verified"] = value;
  };

  const onFinish = async () => {
    await form.validateFields();
    if (Object.keys(newData).length === 0) {
      props.onCancel();
      return;
    }

    setLoading(true);

    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + `/system/users/${props.id}`,
        newData,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      message.success(data.message, 2);
      form.resetFields();
      props.onUpdate();
      props.onCancel();
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => setShowAddRole(false);

  const onAdd = () => {
    getUser();
    props.onUpdate();
    setShowAddRole(false);
  };

  const onDelete = async (role_name) => {
    setSkeleton(true);

    try {
      const { data } = await axios.delete(
        REACT_APP_HOST_API + `/system/user-roles/${props.id}/${role_name}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      getUser();
      props.onUpdate();
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setSkeleton(false);
    }
  };

  return (
    <>
      <Modal
        okText="Simpan"
        cancelText="Batal"
        onOk={onFinish}
        open={props.show}
        onCancel={props.onCancel}
        okButtonProps={{ loading }}
        style={{ top: 25 }}
      >
        {skeleton ? (
          <>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </>
        ) : (
          <Form layout="vertical" form={form}>
            <Form.Item
              label="Nama"
              name="name"
              rules={[{ min: 5, message: "Masukkan minimal 5 Huruf" }]}
            >
              <Input
                onChange={({ target: { value } }) => (newData["name"] = value)}
              />
            </Form.Item>
            {isSuperAdmin && (
              <Form.Item label="Role">
                <div style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
                  {roles?.map((item, i) => (
                    <Tag
                      key={i}
                      closable
                      style={{ cursor: "pointer" }}
                      onClose={() => onDelete(item.role_name)}
                    >
                      {item.role_name}
                    </Tag>
                  ))}
                  <Tag
                    style={{ borderStyle: "dashed", cursor: "pointer" }}
                    onClick={() => setShowAddRole(true)}
                  >
                    <PlusOutlined /> Tambah Role
                  </Tag>
                </div>
              </Form.Item>
            )}
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { type: "email", message: "Harap masukkan email yang valid" },
              ]}
            >
              <Input
                onChange={({ target: { value } }) => (newData["email"] = value)}
              />
            </Form.Item>
            {isSuperAdmin && (
              <Form.Item label="ID Karyawan" name="employee_id">
                <Select
                  showSearch
                  onChange={(val) => {
                    newData["employee_id"] = val;
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {dataEmployee?.data.map((employee) => (
                    <Select.Option key={employee.name} value={employee.id}>
                      {`${employee.name} - ${employee.nip}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {isSuperAdmin && (
              <Form.Item label="Terverifikasi" name="verified">
                <Radio.Group onChange={handleRadioChange} value={radioValue}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            )}
          </Form>
        )}
      </Modal>
      <AddUserRole
        userRoles={roles}
        id={props.id}
        show={showAddRole}
        onAdd={onAdd}
        onCancel={onCancelModal}
      />
    </>
  );
};

export default EditUser;
