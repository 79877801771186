import { Form, Input, message, Modal } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import React, { useEffect, useState } from "react";

export default function EditCategory({ open, setOpen, data }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [newData, setNewData] = useState({});

  const { REACT_APP_HOST_API } = process.env;

  const errorModal = (title, content) => {
    Modal.error({
      title,
      content,
    });
  };

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        name: data?.name,
      });
    }
    // eslint-disable-next-line
  }, [open]);

  const onSubmit = async () => {
    await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Nothing has changed");
      return;
    }

    setIsLoading(true);
    try {
      await axios.put(
        REACT_APP_HOST_API + `/links/categories/${data?.id}`,
        newData,
        {
          headers: {
            Authorization: `Bearer ${decryptCookies("token")}`,
          },
        }
      );

      message.success("Berhasil menambahkan kategori");
      form.resetFields();
      setOpen();
    } catch (error) {
      console.log(error);
      errorModal(
        "Terjadi Kesalahan",
        <pre>{error?.response?.data?.message}</pre>
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setNewData({});
    setOpen();
  };
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
      okText="Simpan"
      cancelText="Batal"
      confirmLoading={isLoading}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Nama Kategori"
          rules={[{ required: true, min: 3 }]}
        >
          <Input
            onChange={({ target: { value } }) => (newData["name"] = value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
