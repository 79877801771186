import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useLinkGroupPagination = (dataTable, foundation_id, keyword) => {
  return useQuery(
    [
      "get-link-group-pagination",
      dataTable.current_page,
      dataTable.per_page,
      foundation_id,
      keyword,
    ],
    () =>
      GetPagination(
        `/links/link-groups?foundation_id=${foundation_id}&keyword=${keyword}&page=${dataTable.current_page}&limit=${dataTable.per_page}`
      )
  );
};
