import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
  Tooltip,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useBaseUrl } from "hooks/base-url-hook/useBaseUrl";
import { useEmployeeDetail } from "hooks/employee-hook/useEmployeeDetail";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { usePermission } from "hooks/usePermissions";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

export default function CreateGroup() {
  const [foundationId, setFoundationId] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [unit, setUnit] = useState([]);
  const [unitId, setUnitId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelected, setRowSelected] = useState([]);

  const roles = useMemo(() => decryptCookies("role").split(","), []);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { REACT_APP_HOST_API } = process.env;

  //* Hooks ---
  const { refetch: refetchBaseUrl } = useBaseUrl();
  const { data: dataFoundation } = useFoundationList();
  const { data: dataEmployee, refetch } = useEmployeeList(
    "",
    "",
    "",
    "",
    foundationId
  );
  const {
    data: dataMember,
    refetch: refetchMember,
    isFetching,
  } = useEmployeeList(keyword, "", "", "", foundationId, employeeId, unitId);
  const { refetch: refetchEmployeeDetail } = useEmployeeDetail(
    employeeId,
    false
  );

  // fetch  permission
  const { data: dataPermission } = usePermission("mdlLink", "mnuLinkGroup");

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddLinkGroup");

  const setUnitEmployee = useCallback(async () => {
    const { data } = await refetchEmployeeDetail();
    setUnit(data?.data[0]?.employeeUnits);
  }, [refetchEmployeeDetail]);

  const setValEmployeeId = useCallback(async () => {
    const { data } = await refetchBaseUrl();
    setEmployeeId(data?.data[0]?.employee_id);
  }, [refetchBaseUrl]);

  useEffect(() => {
    if (!roles.includes("super_admin")) {
      setValEmployeeId();
    }
  }, [roles, setValEmployeeId, setUnitEmployee]);

  useEffect(() => {
    if (employeeId) {
      setUnitEmployee();
    }
  }, [employeeId, roles, setUnitEmployee]);

  useEffect(() => {
    refetch();
  }, [foundationId, refetch]);

  useEffect(() => {
    refetchMember();
  }, [foundationId, employeeId, unitId, keyword, refetchMember]);

  const columns = useMemo(
    () => [
      {
        title: "Nama",
        dataIndex: "name",
      },
      {
        title: "NIP",
        dataIndex: "nip",
      },
      {
        title: "Unit",
        dataIndex: "employeeUnits",
        render: (units) => {
          return <p>{units?.map((x) => x?.unit?.name).join(", ")}</p>;
        },
      },
    ],
    []
  );

  const dataSource = useMemo(
    () =>
      dataMember?.data?.map((x) => ({
        ...x,
        key: x.id,
        name: x.name,
        nip: x.nip,
        id: x.id,
      })),
    [dataMember]
  );

  const onSubmit = async () => {
    const { foundation_id, employee_id, name, description } =
      await form.validateFields();
    setIsLoading(true);
    try {
      const payloadData = {
        foundation_id,
        employee_id,
        name,
        description,
        members: rowSelected,
      };

      await axios.post(`${REACT_APP_HOST_API}/links/link-groups`, payloadData, {
        headers: {
          Authorization: `Bearer ${decryptCookies("token")}`,
        },
      });
      message.success("Data berhasil ditambahkan");
      onCancel();
    } catch (error) {
      console.error(error);
      Modal.error({
        title: "Gagal menambahkan group",
        content: <pre>{error?.response?.data?.message}</pre>,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setRowSelected([]);
    navigate(-1);
  };

  const onSelectChange = (newRowSelected) => {
    setRowSelected(newRowSelected);
  };
  const rowSelection = {
    selectedRowKeys: rowSelected,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form form={form} layout="vertical" className="w-full md:w-1/2">
        {roles.includes("super_admin") && (
          <>
            <Form.Item
              label="Foundation"
              name="foundation_id"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Pilih Yayasan"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => {
                  setFoundationId(e);
                  form.resetFields(["employee_id", "unit"]);
                }}
              >
                {dataFoundation?.data?.data?.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Owner"
              name="employee_id"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={setEmployeeId}
              >
                {dataEmployee?.data?.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}

        <Form.Item label="Nama" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Deskripsi"
          name="description"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <div>
          <h1 className="font-bold">Orang yang dapat mengakses link grup</h1>

          <div className="flex gap-2 mt-5">
            <Tooltip title="filter unit">
              <Form.Item className="w-1/2" name="unit">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={setUnitId}
                >
                  <Select.Option value="">Semua</Select.Option>
                  {unit?.map((x) => (
                    <Select.Option key={x?.unit?.id} value={x?.unit?.id}>
                      {x?.unit?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Tooltip>

            <Tooltip title="Cari">
              <Input
                prefix={<SearchOutlined />}
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="cari berdasarkan nama..."
                className="w-1/2 h-[32px]"
              />
            </Tooltip>
          </div>

          <div className="-mt-10">
            <Table
              loading={isFetching}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              scroll={{ y: 400 }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            hidden={!btnAdd}
            disabled={btnAdd?.type === "disabled"}
            className="w-full md:w-1/2"
            loading={isLoading}
            type="primary"
            onClick={onSubmit}
          >
            Simpan
          </Button>
          <Button
            className="w-full md:w-1/2"
            type="primary"
            ghost
            onClick={onCancel}
          >
            Batal
          </Button>
        </div>
      </Form>
    </div>
  );
}
