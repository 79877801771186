import { message, Modal, Popconfirm, Space, Tag } from "antd";
import axios from "axios";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { useAssetPagination } from "hooks/asset-hook/useAssetPagination";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InventoryContext } from "../../inventory-context/InventoryContext";
import { useAssetCategories } from "hooks/asset-hook/useAssetCategories";
import { useAssetSumberDana } from "hooks/asset-hook/useAssetSumberDana";
import useResetPage from "components/Helper/useResetPage";
import useExportExcel from "components/Helper/useExportExcel";

const disabled = { color: "#ccc", cursor: "not-allowed" };
const AssetsLogic = () => {
  const [foundationId, setFoundationId] = useState("");
  const [showImport, setShowImport] = useState(false);
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [fundSourceId, setFundSourceId] = useState("");
  const [showAddAssets, setShowAddAssets] = useState(false);
  const [showEditAssets, setShowEditAssets] = useState(false);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });

  const navigate = useNavigate();
  const { REACT_APP_HOST_API } = process.env;
  const { setInventoryFetch } = useContext(InventoryContext);
  const roles = decryptCookies("role").split(",");

  const { data, isLoading, isFetching, refetch } = useAssetPagination(
    dataTable,
    keyword,
    foundationId,
    categoryId,
    fundSourceId
  );
  const { data: dataCategories } = useAssetCategories("", foundationId);
  const { data: dataFundSource } = useAssetSumberDana("", foundationId);

  // fetch permission
  const { data: dataPermission } = usePermission("mdlInventory", "mnuAssets");

  // Buttons Permission
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddAssets");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditAssets");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailAssets");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteAssets");
  const btnExport = dataPermission?.find(
    (x) => x.id === "btnExportExcelAssets"
  );
  const btnImport = dataPermission?.find((x) => x.id === "btnImportAssets");

  const handleSelectMenu = (param) => {
    encryptCookies("defaultInventoryMenu", param);
  };

  const onDelete = async (id) => {
    try {
      const { data } = await axios.delete(
        REACT_APP_HOST_API + "/inventories/assets/" + id,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message, 2);
      refetch();
      setInventoryFetch(true);
    } catch (error) {
      Modal.error({
        title: "Gagal Menghapus Data",
        content: <pre>{error?.response?.data?.message}</pre>,
      });
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 50,
    },
    { title: "Serial", dataIndex: "serial", align: "left", fixed: "left" },
    { title: "Status", dataIndex: "asset_status_id", align: "left" },
    {
      title: "Kategori",
      dataIndex: "categories",
      width: 200,
      align: "left",
      render: (categories) => categories?.map((x) => x.name).join(", "),
    },
    { title: "Tag", width: 200, dataIndex: "tag", align: "left" },
    { title: "Notes", width: 150, dataIndex: "notes", align: "left" },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      width: 350,
      render: (id) => {
        const isDisabled = (action) => {
          return action?.type === "disabled";
        };

        const getButtonStyle = (action) => {
          return isDisabled(action) ? disabled : { cursor: "pointer" };
        };
        return (
          <>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                onDelete(id);
              }}
              disabled={isDisabled(btnDelete)}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={getButtonStyle(btnDelete)}
              >
                Hapus
              </Tag>
            </Popconfirm>

            <Tag
              color="orange"
              hidden={!btnEdit}
              style={getButtonStyle(btnEdit)}
              onClick={() => {
                if (!isDisabled(btnEdit)) {
                  setDataId(id);
                  setShowEditAssets(true);
                }
              }}
              disabled={isDisabled(btnEdit)}
            >
              Ubah
            </Tag>

            <Tag
              color="blue"
              hidden={!btnDetail}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnDetail?.type === "disabled") return;
                navigate(`/inventory/assets/detail-${id}`);
              }}
            >
              Detail
            </Tag>
          </>
        );
      },
    },
  ];

  const dataKey = data?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
        fundSource: data?.fundSource?.name,
        asset_status_id: data.assetStatus && (
          <Tag
            color={data.assetStatus.color}
            style={{
              textTransform: "capitalize",
              // cursor: "pointer",
              borderRadius: "5px",
            }}
            onClick={() => {
              handleSelectMenu(data.assetStatus.id);
              // navigate(
              //   `/inventory/assets/${data.assetStatus.id.toLowerCase()}`
              // );
            }}
          >
            {data.assetStatus.id.toLowerCase()}
          </Tag>
        ),
        tag: data.tag && (
          <Space style={{ flexWrap: "wrap" }}>
            {data.tag.map((tag) => (
              <Tag key={tag} style={{ borderRadius: "5px" }}>
                {tag}
              </Tag>
            ))}
          </Space>
        ),
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100, data?.data.meta.total],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const onCancel = () => {
    setShowAddAssets(false);
    setShowEditAssets(false);
  };

  const onCreate = useCallback(() => {
    setShowAddAssets(false);
    refetch();
  }, [refetch]);

  const onUpdate = useCallback(() => {
    setShowEditAssets(false);
    refetch();
  }, [refetch]);

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  const handleChangeCategory = (value) => {
    const categoryId = value.join(",");
    setCategoryId(categoryId);
  };

  const dataExport = data?.data.data.map((data, index) => {
    return {
      No: index + 1,
      Serial: data?.serial,
      Status: data?.assetStatus?.id,
      Deskripsi: data?.description,
      "Sumber Dana": data?.fundSource?.name,
      Kategori: data?.categories?.map((x) => x.name).join(", "),
      Tag: data.tag && data.tag.join(", "),
      "Tanggal Pembelian": data?.purchase_date,
      "Nomor Order": data?.order_number,
      Harga: data?.price,
      "Garansi (bulan)": data?.warranty,
      Catatan: data?.notes,
      Gambar: data?.image && REACT_APP_HOST_API + data.image[1],
    };
  });

  const exportToExcel = useExportExcel({
    dataExport,
    fileName: "Aset",
    sheetName: "Aset",
  });

  const onCancelImport = () => {
    setShowImport(false);
  };

  const onImport = useCallback(() => {
    setShowImport(false);
    refetch();
  }, [refetch]);

  useResetPage(setDataTable, [keyword, foundationId, fundSourceId, categoryId]);

  return {
    btnImport,
    setShowImport,
    showImport,
    onCancelImport,
    onImport,
    roles,
    exportToExcel,
    handleChangeCategory,
    setCategoryId,
    setFundSourceId,
    dataFundSource,
    dataCategories,
    setFoundationId,
    btnExport,
    btnAdd,
    dataId,
    dataKey,
    keyword,
    columns,
    isLoading,
    isFetching,
    pagination,
    showAddAssets,
    showEditAssets,
    onCreate,
    onCancel,
    onUpdate,
    handleChange,
    setShowAddAssets,
  };
};

export default AssetsLogic;
