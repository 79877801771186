import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Modal, Skeleton, Upload } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";

const EditManufacturer = ({ id, show, onUpdate, onCancel }) => {
  const [newData, setNewData] = useState({});
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skeleton, setSkeleton] = useState(false);

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    const fetchData = async () => {
      setSkeleton(true);
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API + `/inventories/manufacturers/${id}`,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        );

        setFileList([
          {
            uid: "-1",
            status: "done",
            url: REACT_APP_HOST_API + data.data.image[1],
            thumbUrl: REACT_APP_HOST_API + data.data.image[1],
          },
        ]);

        form.setFieldsValue({
          name: data.data.name,
          url: data.data.url,
          supportPhone: data.data.support_phone,
          supportEmail: data.data.support_email,
          image: data.data.image[1],
        });
      } catch (error) {
        alert(error);
      }
      setSkeleton(false);
    };

    if (show) {
      fetchData();
    }

    return () => {
      setNewData({});
    };
  }, [show, id, form, REACT_APP_HOST_API]);

  const handleSubmit = async () => {
    if (fileList.length === 0) {
      form.resetFields(["image"]);
    }
    await form.validateFields(["name"]);

    if (Object.keys(newData).length === 0) {
      alert("Nothing has changed");
      return;
    }

    for (const key in newData) {
      if (Object.hasOwnProperty.call(newData, key)) {
        let element = newData[key];
        if (element === "") {
          newData[key] = null;
        }
      }
    }

    setLoading(true);

    try {
      const { data } = await axios.put(
        `${REACT_APP_HOST_API}/inventories/manufacturers/${id}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      message.success(data.message);
      form.resetFields();
      setFileList([]);
      onUpdate();
    } catch (error) {
      Modal.error({
        title: "Gagal Mengubah",
        content: <pre>{error?.response?.data?.message}</pre>,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    newData[event.target.name] = event.target.value;
  };

  const onCancelModal = () => {
    onCancel();
    setFileList([]);
  };

  const footerModal = [
    <Button key="2" onClick={onCancelModal}>
      Batal
    </Button>,
    <Button key="1" type="primary" onClick={handleSubmit} loading={loading}>
      Simpan
    </Button>,
  ];

  // upload image
  const [deleteImages, setDeleteImages] = useState([]);

  const isImage = (file) => {
    return (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    );
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // Set the preview property if it's not already set
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    // Show preview modal
    Modal.info({
      title: file.name,
      content: (
        <img
          alt="preview"
          style={{ width: "100%" }}
          src={file.url || file.preview}
        />
      ),
    });
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
    setNewData({ ...newData, image: null });
    setDeleteImages([...deleteImages, file?.name]);
    form.setFieldsValue({ image: null });
  };

  const beforeUpload = (file) => {
    if (!isImage(file)) {
      message.error("hanya bisa upload file gambar (.jpeg, .jpg, .png)");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("gambar ukurannya harus kurang dari 2MB!");
      return false;
    }
    if (!isImage(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isImage(file) ? [file] : []);
    return false;
  };
  const handleChangeImage = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const isLt2M = newFileList[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return;
      }
    }
    setFileList(newFileList);
    setNewData({ ...newData, image: newFileList[0]?.originFileObj || null });
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    fileList,
    onChange: handleChangeImage,
  };
  // end upload image

  return (
    <Modal open={show} onCancel={onCancelModal} footer={footerModal}>
      {skeleton && <Skeleton active />}
      {!skeleton && (
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Nama"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Input name="name" onChange={handleChange} />
          </Form.Item>
          <Form.Item
            name="url"
            label="Link URL"
            rules={[{ type: "url", message: "Harap masukkan url yang valid" }]}
          >
            <Input name="url" onChange={handleChange} />
          </Form.Item>
          <Form.Item
            name="supportPhone"
            label="No Telp"
            rules={[
              {
                pattern: new RegExp("^([0-9]*)$"),
                message: "Masukkan hanya Nomor",
              },
            ]}
          >
            <Input name="supportPhone" onChange={handleChange} />
          </Form.Item>
          <Form.Item
            name="supportEmail"
            label="Email"
            rules={[{ type: "email", message: "Masukkan email yang valid" }]}
          >
            <Input name="supportEmail" onChange={handleChange} />
          </Form.Item>

          <Form.Item
            name="image"
            label="Gambar"
            rules={[
              {
                validator: (_, value) => {
                  const file = value?.fileList[0];
                  if (!file) {
                    return Promise.resolve();
                  }
                  if (!isImage(file)) {
                    return Promise.reject(
                      new Error("Please upload an image file")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              name="image"
              accept=".jpg,.jpeg,.png"
              listType="picture-card"
              showUploadList={true}
              onPreview={handlePreview}
              {...propsUpload}
              disabled={loading}
            >
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  {fileList.length === 0 ? (
                    <span style={{ fontSize: "12px" }}>
                      Upload <br />
                      (max 2 mb)
                    </span>
                  ) : (
                    "Ganti"
                  )}
                  {fileList.length === 0 ? "" : ""}
                </div>
              </div>
            </Upload>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditManufacturer;
