import { SearchOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Input, Space, Table } from "antd";
// import AddEmployeeToUnit from "./AddEmployeeToUnit";
import EditEmployeFromUnit from "./EditEmployeeFromUnit";
import { Popconfirm, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUnitDetailPagination } from "hooks/unit-hook/useUnitDetailPagination";

// const disabled = { color: "#ccc", cursor: "not-allowed" };


const DetailUnit = () => {
  const [keyword, setKeyword] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  // const [showCreate, setShowCreate] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });


  const { unit_id } = useParams();
  const { REACT_APP_HOST_API: api } = process.env;
  const navigate = useNavigate();

  const { data, isLoading, isFetching, refetch } = useUnitDetailPagination(
    unit_id,
    true,
    keyword,
    dataTable
  );

  // fetch permission
  const { data: dataPermission } = usePermission("mdlHRD", "mnuUnit");

  // const onCreate = useCallback(() => {
  //   // setShowCreate(false);
  //   setShowEdit(false);
  //   refetch();
  // }, [refetch]);

  const onCancel = () => {
    // setShowCreate(false);
    setShowEdit(false);
  };

  const onUpdate = useCallback(() => {
    setShowEdit(false);
    setSelectedData([]);
    setSelectedIds([]);
    refetch();
  }, [refetch]);

  const handleChange = async (param) => {
    setKeyword(param.target.value);
  };

  // const handleDelete = async (id) => {
  //   try {
  //     const data = await axios.delete(
  //       api + `/employee-units/${id}`,
  //       { headers: { Authorization: "Bearer " + decryptCookies("token") } }
  //     );
  //     message.success(data.data.message);
  //     refetch();
  //   } catch (error) {
  //     alert(error.message);
  //   }
  // };

  const movetoCreateBatch = () => {
    navigate(`/foundation/unitFoundation/${unit_id}/add-employee-batch`);
  };

  const btnAdd = dataPermission?.find(
    (x) => x.id === "btnAddEmployeeToUnit"
  );
  const btnEdit = dataPermission?.find(
    (x) => x.id === "btnEditEmployeeFromUnit"
  );
  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeleteEmployeeFromUnit"
  );

  const dataSource = data?.data?.employeeUnits.map((data, index) => {
    return {
      ...data,
      key: data.id,
      index: index + 1,
      name: data.employee?.name,
      id: data.id,
      title:
        data.title === "lead"
          ? "Ketua"
          : data.title === "vice"
            ? "Wakil"
            : "Anggota",
    };
  });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    defaultPageSize: dataTable.per_page,
    total: data?.data?.employeeUnits_count,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) =>
      setDataTable((value) => {
        return {
          ...value,
          current_page: curr,
          per_page: size,
        };
      }),
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      width: window.innerWidth > 800 ? 70 : 50,
    },
    { title: "Nama", dataIndex: "name" },
    { title: "Jabatan", dataIndex: "title" },
    { title: "Status", dataIndex: "status", width: 100 },
  ];

  const onSelectChange = (selectedRowKeys, data) => {
    setSelectedIds(selectedRowKeys);
    setSelectedData(data);
  };

  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  const handleDeleteBatch = async () => {
    try {
      const data = await axios.delete(
        api + `/employee-units/batch`,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
          data: { employeeUnits: selectedIds },
        }
      );
      message.success(data.data.message);
      setSelectedIds([]);
      refetch();
    } catch (error) {
      alert(error.message);
    }
  }

  return (
    <>
      <div className="table-header">
        <h1>
          Daftar Anggota Unit <mark>{data?.data?.name}</mark>
        </h1>
        <Space>
          <Popconfirm
            okText="Hapus"
            cancelText="Batal"
            onConfirm={handleDeleteBatch}
            title="Yakin ingin menghapus ?"
            disabled={btnDelete && btnDelete.type === "disabled"}
          >
            <Button
              type="primary"
              hidden={!btnDelete}
              disabled={rowSelection.selectedRowKeys.length === 0 || (btnDelete && btnDelete.type === "disabled")}
              danger
            >
              Hapus
            </Button>
          </Popconfirm>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#FA8C16',
              },
            }}
          >
            <Button
              hidden={!btnEdit}
              onClick={() => setShowEdit(true)}
              disabled={rowSelection.selectedRowKeys.length === 0 || (btnEdit && btnEdit.type === "disabled")}
              type="primary"
            >
              Ubah
            </Button>
          </ConfigProvider>
          <Button
            type="primary"
            hidden={!btnAdd}
            onClick={movetoCreateBatch}
            disabled={btnAdd && btnAdd.type === "disabled"}
          >
            Tambah Anggota
          </Button>
        </Space>
      </div>
      <div className="search-wrapper">
        <Input
          value={keyword}
          onChange={handleChange}
          prefix={<SearchOutlined />}
          placeholder="cari nama anggota"
        />
      </div>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        rowSelection={rowSelection}
        pagination={pagination}
        scroll={
          window.innerHeight < 760
            ? {
              y: "50vh",
              x: 800,
            }
            : {
              y: "55vh",
              x: 800,
            }
        }
      />
      {/* <AddEmployeeToUnit
        show={showCreate}
        onCreate={onCreate}
        onCancel={onCancel}
      /> */}
      <EditEmployeFromUnit
        selectedData={selectedData}
        show={showEdit}
        onUpdate={onUpdate}
        onCancel={onCancel}
      />
    </>
  );
};

export default DetailUnit;
