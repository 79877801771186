import { Form, Input, Modal, Select, Skeleton } from "antd";
import React from "react";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";
import { renderModalError } from "pages/si-finance-report/utlis/utlis";
import { useKodeKeuanganPagination } from "hooks/finance-report-hook/useKodeKeuanganPagination";
import { useRevenuesDetail } from "hooks/finance-hook/useRevenuesDetail";

const EditRevenue = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const { REACT_APP_HOST_API } = process.env;
  const [foundationId, setFoundationId] = useState("");

  // fetch detail units
  const {
    isLoading: skeleton,
    refetch: fetchData,
  } = useRevenuesDetail(props.id, false);

  const {
    data: dataKodeUnit,
    isLoading: isLoadingKodeUnit,
    isFetching: isFetchingKodeUnit,
  } = useKodeKeuanganPagination(
    {
      current_page: 1,
      per_page: 1000,
      total: 0,
    },
    "",
    foundationId,
    "unit");

  const setData = async () => {
    const { data: successData } = await fetchData();

    if (successData) {
      form.setFieldsValue({
        codeUnitId: successData.data?.account?.code_unit_id,
        foundationId: successData.data?.account?.foundation_id,
        noVa: successData.data?.account?.number,
      });
      setFoundationId(successData.data?.account?.foundation_id);
    }
  };

  useEffect(() => {
    if (props.show) {
      setData();
    }

    return () => setNewData({});
    // eslint-disable-next-line
  }, [form, props.show]);


  const handleSubmit = async () => {
    try {
      await form.validateFields();
      if (Object.keys(newData).length === 0) {
        renderModalError("Error : Edit data pemasukan bsi", "Tidak Ada Perubahan data");
        return;
      }

      for (const key in newData) {
        if (Object.hasOwnProperty.call(newData, key)) {
          const element = newData[key];
          if (element === "") newData[key] = null;
        }
      }


      setLoading(true);

      const { data } = await axios.put(
        REACT_APP_HOST_API + `/finance/revenues/${props.id}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      message.success(data.message);
      form.resetFields();
      props.onUpdate();
    } catch (error) {
      renderModalError("Error : Edit data pemasukan BSI", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    props.onCancel();
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={handleCancel}
      okButtonProps={{ loading }}
    >
      {skeleton && <Skeleton active />}

      {!skeleton && (
        <Form form={form} layout="vertical">
          <Form.Item
            name="codeUnitId"
            label="Unit"
          // rules={[{ required: true, message: "Unit harus diisi!" }]}
          >
            <Select
              showSearch
              placeholder="Pilih Unit"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              disabled={(isLoadingKodeUnit || isFetchingKodeUnit)}
              onChange={(value) => (newData["codeUnitId"] = value)}
            >
              {dataKodeUnit?.data?.data?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {`${item.code} - ${item.name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="noVa"
            label="Nomor Rekening"
          >
            <Input
              onChange={(e) => (newData["number"] = e.target.value)}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default React.memo(EditRevenue);
