import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useEmployeeNotInLinkGroup = (group_id, unit_id, keyword) => {
  return useQuery(
    ["get-employee-not-in-link-group", group_id, unit_id, keyword],
    () =>
      GetList(
        `/employees-not-in-link-group?mode=list&linkGroupId=${group_id}&unitId=${unit_id}&keyword=${keyword}`
      )
  );
};
