import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  // InputNumber,
  message,
  Modal,
  Select,
  Space,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useCategoryList } from "hooks/link-hook/category/useCategoryList";
import { useLinkGroupDetail } from "hooks/link-hook/group/useLinkGroupDetail";
import { usePermission } from "hooks/usePermissions";
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";

export default function AddLink() {
  const [isLoading, setIsLoading] = useState(false);
  const roles = decryptCookies("role").split(",");
  const [isEmptyName, setIsEmptyName] = useState(false);
  // const isSuperAdmin = roles.some((x) => x === "super_admin");
  // const isAdminFoundation = roles.some((x) => x === "admin_foundation");
  // const isAdminHrd = roles.some((x) => x === "admin_hrd");


  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;
  const navigate = useNavigate();
  const { group_id } = useParams();

  const isDesktop = useMediaQuery({
    minWidth: 1024,
  });

  //fetch group detail
  const { data: dataGroup } = useLinkGroupDetail(group_id, true);
  //fetch categories list
  const { data: dataCategory, refetch: fetchCategories } = useCategoryList(
    "",
    dataGroup?.data?.foundation_id
  );

  // fetch  permission
  const { data: dataPermission } = usePermission("mdlLink", "mnuLinkGroup");
  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddLinkLG");

  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = async () => {
    let foundation_id = dataGroup?.data?.foundation_id || "";
    let employee_id = dataGroup?.data?.employee_id || "";

    if (!foundation_id && roles.some((x) => x === "super_admin")) {
      errorModal("Gagal menambahkan kategori", "Foundation_id tidak ditemukan");
      return;
    }

    if (!employee_id && roles.some((x) => x === "super_admin")) {
      errorModal("Gagal menambahkan kategori", "Employee_id tidak ditemukan");
      return;
    }

    if (!name) {
      message.error("Kategori harus diisi");
      setIsEmptyName(true);
      return;
    }

    try {
      await axios.post(
        REACT_APP_HOST_API + "/links/categories",
        { foundation_id, employee_id, name },
        {
          headers: {
            Authorization: `Bearer ${decryptCookies("token")}`,
          },
        }
      );
      fetchCategories();
      setName("");
      setIsEmptyName(false);

      message.success("Berhasil menambahkan kategori");
    } catch (error) {
      console.log(error);
      const msgArr = error?.response?.data?.data?.map((msg, i) => {
        return (
          <p key={i}>
            {msg.field} : {msg.message}
          </p>
        );
      });

      errorModal(
        "Gagal menambahkan kategori",
        error?.response?.data?.data ? msgArr : error?.response?.data?.message
      );
    }
  };

  useEffect(() => {
    setItems(dataCategory?.data);
  }, [dataCategory]);

  const errorModal = (title, content) => {
    Modal.error({
      title,
      content,
    });
  };

  const onCancel = () => {
    form.resetFields();
    navigate(-1);
  };

  const onSubmit = async () => {
    const value = await form.validateFields();
    setIsLoading(true);
    const payloadSuperAdmin = {
      foundation_id: dataGroup?.data?.foundation_id,
      employee_id: dataGroup?.data?.employee_id,
    };

    const payloadData = {
      ...(roles.some((x) => x === "super_admin") && payloadSuperAdmin),
      url: value.url,
      description: value.description,
      categories: value.categories,
      // order: value.order,
      groups: [group_id],
    };

    try {
      await axios.post(REACT_APP_HOST_API + `/links/links`, payloadData, {
        headers: {
          Authorization: `Bearer ${decryptCookies("token")}`,
        },
      });
      message.success("Berhasil menambahkan link");
      form.resetFields();
      navigate(-1);
    } catch (error) {
      console.log(error);
      errorModal(
        "Gagal menambahkan link",
        <pre>{error?.response?.data?.message}</pre>
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form
        form={form}
        layout="vertical"
        style={{ width: isDesktop ? "50%" : "100%" }}
      >
        <Form.Item label="Link" name="url" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Deskripsi"
          name="description"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Kategori" name="categories">
          <Select
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            mode="multiple"
            placeholder="pilih kategori..."
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: "8px 0",
                  }}
                />
                <Space
                  style={{
                    padding: "0 8px 4px",
                    position: "relative",
                  }}
                >
                  <div className="w-[180px]">
                    <Input
                      placeholder="Masukan nama kategori..."
                      ref={inputRef}
                      value={name}
                      onChange={onNameChange}
                      onKeyDown={(e) => e.stopPropagation()}
                      className={isEmptyName && "border border-[#ff4d4f]"}
                    />
                    {isEmptyName && (
                      <span className="text-[#ff4d4f] text-sm">
                        Please enter a category name
                      </span>
                    )}
                  </div>

                  <Button
                    style={{ position: "absolute", top: 0 }}
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={addItem}
                  >
                    Tambah Kategori
                  </Button>
                </Space>
              </>
            )}
            options={items?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
          />
        </Form.Item>
        {/* {
          (isSuperAdmin || isAdminFoundation || isAdminHrd) &&
          <Form.Item name="order" label="Order">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>} */}

        <div
          style={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            hidden={!btnAdd}
            disabled={btnAdd?.type === "disabled"}
            style={{ width: isDesktop ? "50%" : "100%" }}
            loading={isLoading}
            type="primary"
            onClick={() => {
              if (btnAdd?.type === "disabled") return;
              onSubmit();
            }}
          >
            Simpan
          </Button>

          <Button
            style={{ width: isDesktop ? "50%" : "100%" }}
            loading={isLoading}
            type="primary"
            ghost
            onClick={onCancel}
          >
            Batal
          </Button>
        </div>
      </Form>
    </div>
  );
}
