import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useManufacturerPagination = (dataTable, keyword) => {
  return useQuery(
    [
      "get-manufacturer-pagination",
      dataTable.per_page,
      dataTable.current_page,
      keyword,
    ],
    () =>
      GetPagination(
        `/inventories/manufacturers?page=${dataTable.current_page}&limit=${dataTable.per_page}&keyword=${keyword}&mode=page`
      )
  );
};
