import Cookies from "js-cookie";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const RequireAuth = ({ children }) => {
  const location = useLocation();

  if (Cookies.get("token") === undefined) {
    alert("Harap login terlebih dahulu");
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};

export default RequireAuth;
