import { Modal } from "antd";

export const numberFormat = (amount) => {
  if (amount === null || amount === undefined || amount === 0) return "-";

  // Format the number
  let formattedAmount = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);

  // Remove the space and replace commas with dots
  formattedAmount = formattedAmount.replace(/\s/g, "").replace(/,/g, ".");

  return formattedAmount;
};

export const renderModalError = (title, error) => {
  let msg = error?.response?.data?.message || error || "Input Field Error";

  Modal.error({
    title: title,
    content: <pre>{msg}</pre>,
  });
};
