import { Form, Input, Modal, Radio, Select } from "antd";
import React from "react";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useState } from "react";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { renderModalError } from "pages/si-finance-report/utlis/utlis";

const CreateKodeKeuangan = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  // get roles
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");


  // get foundation list
  const { data: dataFoundation } = useFoundationList();

  const handleCreateKodeKeuangan = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      if (!isSuperAdmin) {
        values.foundationId = decryptCookies("foundationId");
      }

      const { data } = await axios.post(
        `${REACT_APP_HOST_API}/financial-report/finance-codes`,
        { ...values },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      message.success(data?.message, 2);
      props.onCreate();
      form.resetFields();
    } catch (error) {
      renderModalError("Error : Tambah Kode Keuangan", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    props.onCancel();
  };



  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      open={props.show}
      onCancel={handleCancel}
      onOk={handleCreateKodeKeuangan}
      okButtonProps={{ loading }}
      title="Tambah Kode Keuangan"
    >
      <Form form={form} layout="vertical">
        {(isSuperAdmin) &&
          <Form.Item
            name="foundationId"
            label="Foundation"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder="Pilih foundation"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataFoundation?.data?.data?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        <Form.Item
          label="Kode"
          name="code"
          rules={[
            {
              required: true,
              message: "Kode harus diisi!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nama"
          name="name"
          rules={[
            {
              required: true,
              message: "Nama harus diisi!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tipe"
          name="type"
          rules={[
            {
              required: true,
              message: "Tipe harus diisi!",
            },
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
          >
            <Radio value="unit">Unit</Radio>
            <Radio value="divisi">Divisi</Radio>
            <Radio value="juknis">Juknis</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default React.memo(CreateKodeKeuangan);
