import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useAssetStatusList = () => {
  return useQuery(
    "get-asset-status-list",
    () => GetList("/inventories/asset-statuses?mode=list"),
    { enabled: false }
  );
};
