import { Button, DatePicker, Form, Select, Space, Tooltip } from "antd";
import { usePermission } from "hooks/usePermissions";
import { useEffect, useState } from "react";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { decryptCookies } from "components/Helper/CookiesHelper";
import './LaporanKeuangan.css';
import dayjs from "dayjs";
import { useKodeKeuanganPagination } from "hooks/finance-report-hook/useKodeKeuanganPagination";
import TableRecapLaporan from "./TableRecapLaporan";
import TableRecap from "./table-recap/TableRecap";
import TableDanaBos from "./TableDanaBos";
import { useLaporanKeuangan } from "hooks/finance-report-hook/useLaporanKeuangan";
import { usePDF } from "react-to-pdf";

const LaporanKeuangan = () => {
  const [date, setDate] = useState(dayjs());
  const [foundationId, setFoundationId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });
  const [isHidden, setIsHidden] = useState(false);
  const [itemStyle, setItemStyle] = useState({});
  const [shouldExport, setShouldExport] = useState(false);
  const [filterJuknis, setFilterJuknis] = useState({
    akunBiaya: true,
    akunNeraca: true,
    danaBos: true,
  });
  const [form] = Form.useForm();
  const [unitId, setUnitId] = useState("");
  const [unitName, setUnitName] = useState("");
  const [unitFoundation, setUnitFoundation] = useState("");
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  const {
    data: dataKodeUnit,
    isLoading: isLoadingKodeUnit,
    isFetching: isFetchingKodeUnit,
  } = useKodeKeuanganPagination(
    {
      current_page: 1,
      per_page: 1000,
      total: 0,
    },
    "",
    foundationId,
    "unit");

  // get laporan keuangan
  const {
    data: dataLaporanKeuangan,
    isLoading,
    isFetching,
  } = useLaporanKeuangan(unitId, date.format("YYYY-MM"), filterJuknis);

  // fetch permission
  const { data: dataPermission } = usePermission("mdlFinance", "mnuLaporanKeuangan");

  // reset page to 1 when search
  const resetPage = () => {
    setDataTable((value) => {
      return {
        ...value,
        current_page: 1,
      };
    });
  };
  // file name change to laporan keuangan - bulan - tahun - yayasan - unit
  const { toPDF, targetRef } = usePDF({
    filename: `Laporan Keuangan - ${date.format("MMMM")} ${date.format("YYYY")} -${isSuperAdmin ? `${unitFoundation} -` : ''} ${unitName}`,
  });

  const handleExportClick = () => {
    // Hide the item first
    setItemStyle({
      width: "793.70088px",
      margin: "0 auto",
      padding: "20px"
    });
    setIsHidden(true);
    // Set flag to trigger export
    setShouldExport(true);
  };



  useEffect(() => {
    if (shouldExport) {
      // Perform the export operation
      toPDF();
      // Reset the export flag
      setShouldExport(false);
      setItemStyle({});
      setIsHidden(false);
    }
  }, [shouldExport, toPDF]);

  const btnExport = dataPermission?.find((x) => x.id === "btnExportLaporanKeuangan");

  return (
    <div ref={targetRef}
      style={itemStyle}
    >
      <div className="table-header" >
        <h1>Laporan Keuangan</h1>
        {!isHidden &&
          <Space>
            <Button
              hidden={!btnExport}
              // onClick={() => setShowCreate(true)}
              disabled={(btnExport && btnExport.type === "disabled") || isLoading || isFetching}
              type="primary"
              onClick={() => handleExportClick()}
            >
              Export
            </Button>
          </Space>}
      </div >
      {!isHidden &&
        <Form className="filter-laporan-keuangan" form={form}
          layout="vertical"
        >
          <Form.Item
            style={{
              width: "100%",
            }}
            label="Bulan"
          >
            <Tooltip title="Bulan">
              <DatePicker
                picker="month"
                value={date}
                onChange={(x) => {
                  resetPage()
                  setDate(x)
                }}
                allowClear={false}
              />
            </Tooltip>
          </Form.Item>
          {roles.some((x) => x === "super_admin") && (
            <Form.Item
              style={{
                width: "100%",
              }}
              label="Yayasan"
            >
              <Tooltip Tooltip title="Yayasan">
                <Select
                  placeholder="Pilih Yayasan"
                  style={{ minWidth: 150 }}
                  onChange={(x, data) => {
                    resetPage()
                    setUnitId("")
                    setUnitName("")
                    setUnitFoundation(data.children)
                    setFoundationId(x)
                    form.resetFields(["unitId"])
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Select.Option className="select-option-foundation" value="">
                    ALL
                  </Select.Option>
                  {dataFoundation?.data?.data?.map((x) => (
                    <Select.Option
                      key={x.id}
                      value={x.id}
                      className="select-option-foundation"
                    >
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>
              </Tooltip>
            </Form.Item>
          )}
          <Form.Item name="unitId"
            style={{
              width: "100%",
            }}
            label="Unit"
          >
            <Tooltip Tooltip title="Unit">
              <Select
                placeholder="Pilih Unit"
                style={{ minWidth: 150 }}
                onChange={(_, data) => {
                  setUnitId(data?.key)
                  setUnitName(data?.children[0])
                }}
                showSearch
                optionFilterProp="children"
                filterOption={
                  (input, option) => option.value.toLowerCase().includes(input.toLowerCase())
                }
                disabled={isLoadingKodeUnit || isFetchingKodeUnit}
              >
                {dataKodeUnit?.data?.data?.map((x) => (
                  <Select.Option
                    key={x.id}
                    value={`${x.code} - ${x.name}${isSuperAdmin && ` - ${x.foundation.name}`}`}
                    className="select-option-foundation"
                  >
                    {x.code} - {x.name}{isSuperAdmin && ` - ${x.foundation.name}`}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
          </Form.Item>
          <Form.Item
            style={{
              width: "100%",
            }}
            label="Juknis"
          >
            <Tooltip title="Juknis">
              <Select
                placeholder="Pilih Juknis"
                style={{ minWidth: 150 }}

                onChange={(selectedOptions) => {
                  setFilterJuknis(() => {
                    // Initialize all options to false
                    const updatedFilter = { akunBiaya: false, akunNeraca: false, danaBos: false };

                    // Set selected options to true
                    selectedOptions.forEach(option => {
                      updatedFilter[option] = true;
                    });

                    return updatedFilter;
                  });
                }}
                maxTagCount="responsive"
                allowClear
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                defaultValue={["akunBiaya", "akunNeraca", "danaBos"]}
              >
                <Select.Option value="akunBiaya">
                  Akun Biaya
                </Select.Option>
                <Select.Option value="akunNeraca">
                  Akun Neraca
                </Select.Option>
                <Select.Option value="danaBos">
                  Dana Bos
                </Select.Option>
              </Select>
            </Tooltip>
          </Form.Item>
        </Form>}
      <TableRecapLaporan
        result={dataLaporanKeuangan?.result}
        isLoading={isLoading || isFetching}
      />
      <TableRecap
        data={dataLaporanKeuangan?.recap}
        isLoading={isLoading || isFetching}
        hiddenPagination={isHidden}
      />
      <TableDanaBos
        data={dataLaporanKeuangan?.dana_bos}
        isLoading={isLoading || isFetching}
      />
    </div >
  );
};

export default LaporanKeuangan;
