import RequireAuth from "components/Auth/RequireAuth";
import RequireMenuInventory from "components/Auth/RequireMenuInventory";
import LayoutInventory from "Layouts/LayoutInventory/LayoutInventory";
import AssetLoan from "pages/si-inventory/asset-loan/master/AssetLoan";
import AssetsStatus from "pages/si-inventory/assets/asset-status/AssetsStatus";
import DetailAssets from "pages/si-inventory/assets/detail/DetailAssets";
import Assets from "pages/si-inventory/assets/master/Assets";
import Filters from "pages/si-inventory/filter-inventory/master/Filters";
import LoanBatch from "pages/si-inventory/loan-batch/LoanBatch";
import LoanBatchDetail from "pages/si-inventory/loan-batch/LoanBatchDetail";
import Manufacturers from "pages/si-inventory/manufacturers/Manufacturers";
import MasterInventory from "pages/si-inventory/MasterInventory";

export const DataRoutesInventory = [
  {
    path: "inventory",
    element: (
      <RequireAuth>
        <RequireMenuInventory>
          <LayoutInventory content={<MasterInventory />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
  {
    path: "inventory/manufacturers",
    element: (
      <RequireAuth>
        <RequireMenuInventory menu="mnuManufacturers">
          <LayoutInventory content={<Manufacturers />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
  {
    path: "inventory/assets",
    element: (
      <RequireAuth>
        <RequireMenuInventory menu="mnuAssets">
          <LayoutInventory content={<Assets />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
  {
    path: "inventory/assets/detail-:id",
    element: (
      <RequireAuth>
        <RequireMenuInventory menu="mnuAssets">
          <LayoutInventory content={<DetailAssets />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
  {
    path: "inventory/assets/:statusId",
    element: (
      <RequireAuth>
        <RequireMenuInventory menu="mnuAssets">
          <LayoutInventory content={<AssetsStatus />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
  {
    path: "inventory/loanbatch",
    element: (
      <RequireAuth>
        <RequireMenuInventory menu="mnuLoanBatch">
          <LayoutInventory content={<LoanBatch />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
  {
    path: "inventory/loanbatch/:batch_id",
    element: (
      <RequireAuth>
        <RequireMenuInventory menu="mnuLoanBatch">
          <LayoutInventory content={<LoanBatchDetail />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
  {
    path: "inventory/assetloan",
    element: (
      <RequireAuth>
        <RequireMenuInventory menu="mnuAssetLoan">
          <LayoutInventory content={<AssetLoan />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
  {
    path: "inventory/filters",
    element: (
      <RequireAuth>
        <RequireMenuInventory menu="mnuFilters">
          <LayoutInventory content={<Filters />} />
        </RequireMenuInventory>
      </RequireAuth>
    ),
  },
];
