import React from "react";
import styles from "./ParentBilling.module.css";
import { Button } from "antd";
import { usePDF } from "react-to-pdf";
import { useDownloadTagihan } from "hooks/finance-hook/useDownloadTagihan";
import { decryptCookies } from "components/Helper/CookiesHelper";
import useConvertImgToBase64 from "components/Helper/useConvertImgToBase64";
import { numberFormat } from "components/Helper/financeUtils";

export default function DownloadTagihan() {
  const parentId = decryptCookies("parent_id");
  const { data } = useDownloadTagihan(parentId);

  const imageUrl = data?.data?.unit?.logo;
  const tunggakan = data?.data?.billings?.tunggakan;
  const kelas = data?.data?.student?.kelas;
  // Get the first word from the kelas string
  const firstWord = kelas ? kelas.split(' ')[0] : '';

  const remainder = data?.data?.remainder;
  const isNegative = remainder < 0;
  const isPositive = remainder > 0;

  const getStatusText = () => {
    if (isNegative) return "Sisa Tagihan:";
    return "Lunas:";
  };

  const getStatusClass = () => {
    if (isNegative) return "text-red-500 bg-red-300";
    return "text-green-500 bg-green-300";
  };

  const getFooterText = () => {
    if (isNegative) return "*Nominal di atas terakumulasi selama satu semester";
    if (isPositive) return "*Lebih bayar diakumulasikan untuk pembayaran semester berikutnya";
    return "";
  };

  const getTagihanText = () => {
    if (tunggakan < 0) {
      return "Telah Dibayar Sebelum Semester 1";
    } else {
      switch (firstWord) {
        case "X":
          return "Tagihan BP + Seragam + BWT";
        case "XI":
          return "Tagihan Kelas X";
        case "XII":
          return "Tagihan Kelas X - XI";
        default:
          return "Tagihan";
      }
    }
  };

  const imgBase64 = useConvertImgToBase64(imageUrl);


  const { toPDF, targetRef } = usePDF({
    filename: `Kartu Pembiayaan.pdf`,
  });
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <Button
          type="primary"
          onClick={toPDF}
          size="large"
          style={{ float: "right" }}
        >
          Download
        </Button>
        <div ref={targetRef}>
          {/* header--- */}
          <header className={styles.header}>
            <div className={styles.logo}>
              {/* <h1>logo</h1> */}
              <img src={imgBase64} alt="logo" />
            </div>

            <div className={styles.text}>
              <h1>{data?.data?.unit?.name}</h1>
              <p>
                {/* Jl. Yudhistira, Komplek Pemda-Kampung Bulak, 03/03, Jatiasih,
                Bekasi */}
                {data?.data?.unit?.address}
              </p>
              <p>
                {/* cs@smafg.sch.id +62 851 0073 4490 smafuturegate.com */}
                {data?.data?.unit?.email} {data?.data?.unit?.phone}{" "}
                {data?.data?.unit?.website}
              </p>
            </div>
          </header>
          <span className={styles.lineHead}></span>
          {/* end header--- */}

          {/* body--- */}
          <div className={styles.body}>
            <div
              style={{
                border: "1px solid #bbb",
                padding: "20px 0px 10px 0px",
              }}
            >
              <div className={styles.title}>
                <h1>Kartu Administrasi Siswa</h1>
                <p className="mt-4 text-red-500 font-bold">
                  {data?.data?.semester}
                </p>
              </div>

              <div className="grid grid-cols-6 text-sm px-10">
                <p className="col-span-1 mb-0">Nama</p>
                <p className="col-span-2 mb-0">: {data?.data?.student?.name}</p>
                <p className="col-span-1 mb-0">VA</p>
                <p className="col-span-2 mb-0">: {data?.data?.student?.no_va}</p>
                <p className="col-span-1 mb-0">Angakatan</p>
                <p className="col-span-2 mb-0">: {data?.data?.student?.angkatan}</p>
                <p className="col-span-1 mb-0">NISN</p>
                <p className="col-span-2 mb-0">: {data?.data?.student?.nisn}</p>
                <p className="col-span-1 mb-0">Kelas</p>
                <p className="col-span-2 mb-0">: {data?.data?.student?.kelas}</p>
                <p className="col-span-1 mb-0">Program</p>
                <p className="col-span-2 mb-0">: {data?.data?.student?.program}</p>

                <p className="col-span-3 mb-0 mt-5 font-bold">Besaran SPP</p>
                <p className="col-span-3 mb-0 mt-5 font-bold">: {numberFormat(data?.data?.spp)}</p>
                <p className="col-span-3 mb-0 font-bold">Besaran BWT</p>
                <p className="col-span- mb-0 font-bold">: {numberFormat(data?.data?.bwt)}</p>
              </div>

              <div className="grid grid-cols-2 mt-5 gap-1">
                <div className="bg-red-300 col-span-2 grid grid-cols-2">
                  <p className="mb-0 text-sm text-center font-bold">Tagihan</p>
                  <p className="mb-0 text-sm text-center font-bold">Jumlah</p>
                </div>
                <div className="px-10 col-span-2 grid grid-cols-2">
                  <p className="mb-0 text-sm text-left">Tagihan {data?.data?.semester} {data?.data?.student.kelas}  (BWT + SPP)</p>
                  <p className="mb-0 text-sm text-right">{numberFormat(data?.data?.billings.tagihan_semester)}</p>

                  <p className="mb-0 text-sm text-left">{getTagihanText()}</p>
                  <p className="mb-0 text-sm text-right">{numberFormat(data?.data?.billings.tunggakan)}</p>

                  <p className="mb-0 text-sm text-left">Total Tagihan {data?.data?.semester}</p>
                  <p className="mb-0 text-sm text-right">{numberFormat(data?.data?.billings.total_tagihan)}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 mt-5 gap-1">
                <div className="bg-green-300 col-span-2 grid grid-cols-2">
                  <p className="mb-0 text-sm text-center font-bold">Pembayaran</p>
                  <p className="mb-0 text-sm text-center font-bold">Tanggal</p>
                </div>

                {data?.data?.revenues?.revenue?.map((item, index) => (
                  <React.Fragment key={index}>
                    <p className="mb-0 text-sm text-center">{numberFormat(item.amount)}</p>
                    <p className="mb-0 text-sm text-center">{item.time_received}</p>
                  </React.Fragment>
                ))}

                <p className="mb-0 text-sm text-center font-bold">Total Pembayaran {data?.data?.semester}</p>
                <p className="mb-0 text-sm text-center font-bold">{numberFormat(data?.data?.revenues?.total_revenue)}</p>
              </div>


              <div className="grid grid-cols-2 mt-5 px-10 gap-1">
                <p className="mb-0 text-sm text-left font-bold">{getStatusText()}</p>
                <p className={`mb-0 text-sm text-center font-bold ${getStatusClass()}`}>
                  {numberFormat(remainder)}
                </p>
                <p className="mb-0 text-xs font-bold col-span-2 text-right">{getFooterText()}</p>
              </div>

              <div className="grid grid-cols-2 mt-5 gap-1 px-8">
                <div className="col-span-2 grid grid-cols-2 bg-gray-200 px-2 font-bold">
                  <p className="mb-0 text-sm text-left">Update Pembayaran Tanggal:</p>
                  <p className="mb-0 text-sm text-center">{data?.data?.update_pembayaran}</p>
                </div>
                <p className="mb-0 text-xs font-bold col-span-2">*Bila terdapat perbedaan data dengan catatan Bapak/Ibu,serta pertanyaan dapat diajukan melalui nomor WhatsApp: <a href="https://wa.me/6285100734490"
                  className="inline text-blue-500 hover:underline"
                >
                  +6285100734490
                </a>
                </p>

                <p className="mb-0 text-sm col-span-2 mt-5 font-bold">Pembayaran ke rekening Bank BSI dengan tata cara pembayaran dapat dilihat melalui link berikut ini:</p>
                <a
                  href="https://drive.google.com/file/d/1EzZeiq_ChY0P9PbPmka6S2dwgsZwsEpr/view?usp=sharing"
                  className="mb-0 text-sm font-bold col-span-2 text-blue-500 hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >https://drive.google.com/file/d/1EzZeiq_ChY0P9PbPmka6S2dwgsZwsEpr/view?usp=sharing
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* end body--- */}
      </div>
    </div>
  );
}
