import { Image, message, Modal, Tooltip } from "antd";
import React from "react";
import { FaRegCopy } from "react-icons/fa";

export default function DetailManufacturer({ open, setOpen, data }) {
  const onCopyLink = () => {
    navigator.clipboard.writeText(data?.url);
    message.success("Berhasil menyalin link");
  };
  return (
    <Modal
      open={open}
      onCancel={setOpen}
      onOk={setOpen}
      okText="Tutup"
      cancelButtonProps={{ hidden: true }}
    >
      <table>
        <tbody>
          <tr>
            <td>Nama</td>
            <td>: {data?.name}</td>
          </tr>
          <tr>
            <td>Link Url</td>
            <td
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              :
              <a
                href={
                  data?.url?.startsWith("http://") ||
                  data?.url?.startsWith("https://")
                    ? data?.url
                    : `https://${data?.url}`
                }
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#4096FF",
                  marginLeft: 5,
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-all",
                  maxWidth: "100%",
                }}
              >
                {data?.url}
              </a>
              <Tooltip title="Copy Link">
                <span
                  style={{
                    cursor: "pointer",
                    marginLeft: 5,
                    color: "#4096FF",
                  }}
                  onClick={() => onCopyLink()}
                >
                  <FaRegCopy />
                </span>
              </Tooltip>
            </td>
          </tr>
          <tr>
            <td>No Hp</td>
            <td>: {data?.support_phone}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>: {data?.support_email}</td>
          </tr>
          <tr>
            <td>Gambar</td>
            <td>
              <Image width={200} src={data?.image?.props?.src} alt="image" />
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
}
