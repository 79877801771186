import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useAboutDetail = (link_id, enabled) => {
  return useQuery(
    ["get-about-detail", link_id],
    () => GetDetail(`/landing-page/abouts/${link_id}`),
    {
      enabled,
    }
  );
};
