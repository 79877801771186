import { Form, Input, message, Modal } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { editValidation } from "components/Helper/editValidation";
import React, { useEffect, useState } from "react";

export default function EditFundSource({ open, setOpen, record }) {
  const [isLoading, setIsLoading] = useState(false);
  const [newData, setNewData] = useState({});

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        name: record?.name,
      });
    }
    return () => {
      setNewData({});
    };
  }, [record, open, form]);

  const onSubmit = async () => {
    await form.validateFields();

    const isValid = editValidation({ newData });
    if (!isValid) return;

    setIsLoading(true);

    try {
      await axios.put(
        `${REACT_APP_HOST_API}/inventories/asset-fund-source/${record?.id}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );

      message.success("Berhasil mengubah sumber dana");
      onCancel();
    } catch (error) {
      Modal.error({
        title: "Gagal Mengubah",
        content: <pre>{error?.response?.data?.message}</pre>,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setOpen(false);
  };

  return (
    <Modal
      loading={isLoading}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
      okText="Simpan"
      cancelText="Batal"
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Nama" name="name" rules={[{ required: true }]}>
          <Input onChange={({ target: { value } }) => (newData.name = value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
