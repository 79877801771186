import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useAssetStatusPagination = (params) => {
  const {
    dataTable = "",
    keyword = "",
    statusId = "",
    foundationId = "",
    categoryId = "",
    fundSourceId = "",
  } = params;

  return useQuery(
    [
      "get-asset-status-pagination",
      dataTable.per_page,
      dataTable.current_page,
      keyword,
      statusId,
      foundationId,
      categoryId,
      fundSourceId,
    ],
    () =>
      GetPagination(
        `/inventories/assets?page=${dataTable.current_page}&limit=${
          dataTable.per_page
        }&keyword=${keyword}&mode=page&status=${statusId.toUpperCase()}&foundationId=${foundationId}&categoryId=${categoryId}&fundSourceId=${fundSourceId}`
      )
  );
};
