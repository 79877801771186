import { Form, InputNumber, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { useAccountList } from "hooks/finance-hook/useAccountList";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

const AddBillingsLogic = () => {
  const [form] = Form.useForm();
  const [searchQuery, setSearchQuery] = useState("");
  const [search] = useDebounce(searchQuery, 2000);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedDate, setSelectedDate] = useState({});
  const [tipe, setTipe] = useState("");
  const [tipeFilter, setTipeFilter] = useState("");
  const [posting, setPosting] = useState(false);
  const { REACT_APP_HOST_API } = process.env;

  const [dataSource, setDataSource] = useState([]);

  const {
    data: accountsRef,
    isLoading: isLoadingRef,
    refetch,
  } = useAccountList(search, tipe === "others" ? tipeFilter : tipe);

  const navigate = useNavigate();

  const navigateToBillings = () => {
    navigate("/finance/tabeltagihan");
  };
  const handleInputChange = (key, value) => {
    setDataSource((prevData) =>
      prevData.map((item) =>
        item.key === key ? { ...item, amount_input: value } : item
      )
    );
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      fixed: "left",
      width: window.innerWidth > 800 ? 70 : 50,
      responsive: ["md"],
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
      width: window.innerWidth > 800 ? 200 : 65,
      fixed: "left",
    },
    {
      title: "No. Rekening",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Jenis VA",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Nominal ",
      dataIndex: "amount_input",
      key: "amount_input",
      render: (balance, record) => {
        return (
          <Form.Item name={`amount_${record.id}`}>
            <InputNumber
              onChange={(e) => handleInputChange(record.key, e)}
              formatter={(value) =>
                `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              }
              parser={(value) => value.replace(/Rp\s?|(\.*)/g, "")}
              style={{ width: "100%" }}
            />
          </Form.Item>
        );
      },
    },
  ];

  useEffect(() => {
    if (tipe) {
      refetch();
    }
    // eslint-disable-next-line
  }, [tipe, tipeFilter, searchQuery]);

  const onSelectChange = (selectedRowKeys, record) => {
    setSelectedIds(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  const mapData = accountsRef?.data.map((x, i) => {
    return {
      ...x,
      key: x.id,
      index: i + 1,
      name: x?.account_name,
      number: x?.number,
      amount_input: 0,
    };
  });

  useEffect(() => {
    setDataSource(mapData);
    // eslint-disable-next-line
  }, [accountsRef?.data]);

  const handleSubmit = async () => {
    try {
      setPosting(true);
      const values = await form.validateFields();
      if (selectedIds.length === 0)
        return alert("Harap pilih akun yang akan ditambahkan");

      const filterAmountById = dataSource.filter((x) => {
        return selectedIds.includes(x.id);
      });

      const result = filterAmountById.map((x) => {
        const ifTypeNotOthers = {
          account_id: x.id,
          type: tipe,
          amount: x.amount_input,
          due_date: dayjs(values.due_date).format("YYYY-MM"),
        };
        const ifTypeOthers = {
          ...ifTypeNotOthers,
          description: values.description,
        };
        return values.type === "others" ? ifTypeOthers : ifTypeNotOthers;
      });

      const { data } = await axios.post(
        REACT_APP_HOST_API + `/finance/billings`,
        { billings: result },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      form.resetFields();
      setSelectedIds([]);
      setSearchQuery("");
      navigateToBillings();
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Gagal generate billings"
      );
    } finally {
      setPosting(false);
    }
  };

  return {
    isLoadingRef,
    accountsRef,
    form,
    columns,
    handleSubmit,
    navigateToBillings,
    searchQuery,
    posting,
    dataSource,
    rowSelection,
    setTipe,
    tipe,
    tipeFilter,
    setTipeFilter,
    selectedDate,
    setSelectedDate,
    selectedIds,
    setSelectedIds,
    setSearchQuery,
  };
};

export default AddBillingsLogic;
