import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Table, Tag, Tooltip } from "antd";
import FilterFoundation from "components/UI/FilterFoundation";
import React, { useState } from "react";
import AddCategory from "../create/AddCategory";
import { usePermission } from "hooks/usePermissions";
import { useAssetCategoriesPagination } from "hooks/asset-hook/useAssetCategoriesPagination";
import { DeleteApi } from "api/DeleteApi";
import EditCategory from "../edit/EditCategory";
import useResetPage from "components/Helper/useResetPage";

export default function Categories({ roles }) {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [record, setRecord] = useState({});
  const [foundationId, setFoundationId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });

  const {
    data: dataCategories,
    refetch,
    isFetching,
  } = useAssetCategoriesPagination(dataTable, keyword, foundationId);

  // fetch permission
  const { data: dataPermission } = usePermission("mdlInventory", "mnuFilters");

  // Buttons Permission
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddCategoryInven");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditCategoryInven");
  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeleteCategoryInven"
  );

  const disabled = { color: "#ccc", cursor: "not-allowed" };
  const columns = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Kategori",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Aksi",
      dataIndex: "id",
      key: "id",
      render: (id, record) => {
        const isDisabled = (action) => {
          return action?.type === "disabled";
        };

        const getButtonStyle = (action) => {
          return isDisabled(action) ? disabled : { cursor: "pointer" };
        };
        return (
          <>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                DeleteApi({
                  url: "/inventories/asset-categories/",
                  dataId: id,
                  refetch,
                });
              }}
              disabled={isDisabled(btnDelete)}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={getButtonStyle(btnDelete)}
              >
                Hapus
              </Tag>
            </Popconfirm>

            <Tag
              color="orange"
              hidden={!btnEdit}
              style={getButtonStyle(btnEdit)}
              onClick={() => {
                if (!isDisabled(btnEdit)) {
                  setRecord(record);
                  setOpenEdit(true);
                }
              }}
              disabled={isDisabled(btnEdit)}
            >
              Ubah
            </Tag>
          </>
        );
      },
    },
  ];

  const dataSource = dataCategories?.data?.data?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: dataCategories?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100, dataCategories?.data.meta.total],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const handleOpenAdd = () => {
    setOpenAdd(!openAdd);
    refetch();
  };

  const handleOpenEdit = () => {
    setOpenEdit(!openEdit);
    refetch();
  };

  useResetPage(setDataTable, [keyword, foundationId]);

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Kategori</h1>
        <Button
          disabled={btnAdd && btnAdd.type === "disabled"}
          hidden={!btnAdd}
          type="primary"
          onClick={() =>
            btnAdd && btnAdd.type !== "disabled" ? handleOpenAdd() : null
          }
        >
          Tambah
        </Button>
      </div>

      <div className="grid gap-2 md:grid-cols-2 md:w-1/2">
        <Tooltip title="Cari Kategori">
          <Input
            onChange={(e) => setKeyword(e.target.value)}
            prefix={<SearchOutlined />}
            placeholder="Cari Kategori..."
          />
        </Tooltip>

        {roles.includes("super_admin") && (
          <FilterFoundation setFoundationId={setFoundationId} />
        )}
      </div>

      <Table
        loading={isFetching}
        columns={columns}
        dataSource={dataSource}
        size="small"
        pagination={pagination}
        scroll={{ y: 400 }}
      />

      <AddCategory open={openAdd} setOpen={handleOpenAdd} />
      <EditCategory open={openEdit} setOpen={handleOpenEdit} record={record} />
    </div>
  );
}
