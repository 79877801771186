import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Table, Tag, Tooltip } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { useLinkGroupMemberPagination } from "hooks/link-hook/group/useLinkGroupMemberPagination";
import { usePermission } from "hooks/usePermissions";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const disabled = { color: "#ccc", cursor: "not-allowed" };
export default function Anggota({ data }) {
  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 100,
    total: 0,
  });

  const navigate = useNavigate();
  const { group_id } = useParams();

  const {
    data: dataMember,
    refetch,
    isFetching,
  } = useLinkGroupMemberPagination(dataTable, group_id, keyword);

  // fetch  permission
  const { data: dataPermission } = usePermission("mdlLink", "mnuLinkGroup");

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddMemberLinkGroup");
  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeleteMemberLinkGroup"
  );

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
    },
    {
      title: "NIP",
      dataIndex: "nip",
    },
    {
      title: "Aksi",
      dataIndex: "id",
      render: (id) => {
        const isDisabled = (action) => {
          return action?.type === "disabled";
        };
        return (
          <Popconfirm
            title="Yakin ingin menghapus ?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() => {
              DeleteApi({
                url: `/links/link-groups/${group_id}/members?id=`,
                dataId: id,
                refetch,
              });
            }}
            disabled={isDisabled(btnDelete)}
          >
            <Tag
              color="magenta"
              hidden={!btnDelete || !data?.data?.is_owner}
              style={isDisabled(btnDelete) ? disabled : { cursor: "pointer" }}
            >
              Hapus
            </Tag>
          </Popconfirm>
        );
      },
    },
  ];

  const dataSource = dataMember?.data?.data?.map((x) => ({
    ...x,
    key: x.id,
    name: x.name,
    nip: x.nip,
    id: x.id,
  }));

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: dataMember?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };
  return (
    <div>
      <div className="table-header">
        <h1>Orang yang dapat mengakses link grup</h1>

        <Button
          hidden={!btnAdd || !data?.data?.is_owner}
          disabled={btnAdd?.type === "disabled"}
          onClick={() => {
            if (!btnAdd?.type === "disabled") {
              return;
            }
            navigate("add-members");
          }}
          type="primary"
        >
          Tambah
        </Button>
      </div>

      <Tooltip title="Cari">
        <Input
          prefix={<SearchOutlined />}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="cari berdasarkan nama..."
          className="search-input-billings"
        />
      </Tooltip>

      <Table
        loading={refetch || isFetching}
        columns={columns}
        dataSource={dataSource}
        size="small"
        pagination={pagination}
        scroll={{ y: "calc(100vh - 300px)" }}
      />
    </div>
  );
}
