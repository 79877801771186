import RequireAuth from "components/Auth/RequireAuth";
import LayoutFinance from "Layouts/LayoutFinance/LayoutFinance";
import MasterFinance from "pages/si-finance/MasterFinance";
import RequireMenuFinance from "components/Auth/RequireMenuFinance";
import Billings from "pages/si-finance/master-billings/master/Billings";
import Account from "pages/si-finance/master-account/master/Account";
import AddBillings from "pages/si-finance/master-billings/create/AddBillings";
import AddAccountsBatch from "pages/si-finance/master-account/create-batch/AddAccountsBatch";
import AlokasiPembayaran from "pages/si-finance/master-alokasi-pembayaran/master/AlokasiPembayaran";
import AlokasiPembayaranRevenues from "pages/si-finance/master-alokasi-pembayaran-revenues/master/AlokasiPembayaran";
import Revenues from "pages/si-finance/master-revenues/master/Revenues";
import DetailBillings from "pages/si-finance/master-billings/detail/DetailBillings";
import RecapsBillings from "pages/si-finance/master-recaps-billings/master/RecapsBillings";
import PdfRender from "pages/si-finance/master-recaps-billings/pdf-renderer/PdfRender";
import { AccountProvider } from "context/finance-context/AccountContext";
import DetailAccount from "pages/si-finance/master-account/detail/DetailAccount";
import AlokasiDana from "pages/si-finance/master-alokasi-dana/AlokasiDana";
import AlokasiManual from "pages/si-finance/master-alokasi-dana/AlokasiManual";
import ParentStudent from "pages/si-finance/master-parent/parent-student/ParentStudent";
import AddStudent from "pages/si-finance/master-parent/parent-student/create/AddStudent";
import ParentBilling from "pages/si-finance/master-parent/parent-billings/ParentBilling";
import DownloadTagihan from "pages/si-finance/master-parent/parent-billings/DownloadTagihan";
import ImportBsi from "pages/si-finance/master-import-bsi/master/ImportBsi";

export const DataRoutesFinance = [
  {
    path: "finance/parentstudent",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuParentStudent">
          <LayoutFinance content={<ParentStudent />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/parentstudent/add",
    element: (
      <RequireAuth>
        {/* <RequireMenuFinance menu="mnuParentStudent"> */}
        <LayoutFinance content={<AddStudent />} />
        {/* </RequireMenuFinance> */}
      </RequireAuth>
    ),
  },
  {
    path: "finance/parentbilling",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuParentBilling">
          <LayoutFinance content={<ParentBilling />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/download-tagihan",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuParentBilling">
          <DownloadTagihan />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/alokasidana",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuAlokasiDana">
          <LayoutFinance content={<AlokasiDana />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/alokasidana/:id",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuAlokasiDana">
          <LayoutFinance content={<AlokasiManual />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance",
    element: (
      <RequireAuth>
        <RequireMenuFinance>
          <LayoutFinance content={<MasterFinance />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },

  {
    path: "finance/tabeltagihan",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuBillings">
          <LayoutFinance content={<Billings />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/tabeltagihan/:billings_id",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuBillings">
          <LayoutFinance content={<DetailBillings />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/tabeltagihan/tambah",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuBillings">
          <LayoutFinance content={<AddBillings />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/datava",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuAccount">
          <LayoutFinance
            content={
              <AccountProvider>
                <Account />
              </AccountProvider>
            }
          />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },

  {
    path: "finance/account/:id",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuAccount">
          <LayoutFinance
            content={
              <AccountProvider>
                <DetailAccount />
              </AccountProvider>
            }
          />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },

  {
    path: "finance/account/tambah",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuAccount">
          <LayoutFinance content={<AddAccountsBatch />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },

  {
    path: "finance/alokasipembayaran/:payment_proof_id",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuPaymentProof">
          <LayoutFinance content={<AlokasiPembayaran />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/data-pemasukan",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuRevenues">
          <LayoutFinance content={<Revenues />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/alokasipembayaranrevenues/:revenues_id",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuRevenues">
          <LayoutFinance content={<AlokasiPembayaranRevenues />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/recapsbillings",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuRecapsBillings">
          <LayoutFinance content={<RecapsBillings />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/recapsbillings/:student_id",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuRecapsBillings">
          {<PdfRender />}
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/importbsi",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuImportBsi">
          <LayoutFinance content={<ImportBsi />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
];
