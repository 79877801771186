import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useAgendaList = (
  keyword = "",
  fromDate = "",
  toDate = "",
  foundationId = ""
) => {
  return useQuery(
    ["get-agenda-list", keyword, fromDate, toDate, foundationId],
    () =>
      GetList(
        `/academics/agendas?keyword=${keyword}&fromDate=${fromDate}&toDate=${toDate}&foundationId=${foundationId}&mode=list`
      ),
    {
      enabled: true,
    }
  );
};
