import React from "react";
import Categories from "./Categories";
import FundSource from "./FundSource";
import { decryptCookies } from "components/Helper/CookiesHelper";

export default function Filters() {
  const roles = decryptCookies("role").split(",");
  return (
    <div>
      <FundSource roles={roles} />
      <div className="mt-10" />
      <Categories roles={roles} />
    </div>
  );
}
