import { Form, Input, message, Modal, Select } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import React, { useState } from "react";

export default function AddFundSource({ open, setOpen }) {
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();
  const roles = decryptCookies("role").split(",");
  const { REACT_APP_HOST_API } = process.env;

  const { data: dataFoundation } = useFoundationList();

  const onSubmit = async () => {
    const value = await form.validateFields();
    setIsLoading(true);
    try {
      await axios.post(
        `${REACT_APP_HOST_API}/inventories/asset-fund-source`,
        value,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );

      message.success("Berhasil menambahkan sumber dana");
      onCancel();
    } catch (error) {
      Modal.error({
        title: "Gagal Menyimpan",
        content: <pre>{error?.response?.data?.message}</pre>,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setOpen(false);
  };

  return (
    <Modal
      loading={isLoading}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
      okText="Simpan"
      cancelText="Batal"
    >
      <Form form={form} layout="vertical">
        {roles.includes("super_admin") && (
          <Form.Item
            label="Yayasan"
            name="foundation_id"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option value={x?.id} key={x?.id}>
                  {x?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item label="Nama" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
