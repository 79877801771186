import {
  Button,
  Form,
  Input,
  Table,
  DatePicker,
  Row,
  Col,
  Tooltip,
  Select,
} from "antd";
import "./AddBillings.css";
import AddBillingsLogic from "./AddBillingsLogic";
import { SearchOutlined } from "@ant-design/icons";

const AddBillings = () => {
  const {
    isLoadingRef,
    form,
    columns,
    handleSubmit,
    searchQuery,
    posting,
    dataSource,
    rowSelection,
    setTipe,
    tipe,
    // tipeFilter,
    setTipeFilter,
    selectedDate,
    setSelectedDate,
    selectedIds,
    setSelectedIds,
    setSearchQuery,
  } = AddBillingsLogic();

  const clearSelectedIdsAndAmountFields = () => {
    setSelectedIds([]);
    form.resetFields(
      Object.keys(form.getFieldsValue()).filter((key) =>
        key.startsWith("amount_")
      )
    );
  };

  return (
    <div>
      <div className="table-header">
        <h1>Buat Tagihan</h1>
      </div>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row>
          <Col span={24} style={{ display: "flex", gap: 20 }}>
            <Form.Item
              name="type"
              label="Tipe Tagihan"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "Harap diisi" }]}
            >
              <Select
                onChange={(e) => {
                  setTipe(e);
                  clearSelectedIdsAndAmountFields();
                }}
              >
                <Select.Option value="spp">SPP</Select.Option>
                <Select.Option value="bwt">BWT</Select.Option>
                <Select.Option value="bp">BP</Select.Option>
                <Select.Option value="fg_extra">FG Extra</Select.Option>
                {/* others string kosong */}
                <Select.Option value="others">Lain-lain</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Jatuh Tempo"
              name="due_date"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "Harap diisi" }]}
              onChange={(e) => setSelectedDate(e.target.value)}
            >
              <DatePicker inputReadOnly picker="month" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ display: "flex", gap: 20 }}>
            <Form.Item
              name="description"
              label="Keterangan"
              style={{ width: "100%" }}
              rules={[{ required: tipe === "others", message: "Harap diisi" }]}
              hidden={tipe !== "others"}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Jenis Va"
              name="account_type"
              style={{ width: "100%" }}
              hidden={tipe !== "others"}
            >
              <Select
                onChange={(e) => {
                  setTipeFilter(e);
                  clearSelectedIdsAndAmountFields();
                }}
              >
                <Select.Option value="">Semua</Select.Option>
                <Select.Option value="spp">SPP</Select.Option>
                <Select.Option value="fg_extra">FG Extra</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div className="search-wrapper filter-wrapper">
          <Tooltip title="nisn/nama/nomor VA">
            <Form.Item style={{ width: "100%", marginBottom: 0 }}>
              <Input
                prefix={<SearchOutlined />}
                style={{
                  border: "1px solid #d9d9d9",
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="cari account berdasarkan nisn/nama/nomor VA"
                disabled={posting}
              />
            </Form.Item>
          </Tooltip>
        </div>
        <Table
          style={{ marginBottom: 20 }}
          size={window.innerWidth > 1600 ? "middle" : "small"}
          // pagination={false}
          tableLayout="auto"
          dataSource={tipe ? dataSource : []}
          columns={columns}
          loading={isLoadingRef}
          rowSelection={rowSelection}
          scroll={
            window.innerHeight < 690
              ? {
                y: "40vh",
                x: 800,
              }
              : window.innerHeight < 760
                ? {
                  y: "50vh",
                  x: 800,
                }
                : {
                  y: "55vh",
                  x: 800,
                }
          }
        />
        <div className="button-container">
          <Button
            type="primary"
            htmlType="submit"
            loading={posting}
            disabled={!tipe || !selectedDate || selectedIds.length === 0}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddBillings;
