import { Button, Tabs } from "antd";
import React, { useState } from "react";
import Detail from "./tab/Detail";
import Anggota from "./tab/Anggota";
import { useLinkGroupDetail } from "hooks/link-hook/group/useLinkGroupDetail";
import { useParams } from "react-router-dom";

export default function DetailLinkGroup() {
  const [activeTab, setActiveTab] = useState("detail");

  const { group_id } = useParams();

  //fetch link group detail
  const { data, refetch } = useLinkGroupDetail(group_id, true);

  const onChange = (key) => {
    setActiveTab(key);
  };

  const items = [
    {
      key: "detail",
      label: "Detail",
      children: <Detail data={data} refetch={refetch} />,
    },
    {
      key: "anggota",
      label: "Anggota",
      children: <Anggota data={data} />,
    },
  ];

  return (
    <div>
      <div className="block md:hidden">
        <div className="flex gap-2">
          <Button
            type="primary"
            ghost={activeTab !== "detail"}
            onClick={() => setActiveTab("detail")}
            size="small"
          >
            Detail
          </Button>

          <Button
            type="primary"
            ghost={activeTab !== "anggota"}
            onClick={() => setActiveTab("anggota")}
            size="small"
          >
            Anggota
          </Button>
        </div>
      </div>

      <Tabs activeKey={activeTab} items={items} onChange={onChange} />
    </div>
  );
}
