import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Table, Tag, Tooltip } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { usePermission } from "hooks/usePermissions";
import React, { useState } from "react";
import CreateCategory from "../create/CreateCategory";
import EditCategory from "../edit/EditCategory";
import FilterFoundation from "components/UI/FilterFoundation";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useCategoryPagination } from "hooks/link-hook/category/useCategoryPagination";

const disabled = { color: "#ccc", cursor: "not-allowed" };
export default function Categories() {
  const [keyword, setKeyword] = useState("");
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [dataItem, setDataItem] = useState({});
  const [foundationId, setFoundationId] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 100,
    total: 0,
  });

  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");
  const isAdminFoundation = roles.some((x) => x === "admin_foundation");

  //fetch category list
  const { data, refetch, isFetching } = useCategoryPagination(
    dataTable,
    foundationId,
    keyword
  );

  // fetch  permission
  const { data: dataPermission } = usePermission("mdlLink", "mnuCategories");

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddCategories");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditCategories");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteCategories");

  const handleOpenAdd = () => {
    setOpenAdd(!openAdd);
    refetch();
  };

  const handleOpenEdit = () => {
    setOpenEdit(!openEdit);
    refetch();
  };

  const columns = [
    {
      title: "No. ",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Kategori",
      dataIndex: "name",
      key: "name",
    },
    ...(roles.includes("super_admin")
      ? [
          {
            title: "Foundation",
            dataIndex: "foundation_name",
            key: "foundation_name",
          },
        ]
      : []),
    {
      title: "Aksi",
      dataIndex: "id",
      render: (id, record) => {
        // Fungsi untuk mengecek apakah tombol harus dinonaktifkan
        const isDisabled = (action) => {
          return (
            action?.type === "disabled" ||
            (!record.is_owner && !isSuperAdmin && !isAdminFoundation)
          );
        };

        return (
          <>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                const dataId = id;
                DeleteApi({ url: "/links/categories/", dataId, refetch });
              }}
              disabled={isDisabled(btnDelete)}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={isDisabled(btnDelete) ? disabled : { cursor: "pointer" }}
              >
                Hapus
              </Tag>
            </Popconfirm>

            <Tag
              color="orange"
              hidden={!btnEdit}
              style={isDisabled(btnEdit) ? disabled : { cursor: "pointer" }}
              onClick={() => {
                if (!isDisabled(btnEdit)) {
                  setDataItem(record);
                  handleOpenEdit();
                }
              }}
              disabled={isDisabled(btnEdit)}
            >
              Ubah
            </Tag>
          </>
        );
      },
    },
  ];

  const dataSource = data?.data?.data
    ?.sort((a, b) => a.name.localeCompare(b.name))
    .map((x, index) => {
      return {
        ...x,
        no: index + 1,
        id: x.id,
        key: x.id,
        name: x.name,
        foundation_name: x?.foundation?.name,
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Kategori</h1>

        <Button
          type="primary"
          disabled={btnAdd?.type === "disabled"}
          hidden={!btnAdd}
          onClick={() => (btnAdd?.type === "disabled" ? null : handleOpenAdd())}
        >
          Tambah
        </Button>
      </div>

      <div className="filter-search">
        {roles.some((x) => x === "super_admin") && (
          <FilterFoundation setFoundationId={setFoundationId} />
        )}

        <Tooltip title="Cari">
          <Input
            prefix={<SearchOutlined />}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="cari Kategori..."
            className="search-input-billings"
          />
        </Tooltip>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        size="small"
        pagination={pagination}
        loading={refetch || isFetching}
        scroll={{ x: "max-content", y: "calc(100vh - 300px)" }}
      />

      <CreateCategory open={openAdd} setOpen={handleOpenAdd} />
      <EditCategory open={openEdit} setOpen={handleOpenEdit} data={dataItem} />
    </div>
  );
}
