import {
  Form,
  InputNumber,
  Modal,
  Select,
  Skeleton,
  message,
  Input,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { AccountContext } from "context/finance-context/AccountContext";
import dayjs from "dayjs";
import { useAccountDetail } from "hooks/finance-hook/useAccountDetail";
import { useKodeKeuanganPagination } from "hooks/finance-report-hook/useKodeKeuanganPagination";
import { renderModalError } from "pages/si-finance-report/utlis/utlis";
import { useContext, useEffect, useState } from "react";

dayjs.locale("id");

const EditAccount = () => {
  const { dataId, showEditAccount, setShowEditAccount, setFetchAccount } =
    useContext(AccountContext);

  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(false);
  const [foundationId, setFoundationId] = useState("");

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  const {
    data,
    isLoading: skeleteon,
    refetch: fetchData,
  } = useAccountDetail(dataId, false);

  const {
    data: dataKodeUnit,
    isLoading: isLoadingKodeUnit,
    isFetching: isFetchingKodeUnit,
  } = useKodeKeuanganPagination(
    {
      current_page: 1,
      per_page: 1000,
      total: 0,
    },
    "",
    foundationId,
    "unit");


  useEffect(() => {
    if (showEditAccount) {
      fetchData();
    }

    if (data) {
      form.setFieldsValue({
        balance: parseInt(data.data.balance),
        number: data.data.number,
        codeUnitId: data.data.code_unit_id,
        nisn: data.data?.student?.nisn
      });
      setFoundationId(data.data.foundation_id);
    }

    return () => setNewData({});
  }, [form, data, showEditAccount, fetchData]);

  const onCancelModal = () => {
    form.resetFields();
    setShowEditAccount(false);
  };

  // dev ---
  const handleSubmit = async () => {
    try {
      await form.validateFields();
      if (Object.keys(newData).length === 0) {
        alert("Nothing has changed");
        return;
      }

      if (newData.nisn === "") {
        newData.nisn = null;
      }

      setLoading(true);

      try {
        const { data } = await axios.put(
          REACT_APP_HOST_API + `/finance/accounts/${dataId}`,
          newData,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        );

        message.success(data.message);
        onCancelModal();
        setFetchAccount(true);
      } catch (error) {
        renderModalError("Error Edit Data Va", error);
      } finally {
        setLoading(false);
      }
    } catch {
      alert("Fields Error");
    }
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={showEditAccount}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      title="Edit Data Va"
      centered
    >
      {skeleteon && <Skeleton active />}
      {!skeleteon && (
        <Form form={form} layout="vertical">

          <Form.Item
            name="number"
            label="Nomor Rekening"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <InputNumber style={{ width: '100%' }}
              onChange={(value) => (newData["number"] = value ? value.toString() : null)}
            />
          </Form.Item>
          <Form.Item
            name="codeUnitId"
            label="Unit"
            rules={[{ required: true, message: "Unit harus diisi!" }]}
          >
            <Select
              showSearch
              placeholder="Pilih Unit"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              disabled={(isLoadingKodeUnit || isFetchingKodeUnit)}
              onChange={(value) => (newData["codeUnitId"] = value)}
            >
              {dataKodeUnit?.data?.data?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {`${item.code} - ${item.name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Form.Item
            name="balance"
            label="Saldo"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <InputNumber
              formatter={(value) =>
                `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              }
              parser={(value) => value.replace(/Rp\s?|(\.*)/g, "")}
              style={{ width: "100%" }}
              onChange={(value) => (newData["balance"] = `${value}`)}
            />
          </Form.Item> */}
          <Form.Item
            name="nisn"
            label="NISN"
          >
            <Input
              onChange={({ target: { value } }) =>
                (newData["nisn"] = value)
              }
            />
          </Form.Item>

        </Form>)}
    </Modal>
  );
};

export default EditAccount;
