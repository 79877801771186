import { Button, Col, DatePicker, Row, Tooltip } from "antd";
import React, { useState } from "react";
import Tagihan from "./Tab/Tagihan";
import Mutasi from "./Tab/Mutasi";
import Lunas from "./Tab/Lunas";
import dayjs from "dayjs";

export default function ParentBilling() {
  const [selectTab, setSelectTab] = useState("tagihan");
  const [fromDate, setFromDate] = useState("");

  return (
    <div style={{ maxWidth: "500px", margin: "auto" }}>
      <div style={{ marginBottom: "17px" }}>
        <Tooltip title="Tampilkan sampai tahun">
          <DatePicker
            onChange={(value) =>
              value
                ? setFromDate(dayjs(value).format("YYYY"))
                : setFromDate("")
            }
            picker="year"
            placeholder="Tampilkan sampai tahun"
          />
        </Tooltip>
      </div>

      <Row gutter={12}>
        <Col span={8}>
          <Button
            onClick={() => setSelectTab("tagihan")}
            type="primary"
            style={{ width: "100%" }}
            ghost={selectTab !== "tagihan"}
          >
            Tagihan
          </Button>
        </Col>
        <Col span={8}>
          <Button
            onClick={() => setSelectTab("mutasi")}
            type="primary"
            ghost={selectTab !== "mutasi"}
            style={{ width: "100%" }}
          >
            Mutasi
          </Button>
        </Col>
        <Col span={8}>
          <Button
            onClick={() => setSelectTab("lunas")}
            type="primary"
            ghost={selectTab !== "lunas"}
            style={{ width: "100%" }}
          >
            Lunas
          </Button>
        </Col>
      </Row>

      {selectTab === "tagihan" ? (
        <Tagihan type={selectTab} fromDate={fromDate} />
      ) : selectTab === "mutasi" ? (
        <Mutasi type={selectTab} fromDate={fromDate} />
      ) : selectTab === "lunas" ? (
        <Lunas type={selectTab} fromDate={fromDate} />
      ) : null}
    </div>
  );
}
