import { message, Modal, Popconfirm, Space, Tag } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useAssetStatusPagination } from "hooks/asset-hook/useAssetStatusPagination";
import { usePermission } from "hooks/usePermissions";
import moment from "moment";
import { useCallback, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InventoryContext } from "../../inventory-context/InventoryContext";
import { useAssetCategories } from "hooks/asset-hook/useAssetCategories";
import { useAssetSumberDana } from "hooks/asset-hook/useAssetSumberDana";
import useResetPage from "components/Helper/useResetPage";
import useExportExcel from "components/Helper/useExportExcel";

const disabled = { cursor: "not-allowed", color: "#ccc" };

const AssetsStatusLogic = () => {
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [fundSourceId, setFundSourceId] = useState("");
  const [foundationId, setFoundationId] = useState("");

  const [showEditAssets, setShowEditAssets] = useState(false);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });

  const navigate = useNavigate();
  const { statusId } = useParams();
  const { REACT_APP_HOST_API } = process.env;
  const { setInventoryFetch } = useContext(InventoryContext);
  const roles = decryptCookies("role").split(",");

  const params = {
    dataTable,
    keyword,
    statusId,
    foundationId,
    categoryId,
    fundSourceId,
  };

  const { data, isLoading, isFetching, refetch } =
    useAssetStatusPagination(params);
  const { data: dataCategories } = useAssetCategories("", foundationId);
  const { data: dataFundSource } = useAssetSumberDana("", foundationId);

  const { data: dataPermission } = usePermission("mdlInventory", "mnuAssets");

  const btnEdit = dataPermission?.find((x) => x.id === "btnEditAssets");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailAssets");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteAssets");
  const btnExport = dataPermission?.find(
    (x) => x.id === "btnExportExcelAssets"
  );

  const onCancel = () => {
    setShowEditAssets(false);
  };

  const onUpdate = useCallback(() => {
    setShowEditAssets(false);
    refetch();
  }, [refetch]);

  const onDelete = async () => {
    try {
      const { data } = await axios.delete(
        REACT_APP_HOST_API + "/inventories/assets/" + dataId,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      message.success(data.message, 2);
      refetch();
      setInventoryFetch(true);
    } catch (error) {
      Modal.error({
        title: "Gagal Menghapus Data",
        content: <pre>{error?.response?.data?.message}</pre>,
      });
    }
  };

  const handleChange = ({ target: { value } }) => {
    setKeyword(value);
  };

  const handleReturn = async (param) => {
    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + `/inventories/asset-loans/${param[0].id}`,
        {
          assetLoanBatchId: param[0].asset_loan_batch_id,
          endDate: moment(new Date().toISOString()).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
        },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      refetch();
      setInventoryFetch(true);
    } catch (error) {
      alert(error.message);
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 50,
    },
    { title: "Serial", dataIndex: "serial", align: "left", fixed: "left" },
    { title: "Status", dataIndex: "asset_status_id", align: "left" },
    {
      title: "Kategori",
      dataIndex: "categories",
      width: 200,
      align: "left",
      render: (categories) => categories?.map((x) => x.name).join(", "),
    },
    { title: "Tag", width: 150, dataIndex: "tag", align: "left" },
    { title: "Notes", width: 150, dataIndex: "notes", align: "left" },
    {
      title: "Aksi",
      width: 350,
      dataIndex: "id",
      align: "center",
      render: (_, record) => {
        if (record.assetStatus.id === "BORROWED") {
          if (record.assetLoan.length > 0) {
            return (
              <>
                <Tag
                  color="green"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleReturn(record.assetLoan)}
                >
                  Kembalikan
                </Tag>
                <Tag
                  color="blue"
                  hidden={!btnDetail}
                  style={
                    btnDetail?.type === "disabled"
                      ? disabled
                      : { cursor: "pointer" }
                  }
                  onClick={() => {
                    if (btnDetail?.type === "disabled") return;
                    else navigate(`/inventory/assets/detail-${record.id}`);
                  }}
                >
                  Detail
                </Tag>
              </>
            );
          } else {
            return (
              <>
                <Tag
                  color="orange"
                  hidden={!btnEdit}
                  style={
                    btnEdit?.type === "disabled"
                      ? disabled
                      : { cursor: "pointer" }
                  }
                  onClick={() => {
                    if (btnEdit?.type === "disabled") return;
                    else {
                      setDataId(record.id);
                      setShowEditAssets(true);
                    }
                  }}
                >
                  Ubah
                </Tag>

                <Tag
                  color="blue"
                  hidden={!btnDetail}
                  style={
                    btnDetail?.type === "disabled"
                      ? disabled
                      : { cursor: "pointer" }
                  }
                  onClick={() => {
                    if (btnDetail?.type === "disabled") return;
                    else navigate(`/inventory/assets/detail-${record.id}`);
                  }}
                >
                  Detail
                </Tag>
              </>
            );
          }
        } else {
          return (
            <>
              <Popconfirm
                title="Yakin ingin menghapus ?"
                onConfirm={onDelete}
                okText="Hapus"
                cancelText="Batal"
                disabled={!btnDelete || btnDelete?.type === "disabled"}
              >
                <Tag
                  color="magenta"
                  hidden={!btnDelete}
                  style={
                    btnDelete?.type === "disabled"
                      ? disabled
                      : { cursor: "pointer" }
                  }
                  onClick={() => {
                    if (btnDelete?.type === "disabled") return;
                    else setDataId(record.id);
                  }}
                >
                  Hapus
                </Tag>
              </Popconfirm>

              <Tag
                color="orange"
                hidden={!btnEdit}
                style={
                  btnEdit?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
                onClick={() => {
                  if (btnEdit?.type === "disabled") return;
                  else {
                    setDataId(record.id);
                    setShowEditAssets(true);
                  }
                }}
              >
                Ubah
              </Tag>

              <Tag
                color="blue"
                hidden={!btnDetail}
                style={
                  btnDetail?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
                onClick={() => {
                  if (btnDetail?.type === "disabled") return;
                  else navigate(`/inventory/assets/detail-${record.id}`);
                }}
              >
                Detail
              </Tag>
            </>
          );
        }
      },
    },
  ];

  const dataKey = data?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
        fundSource: data?.fundSource?.name,
        asset_status_id: data.assetStatus && (
          <Tag
            color={data.assetStatus.color}
            style={{ textTransform: "capitalize", borderRadius: 5 }}
          >
            {data.assetStatus.id.toLowerCase()}
          </Tag>
        ),
        tag: data.tag && (
          <Space>
            {data.tag.map((tag) => (
              <Tag key={tag} style={{ borderRadius: 5 }}>
                {tag}
              </Tag>
            ))}
          </Space>
        ),
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100, data?.data.meta.total],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const handleChangeCategory = (value) => {
    const categoryId = value.join(",");
    setCategoryId(categoryId);
  };

  const dataExport = data?.data.data.map((data, index) => {
    return {
      No: index + 1,
      Serial: data?.serial,
      Status: data?.assetStatus?.id,
      Deskripsi: data?.description,
      "Sumber Dana": data?.fundSource?.name,
      Kategori: data?.categories?.map((x) => x.name).join(", "),
      Tag: data.tag && data.tag.join(", "),
      "Tanggal Pembelian": data?.purchase_date,
      "Nomor Order": data?.order_number,
      Harga: data?.price,
      "Garansi (bulan)": data?.warranty,
      Catatan: data?.notes,
      Gambar: data?.image && REACT_APP_HOST_API + data.image[1],
    };
  });

  const exportToExcel = useExportExcel({
    dataExport,
    fileName: "Aset",
    sheetName: "Aset",
  });

  useResetPage(setDataTable, [keyword, foundationId, fundSourceId, categoryId]);

  return {
    roles,
    exportToExcel,
    btnExport,
    setFoundationId,
    setFundSourceId,
    dataFundSource,
    dataCategories,
    handleChangeCategory,

    dataId,
    keyword,
    dataKey,
    columns,
    statusId,
    isLoading,
    isFetching,
    pagination,
    showEditAssets,
    onUpdate,
    onCancel,
    handleChange,
  };
};

export default AssetsStatusLogic;
