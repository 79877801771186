import {
  AppstoreFilled,
  CheckCircleTwoTone,
  DashboardOutlined,
  LogoutOutlined,
  ProfileOutlined,
  ReconciliationOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import Logout from "components/Auth/Logout";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { useShowParent } from "hooks/parents-hook/useShowParent";
import { usePermission } from "hooks/usePermissions";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import {
  FaMoneyCheckAlt,
  FaChartLine,
  FaFileCsv,
  FaBarcode,
  FaWallet,
  FaFilePdf,
} from "react-icons/fa";
import { IoIosPaper } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const LayoutFinanceLogic = () => {
  const [collapsed, setCollapsed] = useState(
    window.innerWidth > 1200 ? false : true
  );
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const email = Cookies.get("email") && decryptCookies("email");

  const { data } = usePermission("mdlFinance");

  const roles = decryptCookies("role").split(",");

  const isParent = roles.includes("parent");

  const handleSelectMenu = (param) => {
    encryptCookies("defaultFinanceMenu", param);
  };

  const handleClickItemUser = (e) => {
    if (e.key === "profile") navigate("/profile");
  };

  const paretnId = isParent ? decryptCookies("parent_id") : "";
  const { data: dataParent, refetch: refetchParent } = useShowParent(paretnId);

  useEffect(() => {
    isParent && refetchParent();
    // eslint-disable-next-line
  }, []);

  const itemsAccount = [
    {
      key: "student",
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "space-between",
            gap: "5px",
            cursor: "default",
            fontWeight: "bold",
          }}
        >
          {dataParent?.data[0]?.student?.name}
          <CheckCircleTwoTone />
        </div>
      ),
    },
    {
      key: "profile",
      label: <div onClick={() => navigate("/profile")}>Profile</div>,
    },
    { key: "logout", label: <Logout>Logout</Logout> },
  ];

  const menuBillings = data?.find((x) => x.id === "mnuBillings");
  const menuAccount = data?.find((x) => x.id === "mnuAccount");
  const menuRevenues = data?.find((x) => x.id === "mnuRevenues");
  const menuRecapBillings = data?.find((x) => x.id === "mnuRecapsBillings");
  const menuAlokasiDana = data?.find((x) => x.id === "mnuAlokasiDana");
  const menuParentStudent = data?.find((x) => x.id === "mnuParentStudent");
  const menuParentBilling = data?.find((x) => x.id === "mnuParentBilling");
  const menuLaporanKeuanganSiswa = menuBillings || menuRevenues;
  const mnuKodeKeuangan = data?.find((x) => x.id === "mnuKodeKeuangan");
  const mnuAnggaran = data?.find((x) => x.id === "mnuAnggaran");
  const mnuTransitData = data?.find((x) => x.id === "mnuTransitData");
  const mnuLaporanKeuangan = data?.find((x) => x.id === "mnuLaporanKeuangan");
  const menuLaporanInternal =
    mnuKodeKeuangan || mnuAnggaran || mnuTransitData || mnuLaporanKeuangan;
  const mnuImportBsi = data?.find((x) => x.id === "mnuImportBsi");

  const itemsUser = [
    ...(isParent
      ? [
          {
            key: "student",
            label: (
              <div
                style={{
                  display: "flex",
                  alignItems: "space-between",
                  gap: "5px",
                  cursor: "default",
                  fontWeight: "bold",
                }}
              >
                {dataParent?.data[0]?.student?.name}
                <CheckCircleTwoTone />
              </div>
            ),
          },
        ]
      : []),
    { key: "profile", label: <span>Profile</span> },
    { key: "logout", label: <Logout>Logout</Logout> },
  ];

  const items = [
    { key: "home", icon: <AppstoreFilled />, label: "Home" },
    ...(!isParent
      ? [{ key: "mnuFinance", icon: <DashboardOutlined />, label: "Dashboard" }]
      : []),
    menuAccount && {
      key: "mnuAccount",
      icon: <FaMoneyCheckAlt />,
      label: "Data VA",
      disabled: menuAccount?.type === "disabled",
    },
    mnuImportBsi && {
      key: "mnuImportBsi",
      icon: <FaFilePdf />,
      label: "Input data BSI",
      disabled: mnuImportBsi?.type === "disabled",
    },
    menuAlokasiDana && {
      key: "mnuAlokasiDana",
      icon: <ReconciliationOutlined />,
      label: "Alokasi Dana",
      disabled: menuAlokasiDana?.type === "disabled",
    },
    menuParentStudent && {
      key: "mnuParentStudent",
      icon: <TeamOutlined />,
      label: "Daftar Siswa",
      disabled: menuParentStudent?.type === "disabled",
    },
    menuParentBilling && {
      key: "mnuParentBilling",
      icon: <ProfileOutlined />,
      label: "Tagihan",
      disabled: menuParentBilling?.type === "disabled",
    },
    menuLaporanKeuanganSiswa && {
      key: "mnuLaporanKeuanganSiswa",
      icon: <IoIosPaper />,
      label: "Keuangan Siswa",
      children: [
        menuBillings && {
          key: "mnuBillings",
          icon: <IoIosPaper />,
          label: "Tabel Tagihan",
          disabled: menuBillings?.type === "disabled" ? true : false,
        },
        menuRecapBillings && {
          key: "mnuRecapsBillings",
          icon: <FaFileCsv />,
          label: "Recap Billings",
          disabled: menuRecapBillings?.type === "disabled" ? true : false,
        },

        menuRevenues && {
          key: "mnuRevenues",
          icon: <FaChartLine />,
          label: "Data Pemasukan (BSI)",
          disabled: menuRevenues?.type === "disabled" ? true : false,
        },
      ],
    },
    menuLaporanInternal && {
      key: "mnuLaporanInternal",
      icon: <IoIosPaper />,
      label: "Keuangan Internal",
      children: [
        mnuKodeKeuangan && {
          key: "mnuKodeKeuangan",
          icon: <FaBarcode />,
          label: "Kode Keuangan",
          disabled: mnuKodeKeuangan?.type === "disabled",
        },
        mnuAnggaran && {
          key: "mnuAnggaran",
          icon: <FaWallet />,
          label: "Anggaran Unit",
          disabled: mnuAnggaran?.type === "disabled",
        },
        mnuTransitData && {
          key: "mnuTransitData",
          icon: <FaFileCsv />,
          label: "Data Pengeluaran (BSI)",
          disabled: mnuTransitData?.type === "disabled",
        },
        mnuLaporanKeuangan && {
          key: "mnuLaporanKeuangan",
          icon: <FaChartLine />,
          label: "Hasil Laporan",
          disabled: mnuLaporanKeuangan?.type === "disabled",
        },
      ],
    },
  ];

  const items2 = [
    { key: "logout", icon: <LogoutOutlined />, label: <Logout>Logout</Logout> },
  ];

  const handleClickMenu = (param) => {
    if (param.key === "") {
      return;
    } else {
      handleSelectMenu(param.key);

      if (param.key === "logout") {
        return;
      } else if (param.key === "home") navigate("/home");
      else if (param.key === "mnuFinance") navigate("/finance");
      else if (param.key === "mnuAccount") navigate("/finance/datava");
      else if (param.key === "mnuRevenues") navigate("/finance/data-pemasukan");
      else if (param.key === "mnuTransitData")
        navigate("/finance/data-pengeluaran");
      else if (param.key === "mnuBillings") navigate("/finance/tabeltagihan");
      else navigate("/finance/" + param.key.toLowerCase().split("mnu")[1]);
    }

    setOpen(false);
  };

  return {
    email,
    collapsed,
    items,
    items2,
    itemsUser,
    itemsAccount,
    setCollapsed,
    handleClickItemUser,
    handleClickMenu,
    open,
    setOpen,
    isParent,
  };
};

export default LayoutFinanceLogic;
