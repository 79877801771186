import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useAssetList = () => {
  return useQuery(
    "get-asset-list",
    () => GetList(`/inventories/assets?&mode=list`),
    { enabled: false }
  );
};
