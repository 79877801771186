import { Badge, Calendar } from "antd";
import React, { useEffect, useState } from "react";

const renderType = (type) => {
  switch (type) {
    case "HOLIDAY":
      return "Libur";
    case "NATIONAL_DAY":
      return "Libur Nasional";
    case "EVENT":
      return "Acara";
    case "SCHOOL_AGENDA":
      return "Agenda Sekolah";
    default:
      return type;
  }
};

export default function CalenderAgenda({ data }) {
  const [dataAgenda, setDataAgenda] = useState([]);
  useEffect(() => {
    if (data) {
      setDataAgenda(data);
    }
  }, [data]);

  const getListData = (value) => {
    const listData = dataAgenda?.data?.filter((event) => {
      const eventDate = new Date(event.date);
      return (
        eventDate.getDate() === value.date() &&
        eventDate.getMonth() === value.month() &&
        eventDate.getFullYear() === value.year()
      );
    });

    return listData?.map((event) => ({
      type: event.type === "HOLIDAY" ? "error" : "success",
      content: `(${renderType(event.type)}) - ${event.name}`,
    }));
  };
  const getMonthData = (value) => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };
  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData?.map((item, index) => (
          <li
            className={`p-2 rounded-lg ${
              item.type === "error" ? "bg-red-100" : "bg-green-100"
            }`}
            key={index}
          >
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };
  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };

  // Custom handler untuk menampilkan dropdown bulan dan tahun
  const headerRender = ({ value, onChange }) => {
    const month = value.month(); // Mengambil bulan saat ini
    const year = value.year(); // Mengambil tahun saat ini

    // Tahun dari 5 tahun sebelum sekarang hingga 5 tahun sesudah
    const years = Array.from({ length: 11 }, (_, i) => year - 5 + i);

    return (
      <div style={{ display: "flex", justifyContent: "flex-end", padding: 8 }}>
        {/* Dropdown untuk pemilihan bulan */}
        <div>
          <select
            value={month}
            onChange={(e) => {
              const newMonth = parseInt(e.target.value, 10);
              const newValue = value.clone().month(newMonth);
              onChange(newValue);
            }}
          >
            {Array.from({ length: 12 }, (_, i) => (
              <option key={i} value={i}>
                {new Date(0, i).toLocaleString("default", { month: "long" })}
              </option>
            ))}
          </select>
        </div>

        {/* Dropdown untuk pemilihan tahun */}
        <div style={{ marginLeft: 10 }}>
          <select
            value={year}
            onChange={(e) => {
              const newYear = parseInt(e.target.value, 10);
              const newValue = value.clone().year(newYear);
              onChange(newValue);
            }}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  return <Calendar cellRender={cellRender} headerRender={headerRender} />;
}
