import { Spin } from "antd"
import { numberFormat } from "pages/si-finance-report/utlis/utlis"
import React from 'react'
import moment from 'moment'


const TableRecapLaporan = ({ result, isLoading }) => {

   const formatDate = (dateString) => {
      return moment(dateString).format('DD MMMM YYYY')
   }

   return (
      <>
         <h2 className="table-header-secondary">Update trx : {result?.update_trx ? formatDate(result.update_trx) : ''}</h2>
         <div className="table-recap-container">
            <div className="table-recap-box">
               <>
                  {
                     isLoading && <Spin active />
                  }
                  {
                     !isLoading && (
                        <>
                           <div className="table-recap-item">
                              <p>Total Anggaran</p>
                              <p>: {numberFormat(result?.total_anggaran)}</p>
                           </div>
                           <div className="table-recap-item">
                              <p>Total Realisasi</p>
                              <p>: {numberFormat(result?.total_realisasi)}</p>
                           </div>
                           <div className="table-recap-item">
                              <p>Selisih</p>
                              <p>: {numberFormat(result?.selisih)}</p>
                           </div>
                        </>
                     )
                  }
               </>
            </div>
            <div className="table-recap-box">
               <>
                  {
                     isLoading && <Spin active />
                  }
                  {
                     !isLoading && (
                        <>
                           <div className="table-recap-item">
                              <p>Gaji</p>
                              <p>: {numberFormat(result?.gaji)}</p>
                           </div>
                           <div className="table-recap-item">
                              <p>Non Anggaran</p>
                              <p>: {numberFormat(result?.non_anggaran)}</p>
                           </div>
                           <div className="table-recap-item">
                              <p>Pendapatan</p>
                              <p>: {numberFormat(result?.pendapatan)}</p>
                           </div>
                        </>
                     )
                  }
               </>
            </div>
            <div className="table-recap-box" style={{
               borderTop: "1px solid #cccbc9",
            }}>
               <>
                  {
                     isLoading && <Spin active />
                  }
                  {
                     !isLoading && (
                        <>
                           <div className="table-recap-item">
                              <p>Total Anggaran Buku</p>
                              <p>: {numberFormat(result?.total_anggaran_buku)}</p>
                           </div>
                           <div className="table-recap-item">
                              <p>Total Realisasi Buku</p>
                              <p>: {numberFormat(result?.total_realisasi_buku)}</p>
                           </div>
                           <div className="table-recap-item">
                              <p>Selisih Buku</p>
                              <p>: {numberFormat(result?.selisih_buku)}</p>
                           </div>
                        </>
                     )
                  }
               </>
            </div>
         </div>
      </>
   )
}

export default TableRecapLaporan