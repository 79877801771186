import {
  AppstoreFilled,
  DashboardOutlined,
  LogoutOutlined,
  TagFilled,
} from "@ant-design/icons";
import axios from "axios";
import Logout from "components/Auth/Logout";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import Cookies from "js-cookie";
import { InventoryContext } from "pages/si-inventory/inventory-context/InventoryContext";
import { useContext, useEffect, useState } from "react";
import { BsApple, BsStack } from "react-icons/bs";
import { HiUser, HiUsers } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { FaFilter } from "react-icons/fa";

const LayoutInventoryLogic = () => {
  const [open, setOpen] = useState(false);
  const [dataMenus, setDataMenus] = useState([]);
  const [assetsStatus, setAssetsStatus] = useState([]);
  const [dataAllAssets, setDataAllAssets] = useState([]);
  const navigate = useNavigate();
  const { REACT_APP_HOST_API } = process.env;
  const { inventoryFetch } = useContext(InventoryContext);
  const email = Cookies.get("email") && decryptCookies("email");

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const fetchPermission = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } },
          { cancelToken: cancelToken.token }
        );

        const selectedMenu = data.data[0].role.permissions.modules.find(
          (x) => x.id === "mdlInventory"
        ).menus;

        setDataMenus(selectedMenu);
      } catch (error) {
        alert(error);
      }
    };

    fetchPermission();

    const fetchStatuses = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API +
            `/inventories/asset-statuses?page=1&limit=10&keyword=&mode=list`,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } },
          { cancelToken: cancelToken.token }
        );

        setAssetsStatus(data.data);
      } catch (error) {
        alert(error);
      }
    };

    fetchStatuses();

    const fetchAssets = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API +
            `/inventories/assets?page=1&limit=10&keyword=&mode=list`,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } },
          { cancelToken: cancelToken.token }
        );

        setDataAllAssets(data.data);
      } catch (error) {
        alert(error);
      }
    };

    fetchAssets();

    return () => {
      cancelToken.cancel();
    };
  }, [REACT_APP_HOST_API, inventoryFetch]);

  const menuManufacturers = dataMenus.find((x) => x.id === "mnuManufacturers");
  const menuAssets = dataMenus.find((x) => x.id === "mnuAssets");
  const menuLoanBatch = dataMenus.find((x) => x.id === "mnuLoanBatch");
  const menuAssetLoan = dataMenus.find((x) => x.id === "mnuAssetLoan");
  const menuFilters = dataMenus.find((x) => x.id === "mnuFilters");

  const handleSelectMenu = (param) =>
    encryptCookies("defaultInventoryMenu", param);

  const handleClickItemUser = (e) => {
    if (e.key === "profile") navigate("/profile");
    else return;
  };

  const handleClickMenu = (param) => {
    if (param.key === "") {
      return;
    } else {
      handleSelectMenu(param.key);

      if (param.key === "logout") {
        return;
      } else if (param.key === "home") navigate("/home");
      else if (param.key === "mnuInventory") navigate("/inventory");
      else if (!param.key.includes("mnu"))
        navigate(`/inventory/assets/${param.key.toLowerCase()}`);
      else navigate("/inventory/" + param.key.toLowerCase().split("mnu")[1]);
    }

    setOpen(false);
  };

  const itemsUser = [
    { key: "profile", label: <span>Profile</span> },
    { key: "logout", label: <Logout>Logout</Logout> },
  ];

  const items2 = [
    { key: "logout", icon: <LogoutOutlined />, label: <Logout>Logout</Logout> },
  ];

  const items = [
    { key: "home", icon: <AppstoreFilled />, label: "Home" },
    { key: "mnuInventory", icon: <DashboardOutlined />, label: "Dashboard" },
    menuManufacturers && {
      key: "mnuManufacturers",
      icon: <BsApple />,
      label: "Manufacturers",
      disabled: menuManufacturers?.type === "disabled",
    },
    menuAssets && {
      key: "assets",
      icon: <BsStack />,
      label: "Assets",
      disabled: menuAssets?.type === "disabled",
      children: [
        {
          key: "mnuAssets",
          icon: <TagFilled />,
          label: `All (${dataAllAssets.length})`,
        },
        ...(assetsStatus &&
          assetsStatus.map((val) => ({
            key: val.id,
            icon: <TagFilled style={{ color: val.color }} />,
            label: `${val.id.toLowerCase()} (${val.asset_count})`,
          }))),
      ],
    },
    menuLoanBatch && {
      key: "mnuLoanBatch",
      icon: <HiUsers />,
      label: "Loan Batch",
      disabled: menuLoanBatch?.type === "disabled",
    },
    menuAssetLoan && {
      key: "mnuAssetLoan",
      icon: <HiUser />,
      label: "Asset Loan",
      disabled: menuAssetLoan?.type === "disabled",
    },
    menuFilters && {
      key: "mnuFilters",
      icon: <FaFilter />,
      label: "Filters",
      disabled: menuFilters?.type === "disabled",
    },
  ];

  return {
    open,
    email,
    items,
    items2,
    itemsUser,
    setOpen,
    handleClickMenu,
    handleClickItemUser,
  };
};

export default LayoutInventoryLogic;
