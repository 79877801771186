import React, { useState } from 'react';
import { Table } from 'antd';
import TableRecapUnit from "./TableRecapUnit";
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { numberFormat } from "pages/si-finance-report/utlis/utlis";

const TableRecap = ({ data, isLoading, hiddenPagination }) => {
   const [expandedRowKeys, setExpandedRowKeys] = useState([]);

   const columns = [
      {
         title: 'No',
         dataIndex: 'index',
         key: 'index',
      },
      {
         title: 'Juknis',
         dataIndex: 'juknis',
         key: 'juknis',
      },
      {
         title: 'Realisasi',
         dataIndex: 'amount',
         key: 'amount',
         render: (amount) => {
            return numberFormat(amount)
         }
      },
   ];

   const dataSource = data?.map((data, index) => {
      return {
         id: data.id,
         index: index + 1,
         key: index,
         juknis: `${data.code} - ${data.name}`,
         amount: data.amounts,
         dataUnit: data.children,
      };
   });


   const onExpand = (expanded, record) => {
      const keys = expanded
         ? [...expandedRowKeys, record.key]
         : expandedRowKeys.filter(k => k !== record.key);
      setExpandedRowKeys(keys);
   };

   const expandIcon = ({ expanded, onExpand, record }) => {
      // Check if dataUnit is empty or not present
      if (!record.dataUnit || record.dataUnit.length === 0) {
         return null; // Don't render an icon if there's no dataUnit data
      }

      // Handle click event to stop propagation and toggle expansion
      const handleClick = (e) => {
         e.stopPropagation(); // Prevent event bubbling
         onExpand(record, e);
      };

      // Render the expand/collapse icon based on the expanded state
      return expanded ? (
         <MinusCircleTwoTone onClick={handleClick} />
      ) : (
         <PlusCircleTwoTone onClick={handleClick} />
      );
   };

   const onRowClick = (record) => ({
      onClick: () => {
         const keys = expandedRowKeys.includes(record.key)
            ? expandedRowKeys.filter(k => k !== record.key)
            : [...expandedRowKeys, record.key];
         setExpandedRowKeys(keys);
      },
   });

   const pagination = hiddenPagination ? false : {
      showSizeChanger: true,
      pageSizeOptions: [10, 20, 50, 100],
   };

   return (
      <>
         <h2 className="table-header-secondary">Tabel Rekap</h2>
         <Table
            columns={columns}
            onRow={onRowClick}
            expandable={{
               expandedRowRender: record => <TableRecapUnit data={record.dataUnit} />,
               rowExpandable: record => record?.dataUnit?.length > 0,
               expandedRowKeys,
               expandIcon,
               onExpand,
               expandedRowClassName: () => "expanded-row",
            }}
            pagination={pagination}
            loading={isLoading}
            dataSource={dataSource}
            size="small"
            className="table-recap-master"
         />
      </>
   );
};
export default TableRecap;