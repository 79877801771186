import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Table, Tag, Tooltip } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { decryptCookies } from "components/Helper/CookiesHelper";
import FilterFoundation from "components/UI/FilterFoundation";
import { useLinkGroupPagination } from "hooks/link-hook/group/useLinkGroupPagination";
import { usePermission } from "hooks/usePermissions";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const disabled = { color: "#ccc", cursor: "not-allowed" };

export default function LinkGroup() {
  const [keyword, setKeyword] = useState("");
  const [foundationId, setFoundationId] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 100,
    total: 0,
  });

  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");
  const isAdminFoundation = roles.some((x) => x === "admin_foundation");
  const navigate = useNavigate();

  // fetch  permission
  const { data: dataPermission } = usePermission("mdlLink", "mnuLinkGroup");

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddLinkGroup");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditLinkGroup");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteLinkGroup");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailLinkGroup");

  //fetch link group pagination
  const { data, refetch, isFetching } = useLinkGroupPagination(
    dataTable,
    foundationId,
    keyword
  );

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
    },
    {
      title: "Owner",
      dataIndex: "owner",
    },
    {
      title: "Foundation",
      dataIndex: "foundation",
    },
    {
      title: "Aksi",
      dataIndex: "id",
      render: (id, record) => {
        // Fungsi untuk mengecek apakah tombol harus dinonaktifkan
        const isDisabled = (action) => {
          return (
            action?.type === "disabled" ||
            (!record.is_owner && !isSuperAdmin && !isAdminFoundation)
          );
        };

        return (
          <>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                const dataId = id;
                DeleteApi({ url: "/links/link-groups/", dataId, refetch });
              }}
              disabled={isDisabled(btnDelete)}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={isDisabled(btnDelete) ? disabled : { cursor: "pointer" }}
              >
                Hapus
              </Tag>
            </Popconfirm>

            <Tag
              color="orange"
              hidden={!btnEdit}
              style={isDisabled(btnEdit) ? disabled : { cursor: "pointer" }}
              onClick={() => {
                if (!isDisabled(btnEdit)) {
                  navigate(`${record.id}/edit`);
                }
              }}
              disabled={isDisabled(btnEdit)}
            >
              Ubah
            </Tag>

            <Tag
              color="blue"
              hidden={!btnDetail}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnDetail?.type === "disabled") return;
                navigate(`${id}`);
              }}
            >
              Detail
            </Tag>
          </>
        );
      },
    },
  ];

  const dataSource = data?.data?.data?.map((x) => ({
    ...x,
    key: x.id,
    owner: x?.owner?.name,
    foundation: x?.foundation?.name,
  }));

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Link Grup</h1>

        <Button
          onClick={() => navigate("add")}
          hidden={!btnAdd}
          disabled={btnAdd?.type === "disabled"}
          type="primary"
        >
          Tambah
        </Button>
      </div>

      <div className="grid w-full grid-cols-1 gap-4 md:w-1/2 md:grid-cols-2">
        {roles.some((x) => x === "super_admin") && (
          <FilterFoundation setFoundationId={setFoundationId} />
        )}

        <Tooltip title="Cari">
          <Input
            prefix={<SearchOutlined />}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="nama, deskripsi, owner..."
            className="search-input-billings"
          />
        </Tooltip>
      </div>

      <Table
        loading={isFetching || refetch}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        size="small"
        scroll={{ x: 900, y: "calc(100vh - 300px)" }}
      />
    </div>
  );
}
