import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Select, Table, Tooltip } from "antd";
import AddBatch from "./AddBatch";
import { Tag } from "antd";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { useLoanBatchPagination } from "hooks/loan-batch-hook/useLoanBatchPagination";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import "./LoanBatch.css";
import FilterFoundation from "components/UI/FilterFoundation";
import { useUnitList } from "hooks/unit-hook/useUnitList";
import useResetPage from "components/Helper/useResetPage";

const disabled = { cursor: "not-allowed", color: "#ccc" };

const LoanBatch = () => {
  const [foundationId, setFoundationId] = useState("");
  const [showAddLoanBatch, setShowAddLoanBatch] = useState(false);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });
  const newDate = new Date();
  const firstDate = new Date(newDate.getFullYear() - 3, 0, 1);
  const lastDate = new Date(newDate.getFullYear(), 11, 31);
  const [unitId, setUnitId] = useState("");
  const [borrowerType, setBorrowerType] = useState("");

  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState({
    from: moment(firstDate).format("YYYY-MM-DD"),
    to: moment(lastDate).format("YYYY-MM-DD"),
  });

  const navigate = useNavigate();
  const roles = decryptCookies("role");

  const { data, isLoading, isFetching } = useLoanBatchPagination(
    dataTable,
    keyword,
    date,
    foundationId,
    unitId,
    borrowerType
  );
  const { data: dataUnit, refetch: refetchUnit } = useUnitList(
    "",
    foundationId
  );

  const { data: dataPermission } = usePermission(
    "mdlInventory",
    "mnuLoanBatch"
  );

  // Buttons Permission
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddLoanBatch");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailLoanBatch");

  const columns = [
    { title: "No", dataIndex: "index", width: 50 },
    {
      title: "Penanggung Jawab",
      dataIndex: "pic",
      width: 200,
    },
    { title: "Unit", dataIndex: "unit", width: 100 },
    { title: "Tipe", dataIndex: "type", width: 100 },
    { title: "Deskripsi", dataIndex: "description", width: 150 },
    { title: "Aset yang dipinjam", dataIndex: "asset_loan", width: 150 },
    {
      title: "Waktu Mulai",
      dataIndex: "start_date",
      width: 170,
      render: (start_date) => moment(start_date).format("DD-MM-YYYY HH:mm:ss"),
      sorter: (a, b) => new Date(a.start_date) - new Date(b.start_date),
    },
    {
      title: "Waktu Selesai",
      dataIndex: "end_date",
      width: 170,
      render: (end_date) => moment(end_date).format("DD-MM-YYYY HH:mm:ss"),
      sorter: (a, b) => new Date(a.start_date) - new Date(b.start_date),
    },
    {
      title: "Aset yang belum dikembalikan",
      dataIndex: "not_returned",
      width: 150,
    },
    {
      title: "Aksi",
      dataIndex: "id",
      width: 100,
      render: (_, record) => {
        return (
          <Tag
            color="blue"
            hidden={!btnDetail}
            style={
              btnDetail?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            onClick={() => {
              if (btnDetail?.type === "disabled") return;
              else {
                navigate(`/inventory/loanbatch/${record.id}`);
                encryptCookies("asset_loan", "1");
              }
            }}
          >
            Detail
          </Tag>
        );
      },
    },
  ];

  const dataKey = data?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        unit: data?.unit?.name,
        start_date: data.start_date,
        end_date: data.start_date,
        key: data.id,
        index: index + 1,
        borrower: data?.employee && data?.employee?.name,
        not_returned:
          data?.not_returned === "0" ? (
            <Tag color="green" className="tag-round">
              {data?.not_returned}
            </Tag>
          ) : (
            <Tag color="red" className="tag-round">
              {data?.not_returned}
            </Tag>
          ),
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const onCancel = () => {
    setShowAddLoanBatch(false);
  };

  const onCreate = useCallback(() => {
    setShowAddLoanBatch(false);
    navigate("/inventory/loanbatch/detail");
  }, [navigate]);

  useEffect(() => {
    setUnitId("");
    refetchUnit();
    // eslint-disable-next-line
  }, [foundationId]);

  useResetPage(setDataTable, [
    keyword,
    foundationId,
    unitId,
    borrowerType,
    date,
  ]);

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Peminjaman Berkelompok</h1>
        <Button
          type="primary"
          hidden={!btnAdd}
          disabled={btnAdd && btnAdd.type === "disabled"}
          onClick={() => setShowAddLoanBatch(true)}
        >
          Tambah Peminjaman
        </Button>
      </div>

      <div className="grid gap-5 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
        <Tooltip title="Cari Peminjaman">
          <Input
            allowClear
            value={keyword}
            placeholder="cari nama..."
            prefix={<SearchOutlined />}
            onChange={({ target: { value } }) => setKeyword(value)}
            className="item-search"
          />
        </Tooltip>

        <Tooltip title="tanggal awal">
          <DatePicker
            value={dayjs(date.from)}
            style={{ width: "100%" }}
            onChange={(value) => {
              setDate((curr) => ({
                ...curr,
                from:
                  value !== null
                    ? value.format("YYYY-MM-DD")
                    : moment(firstDate).format("YYYY-MM-DD"),
              }));
            }}
            placeholder="Awal"
          />
        </Tooltip>

        <Tooltip title="tanggal akhir">
          <DatePicker
            value={dayjs(date.to)}
            onChange={(value) =>
              setDate((curr) => ({
                ...curr,
                to:
                  value !== null
                    ? value.format("YYYY-MM-DD")
                    : moment(lastDate).format("YYYY-MM-DD"),
              }))
            }
            placeholder="Akhir"
          />
        </Tooltip>

        {roles.includes("super_admin") && (
          <FilterFoundation setFoundationId={setFoundationId} />
        )}

        <Tooltip title="Unit">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={setUnitId}
            value={unitId}
          >
            <Select.Option value="">Semua</Select.Option>
            {dataUnit?.data?.map((data) => (
              <Select.Option key={data.id} value={data.id}>
                {data.name}
              </Select.Option>
            ))}
          </Select>
        </Tooltip>

        <Tooltip title="Tipe">
          <Select onChange={setBorrowerType} value={borrowerType}>
            <Select.Option value="">Semua</Select.Option>
            <Select.Option value="internal">Internal</Select.Option>
            <Select.Option value="external">Eksternal</Select.Option>
          </Select>
        </Tooltip>
      </div>

      <Table
        size="small"
        tableLayout={window.innerWidth > 1200 ? "auto" : "fixed"}
        dataSource={dataKey}
        columns={columns}
        loading={isLoading || isFetching}
        pagination={pagination}
        scroll={
          window.innerHeight < 760
            ? {
                y: "50vh",
                x: 800,
              }
            : {
                y: "55vh",
                x: 800,
              }
        }
      />
      <AddBatch
        show={showAddLoanBatch}
        onCreate={onCreate}
        onCancel={onCancel}
      />
    </div>
  );
};

export default LoanBatch;
