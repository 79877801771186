import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useEmployeeNotInUserList = (keyword, employeeId) => {
  keyword = keyword || "";
  employeeId = employeeId || "";
  return useQuery(
    ["get-employee-not-in-user-list", keyword],
    () =>
      GetList(
        `/employees-not-in-user?keyword=${keyword}&employeeId=${employeeId}`
      ),
    {
      enabled: false,
    }
  );
};
