import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Select, Space, Table, Tooltip } from "antd";
import { Popconfirm, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useMemo, useState } from "react";
import CreateKodeKeuangan from "../create/CreateKodeKeuangan";
import EditKodeKeuangan from "../edit/EditKodeKeuangan";
import ImportKodeKeuangan from "../import/ImportKodeKeuangan";
import { useKodeKeuanganPagination } from "hooks/finance-report-hook/useKodeKeuanganPagination";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { decryptCookies } from "components/Helper/CookiesHelper";
import './KodeKeuangan.css';
import { useDebounce } from "use-debounce";
import useResetPage from "components/Helper/useResetPage";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const KodeKeuangan = () => {
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [search] = useDebounce(keyword, 2000);
  const [showEdit, setShowUpdate] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [foundationId, setFoundationId] = useState("");
  const [type, setType] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });

  const roles = decryptCookies("role").split(",");

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  const {
    data: dataUnit,
    isLoading,
    isFetching,
    refetch,
  } = useKodeKeuanganPagination(dataTable, search, foundationId, type);

  // fetch permission
  const { data: dataPermission } = usePermission("mdlFinance", "mnuKodeKeuangan");

  const onCreate = useCallback(() => {
    setShowCreate(false);
    refetch();
  }, [refetch]);

  const onImport = useCallback(() => {
    setShowImport(false);
    refetch();
  }, [refetch]);

  const onUpdate = useCallback(() => {
    setShowUpdate(false);
    refetch();
  }, [refetch]);

  const onCancel = () => {
    setShowCreate(false);
    setShowUpdate(false);
    setShowImport(false);
  };

  // reset page when search, foundationId, type change
  useResetPage(setDataTable, [search, foundationId, type]);


  const handleChange = async (param) => {
    setKeyword(param.target.value);
  };

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddKodeKeuangan");
  const btnImport = dataPermission?.find((x) => x.id === "btnImportKodeKeuangan");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditKodeKeuangan");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteKodeKeuangan");

  const dataSource = dataUnit?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
        foundationName: data.foundation.name,
      };
    });

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      width: 50,
      fixed: "left",
      responsive: ["sm"],
    },
    { title: "Kode", dataIndex: "code", fixed: "left" },
    { title: "Nama", dataIndex: "name" },
    { title: "Tipe", dataIndex: "type" },
    {
      title: "Yayasan", dataIndex: "foundationName",
      hidden: !roles.some((x) => x === "super_admin")
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <>
            <Popconfirm
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({ url: "/financial-report/finance-codes/", dataId: id, refetch })
              }
              title="Yakin ingin menghapus ?"
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setShowUpdate(true);
                  setDataId(id);
                }
              }}
            >
              Ubah
            </Tag>
          </>
        );
      },
    },
  ].filter((x) => !x.hidden);

  const pagination = useMemo(() => ({
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    defaultPageSize: dataTable.per_page,
    total: dataUnit?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100, dataUnit?.data?.meta.total > 200 ? dataUnit?.data?.meta.total : 200],
    onChange: (curr, size) => setDataTable((value) => ({
      ...value,
      current_page: curr,
      per_page: size,
    })),
  }), [dataTable, dataUnit]);

  return (
    <>
      <div className="table-header">
        <h1>Daftar Kode Keuangan</h1>
        <Space>
          <Button
            hidden={!btnAdd}
            onClick={() => setShowCreate(true)}
            disabled={btnAdd && btnAdd.type === "disabled"}
          >
            Tambah
          </Button>
          <Button
            type="primary"
            hidden={!btnImport}
            onClick={() => setShowImport(true)}
            disabled={btnImport && btnImport.type === "disabled"}
          >
            Import
          </Button>
        </Space>
      </div>
      <div className="filter-kode-keuangan">
        <Input
          value={keyword}
          onChange={handleChange}
          prefix={<SearchOutlined />}
          placeholder="cari nama dan kode keuangan"
        />
        {roles.some((x) => x === "super_admin") && (
          <Tooltip Tooltip title="Yayasan">
            <Select
              placeholder="Pilih Yayasan"
              style={{ minWidth: 150 }}
              onChange={(x) => {
                setFoundationId(x)
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Select.Option className="select-option-foundation" value="">
                ALL
              </Select.Option>
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option
                  key={x.id}
                  value={x.id}
                  className="select-option-foundation"
                >
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        )}
        <Tooltip title="Tipe">
          <Select
            placeholder="Pilih Tipe"
            style={{ minWidth: 150 }}
            onChange={(x) => {
              setType(x)
            }}
          >
            <Select.Option value="">ALL</Select.Option>
            <Select.Option value="unit">Unit</Select.Option>
            <Select.Option value="divisi">Divisi</Select.Option>
            <Select.Option value="juknis">Juknis</Select.Option>
          </Select>
        </Tooltip>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "40vh", x: 800 }
            : window.innerHeight < 760
              ? { y: "50vh", x: 800 }
              : { y: "55vh", x: 800 }
        }
      />
      <CreateKodeKeuangan onCancel={onCancel} onCreate={onCreate} show={showCreate} />
      <ImportKodeKeuangan onCancel={onCancel} onImport={onImport} show={showImport} />
      <EditKodeKeuangan
        id={dataId}
        onCancel={onCancel}
        onUpdate={onUpdate}
        show={showEdit}
      />
    </>
  );
};

export default KodeKeuangan;
