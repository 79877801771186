import RequireAuth from "components/Auth/RequireAuth";
import LayoutLandingPage from "Layouts/LayoutLandingPage/LayoutLandingPage";
import RequireMenuLandingPage from "components/Auth/RequireMenuLandingPage";
import MasterLandingPage from "pages/si-landing-page/MasterLandingPage";
import Hero from "pages/si-landing-page/hero/Hero";
import WorkUntis from "pages/si-landing-page/work-units/WorkUntis";
import AboutMe from "pages/si-landing-page/about-me/About";
import CreateHero from "pages/si-landing-page/hero/create/CreateHero";
import EditHero from "pages/si-landing-page/hero/edit/EditHero";
import DetailAboutMe from "pages/si-landing-page/about-me/detail/DetailAbout";
import CreateAboutMe from "pages/si-landing-page/about-me/create/CreateAbout";
import EditAbout from "pages/si-landing-page/about-me/edit/EditAbout";

export const DataRoutesLandingPage = [
  {
    path: "landing-page",
    element: (
      <RequireAuth>
        <RequireMenuLandingPage menu="mnuHero">
          <LayoutLandingPage content={<MasterLandingPage />} />
        </RequireMenuLandingPage>
      </RequireAuth>
    ),
  },
  {
    path: "landing-page/hero",
    element: (
      <RequireAuth>
        <RequireMenuLandingPage menu="mnuHero">
          <LayoutLandingPage content={<Hero />} />
        </RequireMenuLandingPage>
      </RequireAuth>
    ),
  },
  {
    path: "landing-page/hero/add",
    element: (
      <RequireAuth>
        <RequireMenuLandingPage menu="mnuHero">
          <LayoutLandingPage content={<CreateHero />} />
        </RequireMenuLandingPage>
      </RequireAuth>
    ),
  },
  {
    path: "landing-page/hero/edit/:hero_id",
    element: (
      <RequireAuth>
        <RequireMenuLandingPage menu="mnuHero">
          <LayoutLandingPage content={<EditHero />} />
        </RequireMenuLandingPage>
      </RequireAuth>
    ),
  },
  {
    path: "landing-page/work-unit",
    element: (
      <RequireAuth>
        <RequireMenuLandingPage menu="mnuWorkUnit">
          <LayoutLandingPage content={<WorkUntis />} />
        </RequireMenuLandingPage>
      </RequireAuth>
    ),
  },
  {
    path: "landing-page/about",
    element: (
      <RequireAuth>
        <RequireMenuLandingPage menu="mnuAboutMe">
          <LayoutLandingPage content={<AboutMe />} />
        </RequireMenuLandingPage>
      </RequireAuth>
    ),
  },
  {
    path: "landing-page/about/add",
    element: (
      <RequireAuth>
        <RequireMenuLandingPage menu="mnuAboutMe">
          <LayoutLandingPage content={<CreateAboutMe />} />
        </RequireMenuLandingPage>
      </RequireAuth>
    ),
  },
  {
    path: "landing-page/about/edit/:id",
    element: (
      <RequireAuth>
        <RequireMenuLandingPage menu="mnuAboutMe">
          <LayoutLandingPage content={<EditAbout />} />
        </RequireMenuLandingPage>
      </RequireAuth>
    ),
  },
  {
    path: "landing-page/about/:id",
    element: (
      <RequireAuth>
        <RequireMenuLandingPage menu="mnuAboutMe">
          <LayoutLandingPage content={<DetailAboutMe />} />
        </RequireMenuLandingPage>
      </RequireAuth>
    ),
  },
];
