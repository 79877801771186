import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useAnggaranUnitPagination = (
  dataTable,
  foundationId,
  periode,
  financeCodeId
) => {
  return useQuery(
    [
      "get-anggaran-unit-finance-report-pagination",
      dataTable.current_page,
      dataTable.per_page,
      foundationId || "",
      periode || "",
      financeCodeId || "",
    ],
    () =>
      GetPagination(
        `/financial-report/budget-units?page=${dataTable.current_page}&limit=${dataTable.per_page}&mode=page&foundationId=${foundationId}&period=${periode}&financeCodeId=${financeCodeId}`
      )
  );
};
