import React, { useState } from 'react';
import { Table } from 'antd';
import TableRecapDivisi from "./TableRecapDivisi";
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { numberFormat } from "pages/si-finance-report/utlis/utlis";

const TableRecapUnit = ({ data }) => {
   const [expandedRowKeys, setExpandedRowKeys] = useState([]);
   const columns = [
      {
         title: 'No',
         dataIndex: 'index',
         key: 'index',
      },
      {
         title: 'Juknis',
         dataIndex: 'juknis',
         key: 'juknis',
      },
      {
         title: 'Realisasi',
         dataIndex: 'amount',
         key: 'amount',
         render: (amount) => {
            return numberFormat(amount)
         }
      },
   ];

   const dataSource = data?.map((data, index) => {
      return {
         ...data,
         index: index + 1,
         key: index,
         juknis: `${data.code} - ${data.name}`,
      };
   }
   );
   const onExpand = (expanded, record) => {
      const keys = expanded
         ? [...expandedRowKeys, record.key]
         : expandedRowKeys.filter(k => k !== record.key);
      setExpandedRowKeys(keys);
   };

   const expandIcon = ({ expanded, onExpand, record }) => {
      // Check if divisions is empty or not present
      if (!record.divisions || record.divisions.length === 0) {
         return null; // Don't render an icon if there's no divisions data
      }

      return expanded ? (
         <MinusCircleTwoTone className="rotateClose" onClick={e => {
            e.stopPropagation(); // Prevent event bubbling
            onExpand(record, e);
         }} />
      ) : (
         <PlusCircleTwoTone className="rotateOpen" onClick={e => {
            e.stopPropagation(); // Prevent event bubbling
            onExpand(record, e);
         }} />
      );
   };

   const onRowClick = (record) => ({
      onClick: () => {
         const keys = expandedRowKeys.includes(record.key)
            ? expandedRowKeys.filter(k => k !== record.key)
            : [...expandedRowKeys, record.key];
         setExpandedRowKeys(keys);
      },
   });

   return <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      onRow={onRowClick}
      expandable={{
         expandedRowRender: record => {
            return <TableRecapDivisi data={record?.divisions} />
         },
         rowExpandable: record => record?.divisions?.length > 0,
         expandedRowKeys: expandedRowKeys,
         expandIcon,
         onExpand,
      }}
      className="table-recap"
   />;
};

export default TableRecapUnit;