import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useLaporanKeuangan = (unitId, periode, filterJuknis) => {
  return useQuery(
    [
      "get-laporan-keuangan-finance-pagination",
      unitId || "",
      periode || "",
      filterJuknis,
    ],
    () =>
      GetPagination(
        `/financial-report/report?date=${periode}&codeUnitId=${unitId}&danaBos=${filterJuknis.danaBos}&akunNeraca=${filterJuknis.akunNeraca}&akunBiaya=${filterJuknis.akunBiaya}`
      )
  );
};
