import {
  Button,
  Input,
  Select,
  Table,
  Popconfirm,
  Space,
  Tag,
  Tooltip,
} from "antd";
import CreateRpn from "../create/CreateRpn";
import EditRpn from "../edit/EditRpn";
import { DeleteApi } from "api/DeleteApi";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { usePelajaranList } from "hooks/pelajaran-hook/usePelajaranList";
import { useRpnPagination } from "hooks/rpn-hook/useRpnPagination";
import { useTeachersList } from "hooks/teachers-hook/useTeachersList";
import { useTeachingList } from "hooks/teachers-hook/useTeachingList";
import { usePermission } from "hooks/usePermissions";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import useResetPage from "components/Helper/useResetPage";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const Rpn = () => {
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [teacherId, setTeacherId] = useState(
    Cookies.get("teacher_id") ? decryptCookies("teacher_id") : ""
  );
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
  });
  const [foundationId, setFoundationId] = useState("");

  const roles = decryptCookies("role").split(",");

  // get permission Rpn
  const { data: dataPermission } = usePermission(
    "mdlAcademic",
    "mnuRencanaPengambilanNilai"
  );

  // get Rpn pagination
  const { data, isLoading, isFetching, refetch } = useRpnPagination({
    dataTable,
    keyword,
    subjectId,
    teacherId,
    foundationId,
  });

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  // get subject list
  const { data: dataPelajaran, refetch: fetchPelajaran } = usePelajaranList();

  // get teacher list
  const { data: dataGuru, refetch: fetchGuru } = useTeachersList();

  // get teaching list
  const { data: dataMengajar, refetch: fetchMengajar } = useTeachingList(
    Cookies.get("teacher_id") && decryptCookies("teacher_id")
  );

  useEffect(() => {
    if (
      roles.find(
        (x) => x === "super_admin" || x === "admin" || x === "admin_academic"
      )
    ) {
      fetchGuru();
      fetchPelajaran();
    }
    // eslint-disable-next-line
  }, [fetchGuru, fetchPelajaran]);

  useEffect(() => {
    if (roles.find((x) => x === "teacher")) fetchMengajar();
    // eslint-disable-next-line
  }, [fetchMengajar]);

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddRpn");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditRpn");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteRpn");

  const dataSource = data?.data?.data?.data?.map((x, i) => {
    return {
      ...x,
      key: x.id,
      index: i + 1,
      mapel: x.mapel?.name,
      metode: x.metodePengambilanNilai?.nama,
      komponen: x.programSemesterDetail?.kompetensiInti?.nama,
      pertemuan: x?.pertemuan,
      persentase: x.presentase,
    };
  });

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 50,
    },
    {
      title: "Komponen",
      dataIndex: "komponen",
      align: "left",
      width: 120,
    },
    {
      title: "Pertemuan ke",
      dataIndex: "pertemuan",
      align: "left",
      width: 120,
    },
    { title: "Metode", dataIndex: "metode", align: "left", width: 120 },
    { title: "Topik/Materi", dataIndex: "topik", align: "left" },
    { title: "Persentase", dataIndex: "persentase", align: "left", width: 120 },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <Space>
            <Popconfirm
              title="Yakin ingin menghapus data?"
              okText="Delete"
              cancelText="Batal"
              style={{ cursor: "pointer" }}
              onConfirm={() =>
                DeleteApi({
                  url: "/academics/rencana-pengambilan-nilai/",
                  dataId,
                  refetch,
                })
              }
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="red"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
                onClick={() => {
                  if (btnDelete?.type === "disabled") {
                    return;
                  } else {
                    setDataId(id);
                  }
                }}
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setDataId(id);
                  setShowEditModal(true);
                }
              }}
            >
              Ubah
            </Tag>
          </Space>
        );
      },
    },
  ];

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data?.data?.meta?.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) =>
      setDataTable({
        current_page: curr,
        per_page: size,
      }),
  };

  const onCancelModal = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
  };

  const onCreate = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
    refetch();
  };

  useResetPage(setDataTable, [keyword, teacherId, subjectId, foundationId]);

  return (
    <>
      <div className="table-header">
        <h1>Daftar RPN (Rencana Pengambilan Nilai)</h1>
        <Button
          type="primary"
          hidden={!btnAdd}
          disabled={btnAdd && btnAdd.type === "disabled"}
          onClick={() => setShowCreateModal(true)}
        >
          Tambah RPN
        </Button>
      </div>
      <div className="search-wrapper filter-wrapper">
        <Input
          placeholder="cari topik"
          onChange={({ target: { value } }) => setKeyword(value)}
        />
        {roles.some((x) => x === "super_admin") && (
          <Tooltip Tooltip title="Yayasan">
            <Select
              placeholder="Pilih Yayasan"
              style={{ minWidth: 150 }}
              onChange={(x) => setFoundationId(x)}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Select.Option className="select-option-foundation" value="">
                ALL
              </Select.Option>
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option
                  key={x.id}
                  value={x.id}
                  className="select-option-foundation"
                >
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        )}

        {dataGuru && (
          <Select
            style={{ width: "100%" }}
            placeholder="cari guru"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(e) => {
              if (e === "") setTeacherId("");
              else setTeacherId(e);
            }}
          >
            <Select.Option value="">ALL</Select.Option>
            {dataGuru?.data?.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.employee?.name}
              </Select.Option>
            ))}
          </Select>
        )}

        {dataPelajaran && (
          <Select
            placeholder="cari pelajaran"
            style={{ width: "100%" }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(e) => {
              if (e === "") setSubjectId("");
              else setSubjectId(e);
            }}
          >
            <Select.Option value="">ALL</Select.Option>
            {dataPelajaran?.data?.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        )}

        {dataMengajar && (
          <Select
            placeholder="cari pelajaran"
            style={{ width: "100%" }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(e) => {
              if (e === "") setSubjectId("");
              else setSubjectId(e);
            }}
          >
            <Select.Option value="">ALL</Select.Option>
            {dataMengajar?.data?.map((x) => (
              <Select.Option key={x.id} value={x.subject_id}>
                {x.subject?.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </div>

      <Table
        size="small"
        tableLayout="auto"
        dataSource={dataSource}
        columns={columns}
        loading={isLoading || isFetching}
        pagination={pagination}
        scroll={
          window.innerHeight < 760
            ? {
                y: "50vh",
                x: 800,
              }
            : {
                y: "55vh",
                x: 800,
              }
        }
      />

      <CreateRpn
        open={showCreateModal}
        onCancel={onCancelModal}
        onCreate={onCreate}
        dataGuru={dataGuru}
        dataPelajaran={dataPelajaran}
        dataMengajar={dataMengajar}
      />
      <EditRpn
        id={dataId}
        open={showEditModal}
        onCancel={onCancelModal}
        onUpdate={onCreate}
      />
    </>
  );
};

export default Rpn;
