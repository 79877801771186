import React from "react";
import {
  Badge,
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Popover,
  Radio,
  Result,
  Select,
  Skeleton,
  Space,
  Table,
  TimePicker,
  Tooltip,
  message,
} from "antd";
import {
  InfoCircleOutlined,
  InfoCircleTwoTone,
  MoreOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTeachersList } from "hooks/teachers-hook/useTeachersList";
import { useTeachingList } from "hooks/teachers-hook/useTeachingList";
import { useSessionList } from "hooks/attandance-hook/useSession";
import { useStudentSubjectList } from "hooks/students-hook/useStudentSubjectList";
import { useProsemDetailList } from "hooks/prosem-detail-hook/useProsemDetailList";
import dayjs from "dayjs";
import { useEffect } from "react";
import axios from "axios";
import style from "./FormJurnalMengajar.module.css";
import { useProsemWithoutLogin } from "hooks/prosem-hook/useProsemWithoutLogin";
import moment from "moment";
import DynamicTitle from "components/UI/DynamicTitle";
import Backdrop from "components/UI/Backdrop";

const styleGrid = {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
  gap: 20,
};

const itemStyle = { marginBottom: 5 };

const FormJurnalMengajar = () => {
  const [form] = Form.useForm();
  const [teacherId, setTeacherId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [classId, setClassId] = useState("");
  const [className, setClassName] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [pelajaranId, setPelajaranId] = useState("");
  const [idProsem, setIdProsem] = useState("");
  const [isEkskul, setIsEkskul] = useState(false);
  const [statusTeach, setStatusTeach] = useState("");
  const [sourceStudents, setSourceStudents] = useState([]);
  const [posting, setPosting] = useState(false);
  const [isDoneSubmit, setIsDoneSubmit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [recordColumn, setRecordColumn] = useState({});
  const [timeIn, setTimeIn] = useState(null);
  const [timeOut, setTimeOut] = useState(null);

  const { REACT_APP_HOST_API } = process.env;

  const navigate = useNavigate();

  //* get data guru ---
  const {
    data: dataGuru,
    refetch: fetchGuru,
    isLoading: isLoadGuru,
  } = useTeachersList("c1d3e93b-1774-4682-9a81-c1915742c8e2");

  //* get data pelajaran ---
  const {
    data: dataPelajaran,
    isLoading: isloadPelajaran,
    refetch: fetchPelajaran,
  } = useTeachingList(teacherId);

  //* get data session ---
  const { data: dataSession, refetch: fetchSession } = useSessionList();

  //* get student list in teble ---
  const {
    data: dataStudentList,
    isLoading: isLoadStudentList,
    isFetching: isFetchStudentList,
    refetch: fetchStudentList,
  } = useStudentSubjectList({
    keyword,
    classId: classId ? classId : "",
    subjectId: !pelajaranId || !isEkskul ? "" : pelajaranId,
    enabled: Boolean((pelajaranId && classId) || isEkskul),
  });

  //* get prosem list ---
  const {
    data: dataProsem,
    isLoading: isLoadProsem,
    refetch: fetchProsem,
  } = useProsemWithoutLogin({
    teacherId: teacherId ? teacherId : "",
    subjectId: pelajaranId ? pelajaranId : "",
    classId: classId ? classId : "",
    enabled: Boolean(teacherId && pelajaranId && classId),
  });

  //* get prosem detail list ---
  const {
    data: dataProsemDetail,
    isLoading: isLoadProsemDetail,
    refetch: fetchProsemDetail,
  } = useProsemDetailList(idProsem, false);

  // logic sistem in here---

  const filterDataBySubject = async () => {
    const subjectData = await dataPelajaran?.data?.find(
      (item) => item.id === subjectId
    );
    const classIds = subjectData?.class_id;
    const subjectIds = subjectData?.subject_id;
    const isEkskuls = subjectData?.subject?.is_extracurricular;

    setClassId(classIds);
    setPelajaranId(subjectIds);
    setIsEkskul(isEkskuls);
    setClassName(subjectData?.class?.name);

    if (isEkskuls) {
      const filteredData = dataSession?.data?.data?.filter(
        (item) =>
          item.session.toLowerCase() === "sesi extracurricular".toLowerCase()
      );

      form.setFieldsValue({
        // ID dari sesi ekskul",
        sessionId: filteredData[0]?.id,
      });
    }
  };

  // end logic sistem ---

  // useEffect in here ---
  useEffect(() => {
    fetchGuru();
    fetchSession();

    if (teacherId) {
      fetchPelajaran();
    }

    if (idProsem !== "") {
      fetchProsemDetail();
    }
    // eslint-disable-next-line
  }, [
    fetchGuru,
    fetchPelajaran,
    teacherId,
    fetchSession,
    subjectId,
    fetchProsemDetail,
    idProsem,
  ]);

  useEffect(() => {
    if (dataProsem && dataProsem.data && dataProsem.data.length > 0) {
      form.setFieldsValue({
        prosem: dataProsem.data[0].id,
      });
      setIdProsem(dataProsem.data[0].id);
    }
    // eslint-disable-next-line
  }, [dataProsem]);

  useEffect(() => {
    form.setFieldsValue({
      status: "teach",
      post_test: false,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterDataBySubject();

    // eslint-disable-next-line
  }, [subjectId, teacherId]);

  useEffect(() => {
    onGuruChange();

    // eslint-disable-next-line
  }, [teacherId]);

  useEffect(() => {
    onPelajaranChange();
    if (subjectId && classId) {
      fetchProsem();
    }
    fetchStudentList();
    // eslint-disable-next-line
  }, [subjectId]);

  useEffect(() => {
    onProsemChange();

    // eslint-disable-next-line
  }, [idProsem]);

  // end useEffect ---

  // handle in here ---

  const handleSubmit = async () => {
    try {
      if (sourceStudents.length === 0)
        return message.error("Tidak ada data siswa");

      const values = await form.getFieldsValue();
      await form.validateFields();

      const dateFormat = dayjs(values.date).format("YYYY-MM-DD");

      const dateIn = `${dateFormat} ${timeIn || "00:00:00"}`;
      const dateOut = `${dateFormat} ${timeOut || "00:00:00"}`;

      const attendanceData = sourceStudents?.map((student) => {
        return {
          date: dateIn,
          status: student.statusPresence,
          description: values[`description_${student.id}`] || "",
          studentId: student.id,
          sessionId: values.sessionId,
          subjectId: pelajaranId,
        };
      });
      setPosting(true);
      await axios.post(REACT_APP_HOST_API + `/academics/teacher-attendances`, {
        date_in: dateIn,
        date_out: dateOut,
        programSemesterDetailId: values.programSemesterDetailId,
        material: values.material,
        status: values.status,
        reason_not_teach: values.reason_not_teach,
        post_test: values.post_test,
        sessionId: values.sessionId,
        teacherId: values.teacherId,
        subjectId: pelajaranId,
        classId: classId,
        catatanKelas: values.catatanKelas,
      });
      if (values.status !== "not_teach") {
        await axios.post(REACT_APP_HOST_API + `/academics/lesson-attendances`, {
          lessonAttendance: attendanceData,
        });
      }
      message.success("Berhasi menambahkan data kehadiran");
      form.resetFields();
      setClassId("");
      setSourceStudents([]);
      setIsDoneSubmit(true);
    } catch (error) {
      let msg = "";
      console.log(error);
      if (error.response) {
        msg = error?.response?.data?.message;
      } else {
        msg = error?.message;
      }
      message.error(`${msg}`);
    } finally {
      setPosting(false);
    }
  };

  const handleStatusChange = (studentId, newStatus) => {
    const updatedDataSource = sourceStudents.map((student) => {
      if (student.key === studentId) {
        return { ...student, statusPresence: newStatus };
      }
      return student;
    });

    setSourceStudents(updatedDataSource);
  };
  const handleDescChange = (studentId, newDesc) => {
    const updatedDataSource = sourceStudents.map((student) => {
      if (student.key === studentId) {
        return { ...student, descPresence: newDesc };
      }
      return student;
    });

    setSourceStudents(updatedDataSource);
  };

  const onGuruChange = () => {
    form.resetFields([
      "subjectId",
      "prosem",
      "programSemesterDetailId",
      `description_${recordColumn.id}`,
    ]);
    setIdProsem("");
    setSubjectId("");
  };

  const onPelajaranChange = () => {
    form.resetFields([
      "prosem",
      "programSemesterDetailId",
      `description_${recordColumn.id}`,
    ]);
    setIdProsem("");
  };
  const onProsemChange = () => {
    form.resetFields(["programSemesterDetailId"]);
  };

  const handleDesc = (obj) => {
    setOpenModal(true);
    setRecordColumn(obj);
  };

  // end handle ---

  // columns table ---
  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      responsive: ["sm"],
      width: window.innerWidth > 800 ? 70 : 40,
    },
    {
      title: "Nama",
      dataIndex: "name",
      width: window.innerWidth > 800 ? 300 : 180,
      key: "name",
      align: "left",
      render: (text, record) => (
        <Space>
          {text}

          {record.descPresence && (
            <Popover
              content={record.descPresence}
              title="Deskripsi"
              placement="topLeft"
            >
              <InfoCircleTwoTone />
            </Popover>
          )}
        </Space>
      ),
    },
    {
      title: "Status/Deskripsi",
      dataIndex: "statusPresence",
      key: "present",
      align: "left",
      render: (text, record) => (
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
        >
          <Form.Item
            name={record.id}
            initialValue="present"
            style={{ width: "100%" }}
          >
            <Select
              onChange={(e) => handleStatusChange(record.id, e)}
              size="small"
            >
              <Select.Option value="present">
                <Badge color="green" text="Hadir" />
              </Select.Option>
              <Select.Option value="sick">
                <Badge color="orange" text="Sakit" />
              </Select.Option>
              <Select.Option value="permission">
                <Badge color="purple" text="Izin" />
              </Select.Option>
              <Select.Option value="absent">
                <Badge color="red" text="Tidak Hadir" />
              </Select.Option>
            </Select>
          </Form.Item>
          <Button
            onClick={() => handleDesc(record)}
            type="ghost"
            icon={<MoreOutlined />}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (dataStudentList?.data?.data) {
      const initialStudents = dataStudentList.data.data.map((item, index) => ({
        ...item,
        index: index + 1,
        key: item?.id,
        class: item?.class?.name,
        statusPresence: "present",
      }));
      setSourceStudents(initialStudents);
    }
  }, [dataStudentList]);

  const handleChangeSubject = (value) => {
    setSubjectId(value);
  };

  return (
    <div>
      {isLoadGuru ? (
        <Backdrop loading={isLoadGuru} />
      ) : (
        <div className={style.container}>
          <DynamicTitle />

          {!isDoneSubmit ? (
            <div className={style.containerForm}>
              <div className="table-header">
                <h1>Form Jurnal Mengajar</h1>
              </div>
              <Form layout="vertical" form={form}>
                {posting ? (
                  <Skeleton active />
                ) : (
                  <>
                    <div className="wrapper-input">
                      <Form.Item
                        label="Guru"
                        name="teacherId"
                        className="item-input-wrapper"
                        style={{ minWidth: "100%" }}
                        rules={[{ required: true, message: "Harap diisi" }]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(value) => setTeacherId(value)}
                        >
                          {dataGuru?.data.map((guru) => (
                            <Select.Option key={guru.id} value={guru.id}>
                              {guru.employee.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <div style={styleGrid}>
                        <Form.Item
                          label="Mata Pelajaran"
                          style={itemStyle}
                          name="subjectId"
                          rules={[{ required: true, message: "Harap diisi" }]}
                        >
                          <Select
                            placeholder="Pilih Pelajaran"
                            disabled={isloadPelajaran}
                            loading={isloadPelajaran}
                            onChange={(value) => handleChangeSubject(value)}
                          >
                            {dataPelajaran?.data?.map((x) => (
                              <Select.Option
                                key={x.id}
                                value={x.id}
                                style={{ width: "480px" }}
                              >
                                {x.class?.name
                                  ? `${x.subject?.name} - ${x.class?.name}`
                                  : x.subject?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          style={itemStyle}
                          label="Sesi"
                          name="sessionId"
                          rules={[{ required: true, message: "Harap diisi" }]}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            disabled={isEkskul}
                          >
                            {dataSession?.data?.data?.map((session) => (
                              <Select.Option
                                key={session.id}
                                value={session.id}
                              >
                                {session.session}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>

                      <div className="input-inline" style={{ width: "100%" }}>
                        <Form.Item
                          initialValue={dayjs(
                            moment(new Date()).format("YYYY-MM-DD")
                          )}
                          name="date"
                          label="Tanggal"
                          rules={[{ required: true, message: "Harap diisi" }]}
                          style={{ width: "50%" }}
                        >
                          <DatePicker format="YYYY-MM-DD" />
                        </Form.Item>

                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Form.Item
                            name="timeIn"
                            label="Masuk"
                            rules={[
                              statusTeach !== "not_teach" && {
                                required: true,
                                message: "Harap diisi",
                              },
                            ]}
                          >
                            <TimePicker
                              value={timeIn ? dayjs(timeIn, "HH:mm:ss") : null}
                              onSelect={(e) =>
                                setTimeIn(dayjs(e).format("HH:mm:ss"))
                              }
                              style={{ width: "100%" }}
                              format="HH:mm"
                              placeholder="00:00"
                              inputReadOnly
                              popupClassName="time-picker"
                            />
                          </Form.Item>

                          <div style={{ width: "10%" }} />

                          <Form.Item
                            name="timeOut"
                            label="Keluar"
                            rules={[
                              statusTeach !== "not_teach" && {
                                required: true,
                                message: "Harap diisi",
                              },
                            ]}
                          >
                            <TimePicker
                              value={
                                timeOut ? dayjs(timeOut, "HH:mm:ss") : null
                              }
                              onSelect={(e) =>
                                setTimeOut(dayjs(e).format("HH:mm:ss"))
                              }
                              style={{ width: "100%" }}
                              format="HH:mm"
                              placeholder="00:00"
                              inputReadOnly
                              popupClassName="time-picker"
                            />
                          </Form.Item>
                        </div>
                      </div>

                      <div style={styleGrid}>
                        <Form.Item
                          style={itemStyle}
                          label="Prosem"
                          name="prosem"
                        >
                          <Select
                            onChange={(value) => setIdProsem(value)}
                            disabled={isLoadProsem || !subjectId}
                            loading={isLoadProsem}
                          >
                            <Select.Option value="">----------</Select.Option>
                            {dataProsem?.data?.map((x) => (
                              <Select.Option
                                key={x.id}
                                value={x.id}
                                style={{ width: "480px" }}
                              >
                                {x.mapel?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          style={itemStyle}
                          label="Materi Prosem"
                          name="programSemesterDetailId"
                        >
                          <Select
                            disabled={isLoadProsemDetail || !idProsem}
                            loading={isLoadProsemDetail}
                          >
                            <Select.Option value="">----------</Select.Option>
                            {dataProsemDetail?.data?.data?.map((x) => (
                              <Select.Option
                                key={x.id}
                                value={x.id}
                                style={{ width: "480px" }}
                              >
                                {x.materi}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>

                      <div style={styleGrid}>
                        <Form.Item
                          style={itemStyle}
                          label="Materi"
                          name="material"
                          rules={[
                            statusTeach !== "not_teach" && {
                              required: true,
                              message: "Harap diisi",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          style={itemStyle}
                          rules={[
                            statusTeach !== "not_teach" && {
                              required: true,
                              message: "Harap diisi",
                            },
                          ]}
                          label="Catatan Kelas"
                          name="catatanKelas"
                        >
                          <Input
                            suffix={
                              <Tooltip title="Mendeskripsikan keadaan kelas">
                                <InfoCircleOutlined
                                  style={{
                                    color: "rgba(0,0,0,.45)",
                                  }}
                                />
                              </Tooltip>
                            }
                          />
                        </Form.Item>
                      </div>

                      <Form.Item
                        name="status"
                        label="Status"
                        className="item-input-wrapper"
                        style={{ minWidth: "100%" }}
                        rules={[{ required: true, message: "Harap diisi" }]}
                      >
                        <Radio.Group
                          size="small"
                          buttonStyle="solid"
                          onChange={(e) => setStatusTeach(e.target.value)}
                        >
                          <Radio.Button value="teach">Mengajar</Radio.Button>
                          <Radio.Button value="not_teach">
                            Tidak Mengajar
                          </Radio.Button>
                          <Radio.Button value="exam">Ujian</Radio.Button>
                          <Radio.Button value="homework">Tugas </Radio.Button>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        hidden={statusTeach !== "not_teach"}
                        name="reason_not_teach"
                        label="Alasan tidak mengajar"
                        className="item-input-wrapper"
                        style={{ minWidth: "100%" }}
                        rules={[
                          statusTeach === "not_teach" && {
                            required: true,
                            message: "Harap diisi",
                          },
                        ]}
                      >
                        <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                        name="post_test"
                        label="Post test"
                        style={{ marginBottom: "0px", width: "100%" }}
                        className="item-input-wrapper"
                      >
                        <Radio.Group buttonStyle="solid" size="small">
                          <Radio.Button value={true}>Iya</Radio.Button>
                          <Radio.Button value={false}>Tidak</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </div>

                    {/* table and search in here --- */}
                    {statusTeach !== "not_teach" && (
                      <>
                        <h1
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          {className
                            ? `Daftar siswa kelas - ${className}`
                            : "Daftar siswa"}
                        </h1>

                        <div className="search-wrapper filter-wrapper">
                          <Input
                            prefix={<SearchOutlined />}
                            onChange={(e) => setKeyword(e.target.value)}
                            placeholder=" cari nama siswa..."
                          />
                        </div>

                        <Table
                          style={{ marginBottom: 20 }}
                          size={window.innerWidth > 1600 ? "middle" : "small"}
                          scroll={{
                            y: "50vh",
                            // x: 800,
                          }}
                          pagination={false}
                          tableLayout="auto"
                          dataSource={subjectId ? sourceStudents : null}
                          columns={columns}
                          loading={isLoadStudentList || isFetchStudentList}
                        />
                      </>
                    )}
                  </>
                )}

                <div className="button-container">
                  <Button
                    loading={posting}
                    type="primary"
                    onClick={() => handleSubmit()}
                  >
                    Simpan
                  </Button>
                </div>

                {/* modal deskripsi --- */}
                <Modal
                  open={openModal}
                  onCancel={() => setOpenModal(false)}
                  onOk={() => setOpenModal(false)}
                  closable={false}
                  okText="Simpan"
                  cancelText="Kembali"
                  title="Deskripsi"
                >
                  <Form.Item name={`description_${recordColumn.id}`}>
                    <Input
                      placeholder="Description ..."
                      onChange={(e) =>
                        handleDescChange(recordColumn.id, e.target.value)
                      }
                    />
                  </Form.Item>
                </Modal>
              </Form>
            </div>
          ) : (
            <div className={style.containerForm}>
              <Result
                status="success"
                title="Jurnal Mengajar Berhasil Di Tambahkan!"
                subTitle="Login untuk dapat melihat data jurnal mengajar"
                extra={[
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => navigate("/")}
                  >
                    Lihat Jurnal Mengajar
                  </Button>,
                ]}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FormJurnalMengajar;
