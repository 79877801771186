import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useHolidayList = (foundationId = "", enabled) => {
  return useQuery(
    ["get-holiday-list", foundationId],
    () => GetList(`/academics/holidays?foundation_id=${foundationId}`),
    {
      enabled,
    }
  );
};
