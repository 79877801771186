import { Checkbox, Form, Input, Modal, Select, Skeleton } from "antd";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { usePelajaranDetail } from "hooks/pelajaran-hook/usePelajaranDetail";
import { useEffect, useState } from "react";

const EditPelajaran = (props) => {
  const [form] = Form.useForm();
  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(false);

  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  // get foundation list
  const { data: dataFoundation } = useFoundationList();

  const { REACT_APP_HOST_API } = process.env;

  const {
    data,
    isLoading: skeleteon,
    refetch: fetchData,
  } = usePelajaranDetail(props.id, false);

  useEffect(() => {
    if (props.show) {
      fetchData();
    }

    if (data) {
      form.setFieldsValue({
        name: data.data.name,
        description: data.data.description,
        isExtracurricular: data.data.is_extracurricular,
        foundationId: data.data.foundation_id,
      });
    }

    return () => setNewData({});
  }, [form, data, props.show, fetchData]);

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      if (Object.keys(newData).length === 0) {
        alert("Nothing has changed");
        return;
      }

      setLoading(true);

      const { data } = await axios.put(
        `${REACT_APP_HOST_API}/academics/subjects/${props.id}`,
        newData,
        { headers: { Authorization: `Bearer ${decryptCookies("token")}` } }
      );

      message.success(data.message);
      form.resetFields();
      props.onUpdate();
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert("Fields Error");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={props.onCancel}
      okButtonProps={{ loading }}
    >
      {skeleteon && <Skeleton active />}

      {!skeleteon && (
        <Form form={form} layout="vertical">
          {isSuperAdmin && (
            <Form.Item name="foundationId" label="Foundation">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => (newData.foundationId = e)}
              >
                {dataFoundation?.data?.data?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="name"
            label="Nama Pelajaran"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Input
              onChange={({ target: { value } }) => (newData["name"] = value)}
            />
          </Form.Item>
          <Form.Item name="description" label="Deskripsi">
            <Input
              onChange={({ target: { value } }) =>
                (newData["description"] = value)
              }
            />
          </Form.Item>
          <Form.Item name="isExtracurricular" valuePropName="checked">
            <Checkbox
              onChange={(e) =>
                (newData["isExtracurricular"] = e.target.checked)
              }
            >
              Apakah merupakan ekstrakulikuler?
            </Checkbox>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditPelajaran;
