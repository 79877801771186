import {
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Skeleton,
  TimePicker,
  message,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { useActivityCategory } from "hooks/activity-hook/useActivityCategory";
import { useActivityDetail } from "hooks/activity-hook/useActivityDetail";
import { useUnitList } from "hooks/unit-hook/useUnitList";
import { useEffect, useState } from "react";
const format = "HH:mm:ss";

const EditActivity = (props) => {
  const [form] = Form.useForm();
  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(false);
  const [fixedTime, setFixedTime] = useState(false);
  const [timeInStart, setTimeInStart] = useState();

  const { REACT_APP_HOST_API } = process.env;
  const { dataId, show, onUpdate, onCancel } = props;
  const isSuperAdmin = decryptCookies("role")
    .split(",")
    .some((x) => x === "super_admin" || x === "admin_foundation");

  const {
    data,
    isLoading: skeleton,
    refetch,
  } = useActivityDetail(dataId, false);

  const { data: dataCategory, refetch: fetchCategory } = useActivityCategory();
  const { data: dataUnits, isLoading: loadingUnits } = useUnitList();

  useEffect(() => {
    if (show) {
      refetch();
      fetchCategory();
    }

    if (data?.data?.activity_type === "fixed_time") setFixedTime(true);
    if (data?.data?.activity_type === "not_fixed_time") setFixedTime(false);
    if (data?.data?.time_in_start) setTimeInStart(true);
    if (!data?.data?.time_in_start) setTimeInStart(false);

    form.setFieldsValue({
      activityType: data?.data?.activity_type,
      categoryActivityId: data?.data?.categoryActivity?.name,
      assessment: data?.data?.assessment,
      default: data?.data?.default,
      name: data?.data?.name,
      description: data?.data?.description,
      unitId: data?.data?.unit_id,
      maxWorkingDuration:
        data?.data?.max_working_duration &&
        dayjs(data?.data?.max_working_duration, format),
      timeInStart:
        data?.data?.time_in_start && dayjs(data?.data?.time_in_start, format),
      timeLateStart:
        data?.data?.time_late_start &&
        dayjs(data?.data?.time_late_start, format),
      timeInEnd:
        data?.data?.time_in_end && dayjs(data?.data?.time_in_end, format),
      timeOutStart:
        data?.data?.time_out_start && dayjs(data?.data?.time_out_start, format),
      timeOutEnd:
        data?.data?.time_out_end && dayjs(data?.data?.time_out_end, format),
      timeOutDefault:
        data?.data?.time_out_default &&
        dayjs(data?.data?.time_out_default, format),
    });

    return () => {
      setNewData({});
      setFixedTime(false);
      setTimeInStart();
    };
  }, [data, dataId, form, show, refetch, fetchCategory]);

  const handleSubmit = async () => {
    const values = await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Nothing has changed");
      return;
    }

    for (const key in newData) {
      if (Object.hasOwnProperty.call(newData, key)) {
        const element = newData[key];
        if (element === "") newData[key] = null;
      }
    }

    setLoading(true);
    try {
      if (newData.activityType === "fixed_time") {
        const { data } = await axios.put(
          REACT_APP_HOST_API + "/activity/" + dataId,
          {
            ...newData,
            type: "standalone",
            maxWorkingDuration: values?.maxWorkingDuration?.format(format),
            timeInStart: values?.timeInStart?.format(format),
            timeLateStart: values?.timeLateStart?.format(format),
            timeInEnd: values?.timeInEnd?.format(format),
            timeOutStart: values?.timeOutStart?.format(format),
            timeOutEnd: values?.timeOutEnd?.format(format),
            timeOutDefault: values?.timeOutDefault?.format(format),
          },
          { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        );
        onUpdate();
        form.resetFields();
        message.success(data.message, 2);
      } else {
        const { data } = await axios.put(
          REACT_APP_HOST_API + "/activity/" + dataId,
          { ...newData, type: "standalone" },
          { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        );
        onUpdate();
        form.resetFields();
        message.success(data.message, 2);
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputchange = (param) => {
    newData[param.target.name] = param.target.value;
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={onCancel}
      okButtonProps={{ loading }}
      style={{ top: window.innerHeight > 800 ? "" : 20 }}
      width={window.innerWidth > 1300 ? "70%" : "100%"}
    >
      {skeleton && <Skeleton active />}

      {!skeleton && (
        <Form form={form} layout="vertical" className="full-form">
          <div className="first-form">
            <Form.Item label="Tipe" name="activityType">
              <Select
                options={[
                  { label: "Setiap Hari", value: "fixed_time" },
                  { label: "Tidak Setiap Hari", value: "not_fixed_time" },
                ]}
                onChange={(e) => {
                  if (e === "fixed_time") setFixedTime(true);
                  if (e === "not_fixed_time") setFixedTime(false);
                  newData.activityType = e;
                }}
              />
            </Form.Item>
            <Form.Item name="name" label="Nama Aktivitas">
              <Input name="name" onChange={handleInputchange} />
            </Form.Item>
            <Form.Item name="description" label="Deskripsi">
              <Input name="description" onChange={handleInputchange} />
            </Form.Item>
            {isSuperAdmin && (
              <Form.Item name="unitId" label="Unit">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={loadingUnits}
                  onChange={(e) => (newData.unitId = e)}
                >
                  {dataUnits?.data?.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item label="Kategori" name="categoryActivityId">
              <Select onChange={(e) => (newData.categoryActivityId = e)}>
                {dataCategory?.data?.data?.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Default" name="default">
              <InputNumber
                style={{ width: "100%" }}
                onChange={(e) => (newData.default = e)}
              />
            </Form.Item>
            <Form.Item label="Masuk Penilaian" name="assessment">
              <Radio.Group
                buttonStyle="solid"
                onChange={(e) => (newData.assessment = e.target.value)}
              >
                <Radio.Button value={true}>Masuk</Radio.Button>
                <Radio.Button value={false}>Tidak</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
          {!fixedTime ? (
            ""
          ) : (
            <div className="second-form">
              <Form.Item
                name="maxWorkingDuration"
                label="Durasi Maksimal Jam Kerja"
              >
                <TimePicker
                  popupClassName="time-picker"
                  format={format}
                  onChange={(e) => {
                    if (e !== null)
                      newData["maxWorkingDuration"] = e.format(format);
                    else newData.maxWorkingDuration = e;
                  }}
                />
              </Form.Item>
              <Form.Item
                name="timeInStart"
                label="Awal Waktu Masuk"
                rules={[{ required: fixedTime }]}
              >
                <TimePicker
                  popupClassName="time-picker"
                  format={format}
                  onChange={(e) => {
                    if (e !== null) {
                      setTimeInStart(true);
                      newData["timeInStart"] = e.format(format);
                    } else {
                      newData.timeInStart = e;
                      setTimeInStart(false);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="timeLateStart"
                label="Waktu Terlambat"
                rules={[{ required: timeInStart }]}
              >
                <TimePicker
                  popupClassName="time-picker"
                  format={format}
                  onChange={(e) => {
                    if (e !== null) newData["timeLateStart"] = e.format(format);
                    else newData.timeLateStart = e;
                  }}
                />
              </Form.Item>
              <Form.Item
                name="timeInEnd"
                label="Akhir Waktu Masuk"
                hasFeedback
                rules={[
                  { required: timeInStart },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        getFieldValue("timeInStart").format(format) <
                          value.format(format)
                      ) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "masukkan diatas jam " +
                            getFieldValue("timeInStart").format(format)
                        )
                      );
                    },
                  }),
                ]}
              >
                <TimePicker
                  popupClassName="time-picker"
                  format={format}
                  onChange={(e) => {
                    if (e !== null) newData["timeInEnd"] = e.format(format);
                    else newData.timeInEnd = e;
                  }}
                />
              </Form.Item>

              <Form.Item
                name="timeOutStart"
                label="Awal Waktu Pulang"
                hasFeedback
                rules={[
                  { required: timeInStart },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        getFieldValue("timeInEnd").format(format) <
                          value.format(format)
                      ) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "masukkan diatas jam " +
                            getFieldValue("timeInEnd").format(format)
                        )
                      );
                    },
                  }),
                ]}
              >
                <TimePicker
                  popupClassName="time-picker"
                  format={format}
                  onChange={(e) => {
                    if (e !== null) newData["timeOutStart"] = e.format(format);
                    else newData.timeOutStart = e;
                  }}
                />
              </Form.Item>
              <Form.Item
                name="timeOutEnd"
                label="Akhir Waktu Pulang"
                hasFeedback
                rules={[
                  { required: timeInStart },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        getFieldValue("timeOutStart").format(format) <
                          value.format(format)
                      ) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "masukkan diatas jam " +
                            getFieldValue("timeOutStart").format(format)
                        )
                      );
                    },
                  }),
                ]}
              >
                <TimePicker
                  popupClassName="time-picker"
                  format={format}
                  onChange={(e) => {
                    if (e !== null) newData["timeOutEnd"] = e.format(format);
                    else newData.timeOutEnd = e;
                  }}
                />
              </Form.Item>
              <Form.Item
                name="timeOutDefault"
                label="Waktu Pulang Default"
                hasFeedback
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        getFieldValue("timeOutStart").format(format) <
                          value.format(format)
                      ) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "masukkan diatas jam " +
                            getFieldValue("timeOutStart").format(format)
                        )
                      );
                    },
                  }),
                ]}
              >
                <TimePicker
                  popupClassName="time-picker"
                  format={format}
                  onChange={(e) => {
                    if (e !== null)
                      newData["timeOutDefault"] = e.format(format);
                    else newData.timeOutDefault = e;
                  }}
                />
              </Form.Item>
            </div>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default EditActivity;
