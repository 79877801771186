import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, message, Modal, Upload } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useState } from "react";

const AddManufacturer = ({ show, onCreate, onCancel }) => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  const handleSubmit = async () => {
    if (fileList.length === 0) {
      form.resetFields(["image"]);
    }
    const values = await form.validateFields();
    if (fileList.length > 0) {
      values.image = values.image.file;
    }
    try {
      setLoading(true);

      const { data } = await axios.post(
        REACT_APP_HOST_API + `/inventories/manufacturers`,
        { ...values },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      message.success(data.message);
      form.resetFields();
      setFileList([]);
      onCreate();
    } catch (error) {
      Modal.error({
        title: "Gagal Menyimpan",
        content: <pre>{error?.response?.data?.message}</pre>,
      });
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  // upload image
  const isImage = (file) => {
    return (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    );
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // Set the preview property if it's not already set
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    // Show preview modal
    Modal.info({
      title: file.name,
      content: (
        <img
          alt="preview"
          style={{ width: "100%" }}
          src={file.url || file.preview}
        />
      ),
    });
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
    form.setFieldValue("file", null);
  };

  const beforeUpload = (file) => {
    if (!isImage(file)) {
      message.error("hanya bisa upload file gambar (.jpeg, .jpg, .png)");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("gambar ukurannya harus kurang dari 2MB!");
      return false;
    }
    if (!isImage(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isImage(file) ? [file] : []);
    return false;
  };
  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const isLt2M = newFileList[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return;
      }
    }
    setFileList(newFileList);
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    fileList,
    onChange: handleChange,
  };

  // end upload image

  return (
    <Modal
      open={show}
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Nama Produsen"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="url"
          label="Link URL"
          rules={[{ type: "url", message: "Harap masukkan url yang valid" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="supportPhone"
          label="No Telp"
          rules={[
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "Masukkan hanya Nomor",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="supportEmail"
          label="Email"
          rules={[{ type: "email", message: "Masukkan email yang valid" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="image"
          label="Gambar"
          rules={[
            {
              validator: (_, value) => {
                const file = value?.fileList[0];
                if (!file) {
                  return Promise.resolve();
                }
                if (!isImage(file)) {
                  return Promise.reject(
                    new Error("Please upload an image file")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload
            name="image"
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            showUploadList={true}
            onPreview={handlePreview}
            {...propsUpload}
            disabled={loading}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                {fileList.length === 0 ? (
                  <span style={{ fontSize: "12px" }}>
                    Upload <br />
                    (max 2 mb)
                  </span>
                ) : (
                  "Ganti"
                )}
                {fileList.length === 0 ? "" : ""}
              </div>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddManufacturer;
