import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useKodeKeuanganList = (foundationId) => {
  return useQuery(["get-kode-keuangan-list", foundationId || ""], () =>
    GetPagination(
      `/financial-report/finance-codes?page=1&limit=10000&mode=page&foundationId=${foundationId}`
    )
  );
};
