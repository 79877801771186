import { Button, DatePicker, Form, Input, Select, Space, Table } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import EditRecap from "../edit/EditRecap";
import EditSelectRecap from "../edit/selected-edit/EditSelectRecap";
import { useState } from "react";
import { Popconfirm, Tag } from "antd";
import { useDailyAttandance } from "hooks/attandance-hook/useDailyAttandance";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "dayjs/locale/id"; // Import the Indonesian locale
import { usePermission } from "hooks/usePermissions";
import { DeleteApi } from "api/DeleteApi";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useClassListFoundation } from "hooks/classes-hook/useClassListFoundation";
import useResetPage from "components/Helper/useResetPage";

dayjs.locale("id");
const disabled = { color: "#ccc", cursor: "not-allowed" };

const RecapDaily = () => {
  const [form] = Form.useForm();
  const [classId, setClassId] = useState("");
  const [dateIn, setDateIn] = useState(dayjs().format("YYYY-MM-DD"));
  const [dateOut, setDateOut] = useState(dayjs().format("YYYY-MM-DD"));
  const [dataId, setDataId] = useState("");
  const [showEditDaily, setShowEditDaily] = useState(false);
  const [foundationId, setFoundationId] = useState("");
  const [keyword, setKeyword] = useState("");
  const { data: dataClass, isLoading: skeleton } =
    useClassListFoundation(foundationId);
  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });

  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  const {
    data,
    isLoading,
    isFetching,
    refetch: fetchDaily,
  } = useDailyAttandance(
    dataTable,
    keyword,
    classId,
    dateIn,
    dateOut,
    foundationId,
    true
  );

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  const { data: dataPermission } = usePermission(
    "mdlAcademic",
    "mnuPresenceDaily"
  );

  // Buttons Permission
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddPresenceDaily");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditPresenceDaily");
  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeletePresenceDaily"
  );

  const navigateToTambahAbsensi = () => {
    navigate("/academic/dailyAttandance");
  };

  const handleSearch = (param) => {
    setKeyword(param.target.value);
  };

  const onUpdate = useCallback(() => {
    setShowEditDaily(false);
    fetchDaily();
  }, [fetchDaily]);

  const onCancel = () => {
    setShowEditDaily(false);
  };

  const statusMap = {
    present: { text: "Hadir", color: "green" },
    sick: { text: "Sakit", color: "orange" },
    permission: { text: "Izin", color: "purple" },
    absent: { text: "Alpa", color: "red" },
  };

  const getStatusTag = (status) => {
    const { text, color } = statusMap[status] || {
      text: status,
      color: "gray",
    };
    return <Tag color={color}>{text}</Tag>;
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      fixed: "left",
      width: window.innerWidth > 800 ? 70 : 50,
      responsive: ["md"],
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
      width: window.innerWidth > 800 ? 200 : 70,
      fixed: "left",
    },
    {
      title: "Kelas",
      dataIndex: "className",
      key: "class",
      align: "left",
      width: 100,
    },
    {
      title: "Tanggal",
      dataIndex: "dateIn",
      key: "date",
      align: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "left",
      render: (status) => getStatusTag(status),
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      key: "description",
      align: "left",
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      align: "center",
      width: 150,
      render: (id) => {
        return (
          <>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                const dataId = id;
                DeleteApi({
                  url: "/academics/daily-attendances/",
                  dataId,
                  refetch: fetchDaily,
                });
              }}
            >
              <Tag
                color="red"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Delete
              </Tag>
            </Popconfirm>

            <Tag
              color="orange"
              hidden={!btnEdit || rowSelected.length !== 0}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setDataId(id);
                  setShowEditDaily(true);
                }
              }}
            >
              Edit
            </Tag>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (classId !== "") {
      fetchDaily();
    }
  }, [classId, fetchDaily]);

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const dataSources = data?.data.data.map((item, index) => {
    return {
      ...item,
      index: index + 1,
      key: item.id,
      name: item?.student?.name,
      className: item?.student?.class?.name,
      dateIn: dayjs(
        moment.utc(item.date_in).format("dddd DD MMMM YYYY")
      ).format("dddd, DD MMMM YYYY"),
    };
  });

  // dev --

  // rowSelection object indicates the need for row selection
  const [rowSelected, setRowSelected] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
    preserveSelectedRowKeys: true,
  };

  const [isOpenSelect, setIsOpenSelect] = useState(false);

  const handleOpenSelect = () => {
    setIsOpenSelect(!isOpenSelect);
  };

  useResetPage(setDataTable, [keyword, classId, dateIn, dateOut, foundationId]);

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Absensi harian</h1>
        <Space>
          <Button
            onClick={handleOpenSelect}
            hidden={rowSelected.length === 0 || !btnEdit}
            disabled={btnEdit && btnEdit.type === "disabled"}
            type="primary"
            ghost
          >
            Edit
          </Button>

          <Button
            hidden={!btnAdd}
            disabled={btnAdd && btnAdd.type === "disabled"}
            type="primary"
            onClick={navigateToTambahAbsensi}
          >
            Tambah absensi harian
          </Button>
        </Space>
      </div>
      <Form form={form} layout="vertical">
        <div className="search-wrapper filter-wrapper">
          {isSuperAdmin && (
            <Form.Item
              name="foundationId"
              label="Yayasan"
              className="form-item-kehadiran"
              style={{ width: "100%", marginBottom: 0 }}
            >
              <Select
                style={{ minWidth: 150 }}
                onChange={(x) => {
                  setFoundationId(x);
                  setClassId("");
                  form.setFieldsValue({ classId: "" });
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option className="select-option-foundation" value="">
                  ALL
                </Select.Option>
                {dataFoundation?.data?.data?.map((x) => (
                  <Select.Option
                    key={x.id}
                    value={x.id}
                    className="select-option-foundation"
                  >
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="classId"
            label="Kelas"
            className="form-item-kehadiran"
            style={{ width: "100%", marginBottom: 0 }}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => setClassId(value)}
              disabled={skeleton}
            >
              <Select.Option value={""}>Semua kelas</Select.Option>
              {dataClass?.data?.map((kelas) => (
                <Select.Option key={kelas.id} value={kelas.id}>
                  {kelas.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Nama" style={{ width: "100%", marginBottom: 0 }}>
            <Input
              value={keyword}
              onChange={handleSearch}
              placeholder="cari nama murid"
            />
          </Form.Item>
          <Form.Item
            name="date_in"
            label="Tanggal mulai"
            className="form-item-kehadiran"
            initialValue={dayjs(moment(new Date()).format("YYYY-MM-DD"))}
            style={{ width: "100%", marginBottom: 0 }}
          >
            <DatePicker
              style={{ padding: 4 }}
              format="DD/MM/YYYY"
              onChange={(value) => setDateIn(dayjs(value).format("YYYY-MM-DD"))}
            />
          </Form.Item>

          <Form.Item
            name="date_out"
            label="Tanggal akhir"
            className="form-item-kehadiran"
            initialValue={dayjs(moment(new Date()).format("YYYY-MM-DD"))}
            style={{ width: "100%", marginBottom: 0 }}
          >
            <DatePicker
              style={{ padding: 4 }}
              format="DD/MM/YYYY"
              onChange={(value) =>
                setDateOut(dayjs(value).format("YYYY-MM-DD"))
              }
            />
          </Form.Item>
        </div>
      </Form>

      <Table
        rowSelection={rowSelection}
        size={window.innerWidth > 1600 ? "middle" : "small"}
        tableLayout="auto"
        dataSource={dataSources}
        columns={columns}
        loading={isLoading || isFetching}
        scroll={
          window.innerHeight < 690
            ? { y: "52vh", x: 900 }
            : { y: "55vh", x: 900 }
        }
        pagination={pagination}
      />
      <EditRecap
        id={dataId}
        onUpdate={onUpdate}
        onCancel={onCancel}
        show={showEditDaily}
      />
      <EditSelectRecap
        open={isOpenSelect}
        setOpen={handleOpenSelect}
        rowSelected={rowSelected}
        onUpdate={onUpdate}
      />
    </div>
  );
};

export default RecapDaily;
