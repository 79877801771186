import React from 'react';
import { Table } from 'antd';
import { numberFormat } from "pages/si-finance-report/utlis/utlis";

const TableRecapDivisi = ({ data }) => {
   const columns = [
      {
         title: 'No',
         dataIndex: 'index',
         key: 'index',
      },
      {
         title: 'Divisi',
         dataIndex: 'divisi',
         key: 'divisi',
      },
      {
         title: 'Realisasi',
         dataIndex: 'amount',
         key: 'amount',
         render: (amount) => {
            return numberFormat(amount)
         }
      },
   ];
   const dataSource = data?.map((data, index) => {
      return {
         ...data,
         index: index + 1,
         key: index,
         divisi: `${data.code} - ${data.name}`,
      };
   }
   );

   return (
      <Table
         columns={columns}
         dataSource={dataSource}
         size="small"
         pagination={false}
         className="table-recap"
      />
   );
};
export default TableRecapDivisi;