import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useAssetCategories = (keyword = "", foundation_id = "") => {
  return useQuery(
    ["get-assets-categories-list", keyword, foundation_id],
    () =>
      GetList(
        `/inventories/asset-categories?foundation_id=${foundation_id}&keyword=${keyword}&mode=list`
      ),
    { enabled: true }
  );
};
