import { Modal } from "antd";

export const renderModalError = (title, error) => {
  let msg = error?.response?.data?.message || error || "Input Field Error";

  Modal.error({
    title: title,
    content: <pre>{msg}</pre>,
  });
};
