import RequireAuth from "components/Auth/RequireAuth";
import Anggaran from "pages/si-finance-report/master-anggaran/master/Anggaran";
import KodeKeuangan from "pages/si-finance-report/master-kode-keuangan/master/KodeKeuangan";
import TransitData from "pages/si-finance-report/master-transit-data/master/TransitData";
import LaporanKeuangan from "pages/si-finance-report/master-laporan-keuangan/master/LaporanKeuangan";
import DataVa from "pages/si-finance-report/master-data-va/master/DataVa";
import Pendapatan from "pages/si-finance-report/master-pendapatan/master/Pendapatan";
import LayoutFinance from "Layouts/LayoutFinance/LayoutFinance";
import RequireMenuFinance from "components/Auth/RequireMenuFinance";

export const DataRoutesFinanceReport = [
  {
    path: "finance/kodekeuangan",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuKodeKeuangan">
          <LayoutFinance content={<KodeKeuangan />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/anggaran",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuAnggaran">
          <LayoutFinance content={<Anggaran />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/data-pengeluaran",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuTransitData">
          <LayoutFinance content={<TransitData />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/laporankeuangan",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuLaporanKeuangan">
          <LayoutFinance content={<LaporanKeuangan />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/datava",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuDataVa">
          <LayoutFinance content={<DataVa />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
  {
    path: "finance/pendapatan",
    element: (
      <RequireAuth>
        <RequireMenuFinance menu="mnuPendapatan">
          <LayoutFinance content={<Pendapatan />} />
        </RequireMenuFinance>
      </RequireAuth>
    ),
  },
];
