import { Form, Modal, TimePicker, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const SetTimeOutDefault = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const {
    activityId,
    dateFrom,
    dateTo,
    show,
    timeOut,
    onCancel,
    onSubmitTimeOut,
  } = props;

  useEffect(() => {
    if (timeOut)
      form.setFieldsValue({
        timeOut: dayjs(timeOut, "HH:mm:ss"),
      });
  }, [form, timeOut]);

  const { REACT_APP_HOST_API: api } = process.env;

  const handleSubmit = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const { data } = await axios.put(
        api +
          `/presence/timeout?fromDate=${dateFrom}&toDate=${dateTo}&activity_id=${activityId}&timeOut=${values.timeOut.format(
            "HH:mm:ss"
          )}`,
        {},
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      onSubmitTimeOut();
      onCancel();
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      closable={false}
      open={show}
      onOk={handleSubmit}
      onCancel={onCancel}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="timeOut" label="Waktu Pulang">
          <TimePicker popupClassName="time-picker" format="HH:mm:ss" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SetTimeOutDefault;
