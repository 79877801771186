import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Select, Space, Table, Tooltip } from "antd";
import { Popconfirm, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUnitPagination } from "hooks/unit-hook/useUnitPagination";
import CreateUnit from "../create/CreateUnit";
import EditUnit from "../edit/EditUnit";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { decryptCookies } from "components/Helper/CookiesHelper";
import "./Unit.css";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const Unit = () => {
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [showEdit, setShowUpdate] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [foundationId, setFoundationId] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some(
    (x) => x === "super_admin" || x === "admin_foundation"
  );
  const employeeId = decryptCookies("employeeId");

  const navigate = useNavigate();

  // fetch unit pagination
  const {
    data: dataUnit,
    isLoading,
    isFetching,
    refetch,
  } = useUnitPagination(dataTable, keyword, foundationId);

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  // fetch permission
  const { data: dataPermission } = usePermission("mdlHRD", "mnuUnit");

  const onCreate = useCallback(() => {
    setShowCreate(false);
    refetch();
  }, [refetch]);

  const onUpdate = useCallback(() => {
    setShowUpdate(false);
    refetch();
  }, [refetch]);

  const onCancel = () => {
    setShowCreate(false);
    setShowUpdate(false);
  };

  const handleChange = async (param) => {
    setKeyword(param.target.value);
  };

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddUnit");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditUnit");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteUnit");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailUnit");

  const dataSource = dataUnit?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
        lead: data.employeeUnits && data.employeeUnits[0]?.employee?.name,
      };
    });

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      width: 50,
      fixed: "left",
      responsive: ["sm"],
    },
    { title: "Nama", dataIndex: "name", fixed: "left" },
    { title: "Deskripsi", dataIndex: "description" },
    {
      title: "Ketua",
      dataIndex: "lead",
      width: 200,
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id, data) => {
        const isLead = data?.employeeUnits[0]?.employee_id === employeeId;

        return (
          <>
            <Popconfirm
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({ url: "/units/", dataId: id, refetch })
              }
              title="Yakin ingin menghapus ?"
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit || (!isLead && !isSuperAdmin)}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setShowUpdate(true);
                  setDataId(id);
                }
              }}
            >
              Ubah
            </Tag>
            <Tag
              color="blue"
              hidden={!btnDetail || (!isLead && !isSuperAdmin)}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnDetail?.type === "disabled") {
                  return;
                } else navigate(id);
              }}
            >
              Detail
            </Tag>
          </>
        );
      },
    },
  ];

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    defaultPageSize: dataTable.per_page,
    total: dataUnit?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) =>
      setDataTable((value) => {
        return {
          ...value,
          current_page: curr,
          per_page: size,
        };
      }),
  };
  return (
    <>
      <div className="table-header">
        <h1>Daftar Unit</h1>
        <Space>
          <Input
            value={keyword}
            onChange={handleChange}
            prefix={<SearchOutlined />}
            placeholder="cari nama unit"
          />
          {roles.some((x) => x === "super_admin") && (
            <Tooltip Tooltip title="Yayasan">
              <Select
                placeholder="Pilih Yayasan"
                style={{ minWidth: 150, maxWidth: 150 }}
                onChange={(x) => setFoundationId(x)}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option className="select-option-foundation" value="">
                  ALL
                </Select.Option>
                {dataFoundation?.data?.data?.map((x) => (
                  <Select.Option
                    key={x.id}
                    value={x.id}
                    className="select-option-foundation"
                  >
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
          )}
          <Button
            type="primary"
            hidden={!btnAdd}
            onClick={() => setShowCreate(true)}
            disabled={btnAdd && btnAdd.type === "disabled"}
          >
            Tambah
          </Button>
        </Space>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "52vh", x: 900 }
            : { y: "55vh", x: 900 }
        }
      />
      <CreateUnit onCancel={onCancel} onCreate={onCreate} show={showCreate} />
      <EditUnit
        id={dataId}
        onCancel={onCancel}
        onUpdate={onUpdate}
        show={showEdit}
      />
    </>
  );
};

export default Unit;
