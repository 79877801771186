import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useTransitDataDetail = (id, enabled) => {
  return useQuery(
    ["get-transit-data-detail", id],
    () => GetDetail(`/financial-report/transit-data/${id}`),
    {
      enabled,
    }
  );
};
