import { InboxOutlined } from "@ant-design/icons";
import { Form, Modal, Select, Upload } from "antd";
import React from "react";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaFileCsv, FaFilePdf } from "react-icons/fa";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useState } from "react";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { renderModalError } from "pages/si-finance-report/utlis/utlis";
const { Dragger } = Upload;

const ImportPendapatan = (props) => {
   const [form] = Form.useForm();
   const [fileList, setFileList] = useState([]);
   const [fileListPdf, setFileListPdf] = useState([]);
   const [uploading, setUploading] = useState(false);
   const { REACT_APP_HOST_API } = process.env;
   const roles = decryptCookies("role").split(",");
   const isSuperAdmin = roles.some((x) => x === "super_admin");
   const { data: dataFoundation } = useFoundationList();

   const isExcel = (file) => {
      const excelTypes = [
         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
         "application/vnd.ms-excel",
         "application/vnd.ms-excel.sheet.macroEnabled.12",
         "application/vnd.ms-excel.template.macroEnabled.12",
         "application/vnd.ms-excel.addin.macroEnabled.12",
         "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
         "text/csv"
      ];
      return excelTypes.includes(file.type);
   };

   const isPdf = (file) => file.type === "application/pdf";

   const uploadFile = async (url, file, foundationId) => {
      const { data } = await axios({
         method: "POST",
         url: REACT_APP_HOST_API + url,
         headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + decryptCookies("token"),
         },
         data: {
            file,
            foundationId,
         },
      });
      message.success(data.message);
   };

   const handleUpload = async () => {
      setUploading(true);
      try {
         const values = await form.validateFields();
         if ((!values.filePdf || values?.filePdf?.fileList.length === 0) && (!values.file || values?.file?.fileList.length === 0)) {
            message.error("upload salah satu file excel atau pdf");
            return;
         }
         if (values.filePdf) {
            await uploadFile("/financial-report/import-bsi", values.filePdf.file, values.foundationId);
         }
         if (values.file) {
            await uploadFile("/financial-report/import-manual", values.file.file, values.foundationId);
         }
         setFileListPdf([]);
         setFileList([]);
         props.onImport();
         form.resetFields();
      } catch (error) {
         renderModalError("Gagal Melakukan Import", error);
      } finally {
         setUploading(false);
      }
   };

   const handleRemove = (file, setFileListFunc, fileList) => {
      const newFileList = fileList.filter((f) => f.uid !== file.uid);
      setFileListFunc(newFileList);
   };

   const beforeUpload = (file, isFileType, setFileListFunc, fileList) => {
      if (!isFileType(file)) {
         message.error(
            `You can only upload ${isFileType === isExcel ? "Excel" : "PDF"} files!`
         );
      }
      if (!isFileType(file) && fileList.length > 0) {
         setFileListFunc([...fileList]);
         return false;
      }
      setFileListFunc(isFileType(file) ? [file] : []);
      return false;
   };

   const onDrop = (e, isFileType) => {
      const droppedFiles = e.dataTransfer.files;
      if (!isFileType(droppedFiles[0])) {
         message.error(
            `You can only upload ${isFileType === isExcel ? "Excel" : "PDF"} files!`
         );
         return;
      }
      message.success("File dropped");
   };

   const onCancelModal = () => {
      form.resetFields();
      setFileList([]);
      setFileListPdf([]);
      props.onCancel();
   };

   const propsUpload = {
      onRemove: (file) => handleRemove(file, setFileList, fileList),
      beforeUpload: (file) => beforeUpload(file, isExcel, setFileList, fileList),
      onDrop: (e) => onDrop(e, isExcel),
      fileList,
   };

   const propsUploadPdf = {
      onRemove: (file) => handleRemove(file, setFileListPdf, fileListPdf),
      beforeUpload: (file) => beforeUpload(file, isPdf, setFileListPdf, fileListPdf),
      onDrop: (e) => onDrop(e, isPdf),
      fileList: fileListPdf,
   };

   return (
      <Modal
         okText="Simpan"
         cancelText="Batal"
         onOk={handleUpload}
         open={props.show}
         onCancel={onCancelModal}
         okButtonProps={{ loading: uploading }}
         cancelButtonProps={{ disabled: uploading }}
         title="Import Transit Data"
      >

         <Form form={form} layout="vertical">
            {(isSuperAdmin) &&
               <Form.Item
                  name="foundationId"
                  label="Foundation"
                  rules={[{ required: true }]}
               >
                  <Select
                     showSearch
                     placeholder="Pilih foundation"
                     optionFilterProp="children"
                     filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                     }

                  >
                     {dataFoundation?.data?.data?.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                           {item.name}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
            }
            {/* pdf */}
            <Form.Item
               name='filePdf'
               label='BSI'
            >
               <Dragger
                  name='filePdf'
                  accept='.pdf'
                  listType='picture'
                  disabled={uploading}
                  iconRender={(file) => {
                     if (file.type === 'application/pdf') {
                        return <FaFilePdf size={45} color='red' />;
                     }
                     return <InboxOutlined />;
                  }}
                  {...propsUploadPdf}
               >
                  <p className='ant-upload-drag-icon'>
                     <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>
                     Click or drag file to this area to upload
                  </p>
                  <p className='ant-upload-hint'>Support for PDF file.</p>
               </Dragger>
            </Form.Item>
            <div>
               <p
                  style={{
                     fontSize: "15px",
                  }}
               >
                  Download the template file{" "}
                  <a
                     rel="noreferrer"
                     target="_blank"
                     href="https://docs.google.com/spreadsheets/d/19SCVEkv5Ed4TZrpzRIUOTpJQmj3GAq1on6LIVU7B3Ew"
                  >
                     here
                  </a>
                  .
               </p>
            </div>
            {/* excel */}
            <Form.Item
               name="file"
               label="Manual"
            >
               <Dragger
                  name="file"
                  accept=".xlsx, .xls, .csv, .xlsb, .xlsm, .xml"
                  listType="picture"
                  disabled={uploading}
                  iconRender={(file) => {
                     if (
                        file.type === "application/vnd.ms-excel" ||
                        file.type ===
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                     ) {
                        return (
                           <SiMicrosoftexcel
                              size={45}
                              color={
                                 file.type === "application/vnd.ms-excel"
                                    ? "#1e90ff"
                                    : "#008000"
                              }
                           />
                        );
                     }
                     if (file.type === "text/csv") {
                        return (
                           <FaFileCsv
                              size={45}
                              color={file.type === "text/csv" ? "#1e90ff" : "#008000"}
                           />
                        );
                     }
                     return <InboxOutlined />;
                  }}
                  {...propsUpload}
               >
                  <p className="ant-upload-drag-icon">
                     <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                     Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">Support for excel or csv file.</p>
               </Dragger>
            </Form.Item>
         </Form>
      </Modal>
   );
};

export default ImportPendapatan;
