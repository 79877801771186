import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Select, Table, Tooltip } from "antd";
import AssetsStatusLogic from "./AssetsStatusLogic";
import EditAssets from "../edit/EditAssets";
import FilterFoundation from "components/UI/FilterFoundation";

const AssetsStatus = () => {
  const {
    roles,
    btnExport,
    exportToExcel,
    setFoundationId,
    setFundSourceId,
    dataFundSource,
    dataCategories,
    handleChangeCategory,

    dataId,
    keyword,
    dataKey,
    columns,
    statusId,
    isLoading,
    isFetching,
    pagination,
    showEditAssets,
    onUpdate,
    onCancel,
    handleChange,
  } = AssetsStatusLogic();

  return (
    <div>
      <div className="table-header">
        <h1 style={{ textTransform: "capitalize" }}>{statusId} Assets</h1>

        <Button
          type="primary"
          onClick={() => {
            if (btnExport === "disabled") return;
            exportToExcel();
          }}
        >
          Export Excel
        </Button>
      </div>

      <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-4">
        <Tooltip title="Cari Aset">
          <Input
            prefix={<SearchOutlined />}
            value={keyword}
            onChange={handleChange}
            placeholder="Cari Aset Serial, Tag..."
          />
        </Tooltip>

        {roles.includes("super_admin") && (
          <FilterFoundation setFoundationId={setFoundationId} />
        )}

        <Tooltip title="Sumber Data">
          <Select defaultValue={""} onChange={setFundSourceId}>
            <Select.Option value="">Semua</Select.Option>
            {dataFundSource?.data?.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Tooltip>

        <Tooltip title="Kategori Aset">
          <Select
            onChange={handleChangeCategory}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            mode="multiple"
            placeholder="Pilih Kategori Aset"
            options={dataCategories?.data?.map((x) => ({
              value: x.id,
              label: x.name,
            }))}
          />
        </Tooltip>
      </div>
      <Table
        size="small"
        tableLayout="auto"
        dataSource={dataKey}
        columns={columns}
        loading={isLoading || isFetching}
        pagination={pagination}
        scroll={
          window.innerHeight < 760
            ? {
                y: "50vh",
                x: 800,
              }
            : {
                y: "55vh",
                x: 800,
              }
        }
      />
      <EditAssets
        id={dataId}
        show={showEditAssets}
        onUpdate={onUpdate}
        onCancel={onCancel}
      />
    </div>
  );
};

export default AssetsStatus;
