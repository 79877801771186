import { Form, Input, InputNumber, Modal, Radio, Select, Skeleton } from "antd";
import React from "react";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useKodeKeuanganPagination } from "hooks/finance-report-hook/useKodeKeuanganPagination";
import { useDetailDataVa } from "hooks/finance-report-hook/useDetailDataVa";
import { renderModalError } from "pages/si-finance-report/utlis/utlis";

const EditDataVa = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const { REACT_APP_HOST_API } = process.env;

  // get roles
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");


  // get foundation list
  const { data: dataFoundation } = useFoundationList();


  // get kode unit
  const {
    data: dataKodeUnit,
    isLoading: isLoadingKodeUnit,
    isFetching: isFetchingKodeUnit,
  } = useKodeKeuanganPagination(
    {
      current_page: 1,
      per_page: 1000,
      total: 0,
    },
    "",
    '',
    "unit");

  // fetch detail units
  const {
    isLoading: skeleton,
    refetch: fetchData,
  } = useDetailDataVa(props.id, false);

  const setData = async () => {
    const { data } = await fetchData();
    const successData = data?.data;

    if (successData) {
      form.setFieldsValue({
        va: successData.va,
        foundationId: successData.foundation_id,
        codeUnitId: successData.code_unit_id,
        name: successData.name,
        jurusan: successData.jurusan,
      });
    }
  };

  useEffect(() => {
    if (props.show) {
      setData();
    }

    return () => setNewData({});
    // eslint-disable-next-line
  }, [form, props.show]);


  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (Object.keys(newData).length === 0) {
        renderModalError("Error Edit Data Va", "Tidak Ada Perubahan Data");
        return;
      }

      for (const key in newData) {
        if (Object.hasOwnProperty.call(newData, key)) {
          const element = newData[key];
          if (element === "") newData[key] = null;
        }
      }

      if (newData.code) {
        newData.foundationId = newData.foundationId || values.foundationId || decryptCookies("foundationId");
      }

      setLoading(true);

      const { data } = await axios.put(
        REACT_APP_HOST_API + `/financial-report/virtual-accounts/${props.id}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      message.success(data.message);
      form.resetFields();
      props.onUpdate();
    } catch (error) {
      renderModalError("Error Edit Data Va", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    props.onCancel();
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={handleCancel}
      okButtonProps={{ loading }}
    >
      {skeleton && <Skeleton active />}

      {!skeleton && (
        <Form form={form} layout="vertical">
          <Form.Item
            label="Nomor VA"
            name="va"
            rules={[
              {
                required: true,
                message: "Nomor Va harus diisi!",
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }}
              onChange={(value) => (newData["va"] = value ? value.toString() : null)}
            />
          </Form.Item>
          {(isSuperAdmin) &&
            <Form.Item
              name="foundationId"
              label="Foundation"
              rules={[{ required: true, message: "Foundation harus diisi!", }]}
            >
              <Select
                showSearch
                placeholder="Pilih foundation"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => (newData.foundationId = e)}
              >
                {dataFoundation?.data?.data?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          }
          <Form.Item
            name="codeUnitId"
            label="Unit"
            rules={[{ required: true, message: "Unit harus diisi!" }]}
          >
            <Select
              showSearch
              placeholder="Pilih Unit"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e) => (newData.codeUnitId = e)}
              disabled={(isLoadingKodeUnit || isFetchingKodeUnit)}
            >
              {dataKodeUnit?.data?.data?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {`${item.code} - ${item.name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Nama (opsional)"
            name="name"
          >
            <Input
              onChange={(e) => (newData.name = e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Jurusan (opsional)"
            name="jurusan"
          >
            <Radio.Group buttonStyle="solid"
              onChange={(e) => (newData.jurusan = e.target.value)}
            >
              <Radio.Button value="F"> F (Fullday)</Radio.Button>
              <Radio.Button value="M">M (Mahad)</Radio.Button>
              <Radio.Button value="B">B (Boarding)</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default React.memo(EditDataVa);
