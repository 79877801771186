import { Popconfirm, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import useResetPage from "components/Helper/useResetPage";
import { useManufacturerPagination } from "hooks/manufacturer-hook/useManufacturerPagination";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useState } from "react";

const disabled = { cursor: "not-allowed", color: "#ccc" };

const ManufacturersLogic = () => {
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [showAddManufacturer, setShowAddManufacturer] = useState(false);
  const [showEditManufacturer, setShowEditManufacturer] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [record, setRecord] = useState({});
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });

  const { REACT_APP_HOST_API } = process.env;

  // fetch manufacturer pagination
  const { data, isLoading, isFetching, refetch } = useManufacturerPagination(
    dataTable,
    keyword
  );

  // fetch permission
  const { data: dataPermission } = usePermission(
    "mdlInventory",
    "mnuManufacturers"
  );

  // Buttons Permission
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddManufacturer");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditManufacturer");
  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeleteManufacturer"
  );
  const btnDetail = dataPermission?.find(
    (x) => x.id === "btnDetailManufacturer"
  );

  const columns = [
    { title: "No", dataIndex: "index", align: "left" },
    { title: "Nama", dataIndex: "name", align: "left" },
    { title: "Link Url", dataIndex: "url", align: "left" },
    { title: "No Hp", dataIndex: "support_phone", align: "left" },
    { title: "Email", dataIndex: "support_email", align: "left" },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id, data) => {
        return (
          <>
            <Popconfirm
              disabled={btnDelete?.type === "disabled"}
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({
                  url: "/inventories/manufacturers/",
                  dataId: id,
                  refetch,
                })
              }
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") return;
                else {
                  setDataId(id);
                  setShowEditManufacturer(true);
                }
              }}
            >
              Ubah
            </Tag>
            <Tag
              color="blue"
              hidden={!btnDetail}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnDetail?.type === "disabled") return;
                else {
                  setRecord(data);
                  setOpenDetail(true);
                }
              }}
            >
              Detail
            </Tag>
          </>
        );
      },
    },
  ];

  const dataKey = data?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
        image: data.image && (
          <img
            className="table-image"
            src={REACT_APP_HOST_API + data.image[1]}
            alt="img"
          />
        ),
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const handleOpenDetail = () => {
    setOpenDetail(!openDetail);
  };

  const onCancel = () => {
    setShowAddManufacturer(false);
    setShowEditManufacturer(false);
  };

  const onCreate = useCallback(() => {
    setShowAddManufacturer(false);
    refetch();
  }, [refetch]);

  const onUpdate = useCallback(() => {
    setShowEditManufacturer(false);
    refetch();
  }, [refetch]);

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  useResetPage(setDataTable, [keyword]);

  return {
    record,
    openDetail,
    handleOpenDetail,
    btnAdd,
    dataId,
    dataKey,
    keyword,
    columns,
    isLoading,
    isFetching,
    pagination,
    showAddManufacturer,
    showEditManufacturer,
    onCreate,
    onCancel,
    onUpdate,
    handleChange,
    setShowAddManufacturer,
  };
};

export default ManufacturersLogic;
