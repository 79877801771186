import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useAssetSumberDanaPagination = (
  dataTable = "",
  keyword = "",
  foundationId = ""
) => {
  return useQuery(
    [
      "get-asset-sumber-dana-pagination",
      dataTable.per_page,
      dataTable.current_page,
      keyword,
      foundationId,
    ],
    () =>
      GetPagination(
        `/inventories/asset-fund-source?foundation_id=${foundationId}&keyword=${keyword}&page=${dataTable.current_page}&limit=${dataTable.per_page}`
      )
  );
};
