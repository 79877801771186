import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useStudentList = (foundationId = "") => {
  return useQuery(
    ["get-student-list", foundationId],
    () =>
      GetList(
        `/academics/students?page=1&limit=10000&keyword=&mode=page&isGraduated=false&foundationId=${foundationId}`
      ),
    { enabled: false }
  );
};
