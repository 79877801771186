import { DatePicker, Form, Input, Modal, Radio, Select, Skeleton } from "antd";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useAssetList } from "hooks/asset-hook/useAssetList";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import { useStudentList } from "hooks/students-hook/useStudentList";
import moment from "moment";
import { useEffect, useState } from "react";
import "../AssetLoan.css";
import dayjs from "dayjs";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useUnitList } from "hooks/unit-hook/useUnitList";

const AddAssetLoan = (props) => {
  const [loading, setLoading] = useState(false);
  const [borrowerTypeStatus, setBorrowerTypeStatus] = useState("student");
  const [borrowerType, setBorrowerType] = useState("internal");
  const [unitId, setUnitId] = useState("");
  const [foundationId, setFoundationId] = useState("");

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;
  const roles = decryptCookies("role").split(",");

  const { data: dataStudent, refetch: fetchStudent } =
    useStudentList(foundationId);
  const { data: dataEmployee, refetch: fetchEmployee } = useEmployeeList(
    "",
    "",
    "",
    "",
    foundationId,
    "",
    unitId
  );
  const { data: dataAssets, isLoading, refetch: fetchAsset } = useAssetList();
  const { data: dataFoundation } = useFoundationList();
  const { data: dataUnit, refetch: fetchUnit } = useUnitList("", foundationId);

  const initialValues = {
    startDate: dayjs(moment(new Date())),
    borrower_type_status: borrowerTypeStatus ? borrowerTypeStatus : "student",
    borrowerType: "internal",
  };

  useEffect(() => {
    if (!props.show) return;

    if (dataFoundation && roles.includes("super_admin")) {
      const foundation = dataFoundation?.data?.data?.find(
        (foundation) =>
          foundation.name.toLowerCase() === "yayasan islam prambanan"
      );
      form.setFieldsValue({ foundationId: foundation?.id });
      setFoundationId(foundation?.id);
    }

    if (dataUnit) {
      const unit = dataUnit?.data?.find(
        (unit) => unit.name.toLowerCase() === "sma fg"
      );
      if (unit) {
        form.setFieldsValue({ unitId: unit?.id });
        setUnitId(unit?.id);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show, dataFoundation]);

  useEffect(() => {
    if (props.show) {
      fetchAsset();
    }
  }, [props.show, dataStudent, fetchAsset, fetchStudent, fetchEmployee]);

  const handleAddAssetLoan = () => {
    form
      .validateFields()
      .then(async (fieldsValue) => {
        setLoading(true);
        try {
          const { data } = await axios.post(
            REACT_APP_HOST_API + `/inventories/asset-loans/`,
            {
              ...fieldsValue,
              startDate:
                fieldsValue.startDate &&
                fieldsValue.startDate.format("YYYY-MM-DD HH:mm:ss"),
              endDate:
                fieldsValue.endDate &&
                fieldsValue.endDate.format("YYYY-MM-DD HH:mm:ss"),
            },
            {
              headers: { Authorization: "Bearer " + decryptCookies("token") },
            }
          );

          message.success(data.message);
          props.onCreate();
          form.resetFields();
          setBorrowerType("internal");
        } catch (error) {
          Modal.error({
            title: "Gagal Menyimpan",
            content: <pre>{error?.response?.data?.message}</pre>,
          });
        }
        setLoading(false);
      })
      .catch(() => {
        alert("Input Field Error");
      });
  };

  const onCancelModal = () => {
    props.onCancel();
    form.resetFields();
    setBorrowerType("internal");
    setFoundationId("");
    setUnitId("");
  };

  useEffect(() => {
    form.resetFields(["studentId", "employeeId"]);
    fetchEmployee();
    fetchStudent();
    // eslint-disable-next-line
  }, [unitId, fetchEmployee, fetchStudent]);

  useEffect(() => {
    fetchUnit();
    fetchEmployee();
    fetchStudent();
    // eslint-disable-next-line
  }, [foundationId]);

  return (
    <Modal
      className="top-5"
      okText="Simpan"
      cancelText="Batal"
      open={props.show}
      onCancel={onCancelModal}
      onOk={handleAddAssetLoan}
      okButtonProps={{ loading }}
    >
      {isLoading ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item label="Tipe" name="borrowerType">
            <Select onChange={(e) => setBorrowerType(e)}>
              <Select.Option value="internal">Internal</Select.Option>
              <Select.Option value="external">Eksternal</Select.Option>
            </Select>
          </Form.Item>

          {roles.includes("super_admin") && borrowerType === "internal" && (
            <Form.Item
              label="Yayasan"
              name="foundationId"
              rules={[{ required: true, message: "Harap diisi" }]}
            >
              <Select
                placeholder="Pilih Yayasan"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => {
                  setUnitId("");
                  setFoundationId(e);
                  form.resetFields(["unitId"]);
                  form.resetFields(["employeeId"]);
                  form.resetFields(["studentId"]);
                }}
              >
                {dataFoundation?.data?.data?.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {borrowerType === "internal" && (
            <>
              <Form.Item
                label="Unit"
                name="unitId"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(e) => {
                    setUnitId(e);
                  }}
                >
                  {dataUnit?.data?.map((data) => (
                    <Select.Option key={data.id} value={data.id}>
                      {data.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="borrower_type_status" label="Tipe Peminjam">
                <Radio.Group
                  onChange={({ target: { value } }) =>
                    setBorrowerTypeStatus(value)
                  }
                >
                  <Radio value="student">Siswa</Radio>
                  <Radio value="teacher">Guru</Radio>
                </Radio.Group>
              </Form.Item>
            </>
          )}

          <Form.Item
            name="assetId"
            label="Aset"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataAssets?.data
                .filter((x) => x.asset_status_id === "AVAILABLE")
                .map((data) => (
                  <Select.Option key={data.id} value={data.id}>
                    {data.serial}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          {borrowerTypeStatus === "teacher" && borrowerType === "internal" && (
            <Form.Item
              name="employeeId"
              label="Peminjam"
              rules={[{ required: true, message: "Harap diisi" }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.children[0].props.children
                      .toLowerCase()
                      .includes(input.toLowerCase()) ||
                    option.children[1].props.children === input
                  );
                }}
              >
                {dataEmployee?.data.map((data) => (
                  <Select.Option key={data.id} value={data.id}>
                    <span>{data.name}</span>
                    <span className="transparent-rfid">{data.rfid}</span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {borrowerTypeStatus === "student" && borrowerType === "internal" && (
            <Form.Item
              name="studentId"
              label="Peminjam"
              rules={[{ required: true, message: "Harap diisi" }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {dataStudent?.data.data.map((data) => (
                  <Select.Option key={data.id} value={data.id}>
                    {`${data?.name} - ${data?.class?.name}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {borrowerType === "external" && (
            <Form.Item
              label="Peminjam"
              name="borrower"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          )}

          <Form.Item name="startDate" label="Waktu Mulai">
            <DatePicker showTime />
          </Form.Item>

          <Form.Item name="endDate" label="Waktu Selesai">
            <DatePicker showTime />
          </Form.Item>

          <Form.Item name="description" label="Deskripsi">
            <Input.TextArea rows={3} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AddAssetLoan;
