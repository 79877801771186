import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, message, Modal, Select, Table, Tooltip } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEmployeeDetail } from "hooks/employee-hook/useEmployeeDetail";
import { useEmployeeNotInLinkGroup } from "hooks/link-hook/group/useEmployeeNotInLinkGroup";
import { useLinkGroupDetail } from "hooks/link-hook/group/useLinkGroupDetail";
import { usePermission } from "hooks/usePermissions";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function AddAnggota() {
  const [rowSelected, setRowSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [unitId, setUnitId] = useState("");
  const [ownerId, setOwnerId] = useState("");

  const { group_id } = useParams();
  const { REACT_APP_HOST_API } = process.env;
  const navigate = useNavigate();

  const { data } = useEmployeeNotInLinkGroup(group_id, unitId, keyword);
  const { data: dataGroup } = useLinkGroupDetail(group_id, true);
  const { data: ownerDetail, refetch: refetchOwnerDetail } = useEmployeeDetail(
    ownerId,
    false
  );

  // fetch  permission
  const { data: dataPermission } = usePermission("mdlLink", "mnuLinkGroup");

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddMemberLinkGroup");

  useEffect(() => {
    setOwnerId(dataGroup?.data?.owner?.id);
  }, [dataGroup]);

  useEffect(() => {
    if (ownerId) {
      refetchOwnerDetail();
    }
  }, [ownerId, refetchOwnerDetail]);

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
    },
    {
      title: "NIP",
      dataIndex: "nip",
    },
    {
      title: "Unit",
      dataIndex: "employeeUnits",
      render: (units) => {
        return <p>{units?.map((x) => x?.unit?.name).join(", ")}</p>;
      },
    },
  ];

  const dataSource = data?.data?.map((x) => {
    return {
      key: x?.id,
      id: x?.id,
      name: x?.name,
      nip: x?.nip,
      employeeUnits: x?.employeeUnits,
    };
  });

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const new_members = rowSelected.map((x) => x.id);

      await axios.post(
        `${REACT_APP_HOST_API}/links/link-groups/${group_id}/members`,
        { new_members },
        {
          headers: {
            Authorization: `Bearer ${decryptCookies("token")}`,
          },
        }
      );
      message.success("Berhasil menambahkan anggota");
      onCancel();
    } catch (error) {
      console.log(error);
      Modal.error({
        title: "Gagal menambahkan anggota",
        content: <pre>{error?.response?.data?.message}</pre>,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const onCancel = async () => {
    setRowSelected([]);
    navigate(-1);
  };

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setRowSelected(selectedRows);
    },
    preserveSelectedRowKeys: true,
  };

  return (
    <div>
      <div className="flex w-full gap-2 mt-5 md:w-1/2">
        <Tooltip title="filter unit">
          <Select
            className="w-1/2"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(e) => setUnitId(e)}
          >
            <Select.Option value="">Semua</Select.Option>
            {ownerDetail?.data[0]?.employeeUnits?.map((x) => (
              <Select.Option key={x?.unit?.id} value={x?.unit?.id}>
                {x?.unit?.name}
              </Select.Option>
            ))}
          </Select>
        </Tooltip>

        <Tooltip title="Cari">
          <Input
            prefix={<SearchOutlined />}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="cari berdasarkan nama..."
            className="w-1/2 h-[32px]"
          />
        </Tooltip>
      </div>

      <Table
        rowSelection={rowSelection}
        scroll={{ y: 400 }}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        className="mb-5"
      />

      <div
        style={{
          display: "flex",
          gap: "10px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          className="w-full md:w-1/2"
          loading={isLoading}
          type="primary"
          hidden={!btnAdd || !dataGroup?.data?.is_owner}
          disabled={btnAdd?.type === "disabled" || rowSelected.length === 0}
          onClick={() => {
            if (btnAdd.type === "disabled" || rowSelected.length === 0) {
              return;
            }
            onSubmit();
          }}
        >
          Simpan
        </Button>
        <Button
          className="w-full md:w-1/2"
          type="primary"
          ghost
          onClick={onCancel}
        >
          Batal
        </Button>
      </div>
    </div>
  );
}
