import React from "react";
import { renderAmount } from "pages/si-finance/utils/utlisFunction";
import dayjs from "dayjs";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useRecapParent } from "../../../../../hooks/finance-hook/useRecapParent";
import { Skeleton } from "antd";

export default function Lunas({ type, fromDate }) {
  const paretnId = decryptCookies("parent_id");
  const { data, isLoading } = useRecapParent(paretnId, type, fromDate);
  return (
    <>
      <p style={{ marginTop: "20px", fontSize: "15px", fontWeight: "bold" }}>
        Daftar Tagihan Lunas
      </p>

      {isLoading ? (
        <Skeleton active />
      ) : (
        <div>
          {data?.data?.report?.map((item) => (
            <div
              key={item?.id}
              style={{
                width: "100%",
                display: "flex",
                gap: "10px",
                padding: "10px",
                border: "0.5px solid #ccc",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  width: "100px",
                  minHeight: "79px",
                  backgroundColor: "#4096FF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "5px",
                }}
              >
                <h1 style={{
                  textTransform: "uppercase",
                  fontSize: "18px",
                }}>{item?.type === "fg_extra" ? "xtra" : item.type}</h1>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <table>
                  <tbody>
                    {
                      item?.type === "spp" ? (
                        <tr>
                          <td>Periode</td>
                          <td>: {item?.semester}</td>
                        </tr>
                      ) : null
                    }
                    <tr>
                      <td>No. Va</td>
                      <td>: {item?.account_number}</td>
                    </tr>
                    <tr>
                      <td>Jatuh Tempo</td>
                      <td>: {dayjs(item?.date).format("DD MMM YYYY")}</td>
                    </tr>
                    <tr>
                      <td>Nominal</td>
                      <td>: {renderAmount(item?.amount)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
