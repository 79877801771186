import { DatePicker, Form, Input, InputNumber, Modal, Select, Skeleton } from "antd";
import React, { useMemo } from "react";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useKodeKeuanganList } from "hooks/finance-report-hook/useKodeKeuanganList";
import { useTransitDataDetail } from "hooks/finance-report-hook/useTransitDataDetail";
import dayjs from "dayjs";
import { renderModalError } from "pages/si-finance-report/utlis/utlis";

const EditKodeKeuangan = (props) => {
  const [form] = Form.useForm();
  const [foundationId, setFoundationId] = useState("");
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const { REACT_APP_HOST_API } = process.env;

  // get roles
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");


  // get foundation list
  const { data: dataFoundation } = useFoundationList();

  // get divisi and juknis list
  const {
    data: dataUnitDivisiJuknis
  } = useKodeKeuanganList(foundationId);

  const dataUnit = useMemo(() => dataUnitDivisiJuknis?.data?.data.filter((item) => item.type === "unit"), [dataUnitDivisiJuknis?.data?.data]);

  const dataDivisi = useMemo(() => dataUnitDivisiJuknis?.data?.data.filter((item) => item.type === "divisi" || 'unit'), [dataUnitDivisiJuknis?.data?.data]);

  const dataJuknis = useMemo(() => dataUnitDivisiJuknis?.data?.data.filter((item) => item.type === "juknis"), [dataUnitDivisiJuknis?.data?.data]);

  // fetch detail units
  const {
    isLoading: skeleton,
    refetch: fetchData,
  } = useTransitDataDetail(props.id, false);

  const setData = async () => {
    const { data: successData } = await fetchData();

    if (successData) {
      form.setFieldsValue({
        foundationId: successData.data?.foundation_id,
        dataOrigin: successData.data?.data_origin,
        date: dayjs(successData.data?.date),
        codeUnitId: successData.data?.code_unit_id,
        codeDivisiId: successData.data?.code_divisi_id,
        codeJuknisId: successData.data?.code_juknis_id,
        amount: successData.data?.amount,
        description: successData.data?.description
      });
      setFoundationId(successData.data?.foundation_id);
    }
  };

  useEffect(() => {
    if (props.show) {
      setData();
    }

    return () => setNewData({});
    // eslint-disable-next-line
  }, [form, props.show]);


  const handleSubmit = async () => {
    try {
      await form.validateFields();
      if (Object.keys(newData).length === 0) {
        renderModalError("Error : Edit Transit Data", "Tidak Ada Perubahan data");
        return;
      }

      for (const key in newData) {
        if (Object.hasOwnProperty.call(newData, key)) {
          const element = newData[key];
          if (element === "") newData[key] = null;
        }
      }

      setLoading(true);

      const { data } = await axios.put(
        REACT_APP_HOST_API + `/financial-report/transit-data/${props.id}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      message.success(data.message);
      form.resetFields();
      props.onUpdate();
    } catch (error) {
      renderModalError("Error : Edit Transit Data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    props.onCancel();
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={handleCancel}
      okButtonProps={{ loading }}
    >
      {skeleton && <Skeleton active />}

      {!skeleton && (
        <Form form={form} layout="vertical">
          {(isSuperAdmin) &&
            <Form.Item
              name="foundationId"
              label="Foundation"
            >
              <Select
                showSearch
                placeholder="Pilih foundation"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => {
                  newData.foundationId = e
                  setFoundationId(e)
                }}
              >
                {dataFoundation?.data?.data?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          }
          <Form.Item
            label="Asal Data"
            name="dataOrigin"
          >
            <Input placeholder="input asal data"
              onChange={({ target: { value } }) =>
                (newData["dataOrigin"] = value)
              }
            />
          </Form.Item>
          <Form.Item
            label="Tanggal"
            name="date"
          >
            <DatePicker
              onChange={(value) => {
                newData["date"] = value.format('YYYY-MM-DD')
              }}
            />
          </Form.Item>
          <Form.Item
            name="codeUnitId"
            label="Unit"
          >
            <Select
              showSearch
              placeholder="Pilih Unit"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => (newData["codeUnitId"] = value)}
            >
              {dataUnit?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {`${item.code} - ${item.name} ${isSuperAdmin ? ` - ${item.foundation.name}` : ``
                    }`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="codeDivisiId"
            label="Divisi"
          >
            <Select
              showSearch
              placeholder="Pilih Divisi"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => (newData["codeDivisiId"] = value)}
            >
              {dataDivisi?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {`${item.code} - ${item.name} ${isSuperAdmin ? ` - ${item.foundation.name}` : ``
                    }`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="codeJuknisId"
            label="Juknis"
          >
            <Select
              showSearch
              placeholder="Pilih Juknis"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => (newData["codeJuknisId"] = value)}
            >
              {dataJuknis?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {`${item.code} - ${item.name} ${isSuperAdmin ? ` - ${item.foundation.name}` : ``}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Jumlah Biaya"
            name="amount"
          >
            <InputNumber
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
              style={{ width: "100%" }}
              onChange={(value) => (newData["amount"] = value)}
            />
          </Form.Item>
          <Form.Item
            label="Keterangan"
            name="description"
          >
            <Input
              onChange={({ target: { value } }) =>
                (newData["description"] = value)
              }
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default React.memo(EditKodeKeuangan);
