import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Select, Space, Table, Tooltip } from "antd";
import AddAssets from "../create/AddAssets";
import AssetsLogic from "./AssetsLogic";
import EditAssets from "../edit/EditAssets";
import FilterFoundation from "components/UI/FilterFoundation";
import ImportExcel from "components/import/ImportExcel";

const Assets = () => {
  const {
    btnImport,
    showImport,
    setShowImport,
    onCancelImport,
    onImport,
    roles,
    exportToExcel,
    handleChangeCategory,
    setFundSourceId,
    dataFundSource,
    dataCategories,
    setFoundationId,
    btnExport,
    btnAdd,
    dataId,
    dataKey,
    keyword,
    columns,
    isLoading,
    isFetching,
    pagination,
    showAddAssets,
    showEditAssets,
    onCreate,
    onCancel,
    onUpdate,
    handleChange,
    setShowAddAssets,
  } = AssetsLogic();

  return (
    <div className="asset-wrapper">
      <div className="table-header">
        <h1>Daftar Aset</h1>

        <Space>
          <Button
            type="primary"
            hidden={!btnImport}
            disabled={btnImport?.type === "disabled"}
            onClick={() =>
              btnImport?.type !== "disabled" && setShowImport(true)
            }
          >
            Import
          </Button>
          <Button
            type="primary"
            hidden={!btnExport}
            disabled={btnExport?.type === "disabled"}
            onClick={() => {
              if (btnExport?.type === "disabled") return;
              exportToExcel();
            }}
          >
            Export Excel
          </Button>
          <Button
            type="primary"
            hidden={!btnAdd}
            disabled={btnAdd && btnAdd.type === "disabled"}
            onClick={() => setShowAddAssets(true)}
          >
            Tambah Aset
          </Button>
        </Space>
      </div>

      <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-4">
        <Tooltip title="Cari Aset">
          <Input
            prefix={<SearchOutlined />}
            value={keyword}
            onChange={handleChange}
            placeholder="Cari Aset Serial, Tag..."
          />
        </Tooltip>

        {roles.includes("super_admin") && (
          <FilterFoundation setFoundationId={setFoundationId} />
        )}

        <Tooltip title="Sumber Dana">
          <Select defaultValue="" onChange={setFundSourceId}>
            <Select.Option value="">Semua</Select.Option>
            {dataFundSource?.data?.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Tooltip>

        <Tooltip title="Kategori Aset">
          <Select
            onChange={handleChangeCategory}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            mode="multiple"
            placeholder="Pilih Kategori Aset"
            options={dataCategories?.data?.map((x) => ({
              value: x.id,
              label: x.name,
            }))}
          />
        </Tooltip>
      </div>

      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        dataSource={dataKey}
        pagination={pagination}
        scroll={
          window.innerHeight < 760
            ? { y: "50vh", x: 800 }
            : { y: "55vh", x: 800 }
        }
        loading={isLoading || isFetching}
      />
      <AddAssets show={showAddAssets} onCreate={onCreate} onCancel={onCancel} />
      <EditAssets
        id={dataId}
        show={showEditAssets}
        onUpdate={onUpdate}
        onCancel={onCancel}
      />
      <ImportExcel
        show={showImport}
        onCancel={onCancelImport}
        onImport={onImport}
        keyword="Template Import Data Assets"
        url="/inventories/assets/import"
        isShowTamplate={true}
        urlTamplate="https://docs.google.com/spreadsheets/d/1gQiN5uEoptKy7A_Hhdw3Sgu8WxFeG2KH7kkEsB4Oo5M/edit?usp=sharing"
      />
    </div>
  );
};

export default Assets;
