import React from 'react';
import { Table, Typography } from 'antd';
import { numberFormat } from "pages/si-finance-report/utlis/utlis";
const { Text } = Typography;

const TableDanaBos = ({ data, isLoading }) => {
   const columns = [
      {
         title: 'No',
         dataIndex: 'index',
         key: 'index',
         width: 70,
      },
      {
         title: 'Juknis',
         dataIndex: 'Juknis',
         key: 'Juknis',
      },
      {
         title: 'Realisasi',
         dataIndex: 'amount',
         key: 'amount',
         render: (amount) => {
            return numberFormat(amount)
         }
      },
   ];
   const dataSource = data?.filter(
      (data) => data.id !== "total"
   )?.map((data, index) => {
      return {
         id: data.id,
         index: index + 1,
         key: index,
         Juknis: `${data.code} - ${data.name}`,
         amount: data.amount,
      };
   }
   );

   return (
      <>
         <h2 className="table-header-secondary">Tabel Dana Bos</h2>
         <Table
            columns={columns}
            dataSource={dataSource}
            size="small"
            pagination={false}
            scroll={
               window.innerHeight < 690
                  ? { y: "40vh", x: 900 }
                  : window.innerHeight < 760
                     ? { y: "50vh", x: 900 }
                     : { y: "55vh", x: 900 }
            }
            style={{ marginBottom: 20 }}
            loading={isLoading}
            summary={() => {
               return (
                  <>
                     {
                        data?.find((x) => x.id === "total") &&
                        <Table.Summary.Row>
                           <Table.Summary.Cell index={0} colSpan={2}>Total</Table.Summary.Cell>
                           <Table.Summary.Cell index={1}>
                              <Text>{numberFormat(
                                 data?.find((x) => x.id === "total")?.total
                              )}</Text>
                           </Table.Summary.Cell>
                        </Table.Summary.Row>}
                  </>
               );
            }}
         />
      </>
   );
};
export default TableDanaBos;