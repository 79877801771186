import { RightOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import axios from "axios";
import {
   decryptCookies,
   encryptCookies,
} from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MasterFinance = () => {
   const [dataMenuFinance, setDataMenuFinance] = useState([]);

   const navigate = useNavigate();
   const { REACT_APP_HOST_API } = process.env;

   useEffect(() => {
      const cancelToken = axios.CancelToken.source();

      const fetchDashboard = async () => {
         try {
            const { data } = await axios.get(
               REACT_APP_HOST_API,
               { headers: { Authorization: "Bearer " + decryptCookies("token") } },
               { cancelToken: cancelToken.token }
            );

            const resultModule = data.data[0].role.permissions.modules;
            if (resultModule.find((x) => x.id === "mdlFinance")) {
               data.data[0].role.permissions.modules
                  .find((x) => x.id === "mdlFinance")
                  .menus.sort((a, b) =>
                     a.id.toLowerCase().localeCompare(b.id.toLowerCase())
                  );

               const resultMenuFinance = data.data[0].role.permissions.modules
                  .find((x) => x.id === "mdlFinance").menus;

               if (resultMenuFinance.find((x) => x.id === "mnuAccount")) {
                  resultMenuFinance.find((x) => x.id === "mnuAccount").id = "mnuDatava";
               }

               if (resultMenuFinance.find((x) => x.id === "mnuBillings")) {
                  resultMenuFinance.find((x) => x.id === "mnuBillings").id = "mnuTabelTagihan";
               }
               setDataMenuFinance(resultMenuFinance);
            }
            encryptCookies("foundationId", data.data[0].employee.foundation_id);
         } catch (error) {
            if (error.response.status === 401) {
               alert("Akun belum terverifikasi, silahkan hubungi IT / HRD");
               return navigate("/");
            } else {
               alert(error);
            }
         } finally {
         }
      };

      fetchDashboard();

      return () => cancelToken.cancel();
   }, [REACT_APP_HOST_API, navigate]);

   const getButtonLabel = (id) => {
      if (id === "mnuRevenues") {
         return "Data Pemasukan (BSI)";
      }
      if (id === "mnuTransitData") {
         return "Data Pengeluaran (BSI)";
      }

      if (id === "mnuTabelTagihan") {
         return "Tabel Tagihan";
      }

      return id.split("nu")[1];
   };

   const handleClickMenu = (menu) => {
      encryptCookies("defaultFinanceMenu", menu);
      if (menu === "mnuRevenues") {
         return navigate("/finance/data-pemasukan");
      }
      if (menu === 'mnuTransitData') {
         return navigate("/finance/data-pengeluaran");
      }
      navigate(menu.toLowerCase().split("nu")[1]);
   };

   return (
      <div>
         {/* <Divider orientation="left" orientationMargin="0">
      </Divider> */}
         <h2>Selamat Datang di Dashboard Finance</h2>
         <h3 style={{ textTransform: "uppercase" }}>Daftar Menu</h3>
         <Space direction="vertical">
            {dataMenuFinance.map((x) =>
               <Button
                  key={x.id}
                  disabled={x.type === "disabled"}
                  type="primary"
                  ghost
                  icon={<RightOutlined />}
                  onClick={() => handleClickMenu(x.id)}
               >
                  {getButtonLabel(x.id)}
               </Button>
            )}
         </Space>
      </div>
   );
};

export default MasterFinance;
