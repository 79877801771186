import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Table,
  Popconfirm,
  Tag,
  message,
  Tooltip,
  DatePicker,
} from "antd";
import ImportExcel from "components/import/ImportExcel";
import "./Billings.css";
import { DeleteApi } from "api/DeleteApi";
import { useBillingsPagination } from "hooks/finance-hook/useBillingsPagination";
import { usePermission } from "hooks/usePermissions";
import {
  renderAmount,
  statusBillingsRender,
} from "pages/si-finance/utils/utlisFunction";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExcelJS from "exceljs";
import PdfBillings from "./PdfBillings";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import FilterFoundation from "components/UI/FilterFoundation";
import { decryptCookies } from "components/Helper/CookiesHelper";
import RealokasiBilling from "../realokasi/RealokasiBilling";
import RefundBilling from "../refund/RefundBilling";
import RefundModal from "../refund/RefundModal";
import { useDebounce } from "use-debounce";
import useResetPage from "components/Helper/useResetPage";
import { DateTime } from "luxon";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const Billings = () => {
  //# mengambil semester saat ini
  const monthNow = DateTime.now().setLocale('id').month
  const yearNow = DateTime.now().setLocale('id').year
  const semester = monthNow <= 6
    ? DateTime.fromObject({ year: yearNow, month: 6, day: 30, hour: 23, minute: 59, second: 59, millisecond: 999 })
    : DateTime.fromObject({ year: yearNow, month: 12, day: 31, hour: 23, minute: 59, second: 59, millisecond: 999 });

  semester.minus({ month: 6 })
  const from_date = semester.minus({ month: 6 }).plus({ millisecond: 1 }).toISO()
  const to_date = semester.toISO()

  console.log({
    from_date,
    to_date
  })

  const navigate = useNavigate();
  const [isOpenRealokasi, setIsOpenRealokasi] = useState(false);
  const [isOpenRefund, setIsOpenRefund] = useState(false);
  const [isOpenRefundModal, setIsOpenRefundModal] = useState(false);
  const [propsData, setPropsData] = useState({});
  const [keyword, setKeyword] = useState("");
  const [search] = useDebounce(keyword, 2000);
  const [filterObject, setFilterObject] = useState({
    tipe: "",
    isGraduated: "",
    from_date: dayjs(from_date).format("YYYY-MM-DD"),
    to_date: dayjs(to_date).format("YYYY-MM-DD"),
    angkatan: "",
  });
  const [foundationId, setFoundationId] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });
  const [showImportBillings, setShowImportBillings] = useState(false);
  const [showImportFgExtra, setShowImportFgExtra] = useState(false);
  const [form] = Form.useForm();
  const roles = decryptCookies("role").split(",");

  const { data, isLoading, isFetching, refetch } = useBillingsPagination(
    dataTable,
    search,
    filterObject,
    foundationId
  );


  // fetch classes permission
  const { data: dataPermission } = usePermission("mdlFinance", "mnuBillings");

  // const onCreate = useCallback(() => {
  //    refetch();
  // }, [refetch]);

  // using custom hooks reset page
  useResetPage(setDataTable, [search, filterObject, foundationId]);

  const gotoAddBillings = () => {
    navigate("/finance/tabeltagihan/tambah");
  };

  const onCancel = () => {
    setShowImportBillings(false);
    setShowImportFgExtra(false);
  };

  const onImport = useCallback(() => {
    setShowImportBillings(false);
    setShowImportFgExtra(false);
    refetch();
  }, [refetch]);

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddBillings");
  // const btnEdit = dataPermission?.find((x) => x.id === "btnEditBillings");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteBillings");
  const btnImport = dataPermission?.find((x) => x.id === "btnImportBillings");
  const btnImportFgExtra = dataPermission?.find((x) => x.id === "btnImportFgExtraBillings");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailBillings");
  const btnRealokasi = dataPermission?.find((x) => x.id === "btnRealokasi");
  const btnRefund = dataPermission?.find((x) => x.id === "btnRefundBillings");

  const exportExcelFile = async () => {
    const statusBillings = {
      paid_full: "Lunas",
      paid_partial: "Bayar Sebagian",
      unpaid: "Belum Bayar",
      approved: "Disetujui",
      past_due: "Lewat Jatuh Tempo",
      refunded: "Refund",
    };

    const newData = data?.data?.data?.map((x) => {
      return {
        key: x?.id,
        nama_akun: x.account?.student?.name,
        rekening: x.account?.number,
        tipe: x.type,
        jumlah: renderAmount(x?.amount),
        sisa_bayar: renderAmount(x?.remaining_amount),
        jatuh_tempo: dayjs(x?.due_date).format("DD-MM-YYYY"),
        status: statusBillings[x?.status] || x?.status,
        lulus: x?.account?.student?.is_graduated ? "Lulus" : "Belum Lulus",
      };
    });

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    // Define columns for the table with headers styled
    worksheet.columns = [
      { header: "Nama akun tertagih", key: "nama_akun", width: 20 },
      { header: "Rekening", key: "rekening", width: 10 },
      { header: "Status Siswa", key: "lulus", width: 15 },
      // { header: "Tipe", key: "tipe", width: 15 },
      { header: "Jumlah", key: "jumlah", width: 10 },
      { header: "Sisa Bayar", key: "sisa_bayar", width: 15 },
      { header: "Jatuh Tempo", key: "jatuh_tempo", width: 15 },
      { header: "Status", key: "status", width: 15 },
    ];

    // Add header row styling
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFA500" }, // Orange color
      };
      cell.font = {
        bold: true,
        color: { argb: "FFFFFFFF" }, // White text color
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // Add rows to the table

    newData?.forEach((row) => {
      worksheet.addRow(row);
    });

    // Write to buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Save to file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Laporan Tagihan.xlsx");
  };

  const handleGeneratePdf = async () => {
    try {
      const fileName = "Laporan Tagihan";
      const doc = <PdfBillings data={data?.data?.data} title={fileName} />;
      const asPdf = pdf();
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, fileName);
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: 50,
      fixed: "left",
    },
    {
      title: "Nama Akun tertagih",
      dataIndex: "account_name",
      align: "left",
      width: 150,
      fixed: "left",
    },
    {
      title: "Rekening",
      dataIndex: "account_number",
      align: "left",
      width: 100,
    },
    {
      title: "Tipe",
      dataIndex: "type",
      align: "left",
      width: 60,
      render(type) {
        return type === "spp" ? "SPP" : type === "bwt" ? "BWT" : type === "bp" ? "BP" : type === "fg_extra" ? "FG Extra" : "Lain-Lain";
      }
    },
    {
      title: "Keterangan",
      dataIndex: "description",
      align: "left",
      width: 150,
      render: (description) => {
        return description || "-";
      }
    },
    {
      title: "Status Siswa",
      dataIndex: "account",
      align: "left",
      render: (account) => {
        return (
          <div>{account?.student?.is_graduated ? "Lulus" : "Belum Lulus"}</div>
        );
      },
      width: 150,
    },
    {
      title: "Jumlah", dataIndex: "jumlah", align: "left",
      width: 100,
    },
    {
      title: "Sisa Bayar",
      dataIndex: "remaining_amount",
      align: "left",
      render: (remainingAmount) => {
        return renderAmount(remainingAmount);
      },
      width: 100,
    },
    {
      title: "Bulan",
      dataIndex: "month",
      align: "left",
      render: (month) => {
        return dayjs().month(month - 1).format("MMMM");
      },
      width: 70,
    },
    {
      title: "Jatuh Tempo",
      dataIndex: "due_date",
      align: "left",
      render: (dueDate) => {
        return dueDate ? dayjs(dueDate).format("DD MMMM YYYY") : "-";
      },
      width: window.innerWidth > 1200 ? 150 : 130,
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "left",
      render: (status, data) => {
        return (
          <div
            onClick={() => {
              if (status === "refunded") {
                setIsOpenRefundModal(true);
                setPropsData(data);
              }
            }}
            style={{ cursor: status === "refunded" && "pointer" }}
          >
            {statusBillingsRender(status)}
          </div>
        );
      },
      width: 100,
    },

    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      width: window.innerWidth > 800 ? 150 : 100,
      render: (id, item) => {
        return (
          <Space>
            <Tag
              color="green"
              hidden={!btnRefund}
              style={
                btnRefund?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                setIsOpenRefund(true);
                setPropsData(item);
              }}
            >
              Refund
            </Tag>

            <Tag
              color="blue"
              hidden={!btnRealokasi}
              style={
                btnRealokasi?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                setIsOpenRealokasi(true);
                setPropsData(item);
              }}
            >
              Realokasi
            </Tag>

            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                const dataId = id;
                DeleteApi({ url: "/finance/billings/", dataId, refetch });
              }}
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="blue"
              hidden={!btnDetail}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                navigate(`/finance/billings/${id}`);
              }}
            >
              Detail
            </Tag>
          </Space>
        );
      },
    },
  ];

  const dataSource = data?.data?.data
    .slice(0, dataTable.per_page)
    .map((x, i) => {
      return {
        ...x,
        key: x?.id,
        index: i + 1,
        type: x?.type || "-",
        account_number: x.account?.number || "-",
        account_name: x.account?.student?.name || "-",
        jumlah: new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(x.amount),
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100, 200, data?.data?.meta.total],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const handleOpenRealokasi = () => {
    setPropsData({});
    setIsOpenRealokasi(!isOpenRealokasi);
    refetch();
  };

  const handleOpenRefund = () => {
    setPropsData({});
    setIsOpenRefund(!isOpenRefund);
    refetch();
  };

  const handleOpenRefundModal = () => {
    setPropsData({});
    setIsOpenRefundModal(!isOpenRefundModal);
  };

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Tabel Tagihan</h1>
        <Space size={[8, 16]} wrap className="order-1">
          <Button
            hidden={!btnImport}
            disabled={btnImport?.type === "disabled"}
            onClick={() => handleGeneratePdf()}
          >
            Export PDF
          </Button>
          <Button
            hidden={!btnImport}
            disabled={btnImport?.type === "disabled"}
            onClick={() => exportExcelFile()}
          >
            Export Excel
          </Button>
        </Space>
        <Space size={[8, 16]} wrap className="order-2">
          <Button
            hidden={!btnAdd}
            onClick={gotoAddBillings}
            disabled={btnAdd && btnAdd.type === "disabled"}
          >
            Buat Tagihan
          </Button>
          <Button
            hidden={!btnImportFgExtra}
            disabled={btnImportFgExtra?.type === "disabled"}
            onClick={() => setShowImportFgExtra(true)}
          >
            Import FG Extra
          </Button>
          <Button
            type="primary"
            hidden={!btnImport}
            disabled={btnImport?.type === "disabled"}
            onClick={() => setShowImportBillings(true)}
          >
            Import
          </Button>
        </Space>
      </div>
      <Form form={form} layout="vertical">
        <div className="filter-search">
          {roles.some((x) => x === "super_admin") && (
            <Form.Item>
              <FilterFoundation setFoundationId={setFoundationId} />
            </Form.Item>
          )}

          <Tooltip title="tipe">
            <Form.Item>
              <Select
                placeholder="Filter berdasarkan tipe"
                onChange={(e) => {
                  setFilterObject((prev) => ({ ...prev, tipe: e }))
                }
                }
              >
                <Select.Option value="">Semua</Select.Option>
                <Select.Option value="spp">SPP</Select.Option>
                <Select.Option value="bwt">BWT</Select.Option>
                <Select.Option value="bp">BP</Select.Option>
                <Select.Option value="fg_extra">FG Extra</Select.Option>
                <Select.Option value="others">Lain-Lain</Select.Option>
              </Select>
            </Form.Item>
          </Tooltip>

          <Form.Item name="account_type">
            <Tooltip title="Status Siswa">
              <Select
                placeholder="Status Siswa"
                onChange={(e) => {
                  setFilterObject((prev) => ({ ...prev, isGraduated: e }))
                }
                }
                value={filterObject.isGraduated}
              >
                <Select.Option value="">Semua</Select.Option>
                <Select.Option value={false}>Belum Lulus</Select.Option>
                <Select.Option value={true}>Lulus</Select.Option>
              </Select>
            </Tooltip>
          </Form.Item>

          <Tooltip title="Jatuh tempo dari tanggal">
            <Form.Item name="from_date" initialValue={dayjs(from_date)}>
              <DatePicker
                inputReadOnly
                placeholder="Jatuh tempo dari tanggal"
                onChange={(e) => {
                  setFilterObject((prev) => ({
                    ...prev,
                    from_date: e !== null ? dayjs(e).format("YYYY-MM-DD") : "",
                  }))
                }
                }
              />
            </Form.Item>
          </Tooltip>

          <Tooltip title="Jatuh tempo sampai tanggal">
            <Form.Item name="to_date" initialValue={dayjs(to_date)}>
              <DatePicker
                inputReadOnly
                placeholder="Jatuh tempo sampai tanggal"
                onChange={(e) => {
                  setFilterObject((prev) => ({
                    ...prev,
                    to_date: e !== null ? dayjs(e).format("YYYY-MM-DD") : "",
                  }))
                }
                }
              />
            </Form.Item>
          </Tooltip>

          <Tooltip title="Angkatan">
            <Form.Item name="angkatan">
              <DatePicker
                inputReadOnly
                placeholder="Angkatan"
                picker="year"
                onChange={(e) => {
                  setFilterObject((prev) => ({
                    ...prev,
                    angkatan: e !== null ? dayjs(e).format("YYYY") : "",
                  }))
                }
                }
              />
            </Form.Item>
          </Tooltip>
        </div>

        <Form.Item name="keyword">
          <Tooltip title="nisn/nama/nomor VA">
            <Input
              prefix={<SearchOutlined />}
              value={keyword}
              onChange={handleChange}
              placeholder="cari billing berdasarkan nisn/nama/nomor VA"
              className="search-input-billings"
            />
          </Tooltip>
        </Form.Item>
      </Form>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={{
          y: "50vh",
          x: 1200,
        }}
      />

      <ImportExcel
        show={showImportBillings}
        onCancel={onCancel}
        onImport={onImport}
        keyword="Template Import Data Billing"
        url="/finance/billings/import"
        isShowTamplate={true}
        urlTamplate="https://docs.google.com/spreadsheets/d/1gslTa9sTqu_TfIkStFDnnAlzHss3T6NAUwm3Ar0HEk4/edit#gid=52071956"
        isBilling={true}
      />
      <ImportExcel
        show={showImportFgExtra}
        onCancel={onCancel}
        onImport={onImport}
        keyword="Template Import Fg Extra"
        url="/finance/billings/import-fg-extra"
        isShowTamplate={true}
        urlTamplate="https://docs.google.com/spreadsheets/d/11LxEOJ6Va4RJZhWm5dH1vwm9iJETwalZ-PtIFr_tBsE/edit?gid=52071956#gid=52071956"
        isBilling={true}
      />
      <RealokasiBilling
        data={propsData}
        open={isOpenRealokasi}
        setOpen={handleOpenRealokasi}
      />
      <RefundBilling
        data={propsData}
        open={isOpenRefund}
        setOpen={handleOpenRefund}
      />
      <RefundModal
        data={propsData}
        open={isOpenRefundModal}
        setOpen={handleOpenRefundModal}
      />
    </div>
  );
};

export default Billings;
