import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useAssetLoanDetail = (id, enabled) => {
  return useQuery(
    ["get-assetLoan-detail", id],
    () => GetDetail(`/inventories/asset-loans/${id}`),
    { enabled }
  );
};
