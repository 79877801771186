import React, { useContext, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  message,
} from "antd";
// import AddAccount from "../create/AddAccount";
import EditAccount from "../edit/EditAccount";
import ImportExcel from "components/import/ImportExcel";
import { Popconfirm, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { useAccountPagination } from "hooks/finance-hook/useAccountPagination";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "context/finance-context/AccountContext";
import ExcelJS from "exceljs";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import "./Account.css";
import PdfDocument from "./PdfDocument";
import { decryptCookies } from "components/Helper/CookiesHelper";
import FilterFoundation from "components/UI/FilterFoundation";
import { useKodeKeuanganPagination } from "hooks/finance-report-hook/useKodeKeuanganPagination";
import { numberFormat } from "pages/si-finance-report/utlis/utlis";
import { useDebounce } from "use-debounce";
import useResetPage from "components/Helper/useResetPage";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const Account = () => {
  const {
    setDataId,
    //  showAddAccount,
    setShowAddAccount,
    setShowEditAccount,
    showImportAccount,
    setShowImportAccount,
    fetchAccount,
    setFetchAccount,
  } = useContext(AccountContext);

  const [keyword, setKeyword] = useState("");
  const [search] = useDebounce(keyword, 2000);
  const [unitId, setUnitId] = useState("");
  const [tipe, setTipe] = useState("");
  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });
  const [showNull, setShowNull] = useState(false);
  const [foundationId, setFoundationId] = useState("");
  const [form] = Form.useForm();

  const { data, isLoading, isFetching, refetch } = useAccountPagination(
    dataTable,
    search,
    tipe,
    foundationId,
    unitId,
    showNull
  );
  const {
    data: dataKodeUnit,
    isLoading: isLoadingKodeUnit,
    isFetching: isFetchingKodeUnit,
  } = useKodeKeuanganPagination(
    {
      current_page: 1,
      per_page: 1000,
      total: 0,
    },
    "",
    foundationId,
    "unit");

  useResetPage(setDataTable, [search, foundationId, unitId, showNull]);

  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  // fetch maste billings permission
  const { data: dataPermission } = usePermission("mdlFinance", "mnuAccount");
  // fetch foundation list

  useEffect(() => {
    refetch();

    return () => setFetchAccount(false);
  }, [refetch, setFetchAccount, fetchAccount]);

  const onCancel = () => {
    setShowAddAccount(false);
    setShowEditAccount(false);
    setShowImportAccount(false);
  };


  const onImport = useCallback(() => {
    setShowImportAccount(false);
    refetch();
  }, [refetch, setShowImportAccount]);

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  const moveToCreate = () => {
    navigate("/finance/account/tambah");
  };

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddAccount");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteAccount");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditAccount");
  const btnImport = dataPermission?.find((x) => x.id === "btnImportAccount");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailAccount");

  const handleGeneratePdf = async () => {
    try {
      const doc = <PdfDocument data={data} />;
      const asPdf = pdf();
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, "Laporan Rekening.pdf");
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 50,
      fixed: "left",
    },
    // {
    //   title: "Yayasan", dataIndex: "foundationName",
    //   hidden: !roles.some((x) => x === "super_admin")
    // },
    {
      title: "Nama",
      dataIndex: "account_name",
      align: "left",
      width: window.innerWidth > 800 ? 200 : 150,
      fixed: "left",
    },
    { title: "NISN", dataIndex: "nisn", align: "left", width: 100 },
    { title: "Kelas", dataIndex: "class", align: "left", width: 100 },
    {
      title: "Jurusan", dataIndex: "program",
      width: 120,
      render: (program) => {
        const data = {
          'fullday': 'F',
          'mahad': 'M',
          'boarding': 'B'
        }
        return data[program]
      }
    },
    {
      title: "Status Siswa",
      dataIndex: "is_graduated",
      align: "left",
      width: 120,
    },
    {
      title: "No Rekening", dataIndex: "number", align: "left",
      width: 120,
    },
    {
      title: "Unit", dataIndex: "unitName",
      width: 150,
    },
    {
      title: "Saldo", dataIndex: "balance", align: "left",
      render: (balance) => {
        return numberFormat(balance)
      },
      width: 120,
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <Space>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                const dataId = id;
                DeleteApi({
                  url: "/finance/accounts/",
                  dataId,
                  refetch,
                });
              }}
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setDataId(id);
                  setShowEditAccount(true);
                }
              }}
              disabled={btnEdit?.type === "disabled"}
            >
              Ubah
            </Tag>
            <Tag
              color="blue"
              hidden={!btnDetail}
              disabled={btnDetail?.type === "disabled"}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnDetail?.type === "disabled") {
                  return;
                } else {
                  navigate(`/finance/account/${id}`);
                }
              }}
            >
              Detail
            </Tag>
          </Space>
        );
      },
    },
  ].filter((x) => !x.hidden);

  const dataSource = data?.data.data
    .slice(0, dataTable.per_page)
    .map((x, i) => {
      return {
        ...x,
        key: x.id,
        index: i + 1,
        class: x?.student?.class?.name,
        program: x?.student?.program,
        is_graduated: x?.student?.is_graduated ? "Lulus" : "Belum Lulus",
        unitName: x.codeUnit ? `${x.codeUnit?.code} - ${x?.codeUnit?.name}` : '-',
        unitCode: x.codeUnit?.code,
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100, 200, data?.data?.meta.total],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const handleGenerateExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Tambahkan header ke worksheet
    worksheet.columns = [
      { header: "No. Rekening", key: "number", width: 15 },
      { header: "Nama", key: "account_name", width: 25 },
      { header: "NISN", key: "nisn", width: 10 },
      { header: "Kelas", key: "class", width: 10 },
      { header: "Program", key: "program", width: 15 },
      { header: "Status Siswa", key: "is_graduated", width: 10 },
      { header: "Saldo", key: "saldo", width: 15 },
      { header: "Unit", key: "unitCode", width: 15 },
    ];

    // Tambahkan data ke worksheet
    worksheet.addRows(dataSource);

    // Format header
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFE699" },
      };
    });

    // Buat file buffer dari workbook
    const buffer = await workbook.xlsx.writeBuffer();

    // Simpan file dengan file-saver
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Laporan_Rekening.xlsx");
  };

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Data Va</h1>
        <Space size={[8, 16]} wrap className="order-1">
          <Button
            hidden={!btnImport}
            disabled={btnImport?.type === "disabled"}
            onClick={() => handleGeneratePdf()}
          >
            Export PDF
          </Button>
          <Button
            hidden={!btnImport}
            disabled={btnImport?.type === "disabled"}
            // onClick={() => exportExcelFile()}
            onClick={() => handleGenerateExcel()}
          >
            Export Excel
          </Button>
        </Space>
        <Space
          //  make this responsive
          size={[8, 16]}
          wrap
          className="order-2"
        >
          <Button
            hidden={!btnImport}
            disabled={btnImport?.type === "disabled"}
            onClick={() => setShowImportAccount(true)}
            type="primary"
          >
            Import
          </Button>
          <Button
            type="primary"
            hidden={!btnAdd}
            onClick={moveToCreate}
            disabled={btnAdd && btnAdd.type === "disabled"}
          >
            Tambah Akun
          </Button>
        </Space>
      </div>
      <Form form={form} layout="vertical">
        <div
          // className="filter-search"
          style={{
            marginTop: 20,
            display: "grid",
            gap: 10,
            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          }}
        >
          <Form.Item name="keyword">
            <Tooltip title="nama/nisn/no rekening">
              <Input
                prefix={<SearchOutlined />}
                value={keyword}
                onChange={handleChange}
                placeholder="Cari Accounts berdasarkan nama/nisn/no rekening"
                className="search-input-billings"
                style={{
                  border: "1px solid #d9d9d9",
                }}
              />
            </Tooltip>
          </Form.Item>
          {roles.some((x) => x === "super_admin") && (
            <Form.Item>
              <FilterFoundation setFoundationId={setFoundationId} />
            </Form.Item>
          )}
          <Form.Item name="unitId"
            style={{
              width: "100%",
            }}
          >
            <Tooltip Tooltip title="Unit">
              <Select
                placeholder="Pilih Unit"
                style={{ minWidth: 150 }}
                onChange={(_, data) => {
                  setUnitId(data?.key)
                }}
                showSearch
                optionFilterProp="children"
                filterOption={
                  (input, option) => option.value.toLowerCase().includes(input.toLowerCase())
                }
                disabled={isLoadingKodeUnit || isFetchingKodeUnit}
              >
                <Select.Option className="select-option-foundation" value="" key=""
                >
                  ALL
                </Select.Option>
                {dataKodeUnit?.data?.data?.map((x) => (
                  <Select.Option
                    key={x.id}
                    value={`${x.code} - ${x.name}${isSuperAdmin && ` - ${x.foundation.name}`}`}
                    className="select-option-foundation"
                  >
                    {x.code} - {x.name}{isSuperAdmin && ` - ${x.foundation.name}`}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
          </Form.Item>
          <Form.Item name="account_type">
            <Tooltip title="Tipe Rekening">
              <Select
                placeholder="Tipe Rekening"
                onChange={(e) => {
                  setTipe(e)
                }}
                value={tipe}
              >
                <Select.Option value="">Semua</Select.Option>
                <Select.Option value="spp">SPP</Select.Option>
                <Select.Option value="fg_extra">FG EXTRA</Select.Option>
              </Select>
            </Tooltip>
          </Form.Item>
          <Form.Item
            style={{
              width: "max-content",
            }}
          >
            <Tooltip title="Tampilkan data yang belum ada unit/nisn">
              <Switch
                checked={showNull}
                onChange={(checked) => {
                  setShowNull(checked)
                }}
              />
            </Tooltip>
          </Form.Item>
        </div>
      </Form>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "40vh", x: 800 }
            : window.innerHeight < 760
              ? { y: "50vh", x: 1000 }
              : { y: "55vh", x: 1000 }
        }
      />
      {/* <AddAccount
            onCreate={onCreate}
            onCancel={onCancel}
            show={showAddAccount}
         /> */}
      <EditAccount />
      <ImportExcel
        show={showImportAccount}
        onCancel={onCancel}
        onImport={onImport}
        keyword="Template Import Data Rekening Siswa"
        url="/finance/accounts/import"
        urlTamplate="https://docs.google.com/spreadsheets/d/1wRex4mKSSDeBaY0HZSxXrHEasQ_jKrU-urGN01udNJ0/edit?usp=sharing"
        isShowTamplate={true}
      />
    </div>
  );
};

export default Account;
