import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Select, Space, Table, Tooltip, Switch, Popconfirm, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useMemo, useState } from "react";
import CreateTransitData from "../create/CreateTransitData";
import EditTransitData from "../edit/EditTransitData";
import ImportTransitData from "../import/ImportTransitData";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { decryptCookies } from "components/Helper/CookiesHelper";
import './TransitData.css';
import { useTransitDataPagination } from "hooks/finance-report-hook/useTransitDataPagination";
import { useKodeKeuanganList } from "hooks/finance-report-hook/useKodeKeuanganList";
import dayjs from "dayjs";
import KeteranganModal from "pages/si-finance-report/component/KeteranganModal";
import { numberFormat } from "pages/si-finance-report/utlis/utlis";
import { useDebounce } from "use-debounce";
import useResetPage from "components/Helper/useResetPage";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const TransitData = () => {
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [search] = useDebounce(keyword, 2000);
  const [financeCodeId, setFinanceCodeId] = useState("");
  const [date, setDate] = useState(dayjs());
  const [showEdit, setShowUpdate] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [foundationId, setFoundationId] = useState("");
  const [showNull, setShowNull] = useState(false);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });

  const [form] = Form.useForm();

  const roles = decryptCookies("role").split(",");

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  // fetch finance code list
  const {
    data: dataFinanceCode,
  } = useKodeKeuanganList(
    roles.some((x) => x === "super_admin") ? foundationId : ""
  );

  const {
    data: dataTransit,
    isLoading,
    isFetching,
    refetch,
  } = useTransitDataPagination(dataTable, search, foundationId, date.format('YYYY-MM'), financeCodeId, showNull);

  // fetch permission
  const { data: dataPermission } = usePermission("mdlFinance", "mnuTransitData");

  const onCreate = useCallback(() => {
    setShowCreate(false);
    refetch();
  }, [refetch]);

  const onImport = useCallback(() => {
    setShowImport(false);
    refetch();
  }, [refetch]);

  const onUpdate = useCallback(() => {
    setShowUpdate(false);
    refetch();
  }, [refetch]);

  const onCancel = () => {
    setShowCreate(false);
    setShowUpdate(false);
    setShowImport(false);
  };

  // reset page when search, foundationId, date, financeCodeId, showNull change
  useResetPage(setDataTable, [search, foundationId, date, financeCodeId, showNull]);

  const handleChange = (param) => {
    setKeyword(param.target.value);
  };

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddTransitData");
  const btnImport = dataPermission?.find((x) => x.id === "btnImportTransitData");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditTransitData");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteTransitData");

  const dataSource = dataTransit?.data?.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
        foundationName: data?.foundation?.name,
        unit: data?.codeUnit && `${data.codeUnit?.code} - ${data.codeUnit?.name}`,
        divisi: data?.codeDivisi && `${data?.codeDivisi?.code} - ${data.codeDivisi?.name}`,
        juknis: data?.codeJuknis && `${data?.codeJuknis?.code} - ${data.codeJuknis?.name}`,
      };
    });


  const columns = [
    {
      title: "No",
      dataIndex: "index",
      width: 50,
      fixed: "left",
      responsive: ["sm"],
    },
    {
      title: "Asal Data", dataIndex: "data_origin", fixed: "left",
      width: 100,
    },
    {
      title: "Tanggal", dataIndex: "date",
      width: 120,
      render: (date) => {
        return dayjs(date).format("DD-MM-YYYY")
      }
    },
    {
      title: "Yayasan", dataIndex: "foundationName",
      hidden: !roles.some((x) => x === "super_admin"),
      width: 150,
    },
    {
      title: "Unit", dataIndex: "unit",
      width: 150,
    },
    {
      title: "Divisi", dataIndex: "divisi",
      width: 150
    },
    {
      title: "Juknis", dataIndex: "juknis",
      width: 150
    },
    {
      title: "Jumlah biaya",
      dataIndex: "amount",
      width: 150,
      render: (amount) => {
        return numberFormat(amount)
      },
      sorter: (a, b) => {
        return a.amount - b.amount
      },
    },
    {
      title: "Keterangan", dataIndex: "description",
      width: 150,
      render: (text, data) => {
        return <KeteranganModal text={text} title="Detail keterangan" itemKey={data.id} />
      }
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      width: 130,
      render: (id) => {
        return (
          <>
            <Popconfirm
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({ url: "/financial-report/transit-data/", dataId: id, refetch })
              }
              title="Yakin ingin menghapus ?"
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setShowUpdate(true);
                  setDataId(id);
                }
              }}
            >
              Ubah
            </Tag>
          </>
        );
      },
    },
  ].filter((x) => !x.hidden);

  const pagination = useMemo(() => ({
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    defaultPageSize: dataTable.per_page,
    total: dataTransit?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100, dataTransit?.data?.meta.total > 200 ? dataTransit?.data?.meta.total : 200],
    onChange: (curr, size) => setDataTable((value) => ({
      ...value,
      current_page: curr,
      per_page: size,
    })),
  }), [dataTable, dataTransit]);

  return (
    <>
      <div className="table-header">
        <h1>Data Pengeluaran (BSI)</h1>
        <Space>
          <Button
            hidden={!btnAdd}
            onClick={() => setShowCreate(true)}
            disabled={btnAdd && btnAdd.type === "disabled"}
          >
            Tambah
          </Button>
          <Button
            type="primary"
            hidden={!btnImport}
            onClick={() => setShowImport(true)}
            disabled={btnImport && btnImport.type === "disabled"}
          >
            Import
          </Button>
        </Space>
      </div>
      <Form className="filter-transtit-data" form={form}
        layout="inline"
      >
        <Form.Item
          style={{
            width: "100%",
          }}
        >
          <Input
            value={keyword}
            onChange={handleChange}
            prefix={<SearchOutlined />}
            placeholder="cari berdasarkan asal data"
          />
        </Form.Item>
        <Form.Item
          style={{
            width: "100%",
          }}
        >
          <Tooltip title="Bulan - Tahun">
            <DatePicker
              picker="month"
              value={date}
              onChange={(x) => {
                setDate(x)
              }}
              allowClear={false}
            />
          </Tooltip>
        </Form.Item>
        {roles.some((x) => x === "super_admin") && (
          <Form.Item
            style={{
              width: "100%",
            }}
          >
            <Tooltip Tooltip title="Yayasan">
              <Select
                placeholder="Pilih Yayasan"
                style={{ minWidth: 150 }}
                onChange={(x) => {
                  setFinanceCodeId("")
                  setFoundationId(x)
                  form.resetFields(["financeCodeId"])
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option className="select-option-foundation" value="">
                  ALL
                </Select.Option>
                {dataFoundation?.data?.data?.map((x) => (
                  <Select.Option
                    key={x.id}
                    value={x.id}
                    className="select-option-foundation"
                  >
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
          </Form.Item>
        )}
        <Form.Item
          name="financeCodeId"
          style={{
            width: "100%",
          }}
        >
          <Tooltip title="Kode Keuangan">
            <Select
              placeholder="Pilih Kode Keuangan"
              onChange={(x, data) => {
                setFinanceCodeId(data?.key)
              }}
              showSearch
              optionFilterProp="children"
              filterOption={
                (input, option) => option.value.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Select.Option value=""
                key=''
                className="select-option-kode-keuangan">ALL</Select.Option>
              {dataFinanceCode?.data?.data?.map((x) => (
                <Select.Option key={x.id} value={`${x.code} - ${x.name} ${!roles.some((x) => x !== "super_admin") ? `- ${x.foundation?.name}` : ''}`} className="select-option-kode-keuangan">
                  {`${x.code} - ${x.name} ${!roles.some((x) => x !== "super_admin") ? `- ${x.foundation?.name}` : ''}`}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        </Form.Item>
        {/* show null switch */}
        <Form.Item
          style={{
            width: "max-content",
          }}
        >
          <Tooltip title="Tampilkan data yang belum ada kode">
            <Switch
              checked={showNull}
              onChange={(checked) => {
                setShowNull(checked)
              }}
            />
          </Tooltip>
        </Form.Item>
      </Form>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "40vh", x: 1300 }
            : window.innerHeight < 760
              ? { y: "50vh", x: 1300 }
              : { y: "55vh", x: 1300 }
        }
      />
      <CreateTransitData onCancel={onCancel} onCreate={onCreate} show={showCreate} />
      <ImportTransitData onCancel={onCancel} onImport={onImport} show={showImport} />
      <EditTransitData
        id={dataId}
        onCancel={onCancel}
        onUpdate={onUpdate}
        show={showEdit}
      />
    </>
  );
};

export default TransitData;
