import { Form, message, Modal } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useTemplateHook } from "hooks/template-hook/useTemplateHook";
import { useEffect, useState } from "react";

const ImportExcelLogic = (props) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { REACT_APP_HOST_API } = process.env;
  const {
    data: dataTemplate,
    refetch,
    isLoading,
  } = useTemplateHook(props.keyword);
  const [foundationId, setFoundationId] = useState("");
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  useEffect(() => {
    if (props.show) {
      refetch();
    }
  }, [props.show, refetch]);

  const isExcel = (file) => {
    return (
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel" ||
      file.type === "application/vnd.ms-excel.sheet.macroEnabled.12" ||
      file.type === "application/vnd.ms-excel.template.macroEnabled.12" ||
      file.type === "application/vnd.ms-excel.addin.macroEnabled.12" ||
      file.type === "application/vnd.ms-excel.sheet.binary.macroEnabled.12" ||
      file.type === "text/csv"
    );
  };

  const renderModalSucces = (title, message) => {
    Modal.success({
       title: title,
       content: <pre>{message}</pre>,
    });
 };

  const errorModal = (title, content) => {
    Modal.error({
      title,
      content: <pre>{content}</pre>,
    });
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      const values = await form.validateFields();
      const postBilling = {
        upload: values.file.file,
        year: values.year ? values.year[0].$y : values.year,
        ...(isSuperAdmin && foundationId && { foundationId: foundationId }),
      };
      const postData = props.isBilling
        ? postBilling
        : {
            upload: values.file.file,
            ...(isSuperAdmin && foundationId && { foundationId: foundationId }),
          };

      const { data } = await axios({
        method: "POST",
        url: REACT_APP_HOST_API + props.url,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + decryptCookies("token"),
        },
        data: postData,
      });
      renderModalSucces("Berhasil Import Data", data.message);
      setFileList([]);
      props.onImport();
      form.resetFields();
      setFoundationId("");
    } catch (error) {
      const msg = error.response.data.message
        ? error.response.data.message
        : error.message;
      errorModal("Gagal Melakukan Import", <pre>{msg}</pre>);
    } finally {
      setUploading(false);
    }
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
  };

  const beforeUpload = (file) => {
    if (!isExcel(file)) {
      message.error(
        "You can only upload Excel files (.xlsx, .xls, .xlsm, .xlsb, .csv)!"
      );
    }
    if (!isExcel(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }

    setFileList(isExcel(file) ? [file] : []);
    return false;
  };

  const onDrop = (e) => {
    const droppedFiles = e.dataTransfer.files;
    if (!isExcel(droppedFiles[0])) {
      message.error(
        "You can only upload Excel files (.xlsx, .xls, .xlsm, .xlsb, .csv)!"
      );
      return;
    }
    message.success("File dropped");
  };

  const onCancelModal = () => {
    form.resetFields();
    setFileList([]);
    props.onCancel();
    setFoundationId("");
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    onDrop,
    fileList,
  };

  return {
    form,
    fileList,
    propsUpload,
    uploading,
    dataTemplate,
    onCancelModal,
    handleUpload,
    isExcel,
    isLoading,
    foundationId,
    setFoundationId,
    isSuperAdmin,
  };
};

export default ImportExcelLogic;
