import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InventoryContext } from "../inventory-context/InventoryContext";
import InventariesLoan from "./InventariesLoan";
import "./LoanBatchDetail.css";
import dayjs from "dayjs";

const LoanBatchDetail = () => {
  const [newData, setnewData] = useState({});
  const [dataBatch, setDataBatch] = useState();
  const [loading, setLoading] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const [editingRows, setEditingRows] = useState(null);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [dataEmployee, setDataEmployee] = useState([]);
  const [dataPermission, setDataPermission] = useState([]);
  const [fetchStatus, setFetchStatus] = useState(false);
  const [showDeleteAsset, setShowDeleteAsset] = useState(false);
  const [borrowerType, setBorrowerType] = useState("");
  const [showAddInventory, setShowAddInventory] = useState(
    decryptCookies("asset_loan") === "0" ? false : true
  );

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { batch_id } = useParams();

  const { REACT_APP_HOST_API } = process.env;
  const { setInventoryFetch } = useContext(InventoryContext);
  const typeLoan = ["TEACH", "WORK", "PERSONAL", "EVENT"];

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    setLoading(true);

    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API + `/inventories/asset-loan-batches/${batch_id}`,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        );

        setDataBatch([data.data]);
        setBorrowerType(data.data.borrower_type);
      } catch (error) {
        alert(`Cannot get data batch
${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const fetchEmployee = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API + `/employee-list?keyword=`,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } },
          { cancelToken: cancelToken.token }
        );

        setDataEmployee(data.data);
      } catch (error) {
        alert(`Cannot GET data Employee 
${error.message}`);
      }
    };

    fetchEmployee();

    return () => {
      cancelToken.cancel();
      setFetchStatus(false);
      setnewData({});
    };
  }, [batch_id, fetchStatus, REACT_APP_HOST_API]);

  const fetchPermission = useCallback(async () => {
    try {
      const { data } = await axios.get(REACT_APP_HOST_API, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });
      setDataPermission(
        data.data[0].role.permissions.modules
          .find((x) => x.id === "mdlInventory")
          .menus.find((x) => x.id === "mnuLoanBatch").functions
      );
    } catch (error) {
      alert(error.message);
    }
  }, [REACT_APP_HOST_API]);

  useEffect(() => {
    fetchPermission();
  }, [fetchPermission]);

  // Buttons Permission
  const btnEdit = dataPermission.find((x) => x.id === "btnEditLoanBatch");
  const btnDelete = dataPermission.find((x) => x.id === "btnDeleteLoanBatch");

  const handleSubmitEdit = () => {
    form
      .validateFields()
      .then(async () => {
        if (Object.keys(newData).length === 0) {
          alert("Nothing has changed");
          return;
        }

        for (const key in newData) {
          if (Object.hasOwnProperty.call(newData, key)) {
            let element = newData[key];
            if (element === "") {
              newData[key] = null;
            }
          }
        }

        setLoadingEdit(true);
        try {
          const { data } = await axios.put(
            REACT_APP_HOST_API + `/inventories/asset-loan-batches/${batch_id}`,

            newData,
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
          );
          message.success(data.message);
          setAllowEdit(false);
          setEditingRows(null);
          setFetchStatus(true);
          setShowDeleteAsset(false);
        } catch (error) {
          Modal.error({
            title: "Gagal Mengubah Data",
            content: <pre>{error?.response?.data?.message}</pre>,
          });
        }
        setLoadingEdit(false);
      })
      .catch(() => {
        alert("Input fields Error");
      });
  };

  const handleDeleteBatch = async () => {
    try {
      const { data } = await axios.delete(
        REACT_APP_HOST_API + `/inventories/asset-loan-batches/${batch_id}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      navigate("/inventory/loanbatch/");
      setInventoryFetch(true);
    } catch (error) {
      alert(error.message);
    }
  };

  const columns = [
    {
      title: "Tipe",
      dataIndex: "type",
      render: (text, record) => {
        if (editingRows === record.key)
          return (
            <Form.Item name="type">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => (newData["type"] = e)}
              >
                {typeLoan.map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          );
        if (text) return text;
        else return "-";
      },
    },
    {
      title: "Penanggung Jawab",
      dataIndex: "employeeId",
      render: (text, record) => {
        if (editingRows === record.key && borrowerType === "internal")
          return (
            <Form.Item name="employeeId">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => (newData["employeeId"] = e)}
              >
                {dataEmployee.length > 0 &&
                  dataEmployee.map((data) => (
                    <Select.Option key={data.id} value={data.id}>
                      {data.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          );

        if (editingRows === record.key && borrowerType === "external")
          return (
            <Form.Item name="pic" initialValue={record.pic}>
              <Input
                name="pic"
                onChange={({ target: { value } }) => (newData["pic"] = value)}
              />
            </Form.Item>
          );
        if (record.pic) return record.pic;
        else return "-";
      },
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      render: (text, record) => {
        if (editingRows === record.key)
          return (
            <Form.Item
              name="description"
              rules={[{ min: 10, message: "Enter a least 10 character" }]}
            >
              <Input
                name="description"
                onChange={({ target: { value } }) =>
                  (newData["description"] = value)
                }
              />
            </Form.Item>
          );
        if (text) return text;
        else return "-";
      },
    },
    {
      title: "Waktu Awal Peminjaman",
      dataIndex: "start_date",
      render: (text, record) => {
        if (editingRows === record.key)
          return (
            <Form.Item name="startDate">
              <DatePicker
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                onChange={(e) =>
                  (newData["startDate"] = e.format("YYYY-MM-DD HH:mm:ss"))
                }
              />
            </Form.Item>
          );
        if (text) return text;
        else return "-";
      },
    },
    {
      title: "Waktu Akhir Peminjaman",
      dataIndex: "end_date",
      render: (text, record) => {
        if (editingRows === record.key)
          return (
            <Form.Item name="endDate">
              <DatePicker
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                onChange={(e) =>
                  (newData["endDate"] = e.format("YYYY-MM-DD HH:mm:ss"))
                }
              />
            </Form.Item>
          );
        if (text) return text;
        else return "-";
      },
    },
  ];

  const dataTable1 =
    dataBatch &&
    dataBatch.map((data, index) => {
      return {
        ...data,
        key: index,
        index: index + 1,
        employeeId: data.employee && data.employee.name,
        start_date:
          data.start_date &&
          moment(data.start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_date:
          data.end_date && moment(data.end_date).format("YYYY-MM-DD HH:mm:ss"),
      };
    });

  const onAddInventory = () => {
    setShowAddInventory(true);
    setAllowEdit(false);
    setEditingRows(null);
    setShowDeleteAsset(false);
    setShowAddInventory(true);
  };

  const onCancelAddInventory = () => {
    setShowAddInventory(false);
  };

  return (
    <div className="loan-batch-wrapper">
      <div className="table-header">
        <h1>
          Peminjaman Inventaris Berkelompok{" "}
          <span className="text-[#1890ff]">{borrowerType}</span>
        </h1>
        <Space>
          <Popconfirm
            okText="Hapus"
            cancelText="Batal"
            onConfirm={handleDeleteBatch}
            disabled={btnDelete && btnDelete.type === "disabled"}
            title="Anda yakin ingin menghapus semua data Peminjaman?"
          >
            <Button
              type="primary"
              danger
              hidden={allowEdit || !btnDelete}
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              Hapus
            </Button>
          </Popconfirm>
          {allowEdit ? (
            <>
              <Button
                onClick={() => {
                  setEditingRows(null);
                  setAllowEdit(false);
                  setShowDeleteAsset(false);
                  setShowAddInventory(true);
                }}
              >
                Batal
              </Button>
              <Button
                type="primary"
                loading={loadingEdit}
                onClick={handleSubmitEdit}
              >
                Simpan
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              hidden={!btnEdit}
              disabled={btnEdit && btnEdit.type === "disabled"}
              onClick={() => {
                form.setFieldsValue({
                  description: dataBatch[0].description,
                  type: dataBatch[0].type,
                  employeeId: dataBatch[0].employee_id,
                  startDate:
                    dataBatch[0].start_date &&
                    dayjs(
                      moment(dataBatch[0].start_date).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    ),
                  endDate:
                    dataBatch[0].end_date &&
                    dayjs(
                      moment(dataBatch[0].end_date).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    ),
                  // startDate:
                  //   dataBatch[0].start_date && moment(dataBatch[0].start_date),
                  // endDate:
                  //   dataBatch[0].end_date && moment(dataBatch[0].end_date),
                });
                setEditingRows(0);
                setAllowEdit(true);
                setShowDeleteAsset(true);
                setShowAddInventory(false);
              }}
            >
              Ubah
            </Button>
          )}
        </Space>
      </div>
      <Form
        form={form}
        className={editingRows !== null ? "form form-edit" : "form"}
      >
        <Table
          size="small"
          dataSource={dataTable1}
          columns={columns}
          loading={loading}
          pagination={false}
        />
      </Form>
      <InventariesLoan
        showDelete={showDeleteAsset}
        showAddInventory={showAddInventory}
        onAddInventory={onAddInventory}
        onCancelAddInventory={onCancelAddInventory}
        isInternal={borrowerType === "internal"}
        dataDetail={dataBatch && dataBatch[0]}
      />
    </div>
  );
};

export default LoanBatchDetail;
