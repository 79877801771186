import { Form, Input, Modal, Radio, Select, Skeleton } from "antd";
import React from "react";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";
import { useKodeKeuanganDetail } from "hooks/finance-report-hook/useKodeKeuanganDetail";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { renderModalError } from "pages/si-finance-report/utlis/utlis";

const EditKodeKeuangan = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const { REACT_APP_HOST_API } = process.env;

  // get roles
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");


  // get foundation list
  const { data: dataFoundation } = useFoundationList();

  // fetch detail units
  const {
    isLoading: skeleton,
    refetch: fetchData,
  } = useKodeKeuanganDetail(props.id, false);

  const setData = async () => {
    const { data: successData } = await fetchData();

    if (successData) {
      form.setFieldsValue({
        name: successData.data?.name,
        code: successData.data?.code,
        foundationId: successData.data?.foundation_id,
        type: successData.data?.type,
      });
    }
  };

  useEffect(() => {
    if (props.show) {
      setData();
    }

    return () => setNewData({});
    // eslint-disable-next-line
  }, [form, props.show]);


  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (Object.keys(newData).length === 0) {
        renderModalError("Error : Edit Kode Keuangan", "Tidak Ada Perubahan data");
        return;
      }

      for (const key in newData) {
        if (Object.hasOwnProperty.call(newData, key)) {
          const element = newData[key];
          if (element === "") newData[key] = null;
        }
      }

      if (newData.code) {
        newData.foundationId = newData.foundationId || values.foundationId || decryptCookies("foundationId");
      }

      setLoading(true);

      const { data } = await axios.put(
        REACT_APP_HOST_API + `/financial-report/finance-codes/${props.id}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      message.success(data.message);
      form.resetFields();
      props.onUpdate();
    } catch (error) {
      renderModalError("Error : Edit Kode Keuangan", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    props.onCancel();
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={handleCancel}
      okButtonProps={{ loading }}
    >
      {skeleton && <Skeleton active />}

      {!skeleton && (
        <Form form={form} layout="vertical">
          {(isSuperAdmin) &&
            <Form.Item
              name="foundationId"
              label="Foundation"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                placeholder="Pilih foundation"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => (newData.foundationId = e)}
              >
                {dataFoundation?.data?.data?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          }
          <Form.Item
            label="Kode"
            name="code"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Input
              onChange={({ target: { value } }) => (newData["code"] = value)}
            />
          </Form.Item>
          <Form.Item
            name="name"
            label="Nama"
            rules={[
              { required: true, message: "Harap diisi" },
            ]}
          >
            <Input
              onChange={({ target: { value } }) =>
                (newData["name"] = value)
              }
            />
          </Form.Item>

          <Form.Item
            label="Tipe"
            name="type"
            rules={[
              {
                required: true,
                message: "Tipe harus diisi!",
              },
            ]}
          >
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              onChange={(e) => (newData["type"] = e.target.value)}
            >
              <Radio value="unit">Unit</Radio>
              <Radio value="divisi">Divisi</Radio>
              <Radio value="juknis">Juknis</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default React.memo(EditKodeKeuangan);
