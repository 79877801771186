import { InboxOutlined } from "@ant-design/icons";
import { Form, Modal, Select, Upload } from "antd";
import React from "react";
import { FaFilePdf } from "react-icons/fa";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useState } from "react";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { renderModalError } from "pages/si-finance-report/utlis/utlis";
import { useNavigate } from "react-router-dom";
const { Dragger } = Upload;

const ImportBsi = () => {
   const [form] = Form.useForm();
   const [show, setShow] = useState(true);
   const [fileListPdf, setFileListPdf] = useState([]);
   const [uploading, setUploading] = useState(false);
   const { REACT_APP_HOST_API } = process.env;
   const roles = decryptCookies("role").split(",");
   const isSuperAdmin = roles.some((x) => x === "super_admin");
   const navigate = useNavigate();
   const { data: dataFoundation } = useFoundationList();

   const isExcel = (file) => {
      const excelTypes = [
         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
         "application/vnd.ms-excel",
         "application/vnd.ms-excel.sheet.macroEnabled.12",
         "application/vnd.ms-excel.template.macroEnabled.12",
         "application/vnd.ms-excel.addin.macroEnabled.12",
         "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
         "text/csv"
      ];
      return excelTypes.includes(file.type);
   };

   const isPdf = (file) => file.type === "application/pdf";

   const renderModalSucces = (title, message) => {
      Modal.success({
         title: title,
         content: <pre>{message}</pre>,
      });
   };

   const uploadFile = async (url, upload, foundationId) => {
      const { data } = await axios({
         method: "POST",
         url: REACT_APP_HOST_API + url,
         headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + decryptCookies("token"),
         },
         data: {
            upload,
            foundationId,
         },
      });
      renderModalSucces("berhasil import data", data.message);
   };

   const handleUpload = async () => {
      setUploading(true);
      try {
         const values = await form.validateFields();
         if ((!values.filePdf || values?.filePdf?.fileList.length === 0)) {
            message.error("upload salah satu file pdf");
            return;
         }
         await uploadFile("/finance/import-data-bsi", values.filePdf.file, values.foundationId);
         setFileListPdf([]);
         form.resetFields();
      } catch (error) {
         renderModalError("Gagal Melakukan Import", error);
      } finally {
         setUploading(false);
      }
   };

   const handleRemove = (file, setFileListFunc, fileList) => {
      const newFileList = fileList.filter((f) => f.uid !== file.uid);
      setFileListFunc(newFileList);
   };

   const beforeUpload = (file, isFileType, setFileListFunc, fileList) => {
      if (!isFileType(file)) {
         message.error(
            `You can only upload ${isFileType === isExcel ? "Excel" : "PDF"} files!`
         );
      }
      if (!isFileType(file) && fileList.length > 0) {
         setFileListFunc([...fileList]);
         return false;
      }
      setFileListFunc(isFileType(file) ? [file] : []);
      return false;
   };

   const onDrop = (e, isFileType) => {
      const droppedFiles = e.dataTransfer.files;
      if (!isFileType(droppedFiles[0])) {
         message.error(
            `You can only upload ${isFileType === isExcel ? "Excel" : "PDF"} files!`
         );
         return;
      }
      message.success("File dropped");
   };

   const onCancelModal = () => {
      form.resetFields();
      setFileListPdf([]);
      setShow(false);
      navigate(-1);
   };

   const propsUploadPdf = {
      onRemove: (file) => handleRemove(file, setFileListPdf, fileListPdf),
      beforeUpload: (file) => beforeUpload(file, isPdf, setFileListPdf, fileListPdf),
      onDrop: (e) => onDrop(e, isPdf),
      fileList: fileListPdf,
   };

   return (
      <Modal
         okText="Simpan"
         cancelText="Batal"
         onOk={handleUpload}
         open={show}
         onCancel={onCancelModal}
         okButtonProps={{ loading: uploading }}
         cancelButtonProps={{ disabled: uploading }}
         title="Import data bsi"
      >

         <Form form={form} layout="vertical">
            {(isSuperAdmin) &&
               <Form.Item
                  name="foundationId"
                  label="Foundation"
                  rules={[{ required: true }]}
               >
                  <Select
                     showSearch
                     placeholder="Pilih foundation"
                     optionFilterProp="children"
                     filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                     }

                  >
                     {dataFoundation?.data?.data?.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                           {item.name}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
            }
            {/* pdf */}
            <Form.Item
               name='filePdf'
               label='BSI'
            >
               <Dragger
                  name='filePdf'
                  accept='.pdf'
                  listType='picture'
                  disabled={uploading}
                  iconRender={(file) => {
                     if (file.type === 'application/pdf') {
                        return <FaFilePdf size={45} color='red' />;
                     }
                     return <InboxOutlined />;
                  }}
                  {...propsUploadPdf}
               >
                  <p className='ant-upload-drag-icon'>
                     <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>
                     Click or drag file to this area to upload
                  </p>
                  <p className='ant-upload-hint'>Support for PDF file.</p>
               </Dragger>
            </Form.Item>
         </Form>
      </Modal>
   );
};

export default ImportBsi;
