import React, { useState } from "react";
import { Button, Image, Input, List, Popconfirm, Space, Tooltip } from "antd";
import CreateWorkUnit from "./create/CreateWorkUnit";
import EditWorkUnit from "./edit/EditWorkUnit";
import { usePermission } from "hooks/usePermissions";
import { useWorkUnitList } from "hooks/landing-page-hook/useWorkUnitList";
import { DeleteApi } from "api/DeleteApi";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";

export default function WorkUntis() {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [record, setRecord] = useState({});

  // fetch work unit
  const { data: dataWorkUnit, refetch } = useWorkUnitList(keyword);

  // fetch  permission
  const { data: dataPermission } = usePermission(
    "mdlLandingPage",
    "mnuWorkUnit"
  );

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddWorkUnit");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditWorkUnit");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteWorkUnit");

  const handleOpenAdd = () => {
    setOpenAdd(!openAdd);
    refetch();
  };

  const handleOpenEdit = () => {
    setOpenEdit(!openEdit);
    refetch();
  };

  return (
    <div>
      <div className="table-header">
        <h1>List Work Units</h1>

        <Button
          type="primary"
          hidden={!btnAdd}
          disabled={btnAdd && btnAdd.type === "disabled"}
          onClick={() => {
            if (btnAdd && btnAdd.type === "disabled") {
              return;
            } else {
              handleOpenAdd();
            }
          }}
        >
          Add Work Unit
        </Button>
      </div>

      <Tooltip title="Cari Work Unit">
        <Input
          prefix={<SearchOutlined />}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="cari work unit..."
          className="search-input-billings"
          allowClear
        />
      </Tooltip>

      <List
        className="mt-10 border border-[#ccc] rounded-[5px] py-[3px] px-[10px]"
        itemLayout="horizontal"
        dataSource={dataWorkUnit?.data}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <div className="w-[50px] h-[50px] overflow-hidden rounded-full border border-[#ccc]">
                  <Image
                    width={50}
                    height={50}
                    src={item?.logo}
                    preview={{
                      mask: (
                        <span className="flex items-center justify-center">
                          {<EyeOutlined />}
                        </span>
                      ),
                    }}
                  />
                </div>
              }
              title={<p>{item?.name}</p>}
              description={
                <div>
                  <a
                    className="text-blue-500"
                    target="_blank"
                    rel="noreferrer"
                    href={item?.link}
                  >
                    {item?.link}
                  </a>
                  <p className="mb-0">{item?.description}</p>
                  <p className="mb-0">Order : {item?.order || "-"}</p>
                </div>
              }
            />

            <Space>
              <Popconfirm
                title="Yakin ingin menghapus ?"
                okText="Hapus"
                cancelText="Batal"
                onConfirm={() => {
                  const dataId = item?.id;
                  DeleteApi({
                    url: "/landing-page/work-units/",
                    dataId,
                    refetch,
                  });
                }}
                disabled={btnDelete && btnDelete.type === "disabled"}
              >
                <Button type="link" danger>
                  Hapus
                </Button>
              </Popconfirm>

              <Button
                type="link"
                onClick={() => {
                  if (btnEdit && btnEdit.type === "disabled") {
                    return;
                  } else {
                    handleOpenEdit();
                    setRecord(item);
                  }
                }}
                hidden={!btnEdit}
                disabled={btnEdit && btnEdit.type === "disabled"}
                style={{
                  cursor:
                    btnEdit?.type === "disabled" ? "not-allowed" : "pointer",
                }}
              >
                Edit
              </Button>
            </Space>
          </List.Item>
        )}
      />

      <CreateWorkUnit open={openAdd} setOpen={handleOpenAdd} />
      <EditWorkUnit open={openEdit} setOpen={handleOpenEdit} data={record} />
    </div>
  );
}
