import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Input,
  Popconfirm,
  Select,
  Space,
  Table,
  Tooltip,
  message,
} from "antd";
import AddAssetLoan from "../create/AddAssetLoan";
import "../AssetLoan.css";
import EditAssetLoan from "../edit/EditAssetLoan";
import { Tag } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useAssetLoanPagination } from "hooks/asset-loan-hook/useAssetLoanPagination";
import { usePermission } from "hooks/usePermissions";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { InventoryContext } from "../../inventory-context/InventoryContext";
import dayjs from "dayjs";
import FilterFoundation from "components/UI/FilterFoundation";
import { useUnitList } from "hooks/unit-hook/useUnitList";
import useResetPage from "components/Helper/useResetPage";

const disabled = { cursor: "not-allowed", color: "#bbb" };

const AssetLoan = () => {
  const [foundationId, setFoundationId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [dataId, setDataId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAddAssetLoan, setShowAddAssetLoan] = useState(false);
  const [showEditAssetLoan, setShowEditAssetLoan] = useState(false);
  const [borrowerType, setBorrowerType] = useState("");
  const [unitId, setUnitId] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });

  const newDate = new Date();
  const firstDate = new Date(newDate.getFullYear() - 3, 0, 1);
  const lastDate = new Date(newDate.getFullYear(), 11, 31);

  const [date, setDate] = useState({
    from: moment(firstDate).format("YYYY-MM-DD"),
    to: moment(lastDate).format("YYYY-MM-DD"),
  });

  const { REACT_APP_HOST_API } = process.env;
  const { setInventoryFetch } = useContext(InventoryContext);
  const roles = decryptCookies("role").split(",");

  const { data, isLoading, isFetching, refetch } = useAssetLoanPagination(
    dataTable,
    keyword,
    date,
    foundationId,
    unitId,
    borrowerType
  );
  const { data: dataUnit, refetch: refetchUnit } = useUnitList(
    "",
    foundationId
  );

  const { data: dataPermission } = usePermission(
    "mdlInventory",
    "mnuAssetLoan"
  );

  // Buttons Permission
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddAssetLoan");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditAssetLoan");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteAssetLoan");
  const btnReturn = dataPermission?.find((x) => x.id === "btnReturnAssetLoan");

  useEffect(() => {
    refetchUnit();
    setUnitId("");
    // eslint-disable-next-line
  }, [foundationId]);

  const onCreate = () => {
    setShowAddAssetLoan(false);
    setInventoryFetch(true);
    refetch();
  };

  const onUpdate = () => {
    setShowEditAssetLoan(false);
    refetch();
  };

  const onCancel = () => {
    setShowAddAssetLoan(false);
    setShowEditAssetLoan(false);
  };

  const handleReturnAsset = async (record) => {
    const { asset_loan_batch_id, id } = record;

    setLoading(true);

    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + `/inventories/asset-loans/${id}`,
        {
          assetLoanBatchId: asset_loan_batch_id,
          endDate: moment(new Date().toISOString()).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
        },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      refetch();
      setInventoryFetch(true);
    } catch (error) {
      alert(error.message);
    }

    setLoading(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const { data } = await axios.delete(
        REACT_APP_HOST_API + `/inventories/asset-loans/${id}`,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );

      message.success(data.message);
      refetch();
      setInventoryFetch(true);
    } catch (error) {
      alert(error.message);
    }

    setLoading(false);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      width: 50,
      fixed: "left",
    },
    { title: "Aset", dataIndex: "asset", fixed: "left" },
    { title: "Unit", dataIndex: "unit" },
    {
      title: "Peminjam",
      dataIndex: "borrower",
      width: 170,
    },
    { title: "Kelas", dataIndex: "class" },
    {
      title: "Waktu Mulai",
      dataIndex: "start_date",
      sorter: (a, b) => new Date(a.start_date) - new Date(b.start_date),
    },
    {
      title: "Waktu Selesai",
      dataIndex: "end_date",
      sorter: (a, b) => new Date(a.end_date) - new Date(b.end_date),
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (_, record) => (
        <Space>
          <Popconfirm
            okText="Hapus"
            cancelText="Batal"
            title="Yakin ingin menghapus data ?"
            disabled={!btnDelete || btnDelete.type === "disabled"}
            onConfirm={() => handleDelete(record.id)}
            okButtonProps={{ loading }}
          >
            <Tag
              style={
                btnDelete?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              color="magenta"
              hidden={!btnDelete}
            >
              Hapus
            </Tag>
          </Popconfirm>
          <Tag
            color="orange"
            hidden={!btnEdit}
            style={
              btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            onClick={() => {
              if (btnEdit?.type === "disabled") return;
              else {
                setShowEditAssetLoan(true);
                setDataId(record.id);
              }
            }}
          >
            Ubah
          </Tag>
          <Tag
            color="green"
            hidden={!btnReturn || record.end_date !== null}
            style={
              btnReturn?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            onClick={() => {
              if (btnReturn?.type === "disabled") return;
              else handleReturnAsset(record);
            }}
          >
            Kembalikan
          </Tag>
        </Space>
      ),
    },
  ];

  const dataSource = data?.data?.data
    ?.sort((a, b) => {
      if (a.end_date && !b.end_date) return 1;
      if (b.end_date && !a.end_date) return -1;

      return a.end_date - b.end_date;
    })
    .map((data, index) => {
      if (data.student === null && data.employee === null)
        return {
          ...data,
          index: index + 1,
          key: data.id,
          asset: data.asset.serial,
          // borrower: "",
          start_date:
            data.start_date &&
            moment(data.start_date).format("YYYY-MM-DD HH:mm:ss"),
          end_date:
            data.end_date &&
            moment(data.end_date).format("YYYY-MM-DD HH:mm:ss"),
        };
      return {
        ...data,
        index: index + 1,
        key: data.id,
        unit: data?.unit?.name,
        asset: data.asset.serial,
        // borrower: data.student ? data.student.name : data.employee.name,
        class: data.student?.class.name,
        start_date:
          data.start_date &&
          moment(data.start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_date:
          data.end_date && moment(data.end_date).format("YYYY-MM-DD HH:mm:ss"),
      };
    });

  const onTableChange = (curr, size) => {
    setDataTable((data) => {
      return {
        ...data,
        current_page: curr,
        per_page: size,
      };
    });
  };

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 30, 50, 100],
    onChange: (curr, size) => onTableChange(curr, size),
  };

  useResetPage(setDataTable, [
    keyword,
    foundationId,
    unitId,
    borrowerType,
    date,
  ]);

  return (
    <div className="loan-asset-wrapper">
      <div className="table-header">
        <h1>Peminjaman Aset</h1>
        <Space>
          <Button
            type="primary"
            hidden={!btnAdd}
            disabled={btnAdd && btnAdd.type === "disabled"}
            onClick={() => setShowAddAssetLoan(true)}
          >
            Tambah
          </Button>
        </Space>
      </div>

      <div className="grid gap-5 sm:grid-cols-3 md:grid-cols-6">
        <Input
          allowClear
          value={keyword}
          placeholder="cari aset..."
          prefix={<SearchOutlined />}
          onChange={({ target: { value } }) => setKeyword(value)}
          className="item-search"
        />

        <DatePicker
          value={dayjs(date.from)}
          style={{ width: "100%" }}
          onChange={(value) => {
            setDate((curr) => ({
              ...curr,
              from:
                value !== null
                  ? value.format("YYYY-MM-DD")
                  : moment(firstDate).format("YYYY-MM-DD"),
            }));
          }}
          placeholder="Awal"
        />

        <DatePicker
          value={dayjs(date.to)}
          onChange={(value) =>
            setDate((curr) => ({
              ...curr,
              to:
                value !== null
                  ? value.format("YYYY-MM-DD")
                  : moment(lastDate).format("YYYY-MM-DD"),
            }))
          }
          placeholder="Akhir"
        />

        {roles.includes("super_admin") && (
          <FilterFoundation setFoundationId={setFoundationId} />
        )}

        <Tooltip title="Unit">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={setUnitId}
            value={unitId}
          >
            <Select.Option value="">Semua</Select.Option>
            {dataUnit?.data?.map((data) => (
              <Select.Option key={data.id} value={data.id}>
                {data.name}
              </Select.Option>
            ))}
          </Select>
        </Tooltip>

        <Tooltip title="Tipe">
          <Select onChange={setBorrowerType} value={borrowerType}>
            <Select.Option value="">Semua</Select.Option>
            <Select.Option value="internal">Internal</Select.Option>
            <Select.Option value="external">Eksternal</Select.Option>
          </Select>
        </Tooltip>
      </div>

      <Table
        size="small"
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        scroll={
          window.innerHeight < 760
            ? { y: "50vh", x: 1300 }
            : { y: "60vh", x: 1300 }
        }
        rowClassName={(record) =>
          record.end_date === null ? "table-pink" : ""
        }
      />
      <AddAssetLoan
        show={showAddAssetLoan}
        onCreate={onCreate}
        onCancel={onCancel}
      />
      <EditAssetLoan
        id={dataId}
        show={showEditAssetLoan}
        onUpdate={onUpdate}
        onCancel={onCancel}
      />
    </div>
  );
};

export default AssetLoan;
