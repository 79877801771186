import RequireAuth from "components/Auth/RequireAuth";
import RequireMenuLink from "components/Auth/RequireMenuLink";
import Link from "pages/si-link/link/master/Link";
import LayoutLink from "Layouts/LayoutLink/LayoutLink";
import LinkGroup from "pages/si-link/link-group/master/LinkGroup";
import CreateLink from "pages/si-link/link/create/CreateLink";
import CreateGroup from "pages/si-link/link-group/create/CreateGroup";
import Categories from "pages/si-link/categories/master/Categories";
import DetailLinkGroup from "pages/si-link/link-group/detail/DetailLinkGroup";
import EditLink from "pages/si-link/link/edit/EditLink";
import EditGroup from "pages/si-link/link-group/edit/EditGroup";
import AddLink from "pages/si-link/link-group/detail/add/AddLink";
import EditLinkLinkGroup from "pages/si-link/link-group/detail/edit/EditLink";
import AddAnggota from "pages/si-link/link-group/detail/add/AddAnggota";

export const DataRoutesLink = [
  // Link ---
  {
    path: "link",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuLink">
          <LayoutLink content={<Link />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },
  {
    path: "link/add",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuLink">
          <LayoutLink content={<CreateLink />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },
  {
    path: "link/edit/:link_id",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuLink">
          <LayoutLink content={<EditLink />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },

  // Link Categories ---
  {
    path: "link/categories",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuCategories">
          <LayoutLink content={<Categories />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },

  // Link Group ---
  {
    path: "link/group",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuLinkGroup">
          <LayoutLink content={<LinkGroup />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },
  {
    path: "link/group/add",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuLinkGroup">
          <LayoutLink content={<CreateGroup />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },
  {
    path: "link/group/:group_id/edit",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuLinkGroup">
          <LayoutLink content={<EditGroup />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },
  {
    path: "link/group/:group_id",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuLinkGroup">
          <LayoutLink content={<DetailLinkGroup />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },
  {
    path: "link/group/:group_id/add-link",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuLinkGroup">
          <LayoutLink content={<AddLink />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },
  {
    path: "link/group/:group_id/edit-link/:link_id",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuLinkGroup">
          <LayoutLink content={<EditLinkLinkGroup />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },
  {
    path: "link/group/:group_id/add-members",
    element: (
      <RequireAuth>
        <RequireMenuLink menu="mnuLinkGroup">
          <LayoutLink content={<AddAnggota />} />
        </RequireMenuLink>
      </RequireAuth>
    ),
  },
];
