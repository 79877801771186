import { Divider, Image, Skeleton, Tag } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./DetailAssets.css";

const DetailAssets = () => {
  const [dataDetail, setDataDetail] = useState(null);

  const { id } = useParams();
  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API + "/inventories/assets/" + id,
          {
            headers: { Authorization: "Bearer " + decryptCookies("token") },
          },
          { cancelToken: cancelToken.token }
        );

        setDataDetail(data.data);
      } catch (error) {
        alert(`GET Data Error
${error.message}`);
      }
    };

    fetchData();

    return () => {
      cancelToken.cancel();
    };
  }, [REACT_APP_HOST_API, id]);

  return (
    <>
      {dataDetail !== null && (
        <Fragment key={dataDetail.id}>
          <Divider orientation="left">Info Aset</Divider>
          <table className="detail-assets__table">
            <tbody>
              <tr>
                <th>Status Aset</th>
                <td>{dataDetail.asset_status_id}</td>
              </tr>
              <tr>
                <th>Nama Serial</th>
                <td>{dataDetail.serial}</td>
              </tr>
              <tr>
                <th>Deskripsi</th>
                <td>{dataDetail.description}</td>
              </tr>
              <tr>
                <th>Sumber Dana</th>
                <td>{dataDetail.fundSource?.name}</td>
              </tr>
              <tr>
                <th>Kategori</th>
                <td>
                  {dataDetail.categories &&
                    dataDetail.categories.map((ctg) => (
                      <Tag key={ctg.id} color="blue">
                        {ctg.name}
                      </Tag>
                    ))}
                </td>
              </tr>
              <tr>
                <th>Tag</th>
                <td>
                  {dataDetail.tag &&
                    dataDetail.tag.map((tag) => (
                      <Tag key={tag} color="purple">
                        {tag}
                      </Tag>
                    ))}
                </td>
              </tr>
              <tr>
                <th>Tanggal Pembelian</th>
                <td>{dataDetail.purchase_date && dataDetail.purchase_date}</td>
              </tr>
              <tr>
                <th>Nomer Order</th>
                <td>{dataDetail.order_number && dataDetail.order_number}</td>
              </tr>
              <tr>
                <th>Harga</th>
                <td>{dataDetail.price && dataDetail.price}</td>
              </tr>
              <tr>
                <th>Garansi (bulan)</th>
                <td>{dataDetail.warranty && dataDetail.warranty}</td>
              </tr>
              <tr>
                <th>Catatan</th>
                <td>{dataDetail.notes && dataDetail.notes}</td>
              </tr>
              <tr
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <th>Gambar</th>
                <td>
                  {dataDetail.image ? (
                    <Image
                      style={{
                        width: 200,
                        height: 100,
                        objectFit: "cover",
                      }}
                      src={REACT_APP_HOST_API + dataDetail.image[1]}
                      alt="asset"
                    />
                  ) : (
                    <Skeleton.Image />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default DetailAssets;
