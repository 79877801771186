import {
   Button,
   Form,
   Input,
   Radio,
   Table,
   message
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useNotEmployeeUnit } from "hooks/unit-hook/useNotEmployeeUnit";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const AddEmployeeBatch = () => {
   const [form] = Form.useForm();
   const [selectedIds, setSelectedIds] = useState([]);
   const [selectedJabatan, setSelectedJabatan] = useState([]);
   const [selectedStatus, setSelectedStatus] = useState([]);
   const [posting, setPosting] = useState(false);
   const [keyword, setKeyword] = useState("");
   const [dataTable, setDataTable] = useState({
      current_page: 1,
      per_page: 15,
      total: 0,
   });

   const { REACT_APP_HOST_API } = process.env;
   const navigate = useNavigate();
   const { unit_id } = useParams();


   const { data: dataUnitMember, isLoading } = useNotEmployeeUnit(
      unit_id,
      dataTable,
      keyword
   );


   const handleSearch = (event) => {
      setKeyword(event.target.value);
   };

   const titleOptions = [
      { label: "Ketua", value: "lead" },
      { label: "Wakil", value: "vice" },
      { label: "Anggota", value: "member" },
   ];

   const columns = [
      {
         title: "Nama",
         dataIndex: "name",
         key: "name",
         width: window.innerWidth > 800 ? 300 : 65,
         fixed: "left",
      },
      {
         title: "Jabatan",
         dataIndex: "position",
         key: "position",
         width: window.innerWidth > 800 ? 300 : 65,
         render: (_, record) => (
            <Form.Item
               name={`title${record.id}`}
               onChange={(e) => {
                  setSelectedJabatan((prev) => {
                     return {
                        ...prev,
                        [`title_${record.id}`]: e.target.value,
                     };
                  });
               }}
            >
               <Radio.Group buttonStyle="solid"
                  defaultValue={"member"}
               >
                  {
                     titleOptions.map((title) => (
                        <Radio.Button key={title.value} value={title.value}>
                           {title.label}
                        </Radio.Button>
                     ))
                  }
               </Radio.Group>
            </Form.Item >
         ),
      },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         width: window.innerWidth > 800 ? 300 : 65,
         render: (_, record) => (
            <Form.Item name={`status${record.id}`}
               onChange={(e) => {
                  setSelectedStatus((prev) => {
                     return {
                        ...prev,
                        [`status_${record.id}`]: e.target.value,
                     };
                  });
               }}
            >
               <Radio.Group buttonStyle="solid" defaultValue={"FULLTIME"}>
                  <Radio.Button value="FULLTIME">Full time</Radio.Button>
                  <Radio.Button value="PARTTIME">Part time</Radio.Button>
               </Radio.Group>
            </Form.Item>
         ),
      },
   ];


   const onSelectChange = (selectedRowKeys) => {
      setSelectedIds(selectedRowKeys);
   };

   const rowSelection = {
      selectedRowKeys: selectedIds,
      onChange: onSelectChange,
      preserveSelectedRowKeys: true,
   };

   const handleSubmit = async () => {
      try {
         setPosting(true);
         if (selectedIds.length === 0)
            return alert("Harap pilih anggota yang akan ditambahkan");

         const employeeData = selectedIds.map((id) => {
            return {
               employeeId: id,
               title: selectedJabatan[`title_${id}`] || "member",
               status: selectedStatus[`status_${id}`] || "FULLTIME",
               unitId: unit_id
            };
         });

         const { data } = await axios.post(
            REACT_APP_HOST_API + `/employee-units`,
            { employeeUnits: employeeData },
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
         );
         message.success(data.message);
         form.resetFields();
         setSelectedIds([]);
         setSelectedJabatan([]);
         setSelectedStatus([]);
         navigate(`/foundation/unitfoundation/${unit_id}`);
      } catch (error) {
         let msg = error?.response?.data?.message || "Input Field Error";
         message.error(msg);
      } finally {
         setPosting(false);
      }
   };

   const dataSource = dataUnitMember?.data?.data
      .slice(0, dataTable.per_page)
      .map((employee, index) => {
         return {
            ...employee,
            index: index + 1,
            key: employee.id,
         };
      })


   const pagination = {
      current: dataTable.current_page,
      pageSize: dataTable.per_page,
      total: dataUnitMember?.data.meta.total,
      showSizeChanger: true,
      pageSizeOptions: [15, 20, 50, 100, 300],
      onChange: (curr, size) => {
         setDataTable((prev) => {
            return {
               ...prev,
               current_page: curr,
               per_page: size,
            };
         });
      },
   };

   return (
      <div>
         <div className="table-header">
            <h1>Form Tambah Angggota Unit</h1>
         </div>
         <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <div className="search-wrapper filter-wrapper">
               <Form.Item style={{ width: "100%", marginBottom: 0 }}>
                  <Input
                     value={keyword}
                     onChange={handleSearch}
                     placeholder=" Cari nama karyawan"
                     disabled={posting}
                  />
               </Form.Item>
            </div>
            <Table
               style={{ marginBottom: 20 }}
               size={window.innerWidth > 1600 ? "middle" : "small"}
               pagination={pagination}
               tableLayout="auto"
               dataSource={dataSource}
               columns={columns}
               loading={isLoading}
               rowSelection={rowSelection}
               scroll={
                  window.innerHeight < 690
                     ? {
                        y: "40vh",
                        x: 800,
                     }
                     : window.innerHeight < 760
                        ? {
                           y: "50vh",
                           x: 800,
                        }
                        : {
                           y: "55vh",
                           x: 800,
                        }
               }
            />
            <div className="button-container">
               <Button
                  type="primary"
                  htmlType="submit"
                  loading={posting}
               >
                  Submit
               </Button>
            </div>
         </Form>
      </div>
   );
};

export default AddEmployeeBatch;
