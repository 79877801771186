import { Form, message, Modal, Select } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useHolidayList } from "hooks/holiday/useHolidayList";
import React, { useEffect, useState } from "react";

export default function CreateWeekend({ isOpen, isClose }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [holidayId, setHolidayId] = useState(null);
  const [newData, setNewData] = useState({});
  const [foundationId, setFoundationId] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const { REACT_APP_HOST_API } = process.env;
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.includes("super_admin");
  const { data, refetch } = useHolidayList(foundationId, true);

  const { data: dataFoundation } = useFoundationList();

  const onCancel = () => {
    form.resetFields();
    setFoundationId("");
    isClose();
    setIsDisabled(true);
  };

  const setValues = () => {
    setHolidayId(data?.data[0]?.id);
    form.setFieldsValue({
      day: data?.data[0]?.holiday_days,
    });
    setIsDisabled(false);
  };

  useEffect(() => {
    if (isOpen && data) {
      setNewData({});

      if (isSuperAdmin ? foundationId : true) {
        setValues();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, foundationId, data]);

  const daysOfWeek = [
    { label: "Senin", value: "1" },
    { label: "Selasa", value: "2" },
    { label: "Rabu", value: "3" },
    { label: "Kamis", value: "4" },
    { label: "Jumat", value: "5" },
    { label: "Sabtu", value: "6" },
    { label: "Minggu", value: "0" },
  ];

  const onSubmit = async () => {
    const { day } = await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Nothing has changed");
      return;
    }

    setIsLoading(true);
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/academics/holidays/${holidayId}`,
        { holiday_days: day },
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );

      message.success("Berhasil mengubah data");
      form.resetFields();
      refetch();
      onCancel();
    } catch (error) {
      console.log(error);
      Modal.error({
        title: "Gagal Mengubah Data",
        content: <pre>{error?.response?.data?.message}</pre>,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      onOk={isDisabled ? null : onSubmit}
      okText="Simpan"
      cancelText="Batal"
      closable={false}
      confirmLoading={isLoading}
    >
      <Form form={form} layout="vertical">
        {isSuperAdmin && (
          <Form.Item label="Pilih Yayasan" name="foundationId">
            <Select
              placeholder="Pilih Yayasan"
              style={{ minWidth: 150 }}
              onChange={(x) => setFoundationId(x)}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option
                  key={x.id}
                  value={x.id}
                  className="select-option-foundation"
                >
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item label="Pilih Hari Libur Pekanan" name="day">
          <Select
            disabled={isDisabled}
            onChange={(e) => setNewData({ ...newData, holiday_days: e })}
            mode="multiple"
            options={daysOfWeek}
            placeholder="Pilih hari"
            maxTagCount="responsive"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
