import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, InputNumber, message, Modal, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { renderModalError } from "components/UI/ErrorModal";
import React, { useEffect, useState } from "react";

export default function EditWorkUnit({ open, setOpen, data }) {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const [deleteImages, setDeleteImages] = useState([]);

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        name: data?.name,
        description: data?.description,
        logo: null,
        link: data?.link,
        order: data?.order,
      });
      if (data?.logo) {
        setFileList([
          {
            uid: "-1",
            name: data?.name,
            status: "done",
            url: data?.logo,
          },
        ]);
      }
    }

    return () => setNewData({});
    //eslint-disable-next-line
  }, [open, data]);

  // upload image
  const isImage = (file) => {
    return (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    );
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // Set the preview property if it's not already set
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    // Show preview modal
    Modal.info({
      title: file.name,
      content: (
        <img
          alt="preview"
          style={{ width: "100%" }}
          src={file.url || file.preview}
        />
      ),
    });
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
    setNewData({ ...newData, logo: null });
    setDeleteImages([...deleteImages, file?.name]);
    form.setFieldsValue({ logo: null });
  };

  const beforeUpload = (file) => {
    if (!isImage(file)) {
      message.error("hanya bisa upload file gambar (.jpeg, .jpg, .png)");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("gambar ukurannya harus kurang dari 2MB!");
      return false;
    }
    if (!isImage(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isImage(file) ? [file] : []);
    return false;
  };

  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const isLt2M = newFileList[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return;
      }
    }
    setFileList(newFileList);
    setNewData({ ...newData, logo: newFileList[0]?.originFileObj || null });
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    fileList,
    onChange: handleChange,
  };

  // end upload image

  const onSubmit = async () => {
    if (newData.logo === null) {
      form.setFieldsValue({ logo: null });
    }
    await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Tidak ada yang berubah");
      return;
    }

    for (const key in newData) {
      if (Object.hasOwnProperty.call(newData, key)) {
        const element = newData[key];
        if (element === "") newData[key] = null;
      }
    }
    // if (deleteImages.length > 0 && !newData.logo) {
    //   newData.deleteImage = data?.data?.file_image;
    // }
    if (fileList.length === 0) {
      alert("Logo tidak boleh kosong");
      return;
    }

    setLoading(true);
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/landing-page/work-units/${data?.id}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("Berhasil menyimpan data");
      onCancel();
    } catch (error) {
      console.log(error);
      renderModalError("Error edit work unit", error);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setFileList([]);
    setOpen();
  };

  return (
    <Modal
      confirmLoading={loading}
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
      okText="Simpan"
      cancelText="Batal"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="logo"
          label="Logo"
          rules={[
            {
              required: fileList.length === 0,
            },
            {
              validator: (_, value) => {
                const file = value?.fileList[0];
                if (!file) {
                  return Promise.resolve();
                }
                if (!isImage(file)) {
                  return Promise.reject(
                    new Error("Please upload an image file")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload
            name="logo"
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            showUploadList={true}
            onPreview={handlePreview}
            {...propsUpload}
            disabled={loading}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                {fileList.length === 0 ? (
                  <span style={{ fontSize: "12px" }}>
                    Upload <br />
                    (max 2 mb)
                  </span>
                ) : (
                  "Ganti"
                )}
                {fileList.length === 0 ? "" : ""}
              </div>
            </div>
          </Upload>
        </Form.Item>

        <Form.Item name="name" label="Nama" rules={[{ required: true }]}>
          <Input onChange={(e) => (newData["name"] = e.target.value)} />
        </Form.Item>

        <Form.Item
          name="description"
          label="Deskripsi"
          rules={[{ required: true }]}
        >
          <TextArea
            rows={4}
            onChange={(e) => (newData["description"] = e.target.value)}
          />
        </Form.Item>

        <Form.Item name="link" label="Link">
          <Input onChange={(e) => (newData["link"] = e.target.value)} />
        </Form.Item>

        <Form.Item name="order" label="Order">
          <InputNumber style={{ width: "100%" }}
            onChange={(e) => newData.order = e}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
