import {
  Button,
  DatePicker,
  Form,
  message,
  Modal,
  Radio,
  Select,
  Space,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useAssetStatusPagination } from "hooks/asset-hook/useAssetStatusPagination";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import { useStudentList } from "hooks/students-hook/useStudentList";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { InventoryContext } from "../inventory-context/InventoryContext";
import "./AddInventory.css";
import dayjs from "dayjs";

const AddInventory = ({ show, onCreate, onCancel, isInternal, dataDetail }) => {
  const [form] = Form.useForm();
  const [borrowerTypeStatus, setBorrowerTypeStatus] = useState("student");
  const [fetchStatus, setFetchStatus] = useState(false);
  const [foundationId, setFoundationId] = useState("");
  const [unitId, setUnitId] = useState("");

  const assetRef = useRef();
  const borrowerRef = useRef();
  const { batch_id } = useParams();
  const { REACT_APP_HOST_API } = process.env;
  const { setInventoryFetch } = useContext(InventoryContext);
  const roles = decryptCookies("role").split(",");

  useEffect(() => {
    if (assetRef.current) {
      assetRef.current.focus();
    }
  }, [show]);

  const params = {
    dataTable: { current_page: 1, per_page: 500 },
    keyword: "",
    statusId: "available",
  };

  const { data, refetch: fetchAssets } = useAssetStatusPagination(params);
  const { data: dataStudent, refetch: fetchStudent } =
    useStudentList(foundationId);
  const { data: dataEmployee, refetch: fetchEmployee } = useEmployeeList(
    "",
    "",
    "",
    "",
    foundationId,
    "",
    unitId
  );

  useEffect(() => {
    if (show && dataDetail) {
      fetchAssets();
      const { foundation_id, unit_id } = dataDetail;
      setFoundationId(foundation_id);
      setUnitId(unit_id);
    }
    return () => setFetchStatus(false);
  }, [show, fetchStatus, fetchAssets, dataDetail]);

  useEffect(() => {
    if (dataDetail) {
      fetchStudent();
      fetchEmployee();
    }
    // eslint-disable-next-line
  }, [foundationId, unitId]);

  const handleSubmit = async () => {
    try {
      const fieldsValue = await form.validateFields();

      const payload = {
        ...(!isInternal ? { borrower: dataDetail.pic } : {}),
        ...(isInternal && roles.includes("super_admin")
          ? { foundationId: dataDetail.foundation_id }
          : {}),
        ...(isInternal ? { unitId: dataDetail.unit_id } : {}),
        ...fieldsValue,
        description: dataDetail.description,
        borrowerType: isInternal ? "internal" : "external",
        assetLoanBatchId: batch_id,
        startDate: fieldsValue.startDate?.format("YYYY-MM-DD HH:mm:ss"),
        endDate: fieldsValue.endDate?.format("YYYY-MM-DD HH:mm:ss"),
      };

      const response = await axios.post(
        `${REACT_APP_HOST_API}/inventories/asset-loans`,
        payload,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );

      message.success(response.data.message);
      form.resetFields();
      setFetchStatus(true);
      setInventoryFetch(true);
      onCreate();
    } catch (err) {
      Modal.error({
        title: "Gagal Menyimpan",
        content: <pre>{err?.response?.data?.message}</pre>,
      });
    }
  };

  const handleEnterBorrower = (param) => {
    if (param.key === "Enter") handleSubmit();
    else return;
  };

  return (
    <div
      style={
        show
          ? {
              display: "block",
              marginTop: 30,
            }
          : { display: "none" }
      }
    >
      <div className="table-header">
        <h1>Tambah Peminjaman Inventaris</h1>
      </div>
      <Form
        form={form}
        initialValues={{
          // startDate: moment(new Date().toISOString()),
          borrower_type_status: borrowerTypeStatus
            ? borrowerTypeStatus
            : "student",
        }}
      >
        <table className="table">
          <tbody>
            <tr>
              <td>Tanggal Pinjam</td>
              <td>
                <Form.Item
                  name="startDate"
                  initialValue={dayjs(
                    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                  )}
                >
                  <DatePicker showTime />
                </Form.Item>
              </td>
            </tr>
            <tr>
              <td>Tanggal Kembali</td>
              <td>
                <Form.Item name="endDate">
                  <DatePicker showTime />
                </Form.Item>
              </td>
            </tr>
            {isInternal && (
              <tr>
                <td>Tipe Peminjam</td>
                <td>
                  <Form.Item name="borrower_type_status">
                    <Radio.Group
                      onChange={({ target: { value } }) => {
                        setBorrowerTypeStatus(value);
                        assetRef.current.focus();
                      }}
                    >
                      <Radio value="student">Siswa</Radio>
                      <Radio value="teacher">Guru</Radio>
                    </Radio.Group>
                  </Form.Item>
                </td>
              </tr>
            )}
            <tr>
              <td>Nama Inventaris</td>
              <td>
                <Form.Item
                  name="assetId"
                  rules={[{ required: true, message: "Harap diisi" }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    autoFocus
                    ref={assetRef}
                    onChange={() => {
                      borrowerRef?.current?.focus();
                    }}
                  >
                    {data?.data.data.map((data) => (
                      <Select.Option key={data.id} value={data.id}>
                        {data.serial}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </td>
            </tr>
            {isInternal && (
              <tr>
                <td>Peminjam</td>
                {borrowerTypeStatus === "teacher" && (
                  <td>
                    <Form.Item name="employeeId">
                      <Select
                        onInputKeyDown={handleEnterBorrower}
                        ref={borrowerRef}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return (
                            option.children[0].props.children
                              .toLowerCase()
                              .includes(input.toLowerCase()) ||
                            option.children[1].props.children === input
                          );
                        }}
                      >
                        {dataEmployee?.data.map((data) => (
                          <Select.Option key={data.id} value={data.id}>
                            <span>{data.name}</span>
                            <span className="transparent-rfid">
                              {data.rfid}
                            </span>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </td>
                )}
                {borrowerTypeStatus === "student" && (
                  <td>
                    <Form.Item name="studentId">
                      <Select
                        onInputKeyDown={handleEnterBorrower}
                        ref={borrowerRef}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {dataStudent?.data.data.map((data) => (
                          <Select.Option key={data.id} value={data.id}>
                            {`${data.name}  - ${data?.class?.name}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </td>
                )}
              </tr>
            )}
            {!isInternal && (
              <tr>
                <td>Peminjam</td>
                <td>{dataDetail?.pic}</td>
              </tr>
            )}
            <tr>
              <td>Deskripsi</td>
              <td>{dataDetail?.description}</td>
            </tr>
          </tbody>
        </table>
      </Form>
      <br />
      <Space>
        <Button
          className="button-green"
          onClick={() => {
            form.resetFields();
            onCancel();
          }}
        >
          Tutup
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          Simpan
        </Button>
      </Space>
    </div>
  );
};

export default AddInventory;
