import { DatePicker, Form, Input, InputNumber, Modal, Select } from "antd";
import React, { useMemo } from "react";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useState } from "react";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useKodeKeuanganList } from "hooks/finance-report-hook/useKodeKeuanganList";
import { renderModalError } from "pages/si-finance-report/utlis/utlis";

const CreateTransitData = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [foundationId, setFoundationId] = useState('');
  const { REACT_APP_HOST_API } = process.env;

  // get roles
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  // get foundation list
  const { data: dataFoundation } = useFoundationList();

  // get divisi and juknis list
  const {
    data: dataUnitDivisiJuknis
  } = useKodeKeuanganList(foundationId);

  const dataUnit = useMemo(() => dataUnitDivisiJuknis?.data?.data.filter((item) => item.type === "unit"), [dataUnitDivisiJuknis?.data?.data]);

  const dataDivisi = useMemo(() => dataUnitDivisiJuknis?.data?.data.filter((item) => item.type === "divisi" || "unit"), [dataUnitDivisiJuknis?.data?.data]);

  const dataJuknis = useMemo(() => dataUnitDivisiJuknis?.data?.data.filter((item) => item.type === "juknis"), [dataUnitDivisiJuknis?.data?.data]);

  const handleCreateKodeKeuangan = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      if (!isSuperAdmin) {
        values.foundationId = decryptCookies("foundationId");
      }

      values.date = values.date.format("YYYY-MM-DD");

      const { data } = await axios.post(
        `${REACT_APP_HOST_API}/financial-report/transit-data`,
        { ...values },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      message.success(data?.message, 2);
      props.onCreate();
      form.resetFields();
    } catch (error) {
      renderModalError("Error : Tambah Transit Data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setFoundationId('');
    props.onCancel();
  };



  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      open={props.show}
      onCancel={handleCancel}
      onOk={handleCreateKodeKeuangan}
      okButtonProps={{ loading }}
      title="Tambah Transit Data"
    >
      <Form form={form} layout="vertical">
        {(isSuperAdmin) &&
          <Form.Item
            name="foundationId"
            label="Foundation"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder="Pilih foundation"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                setFoundationId(value);
                form.resetFields(["codeUnitId", "codeDivisiId", "codeJuknisId"]);
              }}
            >
              {dataFoundation?.data?.data?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        <Form.Item
          label="Asal Data"
          name="dataOrigin"
          rules={[
            {
              required: true,
              message: "Kode harus diisi!",
            },
          ]}
        >
          <Input placeholder="input asal data" />
        </Form.Item>
        {/* bulan dan tahun  */}
        <Form.Item
          label="Tanggal"
          name="date"
          rules={[
            {
              required: true,
              message: "Bulan harus diisi!",
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="codeUnitId"
          label="Unit"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Pilih Unit"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            disabled={foundationId === '' && isSuperAdmin}
          >
            {dataUnit?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {`${item.code} - ${item.name}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="codeDivisiId"
          label="Divisi"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Pilih Divisi"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            disabled={foundationId === '' && isSuperAdmin}
          >
            {dataDivisi?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {`${item.code} - ${item.name}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="codeJuknisId"
          label="Juknis"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Pilih Juknis"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            disabled={foundationId === '' && isSuperAdmin}
          >
            {dataJuknis?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {`${item.code} - ${item.name}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Jumlah Biaya"
          name="amount"
          rules={[
            {
              required: true,
              message: "Jumlah Biaya harus diisi!",
            },
          ]}
        >
          <InputNumber
            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            parser={(value) => value.replace(/Rp\s?|(\.*)/g, '')}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Keterangan"
          name="description"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default React.memo(CreateTransitData);
