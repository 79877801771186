import { Modal, Typography } from "antd";
import React, { useState } from 'react'

const { Paragraph, Link } = Typography;

const ReasonCell = ({ text, title = "" }) => {
   const [isEllipsis, setIsEllipsis] = useState(false);

   const showModal = () => {
      Modal.info({
         title: title === '' ? 'Detail' : title,
         content: (
            <p>{text}</p>
         ),
      });
   };

   const handleEllipsis = (ellipsis) => {
      setIsEllipsis(ellipsis);
   };

   return (
      <div style={{ width: "90px", whiteSpace: "normal" }}>
         <Paragraph
            ellipsis={{
               rows: 3,
               expandable: false,
               suffix: isEllipsis ? <Link onClick={showModal}>detail</Link> : null,
               onEllipsis: handleEllipsis
            }}
         >
            {text}
         </Paragraph>
      </div>
   );
}

export default ReasonCell