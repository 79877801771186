import { Button, Form, Input, message, Modal, Select } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useLinkGroupDetail } from "hooks/link-hook/group/useLinkGroupDetail";
import { usePermission } from "hooks/usePermissions";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function EditGroup() {
  const [foundationId, setFoundationId] = useState("");
  const [newData, setNewData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { group_id } = useParams();
  const roles = decryptCookies("role").split(",");
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;
  const navigate = useNavigate();

  //fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  //fetch employee list
  const { data: dataEmployee, refetch } = useEmployeeList(
    "",
    "",
    "",
    "",
    foundationId
  );

  //fetch link group detail
  const { data: dataLinkGroupDetail } = useLinkGroupDetail(group_id, true);

  // fetch  permission
  const { data: dataPermission } = usePermission("mdlLink", "mnuLinkGroup");

  // Buttons Permission start
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditLinkGroup");

  useEffect(() => {
    if (dataLinkGroupDetail) {
      form.setFieldsValue({
        name: dataLinkGroupDetail?.data?.name,
        description: dataLinkGroupDetail?.data?.description,
        employee_id: dataLinkGroupDetail?.data?.owner?.id,
        foundation_id: dataLinkGroupDetail?.data?.foundation_id,
      });
    }
    // eslint-disable-next-line
  }, [dataLinkGroupDetail]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [foundationId]);

  const onSubmit = async () => {
    await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Nothing has changed");
      return;
    }
    setIsLoading(true);
    try {
      await axios.put(
        REACT_APP_HOST_API + `/links/link-groups/${group_id}`,
        newData,
        {
          headers: {
            Authorization: `Bearer ${decryptCookies("token")}`,
          },
        }
      );
      message.success("Berhasil mengubah group");
      form.resetFields();
      navigate(-1);
    } catch (error) {
      console.log(error);
      Modal.error({
        title: "Gagal mengubah group",
        content: <pre>{error?.response?.data?.message}</pre>,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    form.resetFields();
    navigate(-1);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form form={form} layout="vertical" className="w-full md:w-1/2">
        {roles.some((x) => x === "super_admin") && (
          <Form.Item
            label="Foundation"
            name="foundation_id"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Pilih Yayasan"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e) => {
                setFoundationId(e);
                setNewData({ ...newData, foundation_id: e });
                form.resetFields(["employee_id"]);
              }}
            >
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {roles.some((x) => x === "super_admin") && (
          <Form.Item
            label="Owner"
            name="employee_id"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e) => setNewData({ ...newData, employee_id: e })}
            >
              {dataEmployee?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item label="Nama" name="name" rules={[{ required: true }]}>
          <Input
            onChange={(e) => setNewData({ ...newData, name: e.target.value })}
          />
        </Form.Item>

        <Form.Item
          label="Deskripsi"
          name="description"
          rules={[{ required: true }]}
        >
          <Input
            onChange={(e) =>
              setNewData({ ...newData, description: e.target.value })
            }
          />
        </Form.Item>

        <div
          style={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            hidden={!btnEdit}
            disabled={btnEdit?.type === "disabled"}
            className="w-full md:w-1/2"
            loading={isLoading}
            type="primary"
            onClick={onSubmit}
          >
            Simpan
          </Button>

          <Button
            className="w-full md:w-1/2"
            type="primary"
            ghost
            onClick={onCancel}
          >
            Batal
          </Button>
        </div>
      </Form>
    </div>
  );
}
