import { Button, message, Popconfirm, Space, Table } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import moment from "moment/moment";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InventoryContext } from "../inventory-context/InventoryContext";
import AddInventory from "./AddInventory";

const InventariesLoan = ({
  showDelete,
  showAddInventory,
  onAddInventory,
  onCancelAddInventory,
  isInternal,
  dataDetail,
}) => {
  const [loading, setLoading] = useState(false);
  const [dataAssets, setDataAssets] = useState([]);
  const [dataLoanBatch, setDataLoanBatch] = useState([]);
  const [fetchStatus, setFetchStatus] = useState(false);

  const { setInventoryFetch } = useContext(InventoryContext);
  const { REACT_APP_HOST_API } = process.env;
  const { batch_id } = useParams();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API + `/inventories/asset-loan-batches/${batch_id}`,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        );

        setDataAssets(data.data.assetLoan);
      } catch (error) {
        alert(`Cannot get data Batch
${error.message}`);
      }
    };

    fetchData();

    const fetchAllData = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API +
            `/inventories/asset-loan-batches?page=1&limit=10&keyword=`,
          {
            headers: { Authorization: "Bearer " + decryptCookies("token") },
          }
        );
        const resultData = data.data.data.find((x) => x.id === batch_id);
        setDataLoanBatch(resultData);
      } catch (error) {
        alert("Cannot get data loan batch");
      }
    };

    fetchAllData();

    return () => {
      cancelToken.cancel();
      setFetchStatus(false);
    };
  }, [batch_id, REACT_APP_HOST_API, fetchStatus]);

  const handleReturnAsset = async (record) => {
    const { asset_loan_batch_id, id, end_date } = record;

    if (end_date !== null) {
      alert("Asset Telah dikembalikan");
      return;
    }

    setLoading(true);

    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + `/inventories/asset-loans/${id}`,
        {
          assetLoanBatchId: asset_loan_batch_id,
          endDate: moment(new Date().toISOString()).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
        },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      setFetchStatus(true);
      setInventoryFetch(true);
    } catch (error) {
      alert(error.message);
    }
    setLoading(false);
  };

  const handleReturnAllAsset = async () => {
    if (dataLoanBatch?.not_returned === "0") {
      alert("Semua asset telah dikembalikan");
      return;
    }

    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + `/inventories/asset-loan-batches/${batch_id}`,
        {
          returnAll: true,
          endDate: moment(new Date().toISOString()).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
        },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      setFetchStatus(true);
      setInventoryFetch(true);
    } catch (error) {
      alert(error.message);
    }
  };

  const handleDeleteAssetLoan = async (id) => {
    try {
      const { data } = await axios.delete(
        REACT_APP_HOST_API + `/inventories/asset-loans/${id}`,
        {
          headers: { Authorization: "Bearer " + decryptCookies("token") },
        }
      );
      message.success(data.message);
      setFetchStatus(true);
      setInventoryFetch(true);
    } catch (error) {
      alert(error.message);
    }
  };

  const columns2 = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 70 : 50,
    },
    { title: "Aset", dataIndex: "asset", align: "left" },
    { title: "Peminjam", dataIndex: "borrower", align: "left" },
    { title: "Waktu Mulai", dataIndex: "start_date", align: "left" },
    { title: "Waktu Selesai", dataIndex: "end_date", align: "left" },
    {
      title: "Aksi",
      dataIndex: "asset_loan_batch_id",
      align: "left",
      render: (_, record) => (
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() => handleDeleteAssetLoan(record.id)}
          >
            <Button
              type="primary"
              danger
              size="small"
              shape="round"
              hidden={!showDelete}
            >
              Hapus
            </Button>
          </Popconfirm>
          <Button
            shape="round"
            size="small"
            className="button-green"
            hidden={record.end_date}
            loading={loading}
            onClick={() => {
              handleReturnAsset(record);
            }}
          >
            Kembalikan
          </Button>
        </Space>
      ),
    },
  ];

  const dataTable2 = dataAssets
    ?.sort((a, b) => {
      if (a.end_date && !b.end_date) return 1;
      if (b.end_date && !a.end_date) return -1;

      return a.end_date - b.end_date;
    })
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
        start_date:
          data.start_date &&
          `${data.start_date.split("T")[0]} ${
            data.start_date.split("T")[1].split(".")[0]
          } `,
        end_date:
          data.end_date &&
          `${data.end_date.split("T")[0]} ${
            data.end_date.split("T")[1].split(".")[0]
          } `,
        borrower: data.employee
          ? data?.employee?.name
          : data.student
          ? data?.student?.name
          : data.borrower,
        asset: data.asset && data.asset.serial,
      };
    });

  const onCreate = () => {
    setFetchStatus(true);
    setFetchStatus(true);
  };

  const onCancel = () => {
    onCancelAddInventory();
  };

  return (
    <>
      <AddInventory
        show={showAddInventory}
        onCreate={onCreate}
        onCancel={onCancel}
        isInternal={isInternal}
        dataDetail={dataDetail}
      />
      <div
        style={{
          marginTop: 30,
        }}
      >
        <div className="table-header">
          <h1>Daftar Inventaris yang Dipinjam</h1>
          <Space>
            <Popconfirm
              title="Yakin ingin mengembalikan semua ?"
              okText="Kembalikan"
              cancelText="Batal"
              onConfirm={handleReturnAllAsset}
            >
              <Button
                type="primary"
                hidden={dataLoanBatch?.not_returned === "0"}
              >
                Kembalikan Semua
              </Button>
            </Popconfirm>
            <Button
              type="primary"
              onClick={onAddInventory}
              hidden={showAddInventory}
            >
              Tambah
            </Button>
          </Space>
        </div>
        <Table
          size="small"
          tableLayout="auto"
          dataSource={dataTable2}
          columns={columns2}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 100,
            pageSizeOptions: [100, 200, 300],
          }}
          rowClassName={(record) =>
            record.end_date === null ? "table-pink" : ""
          }
        />
      </div>
    </>
  );
};

export default InventariesLoan;
