import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useRecapDaily = (
  dataTable,
  recap,
  dateIn,
  dateOut,
  keyName,
  classId,
  foundationId,
  missingOnly,
  enabled
) => {
  return useQuery(
    [
      "get-daily-attandce",
      dataTable.current_page,
      dataTable.per_page,
      recap,
      dateIn,
      dateOut,
      classId,
      foundationId,
      keyName,
      missingOnly,
    ],
    () =>
      GetPagination(
        `/academics/daily-attendances?page=${dataTable.current_page}&limit=${dataTable.per_page}&mode=page&fromDate=${dateIn}&toDate=${dateOut}&recap=${recap}&keyword=${keyName}&classId=${classId}&foundationId=${foundationId}&missingOnly=${missingOnly}`
      ),
    {
      enabled,
    }
  );
};
