import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Select, Space, Table, Tooltip, Switch } from "antd";
import { Popconfirm, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { usePermission } from "hooks/usePermissions";
import { useCallback, useState } from "react";
import CreatePendapatan from "../create/CreatePendapatan";
import EditTransitData from "../edit/EditPendapatan";
import ImportTransitData from "../import/ImportPendapatan";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { decryptCookies } from "components/Helper/CookiesHelper";
import './Pendapatan.css';
import { useTransitDataPagination } from "hooks/finance-report-hook/useTransitDataPagination";
import { useKodeKeuanganPagination } from "hooks/finance-report-hook/useKodeKeuanganPagination";
import dayjs from "dayjs";
import { numberFormat } from "pages/si-finance-report/utlis/utlis";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const Pendapatan = () => {
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(dayjs());
  const [showEdit, setShowUpdate] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [foundationId, setFoundationId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [showNull, setShowNull] = useState(false);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });

  const [form] = Form.useForm();

  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  // fetch finance code list

  const {
    data: dataKodeUnit,
    isLoading: isLoadingKodeUnit,
    isFetching: isFetchingKodeUnit,
  } = useKodeKeuanganPagination(
    {
      current_page: 1,
      per_page: 1000,
      total: 0,
    },
    "",
    foundationId,
    "unit");

  const {
    data: dataTransit,
    isLoading,
    isFetching,
    refetch,
  } = useTransitDataPagination(dataTable, keyword, foundationId, date.format('YYYY-MM'), unitId, showNull);

  // fetch permission
  const { data: dataPermission } = usePermission("mdlFinanceReport", "mnuTransitData");

  const onCreate = useCallback(() => {
    setShowCreate(false);
    refetch();
  }, [refetch]);

  const onImport = useCallback(() => {
    setShowImport(false);
    refetch();
  }, [refetch]);

  const onUpdate = useCallback(() => {
    setShowUpdate(false);
    refetch();
  }, [refetch]);

  const onCancel = () => {
    setShowCreate(false);
    setShowUpdate(false);
    setShowImport(false);
  };

  // reset page to 1 when search
  const resetPage = () => {
    setDataTable((value) => {
      return {
        ...value,
        current_page: 1,
      };
    });
  };

  const handleChange = (param) => {
    resetPage();
    setKeyword(param.target.value);
  };

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddTransitData");
  const btnImport = dataPermission?.find((x) => x.id === "btnImportTransitData");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditTransitData");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteTransitData");

  const dataDummy = [
    {
      "id": "19e62870-df44-4698-bd34-bbc8ed7bfdd3",
      "date": "2024-08-01",
      "va": "1232223033",
      "amount": 997000,
      "nominal": 1000000,
      "code_unit_id": "0fcfc210-338f-45de-9723-dce332a0929e",
      "foundation_id": "c1d3e93b-1774-4682-9a81-c1915742c8e2",
      "created_at": "2024-08-12T07:39:21.269+07:00",
      "updated_at": "2024-08-12T07:39:21.269+07:00",
      "foundation": {
        "name": "FG PUTRA Edited",
        "id": "c1d3e93b-1774-4682-9a81-c1915742c8e2"
      },
      "codeUnit": {
        "id": "0fcfc210-338f-45de-9723-dce332a0929e",
        "name": "SMA Future Gate",
        "code": "1200"
      }
    }
  ]

  const dataSource = dataDummy
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        index: index + 1,
        key: data.id,
        unit: `${data?.codeUnit?.code} - ${data?.codeUnit?.name}`,
        foundationName: data?.foundation?.name,
      };
    });


  const columns = [
    {
      title: "No",
      dataIndex: "index",
      width: 50,
      fixed: "left",
      responsive: ["sm"],
    },
    {
      title: "Tanggal", dataIndex: "date",
      width: 120,
      render: (date) => {
        return dayjs(date).format("DD-MM-YYYY")
      }
    },
    {
      title: "VA", dataIndex: "va",
      width: 100,
    },
    {
      title: "Jumlah", dataIndex: "amount",
      render: (amount) => {
        return numberFormat(amount)
      },
    },
    {
      title: "Yayasan", dataIndex: "foundationName",
      hidden: !roles.some((x) => x === "super_admin"),
      width: 150,
    },
    {
      title: "Unit", dataIndex: "unit",
      width: 150,
    },
    {
      title: "Nominal",
      dataIndex: "nominal",
      width: 150,
      render: (amount) => {
        return numberFormat(amount)
      },
      sorter: (a, b) => {
        return a.amount - b.amount
      },
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      width: 130,
      render: (id) => {
        return (
          <>
            <Popconfirm
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({ url: "/financial-report/transit-data/", dataId: id, refetch })
              }
              title="Yakin ingin menghapus ?"
              disabled={btnDelete && btnDelete.type === "disabled"}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  setShowUpdate(true);
                  setDataId(id);
                }
              }}
            >
              Ubah
            </Tag>
          </>
        );
      },
    },
  ].filter((x) => !x.hidden);

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    defaultPageSize: dataTable.per_page,
    total: dataTransit?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) =>
      setDataTable((value) => {
        return {
          ...value,
          current_page: curr,
          per_page: size,
        };
      }),
  };
  return (
    <>
      <div className="table-header">
        <h1>Pendapatan</h1>
        <Space>
          <Button
            hidden={!btnAdd}
            onClick={() => setShowCreate(true)}
            // disabled={btnAdd && btnAdd.type === "disabled"}
            disabled={true}
          >
            Tambah
          </Button>
          <Button
            type="primary"
            hidden={!btnImport}
            onClick={() => setShowImport(true)}
            // disabled={btnImport && btnImport.type === "disabled"}
            disabled={true}
          >
            Import
          </Button>
        </Space>
      </div>
      <Form className="filter-pendapatan" form={form}
        layout="inline"
      >
        <Form.Item
          style={{
            width: "100%",
          }}
        >
          <Input
            value={keyword}
            onChange={handleChange}
            prefix={<SearchOutlined />}
            placeholder="cari berdasarkan VA"
          />
        </Form.Item>
        <Form.Item
          style={{
            width: "100%",
          }}
        >
          <Tooltip title="Bulan - Tahun">
            <DatePicker
              picker="month"
              value={date}
              onChange={(x) => {
                resetPage()
                setDate(x)
              }}
              allowClear={false}
            />
          </Tooltip>
        </Form.Item>
        {roles.some((x) => x === "super_admin") && (
          <Form.Item
            style={{
              width: "100%",
            }}
          >
            <Tooltip Tooltip title="Yayasan">
              <Select
                placeholder="Pilih Yayasan"
                style={{ minWidth: 150 }}
                onChange={(x) => {
                  setFoundationId(x)
                  setUnitId("")
                  form.resetFields(["unitId"])
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option className="select-option-foundation" value="">
                  ALL
                </Select.Option>
                {dataFoundation?.data?.data?.map((x) => (
                  <Select.Option
                    key={x.id}
                    value={x.id}
                    className="select-option-foundation"
                  >
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
          </Form.Item>
        )}
        <Form.Item name="unitId"
          style={{
            width: "100%",
          }}
        >
          <Tooltip Tooltip title="Unit">
            <Select
              placeholder="Pilih Unit"
              style={{ minWidth: 150 }}
              onChange={(_, data) => {
                setUnitId(data?.key)
              }}
              showSearch
              optionFilterProp="children"
              filterOption={
                (input, option) => option.value.toLowerCase().includes(input.toLowerCase())
              }
              disabled={isLoadingKodeUnit || isFetchingKodeUnit}
            >
              <Select.Option className="select-option-foundation" value="" key=""
              >
                ALL
              </Select.Option>
              {dataKodeUnit?.data?.data?.map((x) => (
                <Select.Option
                  key={x.id}
                  value={`${x.name}${isSuperAdmin && ` - ${x.foundation.name}`}`}
                  className="select-option-foundation"
                >
                  {x.name}{isSuperAdmin && ` - ${x.foundation.name}`}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        </Form.Item>
        {/* show null switch */}
        <Form.Item
          style={{
            width: "max-content",
          }}
        >
          <Tooltip title="Tampilkan data yang belum ada kode">
            <Switch
              checked={showNull}
              onChange={(checked) => {
                resetPage()
                setShowNull(checked)
              }}
            />
          </Tooltip>
        </Form.Item>
      </Form>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "40vh", x: 900 }
            : window.innerHeight < 760
              ? { y: "50vh", x: 1000 }
              : { y: "55vh", x: 1000 }
        }
      />
      <CreatePendapatan onCancel={onCancel} onCreate={onCreate} show={showCreate} />
      <ImportTransitData onCancel={onCancel} onImport={onImport} show={showImport} />
      <EditTransitData
        id={dataId}
        onCancel={onCancel}
        onUpdate={onUpdate}
        show={showEdit}
      />
    </>
  );
};

export default Pendapatan;
