import { Form, Popconfirm, Tag } from "antd";
import dayjs from "dayjs";
import { useState } from "react";

import "dayjs/locale/id"; // Import the Indonesian locale
import { useStudentsPerSubject } from "hooks/attandance-hook/useStudentsPerSubject";
import { useEffect } from "react";
import { useClassList } from "hooks/classes-hook/useClassList";
import { usePelajaranList } from "hooks/pelajaran-hook/usePelajaranList";
import { useSessionList } from "hooks/attandance-hook/useSession";
import * as XLSX from "xlsx";
import { DeleteApi } from "api/DeleteApi";
import { usePermission } from "hooks/usePermissions";
import { usePresenceContext } from "context/academic-context/PresencePerSubjectContext";
import useResetPage from "components/Helper/useResetPage";

dayjs.locale("id");

const StudentsPerSubjectLogic = () => {
  const [form] = Form.useForm();

  const [keyword, setKeyword] = useState("");

  //* get presencePerSubject Context ---
  const {
    isRecap,
    setIsRecap,
    dateIn,
    dateOut,
    setDateIn,
    setDateOut,
    className,
    setClassName,
    subject,
    setSubject,
    session,
    setSession,
  } = usePresenceContext();

  const [open, setOpen] = useState(false);
  const [dataId, setDataId] = useState("");

  const { data: dataClass, refetch: fetchClass } = useClassList(false);

  const { data: dataSession, refetch: fetchSession } = useSessionList();

  const { data: dataPelajaran, refetch: fetchPelajaran } = usePelajaranList();

  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });

  // fetch student leasson permission
  const { data: dataPermission } = usePermission(
    "mdlAcademic",
    "mnuStudentPresencePerSubject"
  );

  // Buttons Permission
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteLess");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditLess");

  const {
    data,
    isLoading,
    isFetching,
    refetch: fetchDaily,
  } = useStudentsPerSubject(
    dataTable,
    dateIn,
    dateOut,
    keyword,
    className,
    subject,
    session,
    isRecap,
    true
  );

  useEffect(() => {
    dataTable.current_page = 1;
    // eslint-disable-next-line
  }, [isRecap, dateIn, dateOut, className, subject, session]);

  useEffect(() => {
    fetchDaily();
    fetchClass();
    fetchPelajaran();
    fetchSession();
  }, [fetchClass, fetchDaily, fetchPelajaran, fetchSession, isRecap]);

  const statusMap = {
    present: { text: "Hadir", color: "green" },
    sick: { text: "Sakit", color: "orange" },
    permission: { text: "Izin", color: "purple" },
    absent: { text: "Alpa", color: "red" },
  };

  const getStatusTag = (status) => {
    const { text, color } = statusMap[status] || {
      text: status,
      color: "gray",
    };
    return <Tag color={color}>{text}</Tag>;
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      align: "left",
      width: window.innerWidth > 800 ? 60 : 50,
    },
    {
      title: "Waktu",
      dataIndex: "waktu",
      align: "left",
      hidden: isRecap === true,
    },
    {
      title: "Nama",
      dataIndex: "id",
      key: "id",
      align: "left",
      render: (id, data) => {
        return data?.student?.name;
      },
    },
    {
      title: "Kelas",
      dataIndex: "className",
      key: "class",
      align: "left",
    },
    {
      title: "Pelajaran",
      dataIndex: "subjectName",
      key: "subjectName",
      align: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "",
      align: "left",
      hidden: isRecap === true,
      render: (status) => getStatusTag(status),
    },
    {
      title: "Hadir",
      dataIndex: "present",
      key: "present",
      align: "left",
      render: (id, data) => {
        return `${data?.present} (${data?.present_precentage}%)`;
      },
      hidden: isRecap === false,
    },
    {
      title: "Sakit",
      dataIndex: "sick",
      key: "sick",
      align: "left",
      render: (id, data) => {
        return `${data?.sick} (${data?.sick_precentage}%)`;
      },
      hidden: isRecap === false,
    },
    {
      title: "Izin",
      dataIndex: "permission",
      key: "permission",
      align: "left",
      render: (id, data) => {
        return `${data?.permission} (${data?.permission_precentage}%)`;
      },
      hidden: isRecap === false,
    },
    {
      title: "Alpa",
      dataIndex: "absent",
      key: "absent",
      align: "left",
      render: (id, data) => {
        return `${data?.absent} (${data?.absent_precentage}%)`;
      },
      hidden: isRecap === false,
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      align: "left",
      width: 120,
      hidden: isRecap === true,
    },
    {
      title: "Action",
      width: 200,
      dataIndex: "id",
      key: "action",
      align: "center",
      hidden: isRecap === true,
      render: (id, data) => {
        return (
          <>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              disabled={btnDelete && btnDelete.type === "disabled"}
              cancelText="Batal"
              onConfirm={() => {
                const dataId = id;
                DeleteApi({
                  url: "/academics/lesson-attendances/",
                  dataId,
                  refetch: fetchDaily,
                });
              }}
            >
              <Tag
                color="red"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? { cursor: "not-allowed" }
                    : { cursor: "pointer" }
                }
              >
                Delete
              </Tag>
            </Popconfirm>

            <Tag
              color="orange"
              // hidden={!btnEdit || rowSelected.length !== 0}
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled"
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") {
                  return;
                } else {
                  handleEdit(id);
                }
              }}
            >
              Edit
            </Tag>
          </>
        );
      },
    },
  ];

  const filteredColumn = columns.filter((column) => {
    return !column.hidden;
  });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const customParseFormat = require("dayjs/plugin/customParseFormat");

  dayjs.extend(customParseFormat);

  const dataSources = data?.data?.data
    .slice(0, dataTable.per_page)
    .map((item, index) => {
      return {
        ...item,
        index: index + 1,
        // className: isRecap ? item?.class?.name : item?.student?.class?.name,
        className: item?.student?.class?.name,
        subjectName: item?.subject?.name,
        waktu: dayjs(item?.date, {
          customParseFormat: "YYYY-MM-DDTHH:mm:ss.SSSZ",
        }).format("DD MMMM YYYY, HH:mm"),
        key: index,
      };
    });

  const dataToExport = data?.data?.data.map((datas) => {
    let result;

    const statusRecap = {
      hadir: `${datas.present}  (${datas.present_precentage}%)`,
      sakit: `${datas.sick}  (${datas.sick_precentage}%)`,
      izin: `${datas.permission}  (${datas.permission_precentage}%)`,
      alpa: `${datas.absent}  (${datas.absent_precentage}%)`,
    };

    const status = () => {
      if (datas?.status === "present") {
        return "Hadir";
      } else if (datas?.status === "sick") {
        return "Sakit";
      } else if (datas?.status === "permission") {
        return "Izin";
      } else if (datas?.status === "absent") {
        return "Alpa";
      }
    };

    if (isRecap) {
      result = {
        nama: datas?.student?.name,
        kelas: datas?.student?.class?.name,
        pelajaran: datas?.subject?.name,
        ...statusRecap,
      };
    } else {
      result = {
        waktu: dayjs(datas?.date, {
          customParseFormat: "YYYY-MM-DDTHH:mm:ss.SSSZ",
        }).format("DD MMMM YYYY, HH:mm"),
        nama: datas?.student?.name,
        kelas: datas?.student?.class?.name,
        pelajaran: datas?.subject?.name,
        status: status(),
        deskripsi: datas?.description,
      };
    }

    return result;
  });

  const handleEdit = (id) => {
    setOpen(!open);
    setDataId(id);
    fetchDaily();
  };

  const handleExport = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(dataToExport);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(
      wb,
      `${isRecap ? "rekap" : "data"}_absensi_perpelajaran.xlsx`
    );
  };

  useResetPage(setDataTable, [
    keyword,
    isRecap,
    dateIn,
    dateOut,
    className,
    subject,
    session,
  ]);

  return {
    handleExport,
    dataSession,
    dataPelajaran,
    setSession,
    setSubject,
    setClassName,
    dataClass,
    setKeyword,
    filteredColumn,
    isRecap,
    setIsRecap,
    form,
    columns,
    data,
    isLoading,
    isFetching,
    dataSources,
    setDateIn,
    setDateOut,
    pagination,
    fetchDaily,
    open,
    handleEdit,
    dataId,
  };
};

export default StudentsPerSubjectLogic;
