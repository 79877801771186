import { DashboardOutlined, MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Dropdown, Layout, Drawer } from "antd";
import { default as LOGO, default as LogoFG } from "assets/img/logoFG.png";
import BreadCrumb from "components/UI/BreadCrumb";
import React from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import "./LayoutFoundation.css";
import NavMenus from "Layouts/NavMenus";
import { useNavigate } from "react-router-dom";
import { AppstoreFilled, LogoutOutlined } from "@ant-design/icons";
import Logout from "components/Auth/Logout";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import { usePermission } from "hooks/usePermissions";
import Cookies from "js-cookie";
import { useState } from "react";
import { FaBuilding, FaCodeBranch, FaCubes } from "react-icons/fa";
import DynamicTitle from "components/UI/DynamicTitle";

const { Sider, Content, Header } = Layout;

const LayoutFoundation = (props) => {
  const [collapsed, setCollapsed] = useState(
    window.innerWidth > 1200 ? false : true
  );
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const email = Cookies.get("email") && decryptCookies("email");

  const { data } = usePermission("mdlFoundation");

  const handleSelectMenu = (param) => {
    encryptCookies("defaultFoundationMenu", param);
  };

  const handleClickItemUser = (e) => {
    if (e.key === "profile") navigate("/profile");
  };

  const mnuFoundation = data?.find((x) => x.id === "mnuFoundation");
  const mnuUnit = data?.find((x) => x.id === "mnuUnitFoundation");
  const mnuDivision = data?.find((x) => x.id === "mnuDivisionFoundation");

  const itemsUser = [
    { key: "profile", label: <span>Profile</span> },
    { key: "logout", label: <Logout>Logout</Logout> },
  ];

  const items = [
    { key: "home", icon: <AppstoreFilled />, label: "Home" },
    {
      key: "mnuFoundationMaster",
      icon: <DashboardOutlined />,
      label: "Dashboard",
    },
    mnuFoundation && {
      key: "mnuFoundation",
      icon: <FaBuilding />,
      label: "Foundation",
    },
    mnuUnit && {
      key: "mnuUnitFoundation",
      icon: <FaCubes />,
      label: "Unit",
      disabled: mnuUnit?.type === "disabled",
    },
    mnuDivision && {
      key: "mnuDivisionFoundation",
      icon: <FaCodeBranch />,
      label: "Division",
      disabled: mnuDivision?.type === "disabled",
    },
  ];

  const items2 = [
    { key: "logout", icon: <LogoutOutlined />, label: <Logout>Logout</Logout> },
  ];

  const handleClickMenu = (param) => {
    if (param.key === "") {
      return;
    } else {
      handleSelectMenu(param.key);

      if (param.key === "logout") {
        return;
      } else if (param.key === "home") navigate("/home");
      else if (param.key === "mnuFoundationMaster") navigate("/foundation");
      else navigate("/foundation/" + param.key.toLowerCase().split("mnu")[1]);
    }

    setOpen(false);
  };

  return (
    <Layout>
      <DynamicTitle />
      <Drawer
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        width={250}
      >
        <div className="mobile-menu-wrapper">
          <NavMenus
            items={items}
            theme="light"
            items2={items2}
            handleClickMenu={handleClickMenu}
            defaultMenu={"defaultFoundationMenu"}
            defaultOpen={["Master"]}
          />
        </div>
      </Drawer>
      <div className="menu-mobile">
        <div onClick={() => navigate("/home")}>
          <img src={LogoFG} alt="logo fg" style={{ width: 22 }} />
          <h1 style={{ fontSize: "1.4em" }}>SMA FG</h1>
        </div>
        <MenuOutlined
          style={{ fontSize: "1.3em" }}
          onClick={() => setOpen(true)}
        />
      </div>
      <Sider width={250} trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <img src={LOGO} alt="fg" />
          <span>SMA FG</span>
        </div>

        <div className="sider-menu-wrapper">
          <NavMenus
            items={items}
            theme="dark"
            items2={items2}
            handleClickMenu={handleClickMenu}
            defaultMenu={"defaultFoundationMenu"}
            defaultOpen={["Master"]}
          />
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header>
          {React.createElement(HiOutlineMenuAlt2, {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          })}
          <div className="header-container">
            <Dropdown
              menu={{
                items: itemsUser,
                style: { width: "50%" },
                onClick: handleClickItemUser,
              }}
              placement="bottomLeft"
              arrow
              trigger={["click"]}
            >
              <div className="user-profile">
                <UserOutlined />
                <span>{email}</span>
              </div>
            </Dropdown>
          </div>
        </Header>

        <BreadCrumb />

        <Content className="site-layout-background">{props.content}</Content>
      </Layout>
    </Layout>
  );
};

export default LayoutFoundation;
