import { PlusOutlined } from "@ant-design/icons";
import RichTextEditor from "@mantine/rte";
import { Button, Form, Input, message, Modal, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useAboutDetail } from "hooks/landing-page-hook/useAboutDetail";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function EditAbout() {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const [deleteImages, setDeleteImages] = useState([]);

  const { REACT_APP_HOST_API } = process.env;
  const navigate = useNavigate();
  const { id } = useParams();

  const [valAbout, setValAbout] = useState("wkwkwk");
  const [valVision, setValVision] = useState("");
  const [valMission, setValMission] = useState("");

  const customToolbar = [
    ["bold", "italic", "underline"],
    ["unorderedList", "orderedList", "blockquote", "h1", "h2", "h3"],
    ["alignLeft", "alignCenter", "alignRight"],
  ];

  const [form] = Form.useForm();

  // fetch about detail
  const { data, refetch } = useAboutDetail(id, false);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        logo: null,
        email: data?.data?.email,
        phone: data?.data?.phone,
        address: data?.data?.address,
        about: data?.data?.about,
        mission: data?.data?.mission,
        vision: data?.data?.vision,
      });

      if (data?.data?.logo) {
        setFileList([
          {
            uid: "-1",
            name: "about",
            status: "done",
            url: data?.data?.logo,
          },
        ]);
      }
    }

    return () => setNewData({});
    // eslint-disable-next-line
  }, [data]);

  // upload image
  const isImage = (file) => {
    return (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    );
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // Set the preview property if it's not already set
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    // Show preview modal
    Modal.info({
      title: file.name,
      content: (
        <img
          alt="preview"
          style={{ width: "100%" }}
          src={file.url || file.preview}
        />
      ),
    });
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
    setNewData({ ...newData, logo: null });
    setDeleteImages([...deleteImages, file?.name]);
    form.setFieldsValue({ logo: null });
  };

  const beforeUpload = (file) => {
    if (!isImage(file)) {
      message.error("hanya bisa upload file gambar (.jpeg, .jpg, .png)");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("gambar ukurannya harus kurang dari 2MB!");
      return false;
    }
    if (!isImage(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isImage(file) ? [file] : []);
    return false;
  };

  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const isLt2M = newFileList[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return;
      }
    }
    setFileList(newFileList);
    setNewData({ ...newData, logo: newFileList[0]?.originFileObj || null });
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    fileList,
    onChange: handleChange,
  };

  // end upload image

  const onSubmit = async () => {
    if (newData.logo === null) {
      form.setFieldsValue({ logo: null });
    }

    await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Tidak ada yang berubah");
      return;
    }

    for (const key in newData) {
      if (Object.hasOwnProperty.call(newData, key)) {
        const element = newData[key];
        if (element === "") newData[key] = null;
      }
    }

    if (fileList.length === 0) {
      alert("Logo tidak boleh kosong");
      return;
    }

    setLoading(true);

    try {
      await axios.put(
        `${REACT_APP_HOST_API}/landing-page/abouts/${id}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("data tersimpan");
      form.resetFields();
      navigate(-1);
    } catch (error) {
      console.log(error);
      alert("gagal menyimpan data");
    } finally {
      setLoading(false);
    }
  };
  const resetRichText = (val, fieldName) => {
    if (val === "<p><br></p>") {
      form.resetFields([fieldName]);
    }
  };
  return (
    <div className="flex justify-center">
      <div className="w-full md:w-1/2 rte-wrapper">
        <Form form={form} layout="vertical">
          <Form.Item
            name="logo"
            label="Logo"
            rules={[
              {
                required: fileList.length === 0,
              },
              {
                validator: (_, value) => {
                  const file = value?.fileList[0];
                  if (!file) {
                    return Promise.resolve();
                  }
                  if (!isImage(file)) {
                    return Promise.reject(
                      new Error("Please upload an image file")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              name="logo"
              accept=".jpg,.jpeg,.png"
              listType="picture-card"
              showUploadList={true}
              onPreview={handlePreview}
              {...propsUpload}
              disabled={loading}
            >
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  {fileList.length === 0 ? (
                    <span style={{ fontSize: "12px" }}>
                      Upload <br />
                      (max 2 mb)
                    </span>
                  ) : (
                    "Ganti"
                  )}
                  {fileList.length === 0 ? "" : ""}
                </div>
              </div>
            </Upload>
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true },
              {
                type: "email",
                message: "Input harus berupa alamat email yang valid",
              },
            ]}
          >
            <Input onChange={(e) => (newData["email"] = e.target.value)} />
          </Form.Item>

          <Form.Item
            label="No. Telp"
            name="phone"
            rules={[
              { required: true },
              {
                pattern: new RegExp("^([0-9]*)$"),
                message: "input hanya berupa angka",
              },
            ]}
          >
            <Input onChange={(e) => (newData["phone"] = e.target.value)} />
          </Form.Item>

          <Form.Item label="Alamat" name="address" rules={[{ required: true }]}>
            <TextArea
              onChange={(e) => (newData["address"] = e.target.value)}
              rows={4}
            />
          </Form.Item>

          <Form.Item label="Tentang" name="about" rules={[{ required: true }]}>
            <RichTextEditor
              value={valAbout}
              onChange={(e) => {
                setValAbout(e);
                newData["about"] = e;
                resetRichText(e, "about");
              }}
              controls={customToolbar}
            />
          </Form.Item>

          <Form.Item label="Visi" name="vision" rules={[{ required: true }]}>
            <RichTextEditor
              value={valVision}
              onChange={(e) => {
                setValVision(e);
                newData["vision"] = e;
                resetRichText(e, "vision");
              }}
              controls={customToolbar}
            />
          </Form.Item>

          <Form.Item label="Misi" name="mission" rules={[{ required: true }]}>
            <RichTextEditor
              value={valMission}
              onChange={(e) => {
                setValMission(e);
                newData["mission"] = e;
                resetRichText(e, "mission");
              }}
              controls={customToolbar}
            />
          </Form.Item>
        </Form>

        <div className="flex justify-center">
          <Button onClick={onSubmit} className="w-full md:w-1/2" type="primary">
            Simpan
          </Button>
        </div>
      </div>
    </div>
  );
}
