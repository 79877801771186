import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useCategoryPagination = (dataTable, foundation_id, keyword) => {
  return useQuery(
    [
      "get-category-pagination",
      dataTable.current_page,
      dataTable.per_page,
      foundation_id,
      keyword,
    ],
    () =>
      GetPagination(
        `/links/categories?keyword=${keyword}&page=${dataTable.current_page}&limit=${dataTable.per_page}&foundation_id=${foundation_id}`
      )
  );
};
