import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useCategoryList } from "hooks/link-hook/category/useCategoryList";
import { useGroupList } from "hooks/link-hook/group/useGroupList";
import { useLinkDetail } from "hooks/link-hook/useLinkDetail";
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";

export default function EditLink() {
  const [isLoading, setIsLoading] = useState(false);
  const roles = decryptCookies("role").split(",");
  const [foundationId, setFoundationId] = useState("");
  const [newData, setNewData] = useState({});
  const [isEmptyName, setIsEmptyName] = useState(false);
  const isSuperAdmin = roles.some((x) => x === "super_admin");
  const isAdminFoundation = roles.some((x) => x === "admin_foundation");
  const isAdminHrd = roles.some((x) => x === "admin_hrd");

  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const inputRef = useRef(null);

  const [form] = Form.useForm();

  const { REACT_APP_HOST_API } = process.env;

  const { link_id } = useParams();

  const navigate = useNavigate();

  const isDesktop = useMediaQuery({
    minWidth: 1024,
  });

  //fetch categories list
  const { data: dataCategory, refetch: fetchCategories } = useCategoryList(
    "",
    foundationId
  );

  //fetch employee list
  const { data: dataEmployee, refetch } = useEmployeeList(
    "",
    "",
    "",
    "",
    foundationId
  );

  //fetch link detail
  const { data: dataLink } = useLinkDetail(link_id, true);

  //fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  //fetch group list
  const { data: dataGroups } = useGroupList();

  useEffect(() => {
    form.setFieldsValue({
      foundation_id: dataLink?.data?.foundation_id,
      employee_id: dataLink?.data?.employee_id,
      url: dataLink?.data?.url,
      description: dataLink?.data?.description,
      categories: dataLink?.data?.categories?.map((x) => x.id),
      groups: dataLink?.data?.groups?.map((x) => x.id),
      order: dataLink?.data?.order,
    });

    setFoundationId(dataLink?.data?.foundation_id);
    setNewData({});
    // eslint-disable-next-line
  }, [dataLink]);

  useEffect(() => {
    setItems(dataCategory?.data);
    if (foundationId) {
      refetch();
    }
  }, [dataCategory, refetch, foundationId]);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = async () => {
    const foundation_id = form.getFieldValue("foundation_id");
    const employee_id = form.getFieldValue("employee_id");

    if (!foundation_id && roles.some((x) => x === "super_admin")) {
      errorModal(
        "Gagal menambahkan kategori",
        "Harap pilih foundation terlebih dahulu"
      );
      return;
    }
    if (!employee_id && roles.some((x) => x === "super_admin")) {
      errorModal(
        "Gagal menambahkan kategori",
        "Harap pilih owner terlebih dahulu"
      );
      return;
    }

    if (!name) {
      message.error("Kategori harus diisi");
      setIsEmptyName(true);
      return;
    }

    try {
      await axios.post(
        REACT_APP_HOST_API + "/links/categories",
        { foundation_id, employee_id, name },
        {
          headers: {
            Authorization: `Bearer ${decryptCookies("token")}`,
          },
        }
      );
      fetchCategories();
      setName("");
      setIsEmptyName(false);
      message.success("Berhasil menambahkan kategori");
    } catch (error) {
      console.log(error);
      const msgArr = error?.response?.data?.data?.map((msg, i) => {
        return (
          <p key={i}>
            {msg.field} : {msg.message}
          </p>
        );
      });

      errorModal(
        "Gagal menambahkan kategori",
        error?.response?.data?.data ? msgArr : error?.response?.data?.message
      );
    }
  };

  const errorModal = (title, content) => {
    Modal.error({
      title,
      content,
    });
  };

  const onCancel = () => {
    form.resetFields();
    navigate(-1);
  };

  const onFoundationChange = (value) => {
    newData["foundation_id"] = value;
    setFoundationId(value);
    form.resetFields(["employee_id"]);
    form.resetFields(["categories"]);
    newData["categories"] = [];
  };

  const onSubmit = async () => {
    await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Nothing has changed");
      return;
    }

    setIsLoading(true);

    try {
      await axios.put(REACT_APP_HOST_API + `/links/links/${link_id}`, newData, {
        headers: {
          Authorization: `Bearer ${decryptCookies("token")}`,
        },
      });
      message.success("Berhasil menambahkan link");
      form.resetFields();
      navigate(-1);
    } catch (error) {
      errorModal(
        "Gagal menambahkan link",
        <pre>{error?.response?.data?.message}</pre>
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form
        form={form}
        layout="vertical"
        style={{ width: isDesktop ? "50%" : "100%" }}
      >
        {roles.some((x) => x === "super_admin") && (
          <Form.Item label="Foundation" name="foundation_id">
            <Select
              placeholder="Pilih Yayasan"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                onFoundationChange(value);
              }}
            >
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {roles.some((x) => x === "super_admin") && (
          <Form.Item
            label="Owner"
            name="employee_id"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => (newData["employee_id"] = value)}
            >
              {dataEmployee?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item label="Link" name="url">
          <Input
            onChange={({ target: { value } }) => (newData["url"] = value)}
          />
        </Form.Item>

        <Form.Item label="Deskripsi" name="description">
          <Input
            onChange={({ target: { value } }) =>
              (newData["description"] = value)
            }
          />
        </Form.Item>


        {
          (isSuperAdmin || isAdminFoundation || isAdminHrd) &&
          <Form.Item name="order" label="Order">
            <InputNumber style={{ width: "100%" }}
              onChange={(e) => newData.order = e}
            />
          </Form.Item>
        }

        <Form.Item label="Kategori" name="categories">
          <Select
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            mode="multiple"
            onChange={(value) => (newData["categories"] = value)}
            placeholder="pilih kategori..."
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: "8px 0",
                  }}
                />
                <Space
                  style={{
                    padding: "0 8px 4px",
                    position: "relative",
                  }}
                >
                  <div className="w-[180px]">
                    <Input
                      placeholder="Masukan nama kategori..."
                      ref={inputRef}
                      value={name}
                      onChange={onNameChange}
                      onKeyDown={(e) => e.stopPropagation()}
                      className={isEmptyName && "border border-[#ff4d4f]"}
                    />
                    {isEmptyName && (
                      <span className="text-[#ff4d4f] text-sm">
                        Please enter a category name
                      </span>
                    )}
                  </div>

                  <Button
                    style={{ position: "absolute", top: 0 }}
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={addItem}
                  >
                    Tambah Kategori
                  </Button>
                </Space>
              </>
            )}
            options={items?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
          />
        </Form.Item>

        <div style={{ display: "flex", gap: "10px" }}>
          <Form.Item label="Grup" name="groups" className="w-[80%]">
            <Select
              mode="multiple"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => (newData["groups"] = value)}
            >
              {dataGroups?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id} label={x.name}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <a
            href="/link/group/add"
            target="_blank"
            className="w-[25%] mt-[25px]"
          >
            <Button type="primary">buat baru</Button>
          </a>
        </div>

        <div
          style={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            style={{ width: isDesktop ? "50%" : "100%" }}
            loading={isLoading}
            type="primary"
            onClick={onSubmit}
          >
            Simpan
          </Button>

          <Button
            style={{ width: isDesktop ? "50%" : "100%" }}
            loading={isLoading}
            type="primary"
            ghost
            onClick={onCancel}
          >
            Batal
          </Button>
        </div>
      </Form>
    </div>
  );
}
