import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useDataVaPagination = (
  dataTable,
  keyword,
  foundationId,
  financeCodeId
) => {
  return useQuery(
    [
      "get-anggaran-unit-finance-report-pagination",
      dataTable.current_page,
      dataTable.per_page,
      keyword || "",
      foundationId || "",
      financeCodeId || "",
    ],
    () =>
      GetPagination(
        `/financial-report/virtual-accounts?page=${dataTable.current_page}&limit=${dataTable.per_page}&foundationId=${foundationId}&codeUnitId=${financeCodeId}&keyword=${keyword}`
      )
  );
};
