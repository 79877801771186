import { InboxOutlined } from "@ant-design/icons";
import { Form, DatePicker, Modal, Spin, Upload } from "antd";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaFileCsv } from "react-icons/fa";
import ImportExcelLogic from "./ImportExcelLogic";
import dayjs from "dayjs";
import FilterFoundation from "components/UI/FilterFoundation";
const { Dragger } = Upload;

const { RangePicker } = DatePicker;
const ImportExcel = (props) => {
  const {
    form,
    propsUpload,
    uploading,
    //  dataTemplate,
    isExcel,
    onCancelModal,
    handleUpload,
    isLoading,
    foundationId,
    setFoundationId,
    isSuperAdmin,
  } = ImportExcelLogic(props);

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleUpload}
      open={props.show}
      onCancel={onCancelModal}
      okButtonProps={{ loading: uploading }}
      cancelButtonProps={{ disabled: uploading }}
    >
      <Form form={form} layout="vertical">
        <div>
          {isLoading ? (
            <Spin />
          ) : (
            props.isShowTamplate && (
              <p style={{ fontSize: "16px" }}>
                Download the template file{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={props.urlTamplate}
                  className="text-blue-500"
                >
                  here
                </a>
                .
              </p>
            )
          )}
        </div>

        {isSuperAdmin && (
          <Form.Item
            label="Yayasan"
            name="foundation_id"
            rules={[{ required: isSuperAdmin && !foundationId }]}
          >
            <FilterFoundation
              setFoundationId={setFoundationId}
              isNotShowAll={true}
            />
          </Form.Item>
        )}

        {props.isBilling && (
          <Form.Item
            label="Tahun"
            name="year"
            initialValue={[dayjs(), dayjs().add(1, "year")]}
          >
            <RangePicker
              picker="year"
              id={{
                start: "startInput",
                end: "endInput",
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          name="file"
          label="File Excel atau CSV"
          rules={[
            {
              validator: (_, value) => {
                if (!value || !value.fileList || value.fileList.length === 0) {
                  return Promise.reject(
                    new Error("Please upload an Excel file or CSV")
                  );
                }
                const file = value.fileList[0];
                if (!isExcel(file)) {
                  return Promise.reject(
                    new Error("Please upload an Excel file or CSV")
                  );
                }
                return Promise.resolve();
              },
            },
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Dragger
            name="file"
            accept=".xlsx, .xls, .csv, .xlsb, .xlsm, .xml"
            listType="picture"
            disabled={uploading}
            iconRender={(file) => {
              if (
                file.type === "application/vnd.ms-excel" ||
                file.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ) {
                return (
                  <SiMicrosoftexcel
                    size={45}
                    color={
                      file.type === "application/vnd.ms-excel"
                        ? "#1e90ff"
                        : "#008000"
                    }
                  />
                );
              }
              if (file.type === "text/csv") {
                return (
                  <FaFileCsv
                    size={45}
                    color={file.type === "text/csv" ? "#1e90ff" : "#008000"}
                  />
                );
              }
              return <InboxOutlined />;
            }}
            {...propsUpload}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Support for excel or csv file.</p>
          </Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ImportExcel;
