import {
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Skeleton,
  message,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useAssetLoanDetail } from "hooks/asset-loan-hook/useAssetLoanDetail";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import { useStudentList } from "hooks/students-hook/useStudentList";
import moment from "moment/moment";
import { useContext, useEffect, useState } from "react";
import { InventoryContext } from "../../inventory-context/InventoryContext";
import dayjs from "dayjs";

const EditAssetLoan = (props) => {
  const [form] = Form.useForm();
  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(false);
  const [borrowerTypeStatus, setBorrowerTypeStatus] = useState("");
  const [borrowerType, setBorrowerType] = useState("");
  const [foundationId, setFoundationId] = useState("");
  const [unitId, setUnitId] = useState("");
  const { REACT_APP_HOST_API } = process.env;
  const { setInventoryFetch } = useContext(InventoryContext);

  const {
    data,
    isLoading: skeleton,
    refetch: fetchDetail,
  } = useAssetLoanDetail(props.id, false);

  const {
    data: dataStudent,
    refetch: fetchStudent,
    isFetching: isFetchStudent,
  } = useStudentList(foundationId);
  const {
    data: dataEmployee,
    refetch: fetchEmployee,
    isFetching: isFetchEmployee,
  } = useEmployeeList("", "", "", "", foundationId, "", unitId);

  useEffect(() => {
    if (borrowerType === "internal") {
      fetchEmployee();
      fetchStudent();
    }

    // eslint-disable-next-line
  }, [foundationId, unitId, dataEmployee, dataStudent, borrowerType]);

  useEffect(() => {
    if (props.show) {
      fetchDetail();
    }

    if (data) {
      setFoundationId(data.data.foundation_id || "");
      setUnitId(data.data.unit_id || "");
      setBorrowerType(data.data.borrower_type);
      setBorrowerTypeStatus(data.data.student ? "student" : "teacher");
      form.setFieldsValue({
        borrower: data.data.borrower,
        description: data.data.description,
        startDate: data.data.start_date && dayjs(moment(data.data.start_date)),
        endDate: data.data.end_date && dayjs(moment(data.data.end_date)),
        borrower_type_status: data.data.student ? "student" : "teacher",
        studentId: data.data.student && data.data.student.id,
        employeeId: data.data.employee && data.data.employee.id,
      });
    }

    return () => setNewData({});
  }, [data, props.show, form, fetchDetail, fetchEmployee, fetchStudent]);

  const handleEditAssetLoan = () => {
    form.validateFields().then(async () => {
      if (Object.keys(newData).length === 0) {
        alert("Nothing has changed");
        return;
      }

      setLoading(true);
      try {
        const { data } = await axios.put(
          REACT_APP_HOST_API + `/inventories/asset-loans/${props.id}`,
          newData,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        );

        onCancelModal();
        message.success(data.message);
        props.onUpdate();
        setInventoryFetch(true);
      } catch (error) {
        Modal.error({
          title: "Gagal Mengubah",
          content: <pre>{error?.response?.data?.message}</pre>,
        });
      }
      setLoading(false);
    });
  };

  const onCancelModal = () => {
    props.onCancel();
    form.resetFields();
    setFoundationId("");
    setUnitId("");
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      title={data?.data.asset.serial}
      open={props.show}
      onCancel={onCancelModal}
      onOk={handleEditAssetLoan}
      okButtonProps={{ loading }}
    >
      {skeleton ? (
        <>
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <Form form={form} layout="vertical">
          {borrowerType === "external" && (
            <Form.Item
              name="borrower"
              label="Peminjam"
              rules={[{ required: true }]}
            >
              <Input
                onChange={({ target: { value } }) => (newData.borrower = value)}
              />
            </Form.Item>
          )}

          {borrowerType === "internal" && (
            <Form.Item name="borrower_type_status" label="Tipe Peminjam">
              <Radio.Group
                onChange={({ target: { value } }) =>
                  setBorrowerTypeStatus(value)
                }
              >
                {borrowerTypeStatus === "student" ? (
                  <Radio value="student">Siswa</Radio>
                ) : (
                  <Radio value="teacher">Guru</Radio>
                )}
              </Radio.Group>
            </Form.Item>
          )}

          {borrowerTypeStatus === "teacher" && borrowerType === "internal" && (
            <Form.Item
              name="employeeId"
              label="Peminjam"
              rules={[{ required: true, message: "Harap diisi" }]}
            >
              <Select
                disabled={isFetchEmployee}
                loading={isFetchEmployee}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.children[0].props.children
                      .toLowerCase()
                      .includes(input.toLowerCase()) ||
                    option.children[1].props.children === input
                  );
                }}
                onChange={(e) => (newData["employeeId"] = e)}
              >
                {dataEmployee?.data.map((data) => (
                  <Select.Option key={data.id} value={data.id}>
                    <span>{data.name}</span>
                    <span className="transparent-rfid">{data.rfid}</span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {borrowerTypeStatus === "student" && borrowerType === "internal" && (
            <Form.Item
              name="studentId"
              label="Peminjam"
              rules={[{ required: true, message: "Harap diisi" }]}
            >
              <Select
                disabled={isFetchStudent}
                loading={isFetchStudent}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => (newData["studentId"] = e)}
              >
                {dataStudent?.data.data.map((data) => (
                  <Select.Option key={data.id} value={data.id}>
                    {`${data.name} - ${data?.class?.name}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item name="startDate" label="Waktu Mulai">
            <DatePicker
              showTime
              onChange={(e) => {
                if (e !== null)
                  newData["startDate"] = e.format("YYYY-MM-DD HH:mm:ss");
                else newData["startDate"] = e;
              }}
            />
          </Form.Item>

          <Form.Item name="endDate" label="Waktu Selesai">
            <DatePicker
              showTime
              onChange={(e) => {
                if (e !== null)
                  newData["endDate"] = e.format("YYYY-MM-DD HH:mm:ss");
                else newData["endDate"] = e;
              }}
            />
          </Form.Item>

          <Form.Item name="description" label="Deskripsi">
            <Input.TextArea
              rows={3}
              onChange={({ target: { value } }) =>
                (newData["description"] = value)
              }
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditAssetLoan;
