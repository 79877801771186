import { GetDetail } from "api/GetDetail";
import { useQuery } from "react-query";

export const useLinkGroupDetail = (group_id, enabled) => {
  return useQuery(
    ["get-link-group-detail", group_id],
    () => GetDetail(`/links/link-groups/${group_id}`),
    {
      enabled,
    }
  );
};
