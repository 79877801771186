import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Skeleton,
  Space,
  Tag,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import EditAssetLogic from "./EditAssetLogic";

const EditAssets = (props) => {
  const {
    isImage,
    propsUpload,
    loading,
    handleSubmit,

    roles,
    setFoundationId,
    dataFoundation,

    inputRefSumberDana,
    nameSumberDana,
    onNameChangeSumberDana,
    isEmptyNameSumberDana,
    addItemSumberDana,
    itemsSumberDana,

    inputRefCategory,
    nameCategory,
    onNameChangeCategory,
    isEmptyNameCategory,
    addItemCategory,
    itemsCategory,
    //--
    data,
    tags,
    form,
    format,
    newData,
    fileList,
    skeleton,
    inputAddRef,
    inputEditRef,
    editTagIndex,
    addTagVisible,
    editTagInputValue,
    showInput,
    handleChange,
    onCancelModal,
    handlePreview,
    onSubmitAddTag,
    onSubmitEditTag,
    handleRemoveTag,
    setEditTagIndex,
    onInputAddTagChange,
    setEditTagInputValue,
    onInputEditTagChange,
  } = EditAssetLogic(props);

  return (
    <Modal
      open={props.show}
      onOk={handleSubmit}
      okText="Simpan"
      cancelText="Batal"
      onCancel={onCancelModal}
      width={1000}
      centered
    >
      {skeleton && <Skeleton active />}

      {!skeleton && (
        <Form form={form} layout="vertical" className="full-form">
          <div className="first-form">
            {roles.includes("super_admin") && (
              <Form.Item
                label="Yayasan"
                name="foundationId"
                rules={[{ required: true, message: "Harap diisi" }]}
              >
                <Select
                  placeholder="Pilih Yayasan"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(e) => {
                    newData["foundationId"] = e;

                    setFoundationId(e);
                    form.resetFields(["assetFundSourceId"]);
                    form.resetFields(["categories"]);
                    newData["assetFundSourceId"] = [];
                    newData["categories"] = [];
                  }}
                >
                  {dataFoundation?.data?.data?.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Form.Item
              name="serial"
              label="Serial"
              rules={[{ required: true, message: "Harap diisi" }]}
            >
              <Input name="serial" onChange={handleChange} />
            </Form.Item>

            <Form.Item name="description" label="Deskripsi">
              <Input.TextArea
                name="description"
                onChange={handleChange}
                rows={3}
              />
            </Form.Item>

            <Form.Item name="assetFundSourceId" label="Sumber Dana">
              <Select
                onChange={(e) => {
                  newData["assetFundSourceId"] = e;
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="pilih sumber dana..."
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                        position: "relative",
                      }}
                    >
                      <div className="w-[180px]">
                        <Input
                          placeholder="Masukan Sumber Dana..."
                          ref={inputRefSumberDana}
                          value={nameSumberDana}
                          onChange={onNameChangeSumberDana}
                          onKeyDown={(e) => e.stopPropagation()}
                          className={
                            isEmptyNameSumberDana && "border border-[#ff4d4f]"
                          }
                        />
                        {isEmptyNameSumberDana && (
                          <span className="text-[#ff4d4f] text-sm">
                            Mohon Isi Sumber Dana
                          </span>
                        )}
                      </div>

                      <Button
                        style={{ position: "absolute", top: 0 }}
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={addItemSumberDana}
                      >
                        Tambah
                      </Button>
                    </Space>
                  </>
                )}
                options={itemsSumberDana?.map((item) => ({
                  label: item?.name,
                  value: item?.id,
                }))}
              />
            </Form.Item>

            <Form.Item
              name="assetStatusId"
              label="Status"
              rules={[{ required: true, message: "Harap diisi" }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => {
                  newData["assetStatusId"] = e;
                }}
              >
                {data?.data.map((status) => {
                  if (status.id === "BORROWED") {
                    return null;
                  } else {
                    return (
                      <Select.Option key={status.id} value={status.id}>
                        {status.id}
                      </Select.Option>
                    );
                  }
                })}
              </Select>
            </Form.Item>

            <Form.Item name="categories" label="Kategori">
              <Select
                name
                onChange={(e) => {
                  newData["categories"] = e;
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                mode="multiple"
                placeholder="pilih kategori..."
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                        position: "relative",
                      }}
                    >
                      <div className="w-[180px]">
                        <Input
                          placeholder="Masukan Nama Kategori..."
                          ref={inputRefCategory}
                          value={nameCategory}
                          onChange={onNameChangeCategory}
                          onKeyDown={(e) => e.stopPropagation()}
                          className={
                            isEmptyNameCategory && "border border-[#ff4d4f]"
                          }
                        />
                        {isEmptyNameCategory && (
                          <span className="text-[#ff4d4f] text-sm">
                            Mohon Isi Kategori
                          </span>
                        )}
                      </div>

                      <Button
                        style={{ position: "absolute", top: 0 }}
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={addItemCategory}
                      >
                        Tambah
                      </Button>
                    </Space>
                  </>
                )}
                options={itemsCategory?.map((item) => ({
                  label: item?.name,
                  value: item?.id,
                }))}
              />
            </Form.Item>

            <Form.Item label="Tag">
              {tags &&
                tags.map((tag, index) => {
                  if (editTagIndex === index)
                    return (
                      <Input
                        key={index}
                        size="small"
                        ref={inputEditRef}
                        className="tag-input"
                        value={editTagInputValue}
                        onBlur={onSubmitEditTag}
                        onPressEnter={onSubmitEditTag}
                        onChange={onInputEditTagChange}
                        pattern="[a-zA-Z0-9]+"
                        title="allow only alphanum and dash"
                      />
                    );

                  return (
                    <Tag
                      key={tag}
                      closable
                      className="edit-tag"
                      onClose={() => handleRemoveTag(tag)}
                      onDoubleClick={(e) => {
                        setEditTagIndex(index);
                        setEditTagInputValue(tag);
                        return;
                      }}
                    >
                      {tag}
                    </Tag>
                  );
                })}

              {addTagVisible && (
                <Input
                  size="small"
                  className="tag-input"
                  pattern="[a-zA-Z0-9]+"
                  title="allow only alphanum and dash"
                  ref={inputAddRef}
                  onBlur={onSubmitAddTag}
                  onPressEnter={onSubmitAddTag}
                  onChange={onInputAddTagChange}
                />
              )}

              {!addTagVisible && (
                <Tag className="site-tag-plus" onClick={showInput}>
                  <PlusOutlined /> Tambah Tag
                </Tag>
              )}
            </Form.Item>
          </div>

          <div className="second-form">
            <Form.Item name="purchaseDate" label="Tanggal Pembelian">
              <DatePicker
                format={format}
                onChange={(e) => {
                  newData["purchaseDate"] = e.format(format);
                }}
              />
            </Form.Item>
            <Form.Item name="orderNumber" label="Nomer Order">
              <Input name="orderNumber" onChange={handleChange} />
            </Form.Item>
            <Form.Item
              name="price"
              label="Harga"
              rules={[
                {
                  pattern: new RegExp("^([0-9]*)$"),
                  message: "Masukkan hanya Nomor",
                },
              ]}
            >
              <Input name="price" onChange={handleChange} />
            </Form.Item>
            <Form.Item
              name="warranty"
              label="Garansi (bulan)"
              rules={[
                {
                  pattern: new RegExp("^([0-9]*)$"),
                  message: "Masukkan hanya Nomor",
                },
              ]}
            >
              <Input name="warranty" onChange={handleChange} />
            </Form.Item>
            <Form.Item name="notes" label="Notes">
              <TextArea rows={3} name="notes" onChange={handleChange} />
            </Form.Item>
            <Form.Item
              name="image"
              label="Gambar"
              rules={[
                {
                  validator: (_, value) => {
                    const file = value?.fileList[0];
                    if (!file) {
                      return Promise.resolve();
                    }
                    if (!isImage(file)) {
                      return Promise.reject(
                        new Error("Please upload an image file")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Upload
                name="image"
                accept=".jpg,.jpeg,.png"
                listType="picture-card"
                showUploadList={true}
                onPreview={handlePreview}
                {...propsUpload}
                disabled={loading}
              >
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    {fileList.length === 0 ? (
                      <span style={{ fontSize: "12px" }}>
                        Upload <br />
                        (max 2 mb)
                      </span>
                    ) : (
                      "Ganti"
                    )}
                    {fileList.length === 0 ? "" : ""}
                  </div>
                </div>
              </Upload>
            </Form.Item>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default EditAssets;
