import { Form, message, Modal } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useState } from "react";
import TabIdentitasSiswa from "./Tab/TabIdentitasSiswa";
import TabAlamatSiswa from "./Tab/TabAlamatSiswa";
import TabKontakSiswa from "./Tab/TabKontakSiswa";
import TabDataAkademik from "./Tab/TabDataAkademik";
import TabRekening from "./Tab/TabRekening";
import TabDataLainnya from "./Tab/TabDataLainnya";
import TabDataAyah from "./Tab/TabDataAyah";
import TabDataIbu from "./Tab/TabDataIbu";
import TabDataWali from "./Tab/TabDataWali";
import { useNavigate } from "react-router-dom";

const AddStudentLogic = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  let studentId;
  const navigate = useNavigate();

  const { REACT_APP_HOST_API } = process.env;

  const handleSubmit = async () => {
    const val = await form.validateFields();
    try {
      setLoading(true);
      try {
        const objStudent = {
          ...val,
          birth_day: val.birth_day && val.birth_day.format("YYYY-MM-DD"),
          father0_0birth_date:
            val.father0_0birth_date &&
            val.father0_0birth_date.format("YYYY-MM-DD"),
          mother0_0birth_date:
            val.mother0_0birth_date &&
            val.mother0_0birth_date.format("YYYY-MM-DD"),
          guardian0_0birth_date:
            val.guardian0_0birth_date &&
            val.guardian0_0birth_date.format("YYYY-MM-DD"),
        };
        const guardian = {
          relationship_w_student: "guardian",
        };

        const father = {
          relationship_w_student: "biological father",
        };
        const mother = {
          relationship_w_student: "biological mother",
        };

        for (let key in objStudent) {
          const [identifier, kunci] = key.split("0_0");
          if (identifier === "guardian") {
            guardian[kunci] = objStudent[key];
          } else if (identifier === "father") {
            father[kunci] = objStudent[key];
          } else if (identifier === "mother") {
            mother[kunci] = objStudent[key];
          }
        }

        const { data } = await axios.post(
          REACT_APP_HOST_API + `/academics/students`,
          {
            ...val,
            birth_day: val.birth_day && val.birth_day.format("YYYY-MM-DD"),
          },
          { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        );
        message.success(data.message);
        // form.resetFields();
        studentId = await data.data.id;
        // console.log({ studentId });

        // //** CREAT PARENT

        // ** add ayah
        const validAyah = [
          father.name,
          father.nik,
          father.birth_date,
          father.education,
          father.occupation,
          father.min_salary,
          father.max_salary,
          father.address,
          father.phone_number,
          father.email,
        ];

        // jika ada salah satu saja dari validArray yang tidak kosong maka akan true
        const isValidFather = validAyah.some(
          (value) => value !== undefined && value !== ""
        );

        if (isValidFather) {
          // eslint-disable-next-line
          const { data: dataFather } = await axios.post(
            REACT_APP_HOST_API + `/academics/students/${studentId}/parents`,
            father,
            {
              headers: { Authorization: "Bearer " + decryptCookies("token") },
            }
          );
          message.success("data ayah berhasil ditambah");
        }

        // ** add ibu

        const validIbu = [
          mother.name,
          mother.nik,
          mother.birth_date,
          mother.education,
          mother.occupation,
          mother.min_salary,
          mother.max_salary,
          mother.address,
          mother.phone_number,
          mother.email,
        ];

        // jika ada salah satu saja dari validArray yang tidak kosong maka akan true
        const isValidMother = validIbu.some(
          (value) => value !== undefined && value !== ""
        );

        if (isValidMother) {
          // eslint-disable-next-line
          const { data: dataMother } = await axios.post(
            REACT_APP_HOST_API + `/academics/students/${studentId}/parents`,
            mother,
            {
              headers: { Authorization: "Bearer " + decryptCookies("token") },
            }
          );
          message.success("data ibu berhasil ditambah");
        }

        // ** add wali
        const validWali = [
          guardian.name,
          guardian.nik,
          guardian.birth_date,
          guardian.education,
          guardian.occupation,
          guardian.min_salary,
          guardian.max_salary,
          guardian.address,
          guardian.phone_number,
          guardian.email,
        ];

        // jika ada salah satu saja dari validArray yang tidak kosong maka akan true
        const isValidGuardian = validWali.some(
          (value) => value !== undefined && value !== ""
        );

        if (isValidGuardian) {
          // eslint-disable-next-line
          const { data: dataGuardian } = await axios.post(
            REACT_APP_HOST_API + `/academics/students/${studentId}/parents`,
            guardian,
            {
              headers: { Authorization: "Bearer " + decryptCookies("token") },
            }
          );
          message.success("data wali berhasil ditambah");
        }
        form.resetFields();
        navigate("/academic/students");
      } catch (error) {
        const msg = error?.response?.data?.message;

        Modal.error({
          title: "Gagal menyimpan data",
          content: <pre>{msg}</pre>,
        });
      }
      setLoading(false);
    } catch (error) {
      alert("Fields Error");
      setLoading(false);
    }
  };

  const selectTap = async (key) => {
    try {
      await form.validateFields();
      setActiveKey(key.toString());
    } catch (error) {
      alert("Input yang wajib diisi belum terpenuhi");
    }
  };

  const items = [
    {
      key: "1",
      label: `Identitas`,
      children: <TabIdentitasSiswa form={form} />,
    },
    {
      key: "2",
      label: `Alamat`,
      children: <TabAlamatSiswa form={form} />,
    },
    {
      key: "3",
      label: `Kontak`,
      children: <TabKontakSiswa />,
    },
    {
      key: "4",
      label: `Data akademik`,
      children: <TabDataAkademik />,
    },
    {
      key: "5",
      label: `Data Rekening`,
      children: <TabRekening />,
    },
    {
      key: "6",
      label: `Data lainnya`,
      children: <TabDataLainnya />,
    },
    {
      key: "7",
      label: `Data ayah`,
      children: <TabDataAyah />,
    },
    {
      key: "8",
      label: `Data ibu`,
      children: <TabDataIbu />,
    },
    {
      key: "9",
      label: `Data wali`,
      children: <TabDataWali />,
    },
  ];

  const handleNext = async () => {
    try {
      await form.validateFields();
      selectTap(parseInt(activeKey) + 1);
    } catch (error) {
      alert("Input yang wajib diisi belum terpenuhi");
    }
  };

  return {
    form,
    selectTap,
    items,
    activeKey,
    handleNext,
    handleSubmit,
    loading,
  };
};

export default AddStudentLogic;
