import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Popconfirm,
  Radio,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React from "react";
import CreateAgenda from "../create/CreateAgenda";
import { useState } from "react";
import EditAgenda from "../edit/EditAgenda";
import dayjs from "dayjs";
import { DeleteApi } from "api/DeleteApi";
import { usePermission } from "hooks/usePermissions";
import { useAgendaPagination } from "hooks/agenda-hook/useAgendaPagination";
import moment from "moment";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { decryptCookies } from "components/Helper/CookiesHelper";
import CalenderAgenda from "./CalenderAgenda";
import { useAgendaList } from "hooks/agenda-hook/useAgendaList";
import useResetPage from "./../../../../components/Helper/useResetPage";
import CreateWeekend from "../create/CreateWeekend";

dayjs.locale("id");

export default function Agenda() {
  const [mode, setMode] = useState("calender");
  const [openAgenda, setOpenAgenda] = useState(false);
  const [openEditAgenda, setOpenEditAgenda] = useState(false);
  const [openWeekend, setOpenWeekend] = useState(false);
  const [idAgenda, setIdAgenda] = useState("");
  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");
  const [open, setOpen] = useState(false);
  const [foundationId, setFoundationId] = useState("");
  const newDate = new Date();
  const firstDate = new Date(newDate.getFullYear() - 3, 0, 1);
  const lastDate = new Date(newDate.getFullYear(), 11, 31);

  const [date, setDate] = useState({
    from: moment(firstDate).format("YYYY-MM-DD"),
    to: moment(lastDate).format("YYYY-MM-DD"),
  });

  // get agenda
  const { data, isLoading, isFetching, refetch } = useAgendaPagination(
    dataTable,
    keyword,
    date,
    foundationId
  );

  // get agenda list
  const { data: dataAgenda, refetch: refetchAgendaList } = useAgendaList(
    "",
    "",
    "",
    foundationId
  );

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  useResetPage(setDataTable, [keyword, date, foundationId]);

  const items = [
    {
      key: "1",
      label: (
        <>
          <div>Tanggal</div>
          <DatePicker
            value={dayjs(date.from)}
            style={{ width: "100%" }}
            onChange={(value) => {
              setDate((curr) => ({
                ...curr,
                from:
                  value !== null
                    ? value.format("YYYY-MM-DD")
                    : moment(firstDate).format("YYYY-MM-DD"),
              }));
            }}
            placeholder="Awal"
          />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <div>Tanggal Akhir</div>
          <DatePicker
            value={dayjs(date.to)}
            onChange={(value) =>
              setDate((curr) => ({
                ...curr,
                to:
                  value !== null
                    ? value.format("YYYY-MM-DD")
                    : moment(lastDate).format("YYYY-MM-DD"),
              }))
            }
            placeholder="Akhir"
          />
        </>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          style={{ width: "100%" }}
          type="primary"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      ),
    },
  ];

  const handleMenuClick = (e) => {
    return;
  };

  const handleOpenChange = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen(nextOpen);
    }
  };

  const disabled = { color: "#ccc", cursor: "not-allowed" };

  // fetch student permission--
  const { data: dataPermission } = usePermission("mdlAcademic", "mnuAgenda");

  // Buttons Permission
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddAgenda");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditAgenda");
  const btnDelete = dataPermission?.find((x) => x.id === "btnEditAgenda");
  const btnDefaultWeekend = dataPermission?.find(
    (x) => x.id === "btnDefaultWeekend"
  );

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "",
      width: 60,
      fixed: "left",
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "",
      width: 150,
      fixed: "left",
    },
    {
      title: "Yayasan",
      dataIndex: "foundationName",
      hidden: !isSuperAdmin,
    },
    {
      title: "Tipe",
      dataIndex: "type",
      key: "",
    },
    {
      title: "tanggal",
      dataIndex: "date",
      key: "",
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      key: "",
    },
    {
      title: "Presensi",
      dataIndex: "count_presence",
      key: "",
      render: (text, record) => {
        if (text) {
          return <div>{"Ya"}</div>;
        } else {
          return <div>{"Tidak"}</div>;
        }
      },
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      key: "action",
      render: (text, record) => (
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus ?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() => {
              const dataId = record.id;
              DeleteApi({
                url: "/academics/agendas/",
                dataId,
                refetch,
              });
            }}
            disabled={btnDelete && btnDelete.type === "disabled"}
          >
            <Tag
              color="red"
              hidden={!btnDelete}
              style={
                btnDelete?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
            >
              Delete
            </Tag>
          </Popconfirm>
          <Tag
            onClick={() =>
              btnEdit?.type === "disabled" ? null : handleEditAgenda(record.id)
            }
            color="orange"
            hidden={!btnEdit}
            style={
              btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
          >
            Edit
          </Tag>
        </Space>
      ),
    },
  ].filter((x) => !x.hidden);

  const dataSource = data?.data?.data?.map((item, index) => ({
    ...item,
    index: index + 1,
    key: item.id,
    foundationName: item.foundation?.name,
    date: dayjs(item.date).format("dddd, D MMMM YYYY"),
    type:
      item.type === "HOLIDAY"
        ? "Libur"
        : item.type === "NATIONAL_DAY"
        ? "Hari Nasional"
        : item.type === "EVENT"
        ? "Acara"
        : item.type === "SCHOOL_AGENDA"
        ? "Agenda Sekolah"
        : item.type,
  }));

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const handleAddAgenda = () => {
    setOpenAgenda(!openAgenda);
    refetch();
    refetchAgendaList();
  };
  const handleEditAgenda = (id) => {
    setOpenEditAgenda(!openEditAgenda);
    refetch();
    refetchAgendaList();
    setIdAgenda(id);
  };
  const handleAddWeekend = () => {
    setOpenWeekend(!openWeekend);
  };

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Kalender Akademik</h1>

        <Space>
          <Button
            type="primary"
            hidden={!btnDefaultWeekend}
            disabled={btnDefaultWeekend?.type === "disabled"}
            onClick={() => {
              if (btnDefaultWeekend?.type === "disabled") {
                return;
              }
              handleAddWeekend();
            }}
          >
            Default Hari Libur
          </Button>

          <Button
            hidden={!btnAdd}
            disabled={btnAdd?.type === "disabled"}
            type="primary"
            onClick={() => {
              if (btnAdd?.type === "disabled") {
                return;
              }
              handleAddAgenda();
            }}
          >
            Tambah Agenda
          </Button>
        </Space>
      </div>
      <div
        style={{
          maxWidth: 700,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
          gap: 10,
        }}
      >
        <Input
          allowClear
          value={keyword}
          placeholder="cari agenda..."
          prefix={<SearchOutlined />}
          onChange={({ target: { value } }) => {
            setKeyword(value);
          }}
        />
        <Dropdown
          menu={{ items, onClick: handleMenuClick }}
          onOpenChange={handleOpenChange}
          open={open}
          trigger={["click"]}
        >
          <Button icon={<FilterOutlined />}>Filter</Button>
        </Dropdown>
        {roles.some((x) => x === "super_admin") && (
          <Tooltip Tooltip title="Yayasan">
            <Select
              placeholder="Pilih Yayasan"
              style={{ minWidth: 150 }}
              onChange={(x) => setFoundationId(x)}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Select.Option className="select-option-foundation" value="">
                ALL
              </Select.Option>
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option
                  key={x.id}
                  value={x.id}
                  className="select-option-foundation"
                >
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        )}

        <Tooltip title="Mode">
          <Radio.Group
            buttonStyle="solid"
            value={mode}
            onChange={(e) => {
              setMode(e.target.value);
              refetchAgendaList();
            }}
          >
            <Radio.Button value="calender">Kalender </Radio.Button>
            <Radio.Button value="list">List</Radio.Button>
          </Radio.Group>
        </Tooltip>
      </div>

      {mode === "list" ? (
        <Table
          loading={isLoading || isFetching}
          columns={columns}
          dataSource={dataSource}
          pagination={pagination}
        />
      ) : (
        <div className="mt-8 overflow-auto border rounded-md">
          <div className="min-w-[800px]">
            <CalenderAgenda data={dataAgenda} />
          </div>
        </div>
      )}

      {/* modal --- */}

      <CreateAgenda isOpen={openAgenda} isClose={handleAddAgenda} />
      <EditAgenda
        idAgenda={idAgenda}
        isOpen={openEditAgenda}
        isClose={handleEditAgenda}
      />
      <CreateWeekend isOpen={openWeekend} isClose={handleAddWeekend} />
    </div>
  );
}
