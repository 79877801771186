import { Form, Input, Modal, Select, Upload } from "antd";
import React from "react";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useKodeKeuanganPagination } from "hooks/finance-report-hook/useKodeKeuanganPagination";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";

const CreateUnit = (props) => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileLogoList, setFileLogoList] = useState([]);
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  // get foundation list
  const { data: dataFoundation } = useFoundationList();


  const {
    data: dataKodeUnit,
    isLoading: isLoadingKodeUnit,
    isFetching: isFetchingKodeUnit,
  } = useKodeKeuanganPagination(
    {
      current_page: 1,
      per_page: 1000,
      total: 0,
    },
    "",
    '',
    "unit");

  const handleCreateDivision = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      if(!isSuperAdmin){
        values.foundationId = decryptCookies("foundationId");
      }

      const postData = {
        name: values.name,
        description: values.description,
        foundationId : values.foundationId,
        codeUnitId: values.codeUnitId,
        phone: values.phone,
        email: values.email,
        address: values.address,
        website: values.website,
      };

      if (values?.signature && fileList?.length > 0) {
        postData.signature = values.signature.file;
      }

      if (values?.logo && fileLogoList?.length > 0) {
        postData.logo = values.logo.file;
      }

      const { data } = await axios.post(
        `${REACT_APP_HOST_API}/units`,
        { ...postData },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success(data?.message, 2);
      props.onCreate();
      form.resetFields();
      setFileList([]);
      setFileLogoList([]);
    } catch (error) {
      message.error(error?.response?.data?.message, 2);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setFileList([]);
    props.onCancel();
  };

  // upload
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // Set the preview property if it's not already set
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    // Show preview modal
    Modal.info({
      title: file.name,
      content: (
        <img
          alt="preview"
          style={{ width: "100%" }}
          src={file.url || file.preview}
        />
      ),
    });
  };

  const isImage = (file) => {
    return file.type === "image/png";
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const handleChangeLogo = ({ fileList: newFileList }) => setFileLogoList(newFileList);

  const beforeUpload = (file) => {
    if (!isImage(file)) {
      message.error("hanya bisa upload file gambar (.png)");
    }

    if (file.size > 2000000) {
      message.error("Ukuran maksimal foto 2MB");
      return false;
    }

    if (!isImage(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isImage(file) ? [file] : []);
    return false;
  };


  const beforeUploadLogo = (file) => {
    if (!isImage(file)) {
      message.error("hanya bisa upload file gambar (.png)");
    }

    if (file.size > 2000000) {
      message.error("Ukuran maksimal foto 2MB");
      return false;
    }

    if (!isImage(file) && fileList.length > 0) {
      setFileLogoList([...fileList]);
      return false;
    }
    setFileLogoList(isImage(file) ? [file] : []);
    return false;
  };


  const handleRemove = (file) => {
    // setFileList([]);
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const handleRemoveLogo = (file) => {
    // setFileList([]);
    const index = fileLogoList.indexOf(file);
    const newFileList = fileLogoList.slice();
    newFileList.splice(index, 1);
    setFileLogoList(newFileList);
  }

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    fileList,
    onChange: handleChange,
  };

  const propsUploadLogo = {
    onRemove: handleRemoveLogo,
    beforeUpload: beforeUploadLogo,
    fileList: fileLogoList,
    onChange: handleChangeLogo,
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      open={props.show}
      onCancel={handleCancel}
      onOk={handleCreateDivision}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Nama Unit"
          name="name"
          rules={[
            {
              required: true,
              message: "Nama unit harus diisi!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Deskripsi"
          name="description"
          rules={[
            {
              required: true,
              message: "Deskripsi harus diisi!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {(isSuperAdmin) &&
          <Form.Item
            name="foundationId"
            label="Foundation"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder="Pilih foundation"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataFoundation?.data?.data?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        <Form.Item
          name="signature"
          label="Gambar"
          rules={[
            {
              validator: (_, value) => {
                const file = value?.fileList[0];
                if (!file) {
                  return Promise.resolve();
                }
                if (!isImage(file)) {
                  return Promise.reject(
                    new Error("Please upload an image file")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload
            name="file"
            accept=".png"
            listType="picture-card"
            showUploadList={true}
            onPreview={handlePreview}
            {...propsUpload}
            disabled={loading}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                {fileList.length === 0 ? (
                  <span style={{ fontSize: "12px" }}>
                    Upload <br />
                    (max 2 mb)
                  </span>
                ) : (
                  "Ganti"
                )}
              </div>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item
          name="logo"
          label="Logo"
          rules={[
            {
              validator: (_, value) => {
                const file = value?.fileList[0];
                if (!file) {
                  return Promise.resolve();
                }
                if (!isImage(file)) {
                  return Promise.reject(
                    new Error("Please upload an image file")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload
            name="file"
            accept=".png"
            listType="picture-card"
            showUploadList={true}
            onPreview={handlePreview}
            {...propsUploadLogo}
            disabled={loading}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                {fileLogoList.length === 0 ? (
                  <span style={{ fontSize: "12px" }}>
                    Upload <br />
                    (max 2 mb)
                  </span>
                ) : (
                  "Ganti"
                )}
              </div>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item
          name="codeUnitId"
          label="Kode Unit"
        >
          <Select
            showSearch
            placeholder="Pilih Kode Unit"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            disabled={(isLoadingKodeUnit || isFetchingKodeUnit)}
          >
            {dataKodeUnit?.data?.data?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {`${item.code} - ${item.name}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Nomor HP"
          name="phone"
          // rules phone
          rules={[
            {
              pattern: new RegExp(/^[0-9\b]+$/),
              message: "Nomor HP tidak valid",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          // rules email
          rules={[
            {
              type: "email",
              message: "Email tidak valid",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Alamat"
          name="address"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Website"
          name="website"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default React.memo(CreateUnit);
