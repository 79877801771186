import { Form, Input, InputNumber, Modal, Radio, Select } from "antd";
import React from "react";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useState } from "react";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useKodeKeuanganPagination } from "hooks/finance-report-hook/useKodeKeuanganPagination";
import { renderModalError } from "pages/si-finance-report/utlis/utlis";

const CreateDataVa = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;
  const [foundationId, setFoundationId] = useState("");

  // get roles
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");


  // get foundation list
  const { data: dataFoundation } = useFoundationList();

  // get kode unit
  const {
    data: dataKodeUnit,
    isLoading: isLoadingKodeUnit,
    isFetching: isFetchingKodeUnit,
  } = useKodeKeuanganPagination(
    {
      current_page: 1,
      per_page: 1000,
      total: 0,
    },
    "",
    foundationId,
    "unit");


  const handleCreateKodeKeuangan = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      if (!isSuperAdmin) {
        values.foundationId = decryptCookies("foundationId");
      }

      // change value va1 and va2 if have data to string
      if (values.va) {
        values.va = values.va.toString();
      }

      const { data } = await axios.post(
        `${REACT_APP_HOST_API}/financial-report/virtual-accounts`,
        { ...values },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      message.success(data?.message, 2);
      props.onCreate();
      form.resetFields();
    } catch (error) {
      renderModalError("Error : Tambah Data Va", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    props.onCancel();
  };



  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      open={props.show}
      onCancel={handleCancel}
      onOk={handleCreateKodeKeuangan}
      okButtonProps={{ loading }}
      title="Tambah Data Va"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Nomor VA"
          name="va"
          rules={[
            { required: true, message: "Nomor VA harus diisi!" },
          ]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        {(isSuperAdmin) &&
          <Form.Item
            name="foundationId"
            label="Foundation"
            rules={[{ required: true, message: "Foundation harus diisi!", }]}
          >
            <Select
              showSearch
              placeholder="Pilih foundation"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(x) => {
                setFoundationId(x);
                form.resetFields(["codeUnitId"]);
              }
              }
            >
              {dataFoundation?.data?.data?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        <Form.Item
          name="codeUnitId"
          label="Unit"
          rules={[{ required: true, message: "Unit harus diisi!" }]}
        >
          <Select
            showSearch
            placeholder="Pilih Unit"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            disabled={(foundationId === '' && isSuperAdmin) || (isLoadingKodeUnit || isFetchingKodeUnit)}
          >
            {dataKodeUnit?.data?.data?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {`${item.code} - ${item.name}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Nama (opsional)"
          name="name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Jurusan (opsional)"
          name="jurusan"
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="F"> F (Fullday)</Radio.Button>
            <Radio.Button value="M">M (Mahad)</Radio.Button>
            <Radio.Button value="B">B (Boarding)</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default React.memo(CreateDataVa);
