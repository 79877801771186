import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, message, Modal, Radio, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { renderModalError } from "components/UI/ErrorModal";
import { useHeroDetail } from "hooks/landing-page-hook/useHeroDetail";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function EditHero() {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const [deleteImages, setDeleteImages] = useState([]);

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;
  const navigate = useNavigate();
  const { hero_id: id } = useParams();

  // fetch hero detail
  const { data } = useHeroDetail(id);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        image: null,
        title: data?.data?.title,
        description: data?.data?.description,
        link: data?.data?.link,
        is_active: data?.data?.is_active,
        order: data?.data?.order,
      });
    }
    if (data?.data?.image) {
      setFileList([
        {
          uid: "-1",
          name: data?.data?.title,
          status: "done",
          url: data?.data?.image,
        },
      ]);
    }

    return () => setNewData({});
    // eslint-disable-next-line
  }, [data]);

  // upload image
  const isImage = (file) => {
    return (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    );
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // Set the preview property if it's not already set
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    // Show preview modal
    Modal.info({
      title: file.name,
      content: (
        <img
          alt="preview"
          style={{ width: "100%" }}
          src={file.url || file.preview}
        />
      ),
    });
  };

  // const handleRemove = (file) => {
  //   const newFileList = fileList.filter((f) => f.uid !== file.uid);
  //   setFileList(newFileList);
  //   form.setFieldValue("image", null);
  //   setNewData({ ...newData, image: null });
  // };
  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
    setNewData({ ...newData, image: null });
    setDeleteImages([...deleteImages, file?.name]);
    form.setFieldsValue({ image: null });
  };

  const beforeUpload = (file) => {
    if (!isImage(file)) {
      message.error("hanya bisa upload file gambar (.jpeg, .jpg, .png)");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("gambar ukurannya harus kurang dari 2MB!");
      return false;
    }
    if (!isImage(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isImage(file) ? [file] : []);
    return false;
  };
  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const isLt2M = newFileList[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return;
      }
    }
    setFileList(newFileList);
    setNewData({ ...newData, image: newFileList[0]?.originFileObj || null });
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    fileList,
    onChange: handleChange,
  };

  // end upload image

  const onSubmit = async () => {
    if (fileList.length === 0) {
      form.resetFields(["image"]);
    }
    await form.validateFields();

    if (Object.keys(newData).length === 0) {
      alert("Tidak ada yang berubah");
      return;
    }

    for (const key in newData) {
      if (Object.hasOwnProperty.call(newData, key)) {
        const element = newData[key];
        if (element === "") newData[key] = null;
      }
    }

    setLoading(true);
    try {
      await axios.put(
        `${REACT_APP_HOST_API}/landing-page/heros/${id}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("Berhasil menyimpan data!");
      navigate(-1);
    } catch (error) {
      renderModalError("Error Edit hero", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center">
      <div className="w-full md:w-1/2">
        <Form form={form} layout="vertical">
          <Form.Item
            name="image"
            label="Gambar"
            rules={[
              {
                required: fileList.length === 0,
              },
              {
                validator: (_, value) => {
                  const file = value?.fileList[0];
                  if (!file) {
                    return Promise.resolve();
                  }
                  if (!isImage(file)) {
                    return Promise.reject(
                      new Error("Please upload an image file")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              name="image"
              accept=".jpg,.jpeg,.png"
              listType="picture-card"
              showUploadList={true}
              onPreview={handlePreview}
              {...propsUpload}
              disabled={loading}
            >
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  {fileList.length === 0 ? (
                    <span style={{ fontSize: "12px" }}>
                      Upload <br />
                      (max 2 mb)
                    </span>
                  ) : (
                    "Ganti"
                  )}
                  {fileList.length === 0 ? "" : ""}
                </div>
              </div>
            </Upload>
          </Form.Item>

          <Form.Item name="title" label="Title" rules={[{ required: true }]}>
            <Input onChange={(e) => (newData["title"] = e.target.value)} />
          </Form.Item>

          <Form.Item name="description" label="Deskripsi">
            <TextArea
              rows={4}
              onChange={(e) => (newData["description"] = e.target.value)}
            />
          </Form.Item>

          <Form.Item name="link" label="Link">
            <Input onChange={(e) => (newData["link"] = e.target.value)} />
          </Form.Item>

          <Form.Item name="order" label="Order">
            <InputNumber style={{ width: "100%" }}
              onChange={(e) => newData.order = e}
            />
          </Form.Item>

          <Form.Item
            name="is_active"
            label="Aktif"
            rules={[{ required: true }]}
          >
            <Radio.Group
              onChange={(e) => (newData["is_active"] = e.target.checked)}
            >
              <Radio value={true}>Aktif</Radio>
              <Radio value={false}>Tidak</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>

        <div className="flex justify-center">
          <Button onClick={onSubmit} type="primary" className="w-full md:w-1/2">
            Simpan
          </Button>
        </div>
      </div>
    </div>
  );
}
