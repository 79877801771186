import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useBillingsPagination = (
  dataTable,
  keyword = "",
  filterObject,
  foundationId
) => {
  return useQuery(
    [
      "get-billings-pagination",
      dataTable.current_page,
      dataTable.per_page,
      keyword,
      filterObject.tipe,
      filterObject.from_date,
      filterObject.to_date,
      filterObject.isGraduated,
      foundationId,
      filterObject.angkatan || "",
    ],
    () =>
      GetPagination(
        `/finance/billings?page=${dataTable.current_page}&limit=${dataTable.per_page}&mode=page&keyword=${keyword}&tipe=${filterObject.tipe}&from_date=${filterObject.from_date}&to_date=${filterObject.to_date}&is_graduated=${filterObject.isGraduated}&foundation_id=${foundationId}&angkatan=${filterObject.angkatan || ""}`
      )
  );
};
