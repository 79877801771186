import { Form, Input, Modal, Select, Skeleton, Upload } from "antd";
import React from "react";
import { message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useEffect, useState } from "react";
import { useUnitDetail } from "hooks/unit-hook/useUnitDetail";
import { PlusOutlined } from "@ant-design/icons";
import { useKodeKeuanganPagination } from "hooks/finance-report-hook/useKodeKeuanganPagination";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";

const EditUnit = (props) => {
  const [fileList, setFileList] = useState([]);
  const [fileLogoList, setFileLogoList] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  const { REACT_APP_HOST_API } = process.env;

  const { data: dataFoundation } = useFoundationList();

  // fetch detail units
  const {
    data: dataUnit,
    isLoading: skeleton,
    refetch: fetchData,
  } = useUnitDetail(props.id, false);



  const {
    data: dataKodeUnit,
    isLoading: isLoadingKodeUnit,
    isFetching: isFetchingKodeUnit,
  } = useKodeKeuanganPagination(
    {
      current_page: 1,
      per_page: 1000,
      total: 0,
    },
    "",
    '',
    "unit");

  const setData = async () => {
    const { data: successData } = await fetchData();

    if (successData) {
      form.setFieldsValue({
        name: successData.data?.name,
        description: successData.data?.description,
        foundationId: successData.data?.foundation_id,
        codeUnitId: successData.data?.code_unit_id,
        phone: successData.data?.phone,
        email: successData.data?.email,
        address: successData.data?.address,
        website: successData.data?.website,
      });
      if (successData.data?.signature) {
        setFileList([
          {
            uid: "-1",
            name: "signature.png",
            status: "done",
            url: successData.data?.signature,
          },
        ]);
      }

      if (successData.data?.logo) {
        setFileLogoList([
          {
            uid: "-1",
            name: "logo.png",
            status: "done",
            url: successData.data?.logo,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    if (props.show) {
      setData();
    }

    return () => setNewData({});
    // eslint-disable-next-line
  }, [form, props.show]);

  // upload image
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // Set the preview property if it's not already set
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    // Show preview modal
    Modal.info({
      title: file.name,
      content: (
        <img
          alt="preview"
          style={{ width: "100%" }}
          src={file.url || file.preview}
        />
      ),
    });
  };
  const isImage = (file) => {
    return file.type === "image/png";
  };

  const handleChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
    const { signature } = await form.validateFields();
    setNewData({ ...newData, signature: signature.file });
  };

  const handleChangeLogo = async ({ fileList: newFileList }) => {
    setFileLogoList(newFileList);
    const { logo } = await form.validateFields();
    setNewData({ ...newData, logo: logo.file });
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("Hanya bisa upload file gambar (.png)");
    }

    if (file.size > 2000000) {
      message.error("Ukuran maksimal foto 2MB");
      return false;
    }

    if (!isImage && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }

    setFileList(isImage ? [file] : []);
    return false;
  };

  const beforeUploadLogo = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("Hanya bisa upload file gambar (.png)");
    }

    if (file.size > 2000000) {
      message.error("Ukuran maksimal foto 2MB");
      return false;
    }

    if (!isImage && fileLogoList.length > 0) {
      setFileLogoList([...fileLogoList]);
      return false;
    }

    setFileLogoList(isImage ? [file] : []);
    return false;
  };

  const onDeleteImg = async () => {
    try {
      axios.delete(REACT_APP_HOST_API + `/unit-delete-image/${props.id}`, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });

      message.success("Berhasil menghapus gambar");
      fetchData();
    } catch (error) {
      console.log(error);
      message.error("Gagal menghapus gambar");
    }
  };
  const handleRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const handleRemoveLogo = (file) => {
    const index = fileLogoList.indexOf(file);
    const newFileList = fileLogoList.slice();
    newFileList.splice(index, 1);
    setFileLogoList(newFileList);
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    fileList,
    onChange: handleChange,
  };

  const propsUploadLogo = {
    onRemove: handleRemoveLogo,
    beforeUpload: beforeUploadLogo,
    fileList: fileLogoList,
    onChange: handleChangeLogo,
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      if (Object.keys(newData).length === 0) {
        message.error("Tidak ada perubahan data");
        return;
      }

      for (const key in newData) {
        if (Object.hasOwnProperty.call(newData, key)) {
          const element = newData[key];
          if (element === "") newData[key] = null;
        }
      }

      if (fileList.length === 0) {
        newData.signature = null;
      }

      setLoading(true);

      if (dataUnit?.data?.signature && newData?.signature === null) {
        onDeleteImg();
      }

      const { data } = await axios.put(
        REACT_APP_HOST_API + `/units/${props.id}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success(data.message);
      form.resetFields();
      setFileList([]);
      props.onUpdate();
    } catch (error) {
      let msg = error?.response?.data?.message || "Input Field Error";
      message.error(msg);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setFileList([]);
    props.onCancel();
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      open={props.show}
      onCancel={handleCancel}
      okButtonProps={{ loading }}
    >
      {skeleton && <Skeleton active />}

      {!skeleton && (
        <Form form={form} layout="vertical">
          <Form.Item
            label="Nama Divisi"
            name="name"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Input
              onChange={({ target: { value } }) => (newData["name"] = value)}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Deskripsi"
            rules={[
              { required: true, message: "Harap diisi" },
              { min: 6, message: "Minimal length 6" },
            ]}
          >
            <Input
              onChange={({ target: { value } }) =>
                (newData["description"] = value)
              }
            />
          </Form.Item>
          {isSuperAdmin && (
            <Form.Item
              name="foundationId"
              label="Foundation"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                placeholder="Pilih foundation"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => (newData.foundationId = e)}
              >
                {dataFoundation?.data?.data?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="signature"
            label="Gambar"
            rules={[
              {
                validator: (_, value) => {
                  const file = value?.fileList[0];
                  if (!file) {
                    return Promise.resolve();
                  }
                  if (!isImage(file)) {
                    return Promise.reject(
                      new Error("Please upload an image file")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              name="file"
              accept=".png"
              listType="picture-card"
              showUploadList={true}
              onPreview={handlePreview}
              {...propsUpload}
              disabled={loading}
            >
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  {fileList.length === 0 ? (
                    <span style={{ fontSize: "12px" }}>
                      Upload <br />
                      (max 2 mb)
                    </span>
                  ) : (
                    "Ganti"
                  )}
                </div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item
            name="logo"
            label="Logo"
            rules={[
              {
                validator: (_, value) => {
                  const file = value?.fileList[0];
                  if (!file) {
                    return Promise.resolve();
                  }
                  if (!isImage(file)) {
                    return Promise.reject(
                      new Error("Please upload an image file")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              name="file"
              accept=".png"
              listType="picture-card"
              showUploadList={true}
              onPreview={handlePreview}
              {...propsUploadLogo}
              disabled={loading}
            >
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  {fileLogoList.length === 0 ? (
                    <span style={{ fontSize: "12px" }}>
                      Upload <br />
                      (max 2 mb)
                    </span>
                  ) : (
                    "Ganti"
                  )}
                </div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item
            name="codeUnitId"
            label="Kode Unit"
          >
            <Select
              showSearch
              placeholder="Pilih Kode Unit"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              disabled={(isLoadingKodeUnit || isFetchingKodeUnit)}
              onChange={(value) => (newData["codeUnitId"] = value)}
            >
              {dataKodeUnit?.data?.data?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {`${item.code} - ${item.name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Nomor HP"
            name="phone"
            rules={[
              {
                pattern: new RegExp(/^[0-9\b]+$/),
                message: "Nomor HP tidak valid",
              },
            ]}
          >
            <Input
              onChange={({ target: { value } }) => (newData["phone"] = value)}
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            // rules email
            rules={[
              {
                type: "email",
                message: "Email tidak valid",
              },
            ]}
          >
            <Input
              onChange={({ target: { value } }) => (newData["email"] = value)}
            />
          </Form.Item>
          <Form.Item
            label="Alamat"
            name="address"
          >
            <Input
              onChange={({ target: { value } }) => (newData["address"] = value)}
            />
          </Form.Item>
          <Form.Item
            label="Website"
            name="website"
          >
            <Input 
                onChange={({ target: { value } }) => (newData["website"] = value)}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default React.memo(EditUnit);
