import {
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Skeleton,
} from "antd";
import axios from "axios";
import {
  decryptCookies,
  encryptCookies,
} from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useUnitList } from "hooks/unit-hook/useUnitList";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AddBatch = ({ show, onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [borrowerType, setBorrowerType] = useState("internal");
  const [foundationId, setFoundationId] = useState("");
  const [unitId, setUnitId] = useState("");

  const typeLoan = ["TEACH", "WORK", "PERSONAL", "EVENT"];
  const format = "YYYY-MM-DD HH:mm:ss";
  const { REACT_APP_HOST_API } = process.env;
  const navigate = useNavigate();
  const roles = decryptCookies("role");

  const {
    data,
    isLoading,
    refetch: fetchEmployee,
  } = useEmployeeList("", "", "", "", foundationId, "", unitId);
  const { data: dataFoundation } = useFoundationList();
  const { data: dataUnit, refetch: fetchUnit } = useUnitList("", foundationId);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const payload = {
        ...values,
        startDate: values.startDate?.format("YYYY-MM-DD HH:mm:ss"),
        endDate: values.endDate?.format("YYYY-MM-DD HH:mm:ss"),
      };

      setLoading(true);

      const { data } = await axios.post(
        `${REACT_APP_HOST_API}/inventories/asset-loan-batches`,
        {
          ...payload,
        },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );

      message.success(data.message);
      encryptCookies("asset_loan", "1");
      navigate(`/inventory/loanbatch/${data.data.id}`);
    } catch (error) {
      Modal.error({
        title: "Gagal Menyimpan",
        content: <pre>{error?.response?.data?.message}</pre>,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!show) return;

    if (dataUnit) {
      const unit = dataUnit?.data?.find(
        (unit) => unit.name.toLowerCase() === "sma fg"
      );
      if (unit) {
        setUnitId(unit.id);
        form.setFieldsValue({ unitId: unit.id });
      }
    }
    if (dataFoundation && roles.includes("super_admin")) {
      const foundation = dataFoundation?.data?.data?.find(
        (foundation) =>
          foundation.name.toLowerCase() === "yayasan islam prambanan"
      );
      if (foundation) {
        setFoundationId(foundation.id);
        form.setFieldsValue({ foundationId: foundation.id });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, dataFoundation]);

  useEffect(() => {
    fetchUnit();
    fetchEmployee();
    // eslint-disable-next-line
  }, [foundationId, unitId]);

  const cancelModal = () => {
    form.resetFields();
    setFoundationId("");
    setUnitId("");
    setBorrowerType("internal");
    onCancel();
  };

  return (
    <Modal
      className="top-5"
      open={show}
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      onCancel={cancelModal}
      okButtonProps={{ loading }}
    >
      {isLoading ? (
        <>
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <Form
          form={form}
          layout="vertical"
          // initialValues={{
          //   startDate: moment(new Date().toISOString()),
          // }}
        >
          <Form.Item label="Tipe" name="borrowerType" initialValue={"internal"}>
            <Select onChange={setBorrowerType}>
              <Select.Option value="internal">Internal</Select.Option>
              <Select.Option value="external">Eksternal</Select.Option>
            </Select>
          </Form.Item>

          {roles.includes("super_admin") && borrowerType === "internal" && (
            <Form.Item
              label="Yayasan"
              name="foundationId"
              rules={[{ required: true, message: "Harap diisi" }]}
            >
              <Select
                placeholder="Pilih Yayasan"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => {
                  setFoundationId(e);
                  setUnitId("");
                  form.resetFields(["unitId"]);
                  form.resetFields(["employeeId"]);
                }}
              >
                {dataFoundation?.data?.data?.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {borrowerType === "internal" && (
            <>
              <Form.Item
                label="Unit"
                name="unitId"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(e) => {
                    setUnitId(e);
                    form.resetFields(["employeeId"]);
                  }}
                >
                  {dataUnit?.data?.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="employeeId"
                label="Penanggung Jawab"
                rules={[{ required: true, message: "Harap diisi" }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {data?.data.map((data) => (
                    <Select.Option key={data.id} value={data.id}>
                      {data.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}

          {borrowerType === "external" && (
            <Form.Item
              label="Penanggung Jawab"
              name="pic"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          )}

          <Form.Item
            name="type"
            label="Tipe Peminjaman"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {typeLoan.map((data) => (
                <Select.Option key={data} value={data}>
                  {data}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="description"
            label="Deskripsi"
            rules={[
              { required: true, message: "Harap diisi" },
              { min: 10, message: "Masukkan minimal 10 huruf" },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item
            name="startDate"
            label="Waktu Awal Peminjaman"
            initialValue={dayjs(
              moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
            )}
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <DatePicker showTime format={format} />
          </Form.Item>

          <Form.Item name="endDate" label="Waktu Akhir Peminjaman">
            <DatePicker showTime format={format} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AddBatch;
