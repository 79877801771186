import { Form, message, Modal } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useAssetStatusList } from "hooks/asset-hook/useAssetStatusList";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { InventoryContext } from "../../inventory-context/InventoryContext";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useAssetCategories } from "hooks/asset-hook/useAssetCategories";
import { useAssetSumberDana } from "hooks/asset-hook/useAssetSumberDana";

const EditAssetLogic = ({ id, show, onUpdate, onCancel }) => {
  const [newData, setNewData] = useState({});
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skeleton, setSkeleton] = useState(false);
  const [dataDetail, setDataDetail] = useState({});

  const [tags, setTags] = useState([]);
  const [editTagIndex, setEditTagIndex] = useState(-1);
  const [addTagInputValue, setAddTagInputValue] = useState("");
  const [editTagInputValue, setEditTagInputValue] = useState("");
  const [addTagVisible, setAddTagVisible] = useState(false);
  const [foundationId, setFoundationId] = useState("");
  const inputAddRef = useRef(null);
  const inputEditRef = useRef(null);

  // sumber dana state
  const [isEmptyNameSumberDana, setIsEmptyNameSumberDana] = useState(false);
  const [itemsSumberDana, setItemsSumberDana] = useState([]);
  const [nameSumberDana, setNameSumberDana] = useState("");
  const inputRefSumberDana = useRef(null);

  // category state
  const [isEmptyNameCategory, setIsEmptyNameCategory] = useState(false);
  const [itemsCategory, setItemsCategory] = useState([]);
  const [nameCategory, setNameCategory] = useState("");
  const inputRefCategory = useRef(null);

  const [form] = Form.useForm();
  const format = "YYYY-MM-DD";
  const { REACT_APP_HOST_API } = process.env;
  const { setInventoryFetch } = useContext(InventoryContext);
  const roles = decryptCookies("role").split(",");

  const { data, refetch: fetchStatus } = useAssetStatusList();
  const { data: dataFoundation } = useFoundationList();
  const { data: dataSumberDana, refetch: fetchSumberDana } = useAssetSumberDana(
    "",
    foundationId
  );
  const { data: dataCategory, refetch: fetchCategory } = useAssetCategories(
    "",
    foundationId
  );

  const errorModal = (title, content) => {
    Modal.error({
      title,
      content,
    });
  };

  useEffect(() => {
    setItemsSumberDana(dataSumberDana?.data);
    setItemsCategory(dataCategory?.data);
  }, [dataSumberDana, dataCategory]);

  // block sumber dana
  const onNameChangeSumberDana = (event) => {
    setNameSumberDana(event.target.value);
  };

  const addItemSumberDana = async () => {
    const foundation_id = form.getFieldValue("foundationId") || "";

    if (!foundation_id && roles.some((x) => x === "super_admin")) {
      errorModal(
        "Gagal menambahkan sumber dana",
        "Harap pilih foundation terlebih dahulu"
      );
      return;
    }

    if (!nameSumberDana) {
      message.error("Sumber Dana harus diisi");
      setIsEmptyNameSumberDana(true);
      return;
    }

    try {
      await axios.post(
        REACT_APP_HOST_API + "/inventories/asset-fund-source",
        { foundation_id, name: nameSumberDana },
        {
          headers: {
            Authorization: `Bearer ${decryptCookies("token")}`,
          },
        }
      );
      setNameSumberDana("");
      setIsEmptyNameSumberDana(false);
      fetchSumberDana();
      message.success("Berhasil menambahkan sumber dana");
    } catch (error) {
      errorModal(
        "Gagal menambahkan sumber dana",
        <pre>{error?.response?.data?.message}</pre>
      );
    }
  };
  // end block Sumber dana

  // block Kategori
  const onNameChangeCategory = (event) => {
    setNameCategory(event.target.value);
  };

  const addItemCategory = async () => {
    const foundation_id = form.getFieldValue("foundationId") || "";

    if (!foundation_id && roles.some((x) => x === "super_admin")) {
      errorModal(
        "Gagal menambahkan kategori",
        "Harap pilih foundation terlebih dahulu"
      );
      return;
    }

    if (!nameCategory) {
      message.error("kategori harus diisi");
      setIsEmptyNameCategory(true);
      return;
    }

    try {
      await axios.post(
        REACT_APP_HOST_API + "/inventories/asset-categories",
        { foundation_id, name: nameCategory },
        {
          headers: {
            Authorization: `Bearer ${decryptCookies("token")}`,
          },
        }
      );
      setNameCategory("");
      setIsEmptyNameCategory(false);
      fetchCategory();
      message.success("Berhasil menambahkan kategori");
    } catch (error) {
      errorModal(
        "Gagal menambahkan kategori",
        <pre>{error?.response?.data?.message}</pre>
      );
    }
  };
  // end block Kategori

  useEffect(() => {
    const fetchData = async () => {
      setSkeleton(true);
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API + `/inventories/assets/${id}`,
          { headers: { Authorization: "Bearer " + decryptCookies("token") } }
        );

        if (data.data.image) {
          setFileList([
            {
              uid: "-1",
              status: "done",
              url: REACT_APP_HOST_API + data.data.image[1],
              thumbUrl: REACT_APP_HOST_API + data.data.image[1],
            },
          ]);
        }

        if (data.data.tag) setTags(data.data.tag);
        setDataDetail(data.data);
        setFoundationId(data.data.foundation_id);
        form.setFieldsValue({
          foundationId: data.data.foundation_id,
          categories: data.data.categories?.map((x) => x.id),
          assetFundSourceId: data.data?.fundSource?.id,
          serial: data.data.serial,
          description: data.data.description,
          assetStatusId: data.data.asset_status_id,
          purchaseDate:
            data.data.purchase_date && moment(data.data.purchase_date),
          orderNumber: data.data.order_number,
          price: data.data.price,
          warranty: data.data.warranty,
          notes: data.data.notes,
        });
      } catch (error) {
        console.log(error);
        alert(error);
      }
      setSkeleton(false);
    };

    if (show && id) {
      fetchData();
      fetchStatus();
    }

    return () => {
      setNewData({});
    };
  }, [show, id, form, REACT_APP_HOST_API, fetchStatus]);

  const handleSubmit = async () => {
    if (fileList.length === 0) {
      form.resetFields(["image"]);
    }
    await form.validateFields();
    if (Object.keys(newData).length === 0) {
      alert("Tidak ada yang dirubah");
      return;
    }

    for (const key in newData) {
      if (Object.hasOwnProperty.call(newData, key)) {
        let element = newData[key];
        if (element === "") {
          newData[key] = null;
        }
      }
    }

    setLoading(true);

    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + `/inventories/assets/${id}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success(data.message);
      form.resetFields();
      onUpdate();
      setFileList([]);
      setInventoryFetch(true);
    } catch (error) {
      errorModal(
        "Gagal Mengubah Data",
        <pre>{error?.response?.data?.message}</pre>
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    newData[event.target.name] = event.target.value;
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
    setFileList([]);
    setTags([]);
  };

  // Tags Function
  useEffect(() => {
    if (addTagVisible) inputAddRef.current?.focus();
  }, [addTagVisible]);

  useEffect(() => {
    inputEditRef.current?.focus();
  }, [editTagInputValue]);

  const showInput = () => {
    setAddTagVisible(true);
  };

  const onInputAddTagChange = ({ target: { value } }) => {
    setAddTagInputValue(value);
  };

  const onSubmitAddTag = () => {
    if (addTagInputValue && tags.indexOf(addTagInputValue) === -1) {
      newData["tag"] = [...tags, addTagInputValue];
      setTags((curr) => [...curr, addTagInputValue]);
    }

    setAddTagVisible(false);
    setAddTagInputValue("");
  };

  const onInputEditTagChange = ({ target: { value } }) => {
    setEditTagInputValue(value);
  };

  const onSubmitEditTag = () => {
    const newTags = [...tags];

    if (editTagInputValue) {
      newTags[editTagIndex] = editTagInputValue;
      newData["tag"] = newTags;
      setTags(newTags);
    }

    setEditTagIndex(-1);
    setEditTagInputValue("");
  };

  const handleRemoveTag = (param) => {
    const newTags = tags.filter((tag) => tag !== param);
    newData["tag"] = newTags;
    setTags(newTags);
  };

  // upload image
  const [deleteImages, setDeleteImages] = useState([]);

  const isImage = (file) => {
    return (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    );
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // Set the preview property if it's not already set
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    // Show preview modal
    Modal.info({
      title: file.name,
      content: (
        <img
          alt="preview"
          style={{ width: "100%" }}
          src={file.url || file.preview}
        />
      ),
    });
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
    setNewData({ ...newData, image: null });
    setDeleteImages([...deleteImages, file?.name]);
    form.setFieldsValue({ image: null });
  };

  const beforeUpload = (file) => {
    if (!isImage(file)) {
      message.error("hanya bisa upload file gambar (.jpeg, .jpg, .png)");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("gambar ukurannya harus kurang dari 2MB!");
      return false;
    }
    if (!isImage(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isImage(file) ? [file] : []);
    return false;
  };
  const handleChangeImage = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const isLt2M = newFileList[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return;
      }
    }
    setFileList(newFileList);
    setNewData({ ...newData, image: newFileList[0]?.originFileObj || null });
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    fileList,
    onChange: handleChangeImage,
  };
  // end upload image

  return {
    isImage,
    propsUpload,
    loading,
    handleSubmit,

    roles,
    setFoundationId,
    dataFoundation,

    inputRefSumberDana,
    nameSumberDana,
    onNameChangeSumberDana,
    isEmptyNameSumberDana,
    addItemSumberDana,
    itemsSumberDana,

    inputRefCategory,
    nameCategory,
    onNameChangeCategory,
    isEmptyNameCategory,
    addItemCategory,
    itemsCategory,
    // --
    data,
    tags,
    show,
    form,
    format,
    newData,
    fileList,
    skeleton,
    dataDetail,
    inputAddRef,
    inputEditRef,
    editTagIndex,
    addTagVisible,
    editTagInputValue,
    showInput,
    setFileList,
    handleChange,
    onCancelModal,
    handlePreview,
    onSubmitAddTag,
    onSubmitEditTag,
    handleRemoveTag,
    setEditTagIndex,
    onInputAddTagChange,
    setEditTagInputValue,
    onInputEditTagChange,
  };
};

export default EditAssetLogic;
