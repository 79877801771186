import { EditOutlined } from "@ant-design/icons";
import { Button, Image } from "antd";
import { useAboutDetail } from "hooks/landing-page-hook/useAboutDetail";
import { usePermission } from "hooks/usePermissions";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function DetailAbout() {
  const { id } = useParams();

  const { data } = useAboutDetail(id);
  const navigate = useNavigate();

  // fetch  permission
  const { data: dataPermission } = usePermission(
    "mdlLandingPage",
    "mnuAboutMe"
  );

  // Buttons Permission start
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditAboutMe");

  return (
    <div className="flex justify-center">
      <div className="absolute w-full p-4 md:p-0 md:w-1/2">
        <div className="flex justify-between">
          <Image width={150} src={data?.data?.logo} alt="logo" />

          <Button
            onClick={() => {
              if (btnEdit?.type === "disabled") return;
              navigate(`/landing-page/about/edit/${id}`);
            }}
            hidden={!btnEdit}
            disabled={btnEdit?.type === "disabled"}
            icon={<EditOutlined />}
            type="primary"
          >
            Edit
          </Button>
        </div>
        <table className="detail-teachers__table">
          <tbody>
            <tr>
              <th>Alamat</th>
              <td>: {data?.data?.address}</td>
            </tr>
            <tr>
              <th>No. Telp</th>
              <td>: {data?.data?.phone}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>: {data?.data?.email}</td>
            </tr>
          </tbody>
        </table>

        <h1 className="text-xl font-bold">Tentang</h1>
        <div
          className="rte-wrapper"
          dangerouslySetInnerHTML={{ __html: data?.data?.about }}
        />

        <h1 className="mt-5 text-xl font-bold">Visi</h1>
        <div
          className="rte-wrapper"
          dangerouslySetInnerHTML={{ __html: data?.data?.vision }}
        />

        <h1 className="mt-5 text-xl font-bold">Misi</h1>
        <div
          className="rte-wrapper"
          dangerouslySetInnerHTML={{ __html: data?.data?.mission }}
        />
      </div>
    </div>
  );
}
