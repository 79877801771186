import {
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  TimePicker,
  message,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useActivityCategory } from "hooks/activity-hook/useActivityCategory";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useUnitList } from "hooks/unit-hook/useUnitList";

const CreateActivity = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fixedTime, setFixedTime] = useState(false);
  const [timeInStart, setTimeInStart] = useState(false);

  const format = "HH:mm:ss";
  const { REACT_APP_HOST_API } = process.env;

  const { data: dataCategory, refetch: fetchCategory } = useActivityCategory();
  const { data: dataUnits, isLoading: loadingUnits } = useUnitList();
  const isSuperAdmin = decryptCookies("role")
    .split(",")
    .some((x) => x === "super_admin" || x === "admin_foundation");

  useEffect(() => {
    if (props.show) {
      fetchCategory();
    }

    return () => setFixedTime(false);
  }, [props.show, fetchCategory]);

  const handleCreate = async () => {
    const values = await form.validateFields();
    if (!isSuperAdmin) {
      const unitIdFromArray = dataUnits?.data?.find(
        (x) => x.employeeUnits.length > 0
      );
      values.unitId = unitIdFromArray?.id;
    }

    setLoading(true);

    try {
      const { data } = await axios.post(
        REACT_APP_HOST_API + `/activity`,
        {
          ...values,
          type: "standalone",
          maxWorkingDuration:
            values.maxWorkingDuration &&
            values.maxWorkingDuration.format(format),
          timeInStart: values.timeInStart && values.timeInStart.format(format),
          timeLateStart:
            values.timeLateStart && values.timeLateStart.format(format),
          timeInEnd: values.timeInEnd && values.timeInEnd.format(format),
          timeOutStart:
            values.timeOutStart && values.timeOutStart.format(format),
          timeOutEnd: values.timeOutEnd && values.timeOutEnd.format(format),
          timeOutDefault:
            values.timeOutDefault && values.timeOutDefault.format(format),
        },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      props.onCreate();
      form.resetFields();
      message.success(data.message, 2);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelModal = () => {
    props.onCancel();
    form.resetFields();
  };

  return (
    <Modal
      okText="Simpan"
      cancelText="Batal"
      onOk={handleCreate}
      open={props.show}
      okButtonProps={{ loading }}
      onCancel={handleCancelModal}
      style={{ top: window.innerHeight > 800 ? "" : 20 }}
      width={window.innerWidth > 1300 ? "70%" : "100%"}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        className="full-form"
      >
        <div className="first-form">
          <Form.Item
            label="Tipe"
            name="activityType"
            rules={[{ required: true }]}
          >
            <Select
              options={[
                { label: "Setiap Hari", value: "fixed_time" },
                { label: "Tidak Setiap Hari", value: "not_fixed_time" },
              ]}
              onChange={(e) => {
                if (e === "fixed_time") setFixedTime(true);
                else setFixedTime(false);
              }}
            />
          </Form.Item>
          <Form.Item
            name="name"
            label="Nama Aktivitas"
            rules={[{ required: true, message: "harap diisi" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Deskripsi">
            <Input />
          </Form.Item>
          {isSuperAdmin && (
            <Form.Item
              name="unitId"
              label="Unit"
              rules={[{ required: true, message: "Harap diisi" }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                disabled={loadingUnits}
              >
                {dataUnits?.data?.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label="Kategori"
            name="categoryActivityId"
            rules={[{ required: true }]}
          >
            <Select>
              {dataCategory?.data?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            hidden={fixedTime}
            label="Default Pertemuan dalam Sebulan"
            name="default"
          >
            <InputNumber style={{ width: "100%" }} min={0} />
          </Form.Item>
          <Form.Item
            label="Masuk Penilaian"
            name="assessment"
            rules={[{ required: true }]}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={true}>Masuk</Radio.Button>
              <Radio.Button value={false}>Tidak</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
        {!fixedTime ? (
          ""
        ) : (
          <div className="second-form">
            <Form.Item
              name="maxWorkingDuration"
              label="Durasi Maksimal Jam Kerja"
              initialValue={dayjs("10:00:00", "HH:mm:ss")}
            >
              <TimePicker
                popupClassName="time-picker"
                showNow={false}
                format="HH:mm:ss"
                placeholder="jam/menit/detik"
              />
            </Form.Item>
            <Form.Item
              name="timeInStart"
              label="Awal Waktu Masuk"
              rules={[{ required: fixedTime }]}
            >
              <TimePicker
                popupClassName="time-picker"
                showNow={false}
                onChange={(e) => {
                  if (e) setTimeInStart(true);
                  else setTimeInStart(false);
                }}
                format={format}
                placeholder="jam/menit"
              />
            </Form.Item>
            <Form.Item
              name="timeLateStart"
              label="Waktu Terlambat"
              rules={[
                { required: timeInStart },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      getFieldValue("timeInStart").format("HH:mm") <
                        value.format("HH:mm")
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "masukkan diatas jam " +
                          getFieldValue("timeInStart").format("HH:mm")
                      )
                    );
                  },
                }),
              ]}
            >
              <TimePicker
                popupClassName="time-picker"
                showNow={false}
                format={format}
                placeholder="jam/menit"
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              name="timeInEnd"
              label="Akhir Waktu Masuk"
              rules={[
                { required: timeInStart },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      getFieldValue("timeInStart").format("HH:mm") <
                        value.format("HH:mm")
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "masukkan diatas jam " +
                          getFieldValue("timeInStart").format("HH:mm")
                      )
                    );
                  },
                }),
              ]}
            >
              <TimePicker
                popupClassName="time-picker"
                showNow={false}
                format={format}
                placeholder="jam/menit"
              />
            </Form.Item>

            <Form.Item
              name="timeOutStart"
              label="Awal Waktu Pulang"
              hasFeedback
              rules={[
                { required: timeInStart },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      getFieldValue("timeInEnd").format("HH:mm") <
                        value.format("HH:mm")
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "masukkan diatas jam " +
                          getFieldValue("timeInEnd").format("HH:mm")
                      )
                    );
                  },
                }),
              ]}
            >
              <TimePicker
                popupClassName="time-picker"
                showNow={false}
                format={format}
                placeholder="jam/menit"
              />
            </Form.Item>

            <Form.Item
              name="timeOutEnd"
              label="Akhir Waktu Pulang"
              hasFeedback
              rules={[
                { required: timeInStart },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      getFieldValue("timeOutStart").format("HH:mm") <
                        value.format("HH:mm")
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "masukkan diatas jam " +
                          getFieldValue("timeOutStart").format("HH:mm")
                      )
                    );
                  },
                }),
              ]}
            >
              <TimePicker
                popupClassName="time-picker"
                showNow={false}
                format={format}
                placeholder="jam/menit"
              />
            </Form.Item>
            <Form.Item
              name="timeOutDefault"
              label="Waktu Pulang Default (bagi yang tidak tap pulang)"
              hasFeedback
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      getFieldValue("timeOutStart").format("HH:mm") <
                        value.format("HH:mm")
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "masukkan diatas jam " +
                          getFieldValue("timeOutStart").format("HH:mm")
                      )
                    );
                  },
                }),
              ]}
            >
              <TimePicker
                popupClassName="time-picker"
                showNow={false}
                format={format}
                placeholder="jam/menit"
              />
            </Form.Item>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default CreateActivity;
