import { message, Modal, Tag, Tooltip } from "antd";
import React from "react";
import { FaRegCopy } from "react-icons/fa";

export default function DetailLink({ open, setOpen, data }) {
  const onCopyLink = () => {
    navigator.clipboard.writeText(data?.url);
    message.success("Berhasil menyalin link");
  };
  return (
    <Modal open={open} onCancel={setOpen} footer={false}>
      <div className="table-header">
        <h1>DETAIL LINK</h1>
      </div>

      <div>
        <table>
          <tbody>
            <tr>
              <td>Link</td>
              <td
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                :
                <a
                  href={
                    data?.url?.startsWith("http://") ||
                    data?.url?.startsWith("https://")
                      ? data?.url
                      : `https://${data?.url}`
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "#4096FF",
                    marginLeft: 5,
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-all",
                    maxWidth: "100%",
                  }}
                >
                  {data?.url}
                </a>
                <Tooltip title="Copy Link">
                  <span
                    style={{
                      cursor: "pointer",
                      marginLeft: 5,
                      color: "#4096FF",
                    }}
                    onClick={() => onCopyLink()}
                  >
                    <FaRegCopy />
                  </span>
                </Tooltip>
              </td>
            </tr>
            <tr>
              <td>Deskripsi</td>
              <td>: {data?.description}</td>
            </tr>
            <tr>
              <td>Kategori</td>
              <td>
                :{" "}
                {data?.categories?.map((x) => (
                  <Tag key={x?.id} color="blue">
                    {x?.name}
                  </Tag>
                ))}
              </td>
            </tr>
            <tr>
              <td>Owner</td>
              <td>: {data?.employee}</td>
            </tr>
            {/* <tr>
              <td>Grub</td>
              <td>
                :
                {data?.groub?.map((x) => (
                  <Tag key={x?.id} color="blue">
                    {x?.name}
                  </Tag>
                ))}
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
