import { Button, Image, Popconfirm, Table, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { useAboutList } from "hooks/landing-page-hook/useAboutList";
import { usePermission } from "hooks/usePermissions";
import React from "react";
import { useNavigate } from "react-router-dom";

const disabled = { color: "#ccc", cursor: "not-allowed" };
export default function About() {
  const navigate = useNavigate();

  //fetch about
  const { data: dataAbout, refetch } = useAboutList();

  // fetch  permission
  const { data: dataPermission } = usePermission(
    "mdlLandingPage",
    "mnuAboutMe"
  );

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddAboutMe");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditAboutMe");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteAboutMe");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailAboutMe");

  const columns = [
    {
      title: "Logo",
      dataIndex: "logo",
      render: (logo) => <Image width={100} src={logo} />,
    },
    {
      title: "Alamat",
      dataIndex: "address",
      width: 200,
    },
    {
      title: "No. Telp",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Aksi",
      dataIndex: "id",
      render: (id) => (
        <>
          <Popconfirm
            title="Yakin ingin menghapus ?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() => {
              const dataId = id;
              DeleteApi({ url: "/landing-page/abouts/", dataId, refetch });
            }}
            disabled={btnDelete && btnDelete.type === "disabled"}
          >
            <Tag
              color="magenta"
              hidden={!btnDelete}
              style={
                btnDelete?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
            >
              Hapus
            </Tag>
          </Popconfirm>
          <Tag
            color="orange"
            hidden={!btnEdit}
            style={
              btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            onClick={() => {
              if (btnEdit?.type === "disabled") {
                return;
              } else {
                navigate(`edit/${id}`);
              }
            }}
            disabled={btnEdit?.type === "disabled"}
          >
            Edit
          </Tag>
          <Tag
            color="blue"
            hidden={!btnDetail}
            style={
              btnDetail?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            onClick={() => {
              if (btnDetail?.type === "disabled") return;

              navigate(`${id}`);
            }}
          >
            Detail
          </Tag>
        </>
      ),
    },
  ];

  const dataSource = dataAbout?.data?.map((x) => {
    return {
      ...x,
      key: x.id,
    };
  });

  return (
    <div>
      <div className="table-header">
        <h1>About</h1>

        <Button
          type="primary"
          hidden={!btnAdd || dataAbout?.data?.length > 0}
          disabled={btnAdd && btnAdd.type === "disabled"}
          onClick={() => navigate("add")}
        >
          Add About
        </Button>
      </div>

      <Table
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={dataSource}
        size="small"
        pagination={false}
      />
    </div>
  );
}
