import { Button, Input, Popconfirm, Table, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import AddFundSource from "../create/AddFundSource";
import { SearchOutlined } from "@ant-design/icons";
import FilterFoundation from "components/UI/FilterFoundation";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useAssetSumberDanaPagination } from "hooks/asset-hook/useAssetSumberDanaPagination";
import { DeleteApi } from "api/DeleteApi";
import { usePermission } from "hooks/usePermissions";
import EditFundSource from "../edit/EditFundSource";
import useResetPage from "components/Helper/useResetPage";

export default function FundSource() {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [record, setRecord] = useState({});
  const [keyword, setKeyword] = useState("");
  const [foundationId, setFoundationId] = useState("");
  const [dataTable, setDataTable] = useState({
    page: 1,
    per_page: 10,
    total: 0,
  });

  const roles = decryptCookies("role").split(",");

  const {
    data: dataFundSource,
    refetch,
    isFetching,
  } = useAssetSumberDanaPagination(dataTable, keyword, foundationId);

  // fetch permission
  const { data: dataPermission } = usePermission("mdlInventory", "mnuFilters");

  // Buttons Permission
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddSumberDanaInven");
  const btnEdit = dataPermission?.find(
    (x) => x.id === "btnEditSumberDanaInven"
  );
  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeleteSumberDanaInven"
  );

  const disabled = { color: "#ccc", cursor: "not-allowed" };
  const columns = [
    {
      title: "No.",
      dataIndex: "index",
    },
    {
      title: "Sumber Dana",
      dataIndex: "name",
    },
    {
      title: "Aksi",
      dataIndex: "id",
      render: (id, record) => {
        const isDisabled = (action) => {
          return action?.type === "disabled";
        };

        const getButtonStyle = (action) => {
          return isDisabled(action) ? disabled : { cursor: "pointer" };
        };
        return (
          <>
            <Popconfirm
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() => {
                DeleteApi({
                  url: "/inventories/asset-fund-source/",
                  dataId: id,
                  refetch,
                });
              }}
              disabled={isDisabled(btnDelete)}
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={getButtonStyle(btnDelete)}
              >
                Hapus
              </Tag>
            </Popconfirm>

            <Tag
              color="orange"
              hidden={!btnEdit}
              style={getButtonStyle(btnEdit)}
              onClick={() => {
                if (!isDisabled(btnEdit)) {
                  setRecord(record);
                  setOpenEdit(true);
                }
              }}
              disabled={isDisabled(btnEdit)}
            >
              Ubah
            </Tag>
          </>
        );
      },
    },
  ];

  const dataSource = dataFundSource?.data?.data?.map((x, i) => ({
    ...x,
    key: x.id,
    index: i + 1,
  }));

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: dataFundSource?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100, dataFundSource?.data.meta.total],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const handleOpenAdd = () => {
    setOpenAdd(!openAdd);
    refetch();
  };

  const handleOpenEdit = () => {
    setOpenEdit(!openEdit);
    refetch();
  };

  useResetPage(setDataTable, [keyword, foundationId]);

  return (
    <div>
      <div>
        <div className="table-header">
          <h1>Daftar Sumber Dana</h1>
          <Button
            hidden={!btnAdd}
            disabled={btnAdd && btnAdd.type === "disabled"}
            onClick={() => {
              if (btnAdd && btnAdd.type !== "disabled") {
                handleOpenAdd();
              }
            }}
            type="primary"
          >
            Tambah
          </Button>
        </div>

        <div className="grid gap-2 md:grid-cols-2 md:w-1/2">
          <Tooltip title="Cari Sumber Dana">
            <Input
              onChange={(e) => setKeyword(e.target.value)}
              prefix={<SearchOutlined />}
              placeholder="Cari Kategori..."
            />
          </Tooltip>

          {roles.includes("super_admin") && (
            <FilterFoundation setFoundationId={setFoundationId} />
          )}
        </div>

        <Table
          loading={isFetching}
          columns={columns}
          dataSource={dataSource}
          pagination={pagination}
          scroll={{ y: 400 }}
          size="small"
        />

        <AddFundSource open={openAdd} setOpen={handleOpenAdd} />
        <EditFundSource
          open={openEdit}
          setOpen={handleOpenEdit}
          record={record}
        />
      </div>
    </div>
  );
}
