import React, { useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Image,
  List,
  message,
  Popconfirm,
  Select,
  Space,
  Tooltip,
} from "antd";
import { useNavigate } from "react-router-dom";
import { usePermission } from "hooks/usePermissions";
import { useHeroList } from "hooks/landing-page-hook/useHeroList";
import { DeleteApi } from "api/DeleteApi";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";

export default function Hero() {
  const [expandedItems, setExpandedItems] = useState({});
  const [isActive, setIsActive] = useState("");
  const roles = decryptCookies("role").split(",");

  const toggleExpand = (id) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const navigate = useNavigate();
  const { REACT_APP_HOST_API } = process.env;
  const [form] = Form.useForm();

  // fetch hero list
  const { data: dataHero, refetch } = useHeroList(isActive);

  // fetch  permission
  const { data: dataPermission } = usePermission("mdlLandingPage", "mnuHero");

  // Buttons Permission start
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddHero");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditHero");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteHero");

  const editActive = async (id, val) => {
    await form.validateFields();

    try {
      await axios.put(
        `${REACT_APP_HOST_API}/landing-page/heros/${id}`,
        { is_active: val },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "application/json",
          },
        }
      );
      refetch();
      message.success("Data Berhasil Diupdate");
    } catch (error) {
      alert("Gagal Menyimpan Data");
      refetch();
    }
  };
  return (
    <>
      <div className="table-header">
        <h1>Hero List</h1>

        <Button
          type="primary"
          hidden={!btnAdd}
          disabled={btnAdd && btnAdd.type === "disabled"}
          onClick={() => navigate("add")}
        >
          Add Hero
        </Button>
      </div>

      <div className="mt-5">
        <Tooltip title="Status">
          <Select
            style={{ width: 200 }}
            defaultValue=""
            onChange={(e) => setIsActive(e)}
          >
            <Select.Option value="">Semua</Select.Option>
            <Select.Option value={true}>Aktif</Select.Option>
            <Select.Option value={false}>Tidak Aktif</Select.Option>
          </Select>
        </Tooltip>
      </div>

      <List
        className="border border-[#ccc] rounded-[5px] pb-5 mt-5"
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: (page) => {
            // console.log(page);
          },
          pageSize: 3,
        }}
        dataSource={dataHero?.data}
        renderItem={(hero) => (
          <List.Item
            key={hero?.title}
            actions={[
              <Popconfirm
                title="Yakin ingin menghapus ?"
                okText="Hapus"
                cancelText="Batal"
                onConfirm={() => {
                  const dataId = hero?.id;
                  DeleteApi({
                    url: `/landing-page/heros/`,
                    dataId,
                    refetch,
                  });
                }}
                disabled={btnDelete && btnDelete.type === "disabled"}
              >
                <Space style={{ cursor: "pointer" }}>
                  <span style={{ color: "red" }}>
                    {React.createElement(DeleteOutlined)}
                  </span>
                  <span style={{ color: "red" }}>Hapus</span>
                </Space>
              </Popconfirm>,

              <Space
                style={{
                  cursor:
                    btnEdit?.type === "disabled" ? "not-allowed" : "pointer",
                }}
                hidden={!btnEdit}
                onClick={() => {
                  if (btnEdit.type === "disabled") {
                    return;
                  } else {
                    navigate(`edit/${hero.id}`);
                  }
                }}
              >
                <span style={{ color: "#1677FF" }}>
                  {React.createElement(EditOutlined)}
                </span>
                <span style={{ color: "#1677FF" }}>Edit</span>
              </Space>,
            ]}
            extra={
              <div>
                <Image width={200} src={hero?.image} alt="image" />
              </div>
            }
          >
            <List.Item.Meta
              title={<p style={{ fontWeight: "bold" }}>{hero.title}</p>}
            />
            <div>
              <p>
                {expandedItems[hero.id] ? (
                  <>
                    {hero?.description}{" "}
                    <span
                      className="font-bold text-[#1677FF] cursor-pointer"
                      onClick={() => toggleExpand(hero?.id)}
                    >
                      Tampilkan lebih sedikit
                    </span>
                  </>
                ) : (
                  <>
                    {hero?.description?.length >= 100 ? (
                      <>
                        {hero?.description?.slice(0, 100)}...{" "}
                        <span
                          className="font-bold text-[#1677FF] cursor-pointer"
                          onClick={() => toggleExpand(hero?.id)}
                        >
                          Selengkapnya
                        </span>
                      </>
                    ) : (
                      hero?.description
                    )}
                  </>
                )}
              </p>

              <a href={hero?.link} style={{ color: "#1677FF" }}>
                Link: {hero?.link}
              </a>
              {
                (
                  roles.includes("admin_hrd")
                  || roles.includes("super_admin")
                  || roles.includes("admin_foundation")
                )
                &&
                <p className="mb-0">
                  Order: <span style={{ fontWeight: "bold" }}>{hero?.order}</span>
                </p>
              }
              <div>
                <Form form={form}>
                  <Form.Item name="is_active">
                    <Checkbox
                      checked={hero?.is_active}
                      disabled={btnEdit?.type === "disabled"}
                      onChange={(e) => {
                        editActive(hero?.id, e.target.checked);
                      }}
                    >
                      Aktif
                    </Checkbox>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </List.Item>
        )}
      />
    </>
  );
}
