import * as XLSX from "xlsx";

export default function useExportExcel({
  dataExport = [],
  fileName = "data",
  sheetName = "Sheet1",
}) {
  const exportToExcel = () => {
    try {
      if (!dataExport.length) {
        console.warn("No data to export!");
        return;
      }

      const worksheet = XLSX.utils.json_to_sheet(dataExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    } catch (error) {
      alert("Gagal export data!", error);
      console.error("Error exporting to Excel:", error);
    }
  };

  return exportToExcel;
}
